import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import validator from 'validator';
import { PermissionService } from '../../../permission.service';
import * as copy from 'copy-to-clipboard';

@Component({
  selector: 'app-schoolmaster',
  templateUrl: './schoolmaster.component.html',
  styleUrls: ['./schoolmaster.component.css'],

})
export class SchoolMaster implements OnInit {

  spinnerType = SPINNER.rectangleBounce;

  public userid: number;
  public btn_text: string = "Save";

  public resData: any;
  public savedData: any;
  public editData: any;

  public StateData: any = [];
  public CityData: any = [];
  public BoardData: any = [];

  public imageToUpload: any = [];

  public school_id: number = 0;
  public selectedstate: number = 0;
  public selectedcity: number = 0;
  public selectedboard: number = 0;

  public schoolname: string = "";
  public schoolcontact: string = "";
  public schoolemail: string = "";
  public schooladdress: string = "";
  public schoollogo: string = "";
  public schoolwebsite: string = "";
  public principalname: string = "";
  public principalemail: string = "";
  public principalmobile: string = "";
  public schoolmobile: string = "";
  public directoremail: string = "";
  public directorname: string = "";
  public directormobile: string = "";
  public contactperson: string = "";
  public desination: string = "";
  public mail: string = "";
  public mobile: string = "";

  public searchTerm: string;
  public pageSize: number = 10;
  public page: number = 1;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  public paymentschoolid: number = 0;
  public paymentschool: string = "";
  public paymentlink: string = "";

  public reg_amount: string = "";

  public enableCopy: boolean = false;

  dropdownSettings = {};

  @ViewChild('fileinput', { static: true }) private myInputVariable: ElementRef;

  constructor(
    private http: HttpClient,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private permission: PermissionService
  ) { }



  ngOnInit() {
    this.loader.start();
    this.userid = this.localstorage.get("userid");

    this.GetActions();
    this.BindState();
    this.BindBoard();
    this.GetSavedData();

    this.loader.stop();
  }


  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  Reset() {
    this.loader.start();

    this.btn_text = "Save";
    this.school_id =0;
    this.selectedstate = 0;
    this.selectedcity = 0;
    this.schoolname = "";
    this.selectedboard = 0;
    this.schoolcontact = "";
    this.schoolemail = "";
    this.schoolwebsite = "";
    this.schooladdress = "";
    this.principalname = "";
    this.principalemail = "";
    this.principalmobile = "";
    this.directorname = "";
    this.directoremail = "";
    this.directormobile = "";
    this.contactperson = "";
    this.desination = "";
    this.mail = "";
    this.mobile = "";
    this.schoollogo = "";

    this.CityData = [];

    this.loader.stop();
  }


  BindState() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.post('api/addstudentpartner/Bindstate', options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.StateData = this.resData.data;
        }
        else {
          this.StateData = [];
        }
      }
    )
  }

  BindCity() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedstate
    }

    this.http.post('api/addstudentpartner/BindCity', body, options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.CityData = this.resData.data;
        }
        else {
          this.CityData = [];
        }
      }
    )
  }

  BindBoard() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/addstudentpartner/BindBoard', options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.status == true) {
          this.BoardData = this.resData.data;
        }
        else {
          this.BoardData = this.resData.data;
        }
      }
    )
  }

  fileName = 'schoollist.xlsx';

  exportexcel(): void {
    this.loader.start();
    let element = document.getElementById('school-data-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
    this.loader.stop();
  }

  GetSavedData() {
    this.loader.start();
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/schoolmaster/GetSchoolData', options).subscribe(
      (data) => {
        this.loader.stop();
        this.resData = data;
        this.savedData = this.resData.data;
      }
    )
  }

  GetImageDetail(event) {

    let file = event.target.files[0];
    let fileList: FileList = event.target.files;

    if (file.type.includes("png") || file.type.includes("jpg") || file.type.includes("jpeg")) {

      this.imageToUpload = fileList[0];

    }
    else {
      Swal.fire("", "Please Select Image", "error");
      this.myInputVariable.nativeElement.value = "";
      this.imageToUpload = [];
    }
  }

  OnSubmit() {
    

    if (this.selectedstate == 0) {
      this.toaster.error('Please Select State', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }


    if (this.selectedcity == 0) {
      this.toaster.error('Please Select City', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.schoolname == "") {
      this.toaster.error('Please Enter School Name', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.selectedboard == 0) {
      this.toaster.error('Please Select Board', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.schoolcontact != "") {
      if (!validator.isMobilePhone(this.schoolcontact)) {
        this.toaster.error('Please Enter Valid School Mobile Number', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }


    if (this.schoolemail != "") {
      if (!validator.isEmail(this.schoolemail)) {
        this.toaster.error('Please Enter Valid School Email', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }

    if (this.schoolwebsite != "") {
      if (!validator.isURL(this.schoolwebsite)) {
        this.toaster.error('Please Enter Valid Website URL', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }



    if (this.schoolcontact != "") {
      if (!validator.isMobilePhone(this.schoolcontact)) {
        this.toaster.error('Please Enter Valid School Mobile Number', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }


    if (this.principalemail != "") {
      if (!validator.isEmail(this.principalemail)) {
        this.toaster.error('Please Enter Valid Principal Email', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }


    if (this.schoolmobile != "") {
      if (!validator.isMobilePhone(this.schoolmobile)) {
        this.toaster.error('Please Enter Valid Principal Mobile Number', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }



    if (this.directoremail != "") {
      if (!validator.isEmail(this.directoremail)) {
        this.toaster.error('Please Enter Valid Director Email', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }


    if (this.directormobile != "") {
      if (!validator.isMobilePhone(this.directormobile)) {
        this.toaster.error('Please Enter Valid Director Mobile Number', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }


    if (this.mail != "") {
      if (!validator.isEmail(this.mail)) {
        this.toaster.error('Please Enter Valid Email', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }


    if (this.mobile != "") {
      if (!validator.isMobilePhone(this.mobile)) {
        this.toaster.error('Please Enter Valid  Mobile Number', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }


    let input = new FormData();

    input.append("school_id", this.school_id.toString()),
    input.append("school_state", this.selectedstate.toString()),
    input.append("school_city", this.selectedcity.toString()),
    input.append("school_name", this.schoolname),
    input.append("school_board", this.selectedboard.toString()),
    input.append("school_phoneno", this.schoolcontact),
    input.append("school_email", this.schoolemail),
    input.append("school_website", this.schoolwebsite),
    input.append("school_address", this.schooladdress),
    input.append("school_principalname", this.principalname),
    input.append("school_principalemail", this.principalemail),
    input.append("school_principalmobile", this.principalmobile),
    input.append("school_directorname", this.directorname),
    input.append("school_directoremail", this.directoremail),
    input.append("school_directormobile", this.directormobile),
    input.append("school_contactname", this.contactperson),
    input.append("school_contactdesignation", this.desination),
    input.append("school_contactmail", this.mail),
    input.append("school_contactmobile", this.mobile),
    input.append("school_logo_file", this.imageToUpload)
    input.append("school_logo_path", this.schoollogo)

    this.loader.start();

    this.http.post('api/schoolmaster/SaveUpdateSchoolMaster', input).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 })
          this.GetSavedData();
          this.Reset();
          return;
        }
        else {
          this.toaster.warning(this.resData.message, '', { easeTime: 1000, timeOut: 3000 })
        }
      }
    )
  }

  OnEdit(School_Id: number) {
    this.loader.start();
    this.btn_text = "Update";

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/schoolmaster/EditSchoolData?school_id=' + School_Id, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        this.editData = this.resData.data

        this.school_id = this.editData.school_id;
        this.selectedstate = this.editData.school_state;
        this.BindCity();       
        this.selectedcity = this.editData.school_city;
        this.schoolname = this.editData.school_name;
        this.selectedboard = this.editData.school_board;
        this.schoolcontact = this.editData.school_phoneno;
        this.schoolemail = this.editData.school_email;
        this.schoolwebsite = this.editData.school_website;
        this.schooladdress = this.editData.school_address;
        this.principalname = this.editData.school_principalname;
        this.principalemail = this.editData.school_principalemail;
        this.principalmobile = this.editData.school_principalmobile;
        this.directorname = this.editData.school_directorname;
        this.directoremail = this.editData.school_directoremail;
        this.directormobile = this.editData.school_directormobile;
        this.contactperson = this.editData.school_contactname;
        this.desination = this.editData.school_contactdesignation;
        this.mail = this.editData.school_contactmail;
        this.mobile = this.editData.school_contactmobile;
        this.schoollogo = this.editData.school_logo_path;

      }
    )
  }

  OnDelete(School_Id: number) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    Swal.fire({
      text: 'Are you sure to delete ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.http.get('api/schoolmaster/DeleteSchoolData?school_id=' + School_Id, options).subscribe(
          (data) => {
            this.resData = data;
            this.loader.stop();
            if (this.resData.status == true) {
              this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 })
              this.GetSavedData();
              this.Reset();
              return;
            }
            else {
              this.toaster.warning(this.resData.message, '', { easeTime: 1000, timeOut: 3000 })
            }
          }
        )
      }
    })

  }

  SetSchoolDetails(School_Id: number, School_Name: string) {
    this.paymentschool = School_Name;
    this.paymentschoolid = School_Id;
  }

  Generate() {
   
    if (this.reg_amount == "" ) {
      this.toaster.error('Please Enter Amount', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    this.loader.start();

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/schoolmaster/Generate?school_id=' + this.paymentschoolid + '&amount=' + this.reg_amount, options).subscribe(
      (data) => {

        this.resData = data;
        this.loader.stop();
        this.enableCopy = true;
        this.paymentlink = this.resData;
      }
    )
  }

  CopyLink(url: string) {
    copy(url);
    this.toaster.success('Link Copied');
  }

  ResetAmount() {
    this.enableCopy = false;
    this.paymentlink = "";
    this.reg_amount = "";
  }
}
