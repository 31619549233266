import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { NgbDateStruct, NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { PermissionService } from '../../../permission.service';


@Component({
  selector: 'app-partneruniversitymaster',
  templateUrl: './partneruniversitymaster.component.html',
  styleUrls: ['./partneruniversitymaster.component.css'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]

})
export class PartnerUniversityMaster implements OnInit {

  spinnerType = SPINNER.rectangleBounce;

  public isDropdownDisabled = false;
  public name: string = "";
  public mobile: string = "";
  public email: string = "";
  public designation: string = "";

  public userid: number;
  public university: string = "";
  public academyids: string = "";
  public careerids: string = "";
  public btn_text: string = "Save";
  public parteneruniversityid: number=0;
  public universityid: number=0;
  public partnerlicenceid: number=0;

  public resData: any;
  public savedData: any;
  public editData: any;
  public controlData: any = [];
  public contactData: any = [];
  public academyData: any = [];
  public careerData: any = [];
  public partnerData: any = [];
  public usersData: any = [];
  public academycareersData: any = [];
  public partnerUniversityData: any = [];
  public selectedAcademyData: any = [];
  public selectedCareerData: any = [];
  public selectedUniversityData: any = [];

  public SelectedStartDate: Date;
  public SelectedEndDate: Date;
  public RenewStartDate: Date;
  public RenewEndDate: Date;
  public Start_Date: any;
  public End_Date: any;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  academydropdownSettings = {};
  careerdropdownSettings = {};
  universitydropdownSettings = {};

  constructor(
    private http: HttpClient,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private config: NgbDatepickerConfig,
    private permission: PermissionService
  ) {
    const current = new Date();
    config.minDate = {
      year: current.getFullYear(), month:
        current.getMonth() + 1, day: current.getDate()
    };
    config.outsideDays = 'hidden';
  }

  ngOnInit() {
    this.loader.start();
    this.userid = this.localstorage.get("userid");

    this.GetActions();
    this.GetPartnerUniversityLists();
    this.AddControlsData();
    this.GetAcademyLists();
    this.GetSavedData('Active');


    this.universitydropdownSettings = {
      singleSelection: true,
      idField: 'universityid',
      textField: 'universityname',
      enableCheckAll: false,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: true,
      defaultOpen: false,
      
    };

    this.academydropdownSettings = {
      singleSelection: false,
      idField: 'academyid',
      textField: 'academyname',
      enableCheckAll: false,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };

    this.careerdropdownSettings = {
      singleSelection: false,
      idField: 'careerid',
      textField: 'careername',
      enableCheckAll: false,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
    this.loader.stop();
  }

  Reset() {
    this.isDropdownDisabled = false;
    this.parteneruniversityid = 0;
    this.partnerlicenceid = 0;
    this.btn_text = 'Save';
    this.university = "";
    this.contactData = [];
    this.AddControlsData();
    this.selectedAcademyData = [];
    this.selectedCareerData = [];
    this.partnerUniversityData = [];
    this.selectedUniversityData = [];
    this.SelectedStartDate = null;
    this.SelectedEndDate = null;
    this.RenewStartDate = null;
    this.RenewEndDate = null;
    this.Start_Date = null;
    this.End_Date = null;
    this.GetPartnerUniversityLists();
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  AddControlsData() {
    this.contactData.push({ "partner_university_userid":0,"name": "", "mobile": "", "email": "", "designation": "" });
  }

  Remove(_Index: number) {
    this.contactData.splice(_Index, 1);
  }

  GetPartnerUniversityLists() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/partneruniversitymaster/GetPartnerUniversityData', options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        this.partnerUniversityData = this.resData.data;
      }
    )
  }

  GetAcademyLists() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/partneruniversitymaster/GetAcademyData', options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        this.academyData = this.resData.data;
      }
    )
  }

  OnAdacemyChange() {
    this.academyids = "";

    if (this.selectedAcademyData.length > 0) {
      this.selectedAcademyData.sort()
    }

    for (var i = 0; i < this.selectedAcademyData.length; i++) {
      if (this.academyids === "") {
        this.academyids = this.selectedAcademyData[i].academyid;
      }
      else {
        this.academyids = this.academyids + ',' + this.selectedAcademyData[i].academyid;
      }
    }
    this.GetCareerLists(this.academyids);
  }

  OnCareerChange() {
    this.careerids = "";
    
    
    for (var i = 0; i < this.selectedCareerData.length; i++) {
      if (this.careerids === "") {
        this.careerids = this.selectedCareerData[i].careerid;
      }
      else {
        this.careerids = this.careerids + ',' + this.selectedCareerData[i].careerid;
      }
    }
  }

  GetCareerLists(_AcademyIds: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/partneruniversitymaster/GetCareerData?academyids=' + _AcademyIds, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.careerData = this.resData.data;
        }
        else {
          this.careerData = [];
        }
      }
    )
  }

  OnUniversityChange() {
    this.universityid = 0;

    for (var i = 0; i < this.selectedUniversityData.length; i++) {
      if (this.universityid === 0) {
        this.universityid = this.selectedUniversityData[i].universityid;
      }  
    }
  }

  GetRenewId(_PartnerUniversityId: number, _PartnerLicenceId: number) {
    this.parteneruniversityid = _PartnerUniversityId;
    this.partnerlicenceid = _PartnerLicenceId;
  }

  RenewLicence() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.RenewStartDate == null || this.RenewStartDate == undefined) {
      this.toaster.error('', 'Please Select Start Date', { easeTime: 1000, timeOut: 3000 });
      return;
    }
    else {
      this.Start_Date = this.RenewStartDate.toISOString().slice(0, 10);
    }
    if (this.RenewEndDate == null || this.RenewEndDate == undefined) {
      this.toaster.error('', 'Please Select End Date', { easeTime: 1000, timeOut: 3000 });
      return;
    }
    else {
      this.End_Date = this.RenewEndDate.toISOString().slice(0, 10);
    }

    var data = {
      "partnetuniversityid": this.parteneruniversityid,
      "partnerlicenceid": this.partnerlicenceid,
      "userid": this.userid,
      "RenewStartDate": this.Start_Date,
      "RenewEndDate": this.End_Date
    }

    let body = JSON.stringify(data);

    this.loader.start();
    this.http.post('api/partneruniversitymaster/RenewPartnerLicence', body, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.GetSavedData('Active');
          this.Reset();
          $('#RenewModal').click();
          return;
        }
        else {
        }
      }
    )
  }

  ChangeTabs(_IsActive: string) {
    $(document).ready(function () {
      if (_IsActive === "InActive") {
        $('#_activetab').removeClass('active');
        $('#_inactivetab').addClass('active');
      }
      else {
        $('#_activetab').addClass('active');
        $('#_inactivetab').removeClass('active');
      }
    });
  }

  GetSavedData(_IsActive: string) {
    this.ChangeTabs(_IsActive);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/partneruniversitymaster/GetPartnerData?_IsActive=' + _IsActive, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.partnerData = this.resData.data;
        }
        else {
          this.partnerData = [];
        }
      }
    )
  }

  GetUserData(_PartnerUniversityId: number) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/partneruniversitymaster/GetUserDetailByPartnerId?partnetuniversityid=' + _PartnerUniversityId, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.usersData = this.resData.data;
        }
        else {
          this.usersData = [];
        }
      }
    )
  }

  GetAllotedAcademyCareersData(_PartnerUniversityId: number) {
    this.parteneruniversityid = _PartnerUniversityId;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/partneruniversitymaster/GetAcademyDetailByPartnerId?partnetuniversityid=' + _PartnerUniversityId, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.academycareersData = this.resData.data;
        }
        else {
          this.academycareersData = [];
        }
      }
    )
  }

  DeleteCareerByAcademyId(_AcademyInterestId: number, _CareerId: number, _AcademyInterestDetailid: number) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/partneruniversitymaster/DeleteAcademyData?academyinterestid=' + _AcademyInterestId + '&careerid=' + _CareerId + '&academyinterestdetailid=' + _AcademyInterestDetailid, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.GetAllotedAcademyCareersData(this.parteneruniversityid);
          this.toaster.success('Removed Successful', '', { easeTime: 1000, timeOut: 3000 });
        }
        else {
          this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
        }
      }
    )
  }

  OnSubmit() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.universityid == 0 || this.universityid == undefined) {
      this.toaster.error('', 'Please Select University', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    for (var i = 0; i <= this.contactData.length - 1; i++) {
      if (this.contactData[i].name == "") {
        this.toaster.error('', 'please enter Name in Sl. No : ' + (i + 1), { easeTime: 1000, timeOut: 3000 });
        return;
      }
      if (this.contactData[i].mobile == "") {
        this.toaster.error('', 'please enter Mobile in Sl. No : ' + (i + 1), { easeTime: 1000, timeOut: 3000 });
        return;
      }
      if (!this.contactData[i].mobile.match('^[0-9]{10}$')) {
        this.toaster.error('', 'please enter valid Mobile No. in Sl. No : ' + (i + 1), { easeTime: 1000, timeOut: 3000 });
        return;
      }
      if (this.contactData[i].email == "") {
        this.toaster.error('', 'please enter Email in Sl. No : ' + (i + 1), { easeTime: 1000, timeOut: 3000 });
        return;
      }
      if (!this.contactData[i].email.match('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')) {
        this.toaster.error('', 'please enter valid Email in Sl. No : ' + (i + 1), { easeTime: 1000, timeOut: 3000 });
        return;
      }
      if (this.contactData[i].designation == "") {
        this.toaster.error('', 'please enter Designation in Sl. No : ' + (i + 1), { easeTime: 1000, timeOut: 3000 });
        return;
      }
    }


    if (this.academyids == "" || this.academyids == undefined) {
      this.toaster.error('', 'Please Select Acadmy', { easeTime: 1000, timeOut: 3000 });
      return;
    }
    if (this.careerids == "" || this.careerids == undefined) {
      this.toaster.error('', 'Please Select Career', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.SelectedStartDate == null || this.SelectedStartDate == undefined) {
      this.toaster.error('', 'Please Select Start Date', { easeTime: 1000, timeOut: 3000 });
      return;
    }
    else {
      this.Start_Date = this.SelectedStartDate.toISOString().slice(0, 10);
    }
    if (this.SelectedEndDate == null || this.SelectedEndDate == undefined) {
      this.toaster.error('', 'Please Select End Date', { easeTime: 1000, timeOut: 3000 });
      return;
    }
    else {
      this.End_Date = this.SelectedEndDate.toISOString().slice(0, 10);
    }

    var savedata = {
      "universityid": this.universityid,
      "partnerlicenceid": this.partnerlicenceid,
      "userid": this.userid,
      "contactData": JSON.stringify(this.contactData),
      "academyinterestdetailids": this.careerids,
      "start_date": this.Start_Date,
      "end_date": this.End_Date,
      "action_type": this.btn_text
    }

    let body = JSON.stringify(savedata);

    this.loader.start();
    this.http.post('api/partneruniversitymaster/SavePartnerUniversityData', body, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.GetSavedData('Active');
          this.Reset();
          this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          return;
        }
        else {
          this.toaster.warning(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
        }
      }
    )
  }

  OnEdit(_PartnerUniversityId: number) {
    
    this.btn_text = 'Update';
    this.parteneruniversityid = _PartnerUniversityId;
    this.selectedUniversityData = [];
    this.partnerUniversityData = [];

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/partneruniversitymaster/EditAcademyDetailByPartnerId?partnetuniversityid=' + _PartnerUniversityId, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.editData = this.resData.data;

          var temparr = [];
          temparr.push({ "universityid": this.editData[0].licencedata.universityid, "universityname": this.editData[0].licencedata.universityname });

          this.partnerUniversityData = temparr;
          this.selectedUniversityData = this.partnerUniversityData;
          this.OnUniversityChange();
          this.isDropdownDisabled = true;

          this.SelectedStartDate = new Date(this.editData[0].licencedata.startdate);
          this.SelectedEndDate = new Date(this.editData[0].licencedata.expirydate);
          this.partnerlicenceid = this.editData[0].licencedata.partnerlicenceid;

          this.contactData = this.editData[0].userdata;
          this.selectedAcademyData = this.editData[0].academydata;
          this.OnAdacemyChange();
          this.selectedCareerData = this.editData[0].careerdata;
          this.OnCareerChange();
        }
        else {
          this.editData = [];
        }
      }
    )
  }

  OnDelete(_PartnerUniversityId: number) {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    Swal.fire({
      text: 'Are you sure to delete ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.http.get('api/partneruniversitymaster/DeleteAcademyDetailByPartnerId?partnetuniversityid=' + _PartnerUniversityId, options).subscribe(
          (data) => {
            this.loader.stop();
            
            this.resData = data;         
            if (this.resData.status == true) {
              this.toaster.success('Deleted', '', { easeTime: 1000, timeOut: 3000 });
              this.GetSavedData('Active');
              this.Reset();
              return;
            }
            else {
              this.toaster.warning('Something went wrong', '', { easeTime: 1000, timeOut: 3000 });
            }
          }
        )
      }
    })

  }

}
