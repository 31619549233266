import { Component, OnInit, Input, PipeTransform, Pipe } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { PermissionService } from '../../../permission.service';
import { find, pull, filter, times, constant, debounce, set, get, keyBy, reduce, cloneDeep, sortedUniq } from 'lodash';
import * as _ from 'lodash';

@Component({
  selector: 'app-articlesreport',
  templateUrl: './articlesreport.component.html',
  styleUrls: ['./articlesreport.css']
})
export class ArticlesReportManager implements OnInit {

  public s_date: any;
  public e_date: any;

  public page = 1;
  public pageSize = 10;

  public page1 = 1;
  public pageSize1 = 10;

  year: number = 0;
  month: number = 0;
  date: number = 0;

  public keyword1: string = '';

  public searchTerm: string = '';

  resData: any = [];
  getarticledata: any = [];
  GetArticleDetails: any = [];

  public SelectedDate27: any;
  public SelectedEndDate28: any;

  spinnerType = SPINNER.rectangleBounce;
  monthwisegraphdata: any = [];
  regcountmonthwise: number = 0;
  regcountstatewise: number = 0;

  DescriptionDetail: string = '';

  studentDetail: any = [];
  studentDetailData: any = [];
  heading: string = '';

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;


  constructor(private http: HttpClient,
    private permission: PermissionService, private router: Router, private localstorage: LocalStorageService, private toaster: ToastrService,
    private loader: NgxUiLoaderService, private config: NgbDatepickerConfig) {

    const currentDate = new Date();
    config.maxDate = { year: currentDate.getFullYear(), month: currentDate.getMonth(), day: currentDate.getDate() };
    config.outsideDays = 'hidden';

    this.date = new Date().getDate();
    this.year = new Date().getFullYear();
    this.month = parseInt(new Date().toISOString().slice(0, 10).split('-')[1]);

  }

  ngOnInit() {
    this.GetActions();
    this.GetArticleReport();

  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  /*name of the excel-file which will be downloaded. */
  fileName = 'ArticlesReportSheet.xlsx';

  exportexcel(tagid: string): void {
    
    /* table id is passed over here */
    let element = document.getElementById(tagid);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }




  GetArticleReport() {
    

    if (this.keyword1 == "" || this.keyword1 == undefined) {
      this.keyword1 = "";
    }

    if (this.SelectedDate27 == undefined || this.SelectedDate27 == null) {
      this.s_date = "1900-01-01";
    }
    else {
      this.s_date = this.SelectedDate27.year + '-' + this.SelectedDate27.month + '-' + this.SelectedDate27.day;

    }
    if (this.SelectedEndDate28 == undefined || this.SelectedEndDate28 == null) {
      this.e_date = "1900-01-01";
    }
    else {
      this.e_date = this.SelectedEndDate28.year + '-' + this.SelectedEndDate28.month + '-' + this.SelectedEndDate28.day;
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };


    this.loader.start();

    this.http.get('api/reports/GetArticleData?keyword=' + this.keyword1 + '&startdate=' + this.s_date.toString() + '&enddate=' + this.e_date.toString(), options).subscribe(
      (data) => {
        this.loader.stop();

        this.page = 1;

        this.getarticledata = data;
        if (this.getarticledata.Status == true) { this.GetArticleDetails = this.getarticledata.data; this.loader.stop(); }
        else { this.GetArticleDetails = []; this.loader.stop(); }

      }
    )
  }

  setPageNo() {
    this.page = 1;
  }


  GetArticlesDetail(id: number) {

    
    this.DescriptionDetail = _.filter(this.GetArticleDetails, { 'id': parseInt(id.toString()) })[0].description;

  }

  GetArticlesStudentDetail(id: number, heading: string) {
    this.heading = heading;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };


    this.loader.start();

    this.http.get('api/reports/GetArticleStudentData?id=' + id, options).subscribe(
      (data) => {
        this.loader.stop();
        
        this.page1 = 1;

        this.studentDetail = data;
        if (this.studentDetail.Status == true) { this.studentDetailData = this.studentDetail.detaildata; this.loader.stop(); }
        else { this.studentDetailData = []; this.loader.stop(); }

      }
    )

  }


  ResetArticleReport() {

    this.SelectedDate27 = null;
    this.SelectedEndDate28 = null;
    this.keyword1 = '';
    this.searchTerm = '';
    this.GetArticleReport();
  }


}
