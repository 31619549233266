import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import * as copy from 'copy-to-clipboard';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbTimepickerConfig, NgbTimeStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { PermissionService } from '../../../permission.service';

import { fromEvent, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, NgbTimepickerConfig]
})


export class SubscriptionManager implements OnInit
{
  public page: number = 1;
  public pageSize: number = 10;
  public resData: any = [];

  public Subscriptionid: number = 0;
  public StateIds: string = "";
  public CityIds: string = "";
  public SchoolIds: string = "";
  public ClassId: number = 0;
  public StreamId: number = 0;
  public StudentId: number = 0;
  public Message: string = "";
  public IsParent: boolean = false;
  public IsStudent: boolean = false;
  public IsSchool: boolean = false;
  public SubscriptionData: any = [];
  public Details: any = {};
  public StateData: any = [];
  public CityData: any = [];
  public SchoolData: any = [];
  public StudentData: any = [];
  public ClassData: any = [];
  public ButtonText: string = 'Save';
  public SelectedStudent: any = [];
  public SelectedStream: any = [];
  public SelectedClass: any = [];
  public AllState: boolean = false;
  public AllCity: boolean = false;
  public AllSchool: boolean = false;
  public classid: string = "";
  public AllClass: boolean = false;
  public streamid: string = "";
  public AllStream: boolean = false;
  public studentid: string = "";
  AllStudent: boolean = false;
  public ShowDiv: boolean = false;

  public GetFilterData: any;
  public GetCityData: any;
  public GetSchoolData: any;
  public HeaderData: any=[];
  public GetSaveData: any = [];
  public getData: any = [];
  public EditSubsscriptionData: any = [];
  public subscriptiondata: any = [];
  public schid: string = "";
  public showstream: number = 0;
  public starttime: NgbTimeStruct;
  public endtime: NgbTimeStruct;
  public startdate: Date;
  public enddate: Date;
  public s_date: any;
  public e_date: any;
  public todaydate: any;
  testimonial: string = "";
  StreamDetails: any = [];
  StreamData: any = [];
  GetStudentData: any = [];
  Detail: any = [];
  statedat: any = [];
  showNotif: boolean = true;
 
  Instruction: any;
  notificationsubject: string;
  notificationtitle: string;
  search: string = "";
  subrecord: number = 0;
  ckeConfig: any;

  base64Conveterbtn: string = "Upload";
  base64Imagepath: string = "";

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  @ViewChild('base64conveter', { static: true }) private base64conveter: ElementRef;

  constructor(private http: HttpClient, private permission: PermissionService, private router: Router, private localstorage: LocalStorageService, private toaster: ToastrService, private loader: NgxUiLoaderService, config: NgbTimepickerConfig, private config1: NgbDatepickerConfig) {
    config.seconds = false;
    config.spinners = false;
    config.meridian = true;
    const current = new Date();
    config1.minDate = {
      year: current.getFullYear(), month:
        current.getMonth() + 1, day: current.getDate()
    };

    config1.maxDate = {
      year: current.getFullYear() + 25,
      month: current.getMonth() + 1,
      day: current.getDate()
    };

    config1.outsideDays = 'hidden';
  }


  ngOnInit() {
    this.GetActions();
    this.GetClass();
    this.GetStream();
    this.BindState();
    this.GetSavedData();
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }


  onUploadImage(event) {
    

    if (event.target.files.length > 0) {
      const fileReader = new FileReader();
      let imageToUpload = event.target.files.item(0);
      this.imageToBase64(fileReader, imageToUpload)
        .subscribe(base64image => {
          this.base64Imagepath = base64image
          this.base64Conveterbtn = "Copy link";
        });
    }
  }

  onCopyImage() {
    copy(this.base64Imagepath);
    this.toaster.success('Image path copied');
    this.base64conveter.nativeElement.value = "";
    this.base64Imagepath = "";
    this.base64Conveterbtn = 'Upload';
  }

  imageToBase64(fileReader: FileReader, fileToRead: File): Observable<string> {
    fileReader.readAsDataURL(fileToRead);
    return fromEvent(fileReader, 'load').pipe(pluck('currentTarget', 'result'));
  }

  ShowInst(msg) {
    this.Instruction = msg;
  }
  //Get Saved subscription data in table
  GetSavedData() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.http.get('api/Subscription/getsubscriptiondata?page=' + this.page + '&pagesize=' + this.pageSize, options).subscribe(
      (data) => {
        
        this.getData = data;
        if (this.getData.Status == true) {
          this.GetSaveData = this.getData.data;
          this.subrecord = this.getData.records;
        }
        else {
          this.GetSaveData = [];
          this.subrecord = 0;
        }

      }
    )
  }

  BindState() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.statedat = [];

    var tmpclass: any = [];
    this.http.post('api/LiveWebinar/Bindstate', options).subscribe(
      (data) => {
        this.statedat = data;
        if (this.statedat.Status == true) {
          this.StateData = this.statedat.data;
        }
        else {
          this.StateData = this.statedat.data;
        }
      }
    )
  }

  GetClass() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/LiveWebinar/GetClass', options).subscribe(
      (data) => {
        this.Details = data;
        if (this.Details.status == true) {
          this.ClassData = this.Details.data;
        }
        else {
          this.toaster.error(this.Details.message.toString(), '', { easeTime: 1000, timeOut: 3000 })
        }
      }
    )
  }

  //get stream
  GetStream() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.http.get('api/LiveWebinar/getStream', options).subscribe(
      (data) => {
        this.StreamDetails = data;
        if (this.StreamDetails.status == true) {
          this.StreamData = this.StreamDetails.data;
        }
        else {
          this.toaster.error(this.StreamDetails.message.toString(), '', { easeTime: 1000, timeOut: 3000 })
        }
      }
    )
  }

  //Get all data for bind dropdowns (Not in use)
  GetData()
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/Subscription/GetData', options).subscribe(
      (data) => {
        this.Details = data;

        if (this.Details.status = true)
        {
          this.StateData = this.Details.statedata;
          this.CityData = this.Details.citydata;
          this.SchoolData = this.Details.schooldata;
          this.ClassData = this.Details.classdata;
          //this.StudentData = this.Details.studentdata;
          
            this.GetSavedData();
        }
        else {
          this.toaster.error(this.Details.message.toString(), '', { easeTime: 1000, timeOut: 3000 })
            }



            if (this.EditSubsscriptionData.Status == true) {
                this.StateIds = this.EditSubsscriptionData.data[0].stateid;
                var tmpstateId = this.EditSubsscriptionData.data[0].stateid.split(",");
                for (var i = 0; i < this.StateData.length; i++) {
                    for (var j = 0; j < tmpstateId.length; j++) {
                        if (this.StateData[i].stateId == tmpstateId[j]) {
                            this.StateData[i].selected = true;


                        }
                    }
                }
                this.onChangeOfMultiCheckBoxToGetCity();

                
               




               // this.onChangeOfMultiCheckBoxToGetSchool();
            }
            else {
            }


            //if (this.EditSubsscriptionData.Status == true) {
            //    this.StateIds = this.EditSubsscriptionData.data[0].stateid;
            //    var tmpstateId = this.EditSubsscriptionData.data[0].stateid.split(",");
            //    for (var i = 0; i < this.StateData.length; i++) {
            //        for (var j = 0; j < tmpstateId.length; j++) {
            //            if (this.StateData[i].stateId == tmpstateId[j]) {
            //                this.StateData[i].selected = true;


            //            }
            //        }
            //    }
              
            //     this.onChangeOfMultiCheckBoxToGetSchool();
            //}
            //else {
            //}


           

      }
    )
  }

  //On change of class Students are bind (Not in use)

  onChangeOfdropdown()
    {
    var classid = 0;
    var stream = "";
    
    if (this.SelectedClass == undefined || this.SelectedClass == 0) {
      classid = 0;
      this.SelectedStream = 0;
    }
    else if (this.SelectedClass == 1 || this.SelectedClass == 2 || this.SelectedClass == 3) {
      
      this.showstream = 0;
      this.SelectedStream = 0;

    }
    else {
      this.showstream = 1;
      classid = parseInt(this.SelectedClass);
      
    }
        

    if (this.SelectedStream == "" || this.SelectedStream == undefined || this.SelectedStream == null) {
      stream = "";
    }
    else {
      stream = this.SelectedStream;
    }
    if ((this.SchoolIds.length) > 0) {
      this.SelectedStudent = 0;
   
    }
    else {
      this.schid = this.SchoolIds;
    }
      

    
      let headers = new HttpHeaders({ 'Content-Type': 'application/json'});
      let options = { headers: headers };
      this.http.get('api/Subscription/getstudentfilter?SchoolId=' + this.SchoolIds + '&CityId=' + this.CityIds + '&StateId=' + this.StateIds + '&ClassId=' + this.SelectedClass + '&StreamId=' + this.SelectedStream, options).subscribe(
      //this.http.get('api/Subscription/GetFilterData?ClassId=' + classid + '&Stream=' + stream +'&School='+this.schid , options).subscribe(
        (data) => {
          this.GetFilterData = data;

          if (this.GetFilterData.Status == true) {
            this.StudentData = this.GetFilterData.schooldata;
          }
          else {
            this.StudentData = [];
          }
       
        },
        (err) => {
       
        }
      );

    }

  //Show div on change of check box (display the class,stream and student list)
  onChangeOfCheckBox()
  {
    if (this.IsParent == true || this.IsStudent == true) {
      this.ShowDiv = true;
      this.showstream = 0;
    }
    else {
      this.ShowDiv = false;
    }
  }


  //Select All function for State
  SelectAllState()
  {
      this.StateIds = "";
      if (this.AllState === true)
      {
        for (var i = 0; i < this.StateData.length; i++) {
          this.StateData[i].selected = true;
        }
        this.StateIds = "0";
      }
      else
      {
        for (var i = 0; i < this.StateData.length; i++)
        {
          this.StateData[i].selected = false;
        }
      }
  }

  //convert Selected state in String format
  SelectedState()
  {
       this.StateIds = "";
       var count = 0;
       for (var i = 0; i < this.StateData.length; i++) {

         if (this.StateData[i].selected === true) {

           if (this.StateIds === '') {
             this.StateIds = this.StateData[i].stateId;
              count++;
            }
            else {
             this.StateIds = this.StateIds + ',' + this.StateData[i].stateId;
              count++;
            }
          }
        }
       if (this.StateData.length === count) {
         this.AllState = true;
         this.StateIds = "0";
        }
        else {
         this.AllState = false;
        }

  }


  //Select All function for City
  SelectAllCity() {
    this.CityIds = "";
    if (this.AllCity === true) {
      for (var i = 0; i < this.CityData.length; i++) {
        this.CityData[i].selected = true;       
      }
      this.CityIds = "0";
    }
    else {
      for (var i = 0; i < this.CityData.length; i++) {
        this.CityData[i].selected = false;
      }
    }

  }

  //convert Selected city in String format
  SelectedCity()
  {
    this.CityIds = "";
    var count = 0;
    for (var i = 0; i < this.CityData.length; i++) {

      if (this.CityData[i].selected === true) {

        if (this.CityIds === '') {
          this.CityIds = this.CityData[i].cityid;
          count++;
        }
        else {
          this.CityIds = this.CityIds + ',' + this.CityData[i].cityid;
          count++;
        }
      }
    }
    if (this.CityData.length === count) {
      this.AllCity = true;
      this.CityIds = "0";
    }
    else {
      this.AllCity = false;
    }

  }

  //Select All function for City
  SelectAllSchool() {
    this.SchoolIds = "";
    if (this.AllSchool === true) {
      for (var i = 0; i < this.SchoolData.length; i++) {
        this.SchoolData[i].selected = true;       
      }
      this.SchoolIds = "0";
    }
    else {
      for (var i = 0; i < this.SchoolData.length; i++) {
        this.SchoolData[i].selected = false;
      }
    }

  }

  //convert Selected city in String format
  SelectedSchool() {

    this.SchoolIds = "";
    var count = 0;
    for (var i = 0; i < this.SchoolData.length; i++) {

      if (this.SchoolData[i].selected === true) {

        if (this.SchoolIds === '') {
          this.SchoolIds = this.SchoolData[i].schoolid;
          count++;
        }
        else {
          this.SchoolIds = this.SchoolIds + ',' + this.SchoolData[i].schoolid;
          count++;
        }
      }
    }
    if (this.SchoolData.length === count) {
      this.AllSchool = true;
      this.SchoolIds = "0";
    }
    else {
      this.AllSchool = false;
    }

  }

  //Select All function for class
  SelectAllClass() {
    this.classid = "";
    if (this.AllClass === true) {
      for (var i = 0; i < this.ClassData.length; i++) {
        this.ClassData[i].selected = true;     
      }
      this.showstream = 1;
      this.classid = '0';
    }
    else {
      for (var i = 0; i < this.ClassData.length; i++) {
        this.ClassData[i].selected = false;
      }
      this.showstream = 0;
      this.StudentData = [];
      this.AllStream = false;
      this.StreamData.forEach(item => { item.selected = false; });
    }
  }

  //Select All function for stream
  SelectAllStream() {
    this.streamid = "";
    if (this.AllStream === true) {
      for (var i = 0; i < this.StreamData.length; i++) {
        this.StreamData[i].selected = true;      
      }
      this.streamid = "0";
    }
    else {
      for (var i = 0; i < this.StreamData.length; i++) {
        this.StreamData[i].selected = false;
      }
    }
  }

  getSelectedClass() {
    this.classid = "";
    var count = 0;
    for (var i = 0; i < this.ClassData.length; i++) {
      if (this.ClassData[i].selected === true) {
        if (this.classid === '') {
          this.classid = this.ClassData[i].classid;
          count++;
        }
        else {
          this.classid = this.classid + ',' + this.ClassData[i].classid;
          count++;
        }
      }
      else {
        this.showstream = 0;
        this.AllStream = false;
        this.StreamData.forEach(item => { item.selected = false; });
      }
    }

    for (var i = 0; i < this.ClassData.length; i++) {
      if (this.ClassData[i].selected === true) {
        if (this.ClassData[i].classid == 1 || this.ClassData[i].classid == 2 || this.ClassData[i].classid == 3) {
          this.showstream = 0;
          this.streamid = "0";
          this.AllStream = false;
          this.StreamData.forEach(item => { item.selected = false; });
        }
        else {
          this.showstream = 1;
        }
      }
    }

    if (this.ClassData.length === count) {
      this.AllClass = true;
      this.classid = "0";
    }
    else {
      this.AllClass = false;
      this.AllStream = false;
      this.StreamData.forEach(item => { item.selected = false; });
      this.StudentData = [];
    }

  }

  //get selected stream
  //get stream
  getSelectedStream() {
    this.streamid = "";
    var count = 0;
    for (var i = 0; i < this.StreamData.length; i++) {
      if (this.StreamData[i].selected === true) {
        if (this.streamid === '') {
          this.streamid = this.StreamData[i].streamid.toString();
          count++;
        }
        else {
          this.streamid = this.streamid + ',' + this.StreamData[i].streamid;
          count++;
        }
      }
    }

    if (this.StreamData.length === count) {
      this.AllStream = true;
      this.streamid = "0";
    }
    else {
      this.AllStream = false;
    }
  }

  onChangeOfMultiCheckBoxToGetCity() {
    this.AllSchool = false;
    if (this.StateIds == null) { this.StateIds = ""; }
    if (this.CityIds == null) { this.CityIds = ""; }
    if (this.StateIds != "") {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.http.get('api/Subscription/getcityfilter?StateId=' + this.StateIds, options).subscribe(
        (data) => {
          this.GetCityData = data;
          if (this.GetCityData.Status == true) {
            this.CityData = this.GetCityData.citydata;
          }


          if (this.EditSubsscriptionData.Status != undefined) {
            if (this.EditSubsscriptionData.Status == true) {
              this.CityIds = this.EditSubsscriptionData.data[0].cityid;

              if (this.CityIds == '0') {
                this.AllCity = true;
                this.CityData.forEach(item => { item.selected = true; });
              }
              else {
                var tmpcityid = this.EditSubsscriptionData.data[0].cityid.split(",");

                for (var j = 0; j < tmpcityid.length; j++) {
                  for (var i = 0; i < this.CityData.length; i++) {
                    if (tmpcityid[j] === this.CityData[i].cityid.toString()) {
                      this.CityData[i].selected = true;
                    }
                  }
                }
              }


              this.onChangeOfMultiCheckBoxToGetSchool();
            }
            else {
            }
          }
        },
        (err) => {

        }
      );
    }
    else {
      this.CityData = [];
      this.SchoolData = [];
      this.StudentData = [];
      this.AllCity = false;
      this.AllSchool = false;
    }

  }

  onChangeOfMultiCheckBoxToGetSchool() {
    this.AllSchool = false;
    if (this.StateIds == null) { this.StateIds = ""; }
    if (this.CityIds == null) { this.CityIds = ""; }

    if (this.StateIds != "" && this.CityIds != "") {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.http.get('api/Subscription/getschoolfilter?StateId=' + this.StateIds + '&CityId=' + this.CityIds, options).subscribe(
        (data) => {
          this.GetSchoolData = data;
          if (this.GetSchoolData.Status == true) {
            this.SchoolData = this.GetSchoolData.schooldata;
          }

          if (this.GetCityData.Status == true) {
            this.CityData = this.GetCityData.citydata;
          }


          if (this.EditSubsscriptionData.Status != undefined) {
            if (this.EditSubsscriptionData.Status == true) {
              this.SchoolIds = this.EditSubsscriptionData.data[0].schoolid;

              if (this.SchoolIds == '0') {
                this.AllSchool = true;
                this.SchoolData.forEach(item => { item.selected = true; });
              }
              else {
                var tmpschoolid = this.EditSubsscriptionData.data[0].schoolid.split(",");

                for (var i = 0; i < this.SchoolData.length; i++) {
                  for (var j = 0; j < tmpschoolid.length; j++) {
                    if (this.SchoolData[i].schoolid == tmpschoolid[j]) {
                      this.SchoolData[i].selected = true;

                    }
                  }
                }
              }
            }
          }
        },
        (err) => {

        }
      );
    }
    else {
      this.SchoolData = [];
      this.StudentData = [];
      this.AllSchool = false;
    }
  }

  onChangeOfMultiCheckBoxToGetStudent() {
    this.AllStudent = false;
    if (this.StateIds == null) { this.StateIds = ""; }
    if (this.CityIds == null) { this.CityIds = ""; }
    if (this.SchoolIds == null) { this.SchoolIds = ""; }
    if (this.classid == null) { this.classid = ""; }
    if (this.streamid == null) { this.streamid = ""; }
    if (this.SchoolIds != "" && this.classid != "") {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.http.get('api/Subscription/getstudentfilter?SchoolId=' + this.SchoolIds + '&CityId=' + this.CityIds + '&StateId=' + this.StateIds + '&ClassId=' + this.classid + '&StreamId=' + this.streamid, options).subscribe(
        (data) => {
          this.GetStudentData = data;

          if (this.GetStudentData.Status == true) {
            this.StudentData = this.GetStudentData.schooldata;
          }
          else {
            this.StudentData = [];
          }

          if (this.EditSubsscriptionData.Status != undefined) {
            if (this.EditSubsscriptionData.Status == true) {

              this.studentid = this.EditSubsscriptionData.data[0].studentid;

              if (this.studentid == '0') {
                this.AllStudent = true;
                this.StudentData.forEach(item => { item.selected = true; });
              }
              else {
                var tmpschoolid = this.EditSubsscriptionData.data[0].studentid.split(",");
                for (var i = 0; i < this.StudentData.length; i++) {
                  for (var j = 0; j < tmpschoolid.length; j++) {
                    if (this.StudentData[i].studentid == tmpschoolid[j]) {
                      this.StudentData[i].selected = true;

                    }
                  }
                }
              }
              
            }            
          }
        }
      );
    }
    else {
      this.StudentData = [];
      this.AllStudent = false;
    }
  }

  SelectAllStuent() {
    this.studentid = "";
    if (this.AllStudent === true) {
      for (var i = 0; i < this.StudentData.length; i++) {
        this.StudentData[i].selected = true;
        if (this.studentid === '') {
          this.studentid = this.StudentData[i].studentid;
        }
        else {
          this.studentid = this.studentid + ',' + this.StudentData[i].studentid;
        }
      }
      this.studentid = '0';
    }
    else {
      for (var i = 0; i < this.StudentData.length; i++) {
        this.StudentData[i].selected = false;
      }
    }

  }

  getSelectedStudent() {
    this.studentid = "";
    var count = 0;
    for (var i = 0; i < this.StudentData.length; i++) {

      if (this.StudentData[i].selected === true) {

        if (this.studentid === '') {
          this.studentid = this.StudentData[i].studentid;
          count++;
        }
        else {
          this.studentid = this.studentid + ',' + this.StudentData[i].studentid;
          count++;
        }
      }
    }
    if (this.StudentData.length === count) {
      this.AllStudent = true;
      this.studentid = '0';
    }
    else {
      this.AllStudent = false;
    }

  }

  //Delete Subscription Data
  DeleteData(i:number, id:number) {
    if (this.StateIds == null) { this.StateIds = ""; }
    if (this.CityIds == null) { this.CityIds = ""; }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    Swal.fire({
      text: 'Are you sure to delete this record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
          this.http.get('api/Subscription/deletesubscription?SubscriptionId=' + id + '&createdBy=' + this.localstorage.get("userid"), options).subscribe(
        (data) => {
          this.subscriptiondata = data;
          if (this.subscriptiondata.status == true) {
            Swal.fire("", "Deleted Successfully", "success");
            this.GetSavedData();
            return;

          }
          }
        )
      }
    })
  }

  //Edit Subscription Data 
  EditData(i: number, SubscriptionId) {
    this.onClear();

    this.ButtonText = 'Update';
    this.showNotif = false;
    var index = i;
    var SubscriptionId = SubscriptionId;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.http.get('api/Subscription/editsubscription?SubscriptionId=' + SubscriptionId, options).subscribe(

      (data) => {
        this.EditSubsscriptionData = data;
        this.Subscriptionid = this.EditSubsscriptionData.data[0].SubscriptionId;

        this.IsSchool = this.EditSubsscriptionData.data[0].IsSchool == 0 ? false : true;
        this.IsParent = this.EditSubsscriptionData.data[0].IsParent == 0 ? false : true;
        this.IsStudent = this.EditSubsscriptionData.data[0].IsStudent == 0 ? false : true;

        this.SelectedClass = this.EditSubsscriptionData.data[0].classid;
        this.SelectedStream = this.EditSubsscriptionData.data[0].streamname;
        this.testimonial = this.EditSubsscriptionData.data[0].testimonial;

        var mdate = new Date(this.EditSubsscriptionData.data[0].startdate);
        var edate = new Date(this.EditSubsscriptionData.data[0].enddate);

        this.startdate = mdate;
        this.enddate = edate;

        var mtime = this.EditSubsscriptionData.data[0].starttime.split(":");
        var etime = this.EditSubsscriptionData.data[0].endtime.split(":");

        this.starttime = { hour: parseInt(mtime[0]), minute: parseInt(mtime[1]), second: parseInt(mtime[2]) };
        this.endtime = { hour: parseInt(etime[0]), minute: parseInt(etime[1]), second: parseInt(etime[2]) };

        if (this.EditSubsscriptionData.data[0].stateid == "0" && this.EditSubsscriptionData.data[0].cityid == "0" && this.EditSubsscriptionData.data[0].schoolid == "0") {
          this.AllState = true;
          this.SelectAllState();
          this.onChangeOfMultiCheckBoxToGetCity();
          this.AllCity = true;
          this.SelectAllCity()
          this.onChangeOfMultiCheckBoxToGetSchool();
          this.AllSchool = true;
          this.SelectAllSchool();
        }
        else {
          if (this.EditSubsscriptionData.Status != undefined) {
            if (this.EditSubsscriptionData.Status == true) {
              this.StateIds = this.EditSubsscriptionData.data[0].stateid;

              if (this.StateIds == '0') {
                this.AllState = true;
                this.StateData.forEach(item => { item.selected = true; });
              }
              else {
                var tmpstateId = this.EditSubsscriptionData.data[0].stateid.split(",");

                for (var j = 0; j < tmpstateId.length; j++) {
                  for (var i = 0; i < this.StateData.length; i++) {
                    if (tmpstateId[j] === this.StateData[i].stateId.toString()) {
                      this.StateData[i].selected = true;
                    }
                  }
                }
              }

              this.onChangeOfMultiCheckBoxToGetCity();
            }
          }
        }

        if (this.IsSchool == true) {
          //for (var i = 0; i < this.CityData.length; i++) {
          //  if (this.CityData.cityid == this.EditSubsscriptionData.data[0].CityId) {
          //    this.CityData.selected = this.EditSubsscriptionData.data[0].CityId;
          //  }
          //}
          this.ShowDiv = false;
        }
        
        if (this.IsParent == true || this.IsStudent == true) {
          this.ShowDiv = true;

          this.classid = this.EditSubsscriptionData.data[0].classid;
          this.streamid = this.EditSubsscriptionData.data[0].streamid;

          if (this.classid == '0') {
            this.AllClass = true;
            this.ClassData.forEach(item => { item.selected = true; });
            this.showstream = 1;
          }
          else {
            var tmpClassId = this.EditSubsscriptionData.data[0].classid.split(",");

            for (var i = 0; i < this.ClassData.length; i++) {
              for (var j = 0; j < tmpClassId.length; j++) {
                if (this.ClassData[i].classid == tmpClassId[j]) {
                  this.ClassData[i].selected = true;
                }
              }
            }

            for (var i = 0; i < this.ClassData.length; i++) {
              if (this.ClassData[i].selected === true) {
                if (this.ClassData[i].classid == 1 || this.ClassData[i].classid == 2 || this.ClassData[i].classid == 3) {
                  this.showstream = 0;
                }
                else {
                  this.showstream = 1;
                }
              }
            }
          }

          

          if (this.streamid == '0') {
            this.AllStream = true;
            this.StreamData.forEach(item => { item.selected = true; });
          }
          else {
            var tmpStreamid = this.EditSubsscriptionData.data[0].streamid.split(",");
            for (var i = 0; i < this.StreamData.length; i++) {
              for (var j = 0; j < tmpStreamid.length; j++) {
                if (this.StreamData[i].streamid == tmpStreamid[j]) {
                  this.StreamData[i].selected = true;
                }
              }
            }
          }
        }
        setTimeout(() => this.onChangeOfMultiCheckBoxToGetStudent() , 2000);
        //this.onChangeOfdropdown();

        //this.studentid = this.EditSubsscriptionData.data[0].studentid;
        this.Message = this.EditSubsscriptionData.data[0].Message;
      }
    )
  }

  //Save Subscription Details
  onSubmit()
  {
    if (this.startdate == null || this.startdate == undefined) {
      Swal.fire("", "Please enter start date ", "error");
      return;
    }

    if (this.enddate == null || this.enddate == undefined) {
      Swal.fire("", "Please enter end date ", "error");
      return;
    }
    if (this.starttime == null || this.starttime == undefined) {
      Swal.fire("", "Please enter start time", "error");
      return;
    }
    if (this.endtime == null || this.endtime == undefined) {
      Swal.fire("", "Please enter end time", "error");
      return;
    }
    if (this.testimonial == "" || this.testimonial == undefined) {
      Swal.fire("", "Please enter testimonials", "error");
      return;
    }

    if (this.StateIds == "") {
      Swal.fire("", "Please Select State", "error");
      return;
    }
    if (this.CityIds == "") {
      Swal.fire("", "Please Select City", "error");
      return;
    }
    if (this.SchoolIds == "") {
      Swal.fire("", "Please Select School", "error");
      return;
    }

    
    //this.s_date = this.startdate.toISOString().slice(0, 10);
    //this.e_date = this.enddate.toISOString().slice(0, 10);

    var temp_s_date = this.startdate.toLocaleString().slice(0, 10).split('/');

    temp_s_date = parseInt(temp_s_date[1]) <= 9 && parseInt(temp_s_date[0]) <= 9 ? this.startdate.toLocaleString().slice(0, 8).split('/') : parseInt(temp_s_date[1]) <= 9 || parseInt(temp_s_date[0]) <= 9 ? this.startdate.toLocaleString().slice(0, 9).split('/') : this.startdate.toLocaleString().slice(0, 10).split('/');

    var temp_e_date = this.enddate.toLocaleString().slice(0, 10).split('/');
    temp_e_date = parseInt(temp_e_date[1]) <= 9 && parseInt(temp_e_date[0]) <= 9 ? this.enddate.toLocaleString().slice(0, 8).split('/') : parseInt(temp_e_date[1]) <= 9 || parseInt(temp_e_date[0]) <= 9 ? this.enddate.toLocaleString().slice(0, 9).split('/') : this.enddate.toLocaleString().slice(0, 10).split('/');

    var s_year = temp_s_date[2]
    var s_day = parseInt(temp_s_date[1]) <= 9 ? '0' + temp_s_date[1] :  temp_s_date[1]
    var s_month = parseInt(temp_s_date[0]) <= 9 ? '0' + temp_s_date[0] :  temp_s_date[0]

    this.s_date = s_year + '-' + s_month +'-' + s_day

    var e_year = temp_e_date[2]
    var e_day = parseInt(temp_e_date[1]) <= 9 ? '0' + temp_e_date[1] :  temp_e_date[1]
    var e_month = parseInt(temp_e_date[0]) <= 9 ? '0' + temp_e_date[0] :  temp_e_date[0]

    this.e_date = e_year + '-' + e_month+ '-' + e_day

    //this.todaydate = new Date().toISOString().slice(0, 10);
    //this.todaydate = new Date().toLocaleString().slice(0, 10).split('/');

    var today_date = new Date().toLocaleString().slice(0, 10).split('/');
    today_date = parseInt(today_date[0]) <= 9 && parseInt(today_date[1]) <= 9 ? new Date().toLocaleString().slice(0, 8).split('/') : parseInt(today_date[0]) <= 9 || parseInt(today_date[1]) <= 9 ? new Date().toLocaleString().slice(0, 9).split('/') : new Date().toLocaleString().slice(0, 10).split('/')

    var t_year = today_date[2]
    var t_day = parseInt(today_date[1]) <= 9 ? '0' + today_date[1] :  today_date[1]
    var t_month = parseInt(today_date[0]) <= 9 ? '0' + today_date[0] :  today_date[0]

    this.todaydate = t_year + '-' + t_month + '-'+ t_day

    var st_time = this.starttime.hour.toString() + ':' + this.starttime.minute.toString() + ':' + this.starttime.second.toString();
    var end_time = this.endtime.hour.toString() + ':' + this.endtime.minute.toString() + ':' + this.endtime.second.toString();

    if (this.s_date == this.e_date) {
      if (this.s_date < this.todaydate) {
        Swal.fire("", "Start date should contains future date", "error");
        return;
      }
      if (this.e_date < this.todaydate) {
        Swal.fire("", "End date should contain future date", "error");
        return;
      }

      if ((this.starttime.hour > this.endtime.hour) || ((this.starttime.hour == this.endtime.hour && this.starttime.minute >= this.endtime.minute))) {
        Swal.fire("", "Start time always less then end time", "error");
        return;
      }
    }
    else {
      if (this.s_date < this.todaydate) {
        Swal.fire("", "Start date should contains future date", "error");
        return;
      }
      if (this.e_date < this.todaydate) {
        Swal.fire("", "End date should contain future date", "error");
        return;
      }

      if (this.s_date > this.e_date) {
        Swal.fire("", "End date always greater then start date", "error");
        return;
      }
    }

    if (this.Message == '' || this.Message == undefined) {
      Swal.fire("", "Please enter message", "error");
      return;
    }
    if (this.IsParent == false && this.IsStudent == false && this.IsSchool==false)
    {
      Swal.fire("", "Please choose an option", "error");
      return;
    }

    if (this.IsParent == true || this.IsStudent == true)
    {
      if (this.classid == '' || this.classid == undefined) {
        Swal.fire("", "Please select Class", "error");
        return;
      }
    }
    else
    {
      this.classid = '0';
      this.streamid = '0';
      this.studentid = '0';
    }

    if (this.ButtonText != "Update") {
      if (this.notificationsubject == '' || this.notificationsubject == undefined) {
        Swal.fire("", "Please enter Notification Subject", "error");
        return;
      }

      if (this.notificationtitle == '' || this.notificationtitle == undefined) {
        Swal.fire("", "Please enter Notification Title", "error");
        return;
      }
    }

    var data
    if (this.ButtonText == "Update") {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers }
      data =
      {
        "SubscriptionId": this.Subscriptionid,
        "stateid": this.StateIds,
        "cityid": this.CityIds,
        "SchoolId": this.SchoolIds,
        "isschool": this.IsSchool == true ? 1 : 0,
        "isparent": this.IsParent == true ? 1 : 0, 
        "isstudents": this.IsStudent == true ? 1 : 0,
        "classid": this.classid,
        "streamid": this.streamid,
        "studentid": this.studentid,
        "message": this.Message,
        "startdate": this.s_date,
        "enddate": this.e_date,
        "testimonials": this.testimonial,
        "starttime": st_time,
        "endtime": end_time,
        "createdby": parseInt(this.localstorage.get("userid"))
      };

      let body = JSON.stringify(data);
      this.http.post('api/Subscription/updatesubscription', body, options).subscribe(

        (data) => {
          this.SubscriptionData = data;
          if (this.SubscriptionData.Status == true) {
            Swal.fire("", "Updated Successfully", "success");
            this.onClear();
            this.GetSavedData();
            return;
          }
        }
      )
    }
    else {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers }

      data =
      {
        "acttype": "save",
        "StateId": this.StateIds,
        "CityId": this.CityIds,
        "SchoolId": this.SchoolIds,
        "ClassId": this.classid,
        "StreamId": this.streamid,
        "StudentId": this.studentid,
        "Message": this.Message,
        "Subject": this.notificationsubject,
        "Title": this.notificationtitle,
        "startdate": this.s_date,
        "enddate": this.e_date,
        "testimonials": this.testimonial,
        "starttime": st_time,
        "endtime": end_time,
        "IsParent": this.IsParent == true ? 1 : 0,
        "IsStudents": this.IsStudent == true ? 1 : 0,
        "IsSchool": this.IsSchool == true ? 1 : 0,
        "createdBy": parseInt(this.localstorage.get("userid"))
      };

      let body = JSON.stringify(data);

      this.http.post('api/Subscription/SaveSubscription', body, options).subscribe(

        (data) => {
          this.SubscriptionData = data;
          if (this.SubscriptionData.Status == true) {
            Swal.fire("", "Saved Successfully", "success");
            this.onClear();
            this.GetSavedData();
            return;
          }
        }
      )
    }
  }

  //Reset All Fields 
  onClear()
  {
    this.ButtonText = 'Save';
    this.showNotif = true;
    this.Message = "";
    this.notificationsubject = "";
    this.notificationtitle = "";
    this.IsParent = false;
    this.IsSchool = false;
    this.IsStudent = false;
    this.SelectedClass = "0";
    this.SelectedStream = "0";
    this.SelectedStudent = "0";
    this.ShowDiv = false;
    this.startdate = null;
    this.enddate = null;
    this.starttime = null;
    this.endtime = null;
    this.testimonial = "";

    this.AllClass = false;
    this.AllCity = false;
    this.AllState = false;
    this.AllSchool = false;

    this.CityData = [];
    this.SchoolData = [];
    this.StudentData = [];

    for (var i = 0; i < this.StateData.length; i++)
    {
      this.StateData[i].selected = false;
    }

    for (var i = 0; i < this.ClassData.length; i++) {
      this.ClassData[i].selected = false;
    }

    for (var i = 0; i < this.StudentData.length; i++) {
      this.StudentData[i].selected = false;
    }

  }

}




