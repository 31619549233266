import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingpagesRoutingModule } from './landingpages-routing.module';

import { SidebarComponent } from '../landingpagelayouts/sidebar/sidebar.component';
import { HeaderComponent } from '../landingpagelayouts/header/header.component';
import { InternetConectivityCheckComponent } from '../adminpanel/internetconnectivity/internetconnectivity.component';
import { SubscriptionManager } from '../adminpanel/Subscription/subscription.component';
import { WebinarManager } from '../adminpanel/Webinar/webinar.component';
import { PlannedaActivityManager } from '../adminpanel/plannedactivity/plannedactivity.component';
import { PlannedaActivityManagerNew } from '../adminpanel/plannedactivity_new/plannedactivity_new.component';
import { RepositoryManager } from '../adminpanel/ssrepository/ssrepository.component';
import { SummerSchoolManager } from '../adminpanel/summerschool/summerschool.component';
import { personalessaymanager } from '../adminpanel/personalessay/personalessay.component';
import { portfoliomanager } from '../adminpanel/portfolio/portfolio.component';
import { lifecoachesmanager } from '../adminpanel/lifecoaches/lifecoaches.component';
import { prepratorymaterialManager } from '../adminpanel/prepratorymaterial/prepratorymaterial.component';
import { uploadmaterialmanager } from '../adminpanel/uploadmaterial/uploadmaterial.component';
import { prepnameManager } from '../adminpanel/prepnamemaster/prepname.component';
import { SafePipe } from '../adminpanel/plannedactivity/plannedactivity.component';
import { SummerTopicManager } from '../adminpanel/summerschooltopic/summertopic.component';
import { UniversityManager } from '../adminpanel/universitymaster/universitymaster.component';
import { SampleEssayManager } from '../adminpanel/sampleessay/sampleessay.component';
import { professionalManager } from '../adminpanel/professionalmaster/professionalmaster.component';
import { TraitManager } from '../adminpanel/traitmaster/traitmaster.component';
import { SamplePortfolioManager } from '../adminpanel/sampleportfolio/sampleportfolio.component';
import { SOPIntrestManager } from '../adminpanel/SOPintrestmaster/sopintrestmaster.component';
import { SampleSOPManager } from '../adminpanel/samplesop/samplesop.component';
import { professionalSOPManager } from '../adminpanel/professionalsop/professionalsop.component';
import { LifeCoachManager } from '../adminpanel/lifecoachtopic/lifecoachtopic.component';
import { WelcomeManager } from '../adminpanel/welcompage/welcome.component';
import { videopdfmanager } from '../adminpanel/uploadvideopdf/uploadvideopdf.component';
import { DatePipe } from '@angular/common';
import { SearchfilterPipe } from '../adminpanel/uploadvideopdf/filtergrid';
import { SearchSummerfilterPipe } from '../adminpanel/summerschool/filtersummerschoolgrid';
import { lifecoachactivitymanager } from '../adminpanel/lifecoachactivity/lifecoachactivity.component';
import { EntranceExamManager } from '../adminpanel/entranceexam/entranceexam.component';
import { PlacementStudentManager } from '../adminpanel/studentplacement/placement.component';
import { AddPlacementManager } from '../adminpanel/placement/placement.component';
import { PrincipalRegistration } from '../adminpanel/principalregistration/principalregistration.component';
import { Selfanalysis } from '../adminpanel/selfanalysis/selfanalysis.component';
import { couponmaster } from '../adminpanel/couponmaster/couponmaster.component';
import { partnerregistration } from '../adminpanel/partner/partner.component';
import { PaidFeaturemanager } from '../adminpanel/paidfeature/paidfeature.component';
import { addstudent } from '../adminpanel/addstudent/addstudent.component';
import { Partnerbankdetail } from '../adminpanel/partnerbankdetail/partnerbankdetail.component';
import { resetpassword } from '../adminpanel/resetpassword/resetpassword.component';
import { guideline } from '../adminpanel/guideline/guideline.component';
import { collegerepository } from '../adminpanel/collegerepository/collegerepository.component';
import { paidfeaturebackend } from '../adminpanel/paidfeaturebackend/paidfeaturebackend.component';
import { boards } from '../adminpanel/boards/boards.component';
import { citymaster } from '../adminpanel/citymaster/citymaster.component';
import { statemaster } from '../adminpanel/statemaster/statemaster.component';
import { lifecoachtypemaster } from '../adminpanel/lifecoachtypemaster/lifecoachtypemaster.component';
import { lifecoachagenda } from '../adminpanel/lifecoachagenda/lifecoachagenda.component';
import { lifecoachinformation } from '../adminpanel/lifecoachinformation/lifecoachinformation.component';
import { lifecoachesinfo } from '../adminpanel/lifecoachesinfo/lifecoachesinfo.component';
import { productusagemanager } from '../adminpanel/productusage/productusage.component';
import { ProductManager } from '../adminpanel/productmaster/productmaster';
import { ReportManager } from '../adminpanel/report/report.component';
import { countrymaster } from '../adminpanel/country/country.component';
import { ProductCoupon } from '../adminpanel/productwisecoupon/productwisecoupon.component';
import { LiveWebinarManager } from '../adminpanel/livewebinar/livewebinar.component';
import { permissionmanager } from '../adminpanel/permission/permission.component';
import { MobileAppManager } from '../adminpanel/MobileApp/mobile.component';
import { ScholarshipReportManager } from '../adminpanel/ScholarshipReport/scholarshipreport.component';
import { EntranceExaminationReportManager } from '../adminpanel/EntranceExaminationReport/entranceexamination.component';
import { ProfileBuildingReportManager } from '../adminpanel/ProfileBuilding/profilebuildingreport.component';
import { SummerSchoolReportManager } from '../adminpanel/SummerSchoolReport/summerschoolreport.component';
import { PrepratoryMaterialReportManager } from '../adminpanel/PrepratoryMaterialReport/prepratorymaterialreport.component';
import { SelfAnalysisTestReportManager } from '../adminpanel/SelfAnalysisTestReport/selfanalysisreport.component';
import { LifeCoachesReportManager } from '../adminpanel/LifeCoachesReport/lifecoachesreport.component';
import { PlacementReportManager } from '../adminpanel/PlacementReport/placementreport.component';
import { WebinarReportManager } from '../adminpanel/WebinarReport/webinarreport.component';
import { OtherReportManager } from '../adminpanel/OtherReport/otherreport.component';
import { FreeReportManager } from '../adminpanel/FreeUserReport/freereport.component';
import { PaidFeaturesReportManager } from '../adminpanel/PaidFeaturesReport/paidfeaturesreport.component';
import { NonSubscriberReportManager } from '../adminpanel/NonSubscriberReport/nonsubscriberreport.component';
import { FAQReportManager } from '../adminpanel/FAQreport/faqreport.component';
import { QueryReportManager } from '../adminpanel/report/FAQuery/Queryreport/queryreport.component';
import { StudentAnalysisReportManager } from '../adminpanel/studentanalysisreport/studentanalysis.component';
import { NotificationReportManager } from '../adminpanel/NotificationReport/notificationreport.component';
import { AcademyMaster } from '../adminpanel/academymaster/academymaster.component';
import { PartnerUniversityMaster } from '../adminpanel/partneruniversitymaster/partneruniversitymaster.component';
import { SopAndEssayReportManager } from '../adminpanel/SOPandEssayReport/sopessayreport.component';
import { DownloadReportManager } from '../adminpanel/DownloadReport/downloadreport.component';
import { MaterialReportManager } from '../adminpanel/MaterialReport/materialreport.component';
import { ActivityManager } from '../adminpanel/activitymaster/activitymaster.component';
import { TestDownloadReportManager } from '../adminpanel/TestDownloadReport/testdownloadreport.component';
import { ArticlesReportManager } from '../adminpanel/ArticlesReport/articlesreport.component';
import { DownloadTestReportManager } from '../adminpanel/DownloadTestReport/downloadtestreport.component';
import { CvReportManager } from '../adminpanel/Cvreport/cvreport.component';
import { TestDiscussionReportManager } from '../adminpanel/TestDiscussionReport/testdiscussionreport.component';
import { DiscountReportManager } from '../adminpanel/DiscountReport/discountreport.component';
import { PendingDiscussionReportManager } from '../adminpanel/PendingDiscussionReport/pendingdiscussionreport.component';
import { AcademyCareerReport } from '../adminpanel/virtualactivityreport/academycareerreport/academycareerreport.component';
import { RegistrationReportManager } from '../adminpanel/RegistrationReport/registrationreport.component';
import { SchoolMaster } from '../adminpanel/schoolmaster/schoolmaster.component';
import { PlacementDetailsMaster } from '../adminpanel/placementdetails/placementdetails.component';
import { DesignationMaster } from '../adminpanel/designationmaster/designationmaster.component';
import { RoleMaster } from './security/rolemaster/rolemaster.component';
import { UserMaster } from './security/usermaster/usermaster.component';
import { MenuMaster } from './security/menumaster/menumaster.component';
import { RollOverMaster } from './rollovermaster/rollovermaster.component';
import { AssignStudentMaster } from './assignstudent/assignstudent.component';
import { SessionMaster } from './sessionmaster/sessionmaster.component';
import { RegistrationPaymentReport } from './registrationpaymentreport/registrationpaymentreport.component';
import { SchoolVerifyMaster } from './schoolverifymaster/schoolverifymaster.component';

import { AuthguardService } from '../../AuthGuard.Service';
import { LogoutService } from '../../logout.service';
import { PermissionService } from '../../permission.service';
import { NgxChartsModule, TooltipModule } from '@swimlane/ngx-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EmbedVideo } from 'ngx-embed-video';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { OrderModule } from 'ngx-order-pipe';
import { CKEditorModule } from 'ng2-ckeditor';



@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    InternetConectivityCheckComponent,
    SubscriptionManager,
    WebinarManager,
    PlannedaActivityManager,
    PlannedaActivityManagerNew,
    RepositoryManager,
    SummerSchoolManager,
    personalessaymanager,
    portfoliomanager,
    lifecoachesmanager,
    prepratorymaterialManager,
    uploadmaterialmanager,
    prepnameManager,
    SafePipe, SummerTopicManager, UniversityManager,
    SampleEssayManager, professionalManager, TraitManager,
    SamplePortfolioManager, SOPIntrestManager, SampleSOPManager,
    professionalSOPManager, LifeCoachManager, WelcomeManager,
    videopdfmanager, SearchfilterPipe, SearchSummerfilterPipe, lifecoachactivitymanager, EntranceExamManager, PlacementStudentManager,
    AddPlacementManager, PrincipalRegistration, Selfanalysis, couponmaster, partnerregistration, PaidFeaturemanager, addstudent,
    Partnerbankdetail, resetpassword, guideline, collegerepository, paidfeaturebackend, boards, citymaster, statemaster,
    lifecoachtypemaster, lifecoachagenda, lifecoachinformation, lifecoachesinfo, productusagemanager, ProductManager, ReportManager, countrymaster,
    ProductCoupon, LiveWebinarManager, permissionmanager, MobileAppManager, ScholarshipReportManager, EntranceExaminationReportManager,
    ProfileBuildingReportManager, SummerSchoolReportManager, PrepratoryMaterialReportManager, SelfAnalysisTestReportManager, LifeCoachesReportManager,
    PlacementReportManager, WebinarReportManager, OtherReportManager, FreeReportManager, PaidFeaturesReportManager, NonSubscriberReportManager,
    FAQReportManager, QueryReportManager, StudentAnalysisReportManager, NotificationReportManager,
    AcademyMaster, PartnerUniversityMaster, SopAndEssayReportManager, SchoolVerifyMaster,
    DownloadReportManager,
    MaterialReportManager
    , ActivityManager
    , TestDownloadReportManager
    , ArticlesReportManager
    , DownloadTestReportManager
    , CvReportManager
    , TestDiscussionReportManager
    , DiscountReportManager
    , PendingDiscussionReportManager
    , AcademyCareerReport
    , RegistrationReportManager
    , SchoolMaster
    , PlacementDetailsMaster
    , DesignationMaster, RoleMaster, UserMaster, MenuMaster, RollOverMaster, AssignStudentMaster, SessionMaster, RegistrationPaymentReport
  ],
  imports: [
    CommonModule,
    LandingpagesRoutingModule,
    NgxChartsModule,
    FormsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    NgxUiLoaderModule,
    NgbModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    EmbedVideo.forRoot(),
    AngularEditorModule,
    ClipboardModule,
    OrderModule,
    CKEditorModule
  ],
  providers: [AuthguardService, RegistrationReportManager, LogoutService, PermissionService],
})
export class LandingpagesModule { }
