
import { Component, OnInit, Input, PipeTransform, Pipe } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbTimepickerConfig, NgbTimeStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { PermissionService } from '../../../permission.service';

@Component({
  selector: 'app-notificationreport',
  templateUrl: './notificationreport.component.html',
  styleUrls: ['./notificationreport.css'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, NgbTimepickerConfig]
 

})
export class NotificationReportManager implements OnInit {

  spinnerType = SPINNER.rectangleBounce;

  public page: number = 1;
  public pageSize: number = 10;

  public page1: number = 1;
  public pageSize1: number = 10;
  public resData: any = [];
  public single: object = [];
  public multi: object[];
  public view: any[] = [500, 300];
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel: "Month";
  public showYAxisLabel = true;
  public yAxisLabel: "Subscription";
  public graphDataChart: any = [];
  legendTitle: string = 'Type';
  public colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };


  stuname: string='';
  email: string='';
  phone: string='';

  searchTerm: string = "";

  searchString: string = '';
  p: any;

  noofappdownloader: number = 0;
  noofnotregistered: number = 0;
  noofthroughapp: number = 0;
  noofbackend: number = 0;
  nooffreeusers: number = 0;
  noofclickonpaynow: number = 0;
  noofpaidclick: number = 0;
  notusing: number = 0;
  studentdetails: any = [];
  countdetails: any = [];
  DownloaderData: any = [];
  AppDownloaderData: any = [];
  public s_date: any;
  public e_date: any;
  public start_date: any;
  public end_date: any;
  public SelectedDate: Date;
  public SelectedEndDate: Date;
  public SelectedDate1: Date;
  public SelectedEndDate1: Date;
  public SelectedDate2: Date;
  public SelectedEndDate2: Date;
  public SelectedDate3: Date;
  public SelectedEndDate3: Date;
  public SelectedDate5: Date;
  public SelectedEndDate6: Date;
  public SelectedDate7: Date;
  public SelectedEndDate8: Date;
  public SelectedDate9: any;
  public SelectedEndDate10: any;
  public SelectedDate11: Date;
  public SelectedEndDate12: Date;
  public SelectedDate13: Date;
  public SelectedEndDate14: Date;
  public SelectedDate15: Date;
  public SelectedEndDate16: Date;
  public SelectedDate17: Date;
  public SelectedEndDate18: Date;
  public SelectedDate19: Date;
  public SelectedEndDate20: Date;
  public SelectedDate21: Date;
  public SelectedEndDate22: Date;
  public SelectedDate23: Date;
  public SelectedEndDate24: Date;
  public SelectedDate25: Date;
  public SelectedEndDate26: Date;
  public SelectedDate27: Date;
  public SelectedEndDate28: Date;
  public SelectedDate29: Date;
  public SelectedEndDate30: Date;

  public SelectedDate51: Date;
  public SelectedDate52: Date;

  public SelectedDate54: Date;
  public SelectedDate55: Date;

  public SelectedDate59: Date;
  public SelectedDate60: Date;

  public SelectedDate61: Date;
  public SelectedDate62: Date;
  selectedbuilder: number = 0;
  selectedstudentq: number = 0;
  StudentData: any = [];
  statedat: any = [];
  StateData: any = [];
  citdata: any = [];
  selectedstate: number = 0;
  selectedstatef: number = 0;
  selectedstateg: number = 0;
  selectedstateh: number = 0;
  selectedstateq: number = 0;
  selectedstatepl: number = 0;
  GetPaymentDetails: any = [];

  CityData: any = [];
  schdata: any = [];
  selectedcity: number = 0;
  selectedcityf: number = 0;
  selectedcityg: number = 0;
  selectedcityh: number = 0;
  selectedcityq: number = 0;
  selectedcitypl: number = 0;


  SchoolData: any = [];
  classdat: any = [];
  ClassData: any = [];
  streamdat: any = [];
  StreamData: any = [];
  selectedschool: number = 0;
  selectedschoolf: number = 0;
  selectedschoolg: number = 0;
  selectedschoolh: number = 0;
  selectedschoolq: number = 0;

  selectedclass: number = 0;
  selectedclassf: number = 0;
  selectedclassg: number = 0;
  selectedclassh: number = 0;
  selectedclassi: number = 0;
  selectedclassj: number = 0;
  selectedclassq: number = 0;
  selectedclasspl: number = 0;


  selectedstream: number = 0;
  selectedstreamf: number = 0;
  selectedstreamg: number = 0;
  selectedstreamh: number = 0;
  selectedstreami: number = 0;
  selectedstreamj: number = 0;
  selectedstreamq: number = 0;
  selectedstreampl: number = 0;

  productdat: any = [];
  Products: any = [];
  selectproduct: number = 0;

  getunregisteredstudent: any = [];
  Getstudentdetails: any = [];
  getfreeuser: any = [];
  GetFreeUserDetails: any = [];
  getnotsubclickpaid: any = [];
  GetClickPaidNotSub: any = [];
  GetSubscribed: any = [];
  getsubscribeduser: any = [];
  getscholarshipdata: any = [];
  GetScholarshipDetails: any = [];
  getentrancedata: any = [];
  GetEntranceExamDetails: any = [];
  noofsummerschool: number = 0;
  Schooldetails: any = [];
  summercountdetails: any = [];
  SummerData: any = [];
  SummerSchoolData: any = [];
  selectedcountry: number = 0;
  location: number = 0;
  city: number = 0;
  univercity: number = 0;
  UniversityData: any = [];
  CityDataCareer: any = [];
  LocationData: any = [];
  CountryData: any = [];
  selectedinterest: number = 0;
  AreaData: number = 0;
  AreaDetails: any = [];
  CountryDetails: any = [];
  locdata: any = [];
  univdata: any = [];
  getsummerschooldata: any = [];
  GetSummerSchoolDetails: any = [];
  studata: any = [];
  StudentDatas: any = [];
  getquerydata: any = [];
  GetQueryDetails: any = [];
  selectedcareer: string = "";
  CareerData: any = [];
  keyword: string = "";
  careerdta: any = [];
  getfaqdata: any = [];
  GetFaqDetails: any = [];
  selectedcareer1: string = "";
  getfaqissuedata: any = [];
  GetFaqissueDetails: any = [];
  keyword1: string = "";
  getarticledata: any = [];
  GetArticleDetails: any = [];
  Coachtypedetails: any = [];
  TopicData: any = [];
  Coachdetail: any = [];
  CoachData: any = [];
  selectedtopic: number = 0;
  selectedcoach: number = 0;
  getcoachdata: any = [];
  GetcoachDetails: any = [];
  getplaceddata: any = [];
  GetplacedDetails: any = [];
  getdatapaid: any = [];
  getclickdata: any = [];
  GetClickDetails: any = [];
  getbuilderdata: any = [];
  GetBuilderDetails: any = [];
  selectedcat: number = 0;
  selectedsubcat: number = 0;
  selectedstatus: number = 0;
  getusagedata: any = [];
  GetUsageDetails: any = [];
  NodataFound1: boolean = false;
  NodataFound2: boolean = false;
  NodataFound3: boolean = false;
  NodataFound4: boolean = false;
  ShowTable1: boolean = false;
  ShowTable2: boolean = false;
  ShowTable3: boolean = false;
  ShowTable4: boolean = false;

  monthwisegraphdata:any=[];
  noofclickonpaynow1: number = 0;
  selectedtype:number=3;
  studentid: number = 0;

  year: number = 0;
  month: number = 0;
  date: number = 0;

  Instruction: any;

  ProductDetail: any = [];
  ProductDetails: any = [];

  NotificationData: any = [];
  NotificationDetailData: any = [];

  selectednotitype: number = 2;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  pipe = new DatePipe('en-US');

  constructor(private http: HttpClient, private permission: PermissionService, private router: Router, private localstorage: LocalStorageService, private toaster: ToastrService,
    private loader: NgxUiLoaderService, config: NgbTimepickerConfig, private config1: NgbDatepickerConfig) {
    config.seconds = false;
    config.spinners = false;
    config.meridian = true;

    this.date = new Date().getDate();
    this.year = new Date().getFullYear();
    this.month = parseInt(new Date().toISOString().slice(0, 10).split('-')[1]);
   
  }
  ngOnInit()
  {
    this.GetActions();
    this.getNotificationReport();
    this.BindState();
    this.BindClass();
    this.BindStream();
  }


  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  /*name of the excel-file which will be downloaded. */
  fileName = 'NotificationReport.xlsx';

  exportexcel(tagid: string): void {
    
    /* table id is passed over here */
    let element = document.getElementById(tagid);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }

  getNotificationReport() {

    if (this.SelectedDate9 == undefined || this.SelectedDate9 == null) {
      this.s_date = "1900-01-01";
    }
    else {
      this.s_date = this.SelectedDate9.year + '-' + this.SelectedDate9.month + '-' + this.SelectedDate9.day;

    }
    if (this.SelectedEndDate10 == undefined || this.SelectedEndDate10 == null) {
      this.e_date = "1900-01-01";
    }
    else {
      this.e_date = this.SelectedEndDate10.year + '-' + this.SelectedEndDate10.month + '-' + this.SelectedEndDate10.day;
    }


    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();

    this.http.get('api/reports/GetNotificationData?stateid=' + this.selectedstateg + '&cityid=' + this.selectedcityg + '&schoolid=' + this.selectedschoolg
      + '&fromdate=' + this.s_date + '&todate=' + this.e_date + '&id=' + this.selectedtype + '&classid=' + this.selectedclassg +
      '&streamid=' + this.selectedstreamg + '&notitype=' + this.selectednotitype, options).subscribe(
        (data) => {

          

          this.loader.stop();

          this.NotificationData = [];

          this.NotificationData = data;

          this.page = 1;

          if (this.NotificationData.Status == true) {
            this.NotificationDetailData = this.NotificationData.data;
            this.loader.stop();
          }
          else {
            this.NotificationDetailData = [];
          }
        }
      )
  }

  ShowInst(msg) {
    this.Instruction = msg;
  }


  getPendingProduct(studentid: number,name:string,email:string,phone:string) {
    
    this.studentid = studentid;

    this.stuname = name;
    this.email = email;
    this.phone = phone;
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    
    this.loader.start();
    this.http.get('api/reports/GetPendingProductDetail?studentid=' + studentid, options).subscribe(
      (data) => {
        
        this.loader.stop();
        this.ProductDetail = data;
        this.page1=1
        if (this.ProductDetail.Status == true)
        {
          this.ProductDetails = this.ProductDetail.data;
        }
        else
        {
          this.ProductDetails = [];
        }
        
      }
    )

  }


  getPendingProductFilterData() {
    
    if (this.SelectedDate11 == undefined || this.SelectedDate11 == null) {
      this.s_date = "1900-01-01";
    }
    else {
      this.s_date = this.SelectedDate11.toISOString().slice(0, 10);

    }
    if (this.SelectedEndDate12 == undefined || this.SelectedEndDate12 == null) {
      this.e_date = "1900-01-01";
    }
    else {
      this.e_date = this.SelectedEndDate12.toISOString().slice(0, 10);
    }


    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    
    this.loader.start();
    this.http.get('api/reports/GetPendingFilterProductDetail?studentid=' + this.studentid + '&fromdate=' + this.s_date + '&todate=' + this.e_date +
        '&productid=' + this.selectproduct, options).subscribe(
      (data) => {
        
        this.loader.stop();

        this.ProductDetail = data;
        this.page1 = 1
        if (this.ProductDetail.Status == true) {
          this.ProductDetails = this.ProductDetail.data;
        }
        else {
          this.ProductDetails = [];
        }

      }
    )


  }



  RestModalData() {

    this.SelectedDate11 = null;
    this.SelectedEndDate12 = null;
    this.selectproduct = 0;
    this.getPendingProductFilterData();
  }



  setpageno() {
    this.page = 1;
  }

  ClearSearch() {
    this.searchString = "";
  }


 

  GetPaidDetails() {
    if (this.selectedclassq == 0 || this.selectedclassq == undefined) {
      this.selectedclassq = 0
    }
    if (this.selectedstreamq == 0 || this.selectedstreamq == undefined) {
      this.selectedstreamq = 0
    }
    if (this.selectedstateq == 0 || this.selectedstateq == undefined) {
      this.selectedstateq = 0
    }
    if (this.selectedcityq == 0 || this.selectedcityq == undefined) {
      this.selectedcityq = 0
    }
    if (this.selectedschoolq == 0 || this.selectedschoolq == undefined) {
      this.selectedschoolq = 0
    }
    if (this.selectedstatus == 0 || this.selectedstatus == undefined) {
      this.selectedstatus = 0
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    var a;
    var tmpclass: any = [];
    this.http.get('api/reports/GetPaidStudentDetails?classid=' + this.selectedclassq + '&streamid=' + this.selectedstreamq + '&stateid=' + this.selectedstateq + '&cityid=' + this.selectedcityq + '&paidstatus=' + this.selectedstatus, options).subscribe(
      (data) => {
        
        this.getdatapaid = data;
        this.GetPaymentDetails = this.getdatapaid.data;
      }
    )
  }


  BindProducts()
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.productdat = [];

    var tmpclass: any = [];
    this.http.post('api/partner/BindProducts', options).subscribe(

      (data) => {
        this.productdat = data;
        if (this.productdat.Status == true) {
          this.Products = this.productdat.data;
        }
        else {
          this.Products = this.productdat.data;
        }
      }
    )
  }

  DisplaypaymentReport() {
    

    if (this.selectedclassq == 0 || this.selectedclassq == undefined) {
      this.selectedclassq = 0
    }
    if (this.selectedstreamq == 0 || this.selectedstreamq == undefined) {
      this.selectedstreamq = 0
    }
    if (this.selectedstateq == 0 || this.selectedstateq == undefined) {
      this.selectedstateq = 0
    }
    if (this.selectedcityq == 0 || this.selectedcityq == undefined) {
      this.selectedcityq = 0
    }
    if (this.selectedschoolq == 0 || this.selectedschoolq == undefined) {
      this.selectedschoolq = 0
    }




    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    var a;
    var tmpclass: any = [];
    this.http.get('api/reports/GetQueryReport?classid=' + this.selectedclassq + '&streamid=' + this.selectedstreamq + '&stateid=' + this.selectedstateq + '&cityid=' + this.selectedcityq + '&enddate=' + this.e_date.toString(), options).subscribe(
      (data) => {
        
        this.getquerydata = data;
        this.GetQueryDetails = this.getquerydata.data;
      }
    )
  }



  BindCoachType() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var a;
    var tmpclass: any = [];
    this.http.get('api/lifecoachactivity/BindCoachType', options).subscribe(
      (data) => {
        
        this.Coachtypedetails = data;

        this.TopicData = this.Coachtypedetails.data;

      }
    )
  }


  BindCoach() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "coachtypeid": this.selectedtopic
    }
    var tmpclass: any = [];
    this.http.post('api/lifecoachactivity/BindCoach', body, options).subscribe(

      (data) => {
        this.Coachdetail = data;
        this.CoachData = this.Coachdetail.data;
      }
    )
  }


  getArea() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.AreaDetails = [];
    var a;
    var tmpclass: any = [];
    this.http.get('api/summerschool/Bindintrestarea', options).subscribe(
      (data) => {
        
        this.AreaDetails = data;

        this.AreaData = this.AreaDetails;

      }
    )
  }

  getCountry() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.CountryDetails = [];
    var a;
    var tmpclass: any = [];
    this.http.get('api/summerschool/Bindcountry', options).subscribe(
      (data) => {
        
        this.CountryDetails = data;

        this.CountryData = this.CountryDetails;

      }
    )
  }

  BindLocation() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.locdata = [];
    var body = {
      "countryid": this.selectedcountry
    }
    var tmpclass: any = [];
    this.http.post('api/summerschool/BindLocation', body, options).subscribe(

      (data) => {
        this.locdata = data;
        if (this.locdata.Status == true) {
          this.LocationData = this.locdata.data;
        }
        else {
          this.LocationData = this.locdata.data;
        }
        //if (this.GetEditedData.Status == true) {
        //    this.location = this.GetEditedData.location;
        //}
      }
    )
  }

  BindCityCareer() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.locdata = [];
    var body = {
      "countryid": this.selectedcountry,
      "locationid": this.location
    }
    var tmpclass: any = [];
    this.http.post('api/summerschool/BindCity', body, options).subscribe(

      (data) => {
        this.citdata = data;
        if (this.citdata.Status == true) {
          this.CityData = this.citdata.data;
        }
        else {
          this.CityData = this.citdata.data;
        }
        //if (this.GetEditedData.Status == true) {
        //    this.location = this.GetEditedData.location;
        //}
      }
    )
  }

  BindUniversity() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.univdata = [];
    var body = {
      "countryid": this.selectedcountry,
      "locationid": this.location,
      "cityid": this.city
    }
    var tmpclass: any = [];
    this.http.post('api/summerschool/BindUniversity', body, options).subscribe(

      (data) => {
        this.univdata = data;
        if (this.univdata.Status == true) {
          this.UniversityData = this.univdata.data;
        }
        else {
          this.UniversityData = this.univdata.data;
        }
        //if (this.GetEditedData.Status == true) {
        //    this.univercity = this.GetEditedData.univercityname;
        //}
      }
    )
  }

  BindState() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.statedat = [];

    var tmpclass: any = [];
    this.http.post('api/LiveWebinar/Bindstate', options).subscribe(
      (data) => {
        this.statedat = data;
        if (this.statedat.Status == true) {
          this.StateData = this.statedat.data;
        }
        else {
          this.StateData = this.statedat.data;
        }
      }
    )
  }



  BindCityg() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.citdata = [];
    var body = {

      "stateid": this.selectedstateg
    }
    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/BindCity', body, options).subscribe(

      (data) => {
        this.citdata = data;
        if (this.citdata.Status == true) {
          this.CityData = this.citdata.data;
        }
        else {
          this.CityData = this.citdata.data;
        }
      }
    )
  }


  BindSchoolh() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.schdata = [];
    var body = {

      "stateid": this.selectedstateh,
      "cityid": this.selectedcityh
    }
    var tmpclass: any = [];
    this.http.post('api/reports/BindSchoolforSubscriberReport', body, options).subscribe(

      (data) => {
        this.schdata = data;
        if (this.schdata.Status == true) {
          this.SchoolData = this.schdata.data;
        }
        else {
          this.SchoolData = this.schdata.data;
        }
      }
    )
  }

  BindSchoolg() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.schdata = [];
    var body = {

      "stateid": this.selectedstateg,
      "cityid": this.selectedcityg
    }
    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/BindSchool', body, options).subscribe(

      (data) => {
        this.schdata = data;
        if (this.schdata.Status == true) {
          this.SchoolData = this.schdata.data;
        }
        else {
          this.SchoolData = this.schdata.data;
        }
      }
    )
  }



  BindClass() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.classdat = [];

    var tmpclass: any = [];
    this.http.post('api/reports/Bindclass', options).subscribe(

      (data) => {
        this.classdat = data;
        if (this.classdat.Status == true) {
          this.ClassData = this.classdat.data;
        }
        else {
          this.ClassData = this.classdat.data;
        }
      }
    )
  }


  
  BindStream() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.streamdat = [];

    var tmpclass: any = [];
    this.http.post('api/reports/BindStream', options).subscribe(

      (data) => {
        
        this.streamdat = data;
        if (this.streamdat.Status == true) {
          this.StreamData = this.streamdat.data;
        }
        else {
          this.StreamData = this.streamdat.data;
        }
      }
    )
  }


  


  
  AppDownloder(i:number) {
    
    var index = i;
  
    if (index == 1) {
      if (this.SelectedDate == undefined || this.SelectedDate == null) {
        this.s_date = "1900-01-01";
        this.start_date = '';
      }
      else {
        this.s_date = this.SelectedDate.toISOString().slice(0, 10);
        this.start_date = 'from ' + this.pipe.transform(this.SelectedDate.toISOString().slice(0, 10), 'dd/MM/yyyy');
      }
      if (this.SelectedEndDate == undefined) {
        this.e_date = "1900-01-01";
        this.end_date = '';
      }
      else {
        this.e_date = this.SelectedEndDate.toISOString().slice(0, 10);
        this.end_date = 'to ' + this.pipe.transform(this.SelectedEndDate.toISOString().slice(0, 10), 'dd/MM/yyyy');
      }
    }

    if (index == 2) {
      if (this.SelectedDate1 == undefined) {
        this.s_date = "1900-01-01";
        this.start_date = '';
      }
      else {
        this.s_date = this.SelectedDate1.toISOString().slice(0, 10);
        this.start_date = 'from ' + this.pipe.transform(this.SelectedDate1.toISOString().slice(0, 10), 'dd/MM/yyyy');
      }
      if (this.SelectedEndDate1 == undefined) {
        this.e_date = "1900-01-01";
        this.end_date = '';
      }
      else {
        this.e_date = this.SelectedEndDate1.toISOString().slice(0, 10);
        this.end_date = 'to ' + this.pipe.transform(this.SelectedEndDate1.toISOString().slice(0, 10), 'dd/MM/yyyy');
      }
    }

    if (index == 3) {
      if (this.SelectedDate2 == undefined) {
        this.s_date = "1900-01-01";
        this.start_date = '';
      }
      else {
        this.s_date = this.SelectedDate2.toISOString().slice(0, 10);
        this.start_date = 'from ' + this.pipe.transform(this.SelectedDate2.toISOString().slice(0, 10), 'dd/MM/yyyy');

      }
      if (this.SelectedEndDate2 == undefined) {
        this.e_date = "1900-01-01";
        this.end_date = '';
      }
      else {
        this.e_date = this.SelectedEndDate2.toISOString().slice(0, 10);
        this.end_date = 'to ' + this.pipe.transform(this.SelectedEndDate2.toISOString().slice(0, 10), 'dd/MM/yyyy');
      }
    }


    if (index == 4) {
      if (this.SelectedDate3 == undefined) {
        this.s_date = "1900-01-01";
        this.start_date = '';
      }
      else {
        this.s_date = this.SelectedDate3.toISOString().slice(0, 10);
        this.start_date = 'from ' + this.pipe.transform(this.SelectedDate3.toISOString().slice(0, 10), 'dd/MM/yyyy');

      }
      if (this.SelectedEndDate3 == undefined) {
        this.e_date = "1900-01-01";
        this.end_date = '';
      }
      else {
        this.e_date = this.SelectedEndDate3.toISOString().slice(0, 10);
        this.end_date = 'to ' + this.pipe.transform(this.SelectedEndDate3.toISOString().slice(0, 10), 'dd/MM/yyyy');
      }
    }
  
    this.loader.start();



    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.http.get('api/reports/appdownloader?id=' + index + '&startdate=' + this.s_date.toString() + '&enddate=' + this.e_date.toString(), options).subscribe(

    //this.http.get('api/reports/appdownloader?id=' + index, options).subscribe(

      (data) => {
        
        this.DownloaderData = data;
        this.AppDownloaderData = this.DownloaderData.data;
        this.loader.stop();

      }
    )
  }


  GetArticleReport() {
    

    if (this.keyword1 == "" || this.keyword1 == undefined) {
      this.keyword1 = "";
    }



    if (this.SelectedDate27 == undefined) {
      this.s_date = "1900-01-01";
    }
    else {
      this.s_date = this.SelectedDate27.toISOString().slice(0, 10);

    }
    if (this.SelectedEndDate28 == undefined) {
      this.e_date = "1900-01-01";
    }
    else {
      this.e_date = this.SelectedEndDate28.toISOString().slice(0, 10);
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    var a;
    var tmpclass: any = [];
    this.http.get('api/reports/GetArticleData?keyword=' + this.keyword1 + '&startdate=' + this.s_date.toString() + '&enddate=' + this.e_date.toString(), options).subscribe(
      (data) => {
        
        this.getarticledata = data;
        this.GetArticleDetails = this.getarticledata.data;
      }
    )
  }


  GetCoachList() {
    

    if (this.selectedtopic == 0 || this.selectedtopic == undefined) {
      this.selectedtopic = 0;
    }
    if (this.selectedcoach == 0 || this.selectedcoach == undefined) {
      this.selectedcoach = 0;
    }



    if (this.SelectedDate29 == undefined) {
      this.s_date = "1900-01-01";
    }
    else {
      this.s_date = this.SelectedDate29.toISOString().slice(0, 10);

    }
    if (this.SelectedEndDate30 == undefined) {
      this.e_date = "1900-01-01";
    }
    else {
      this.e_date = this.SelectedEndDate30.toISOString().slice(0, 10);
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    var a;
    var tmpclass: any = [];
    this.http.get('api/reports/GetCoachData?coachtype=' + this.selectedtopic + '&coach=' + this.selectedcoach + '&startdate=' + this.s_date.toString() + '&enddate=' + this.e_date.toString(), options).subscribe(
      (data) => {
        
        this.getcoachdata = data;
        this.GetcoachDetails = this.getcoachdata.data;
      }
    )
  }

  GetPlacement() {
    

    if (this.selectedstatepl == 0 || this.selectedstatepl == undefined) {
      this.selectedstatepl = 0;
    }
    if (this.selectedcitypl == 0 || this.selectedcitypl == undefined) {
      this.selectedcitypl = 0;
    }
    if (this.selectedclasspl == 0 || this.selectedclasspl == undefined) {
      this.selectedclasspl = 0;
    }
    if (this.selectedstreampl == 0 || this.selectedstreampl == undefined) {
      this.selectedstreampl = 0;
    }


 

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    var a;
    var tmpclass: any = [];
    this.http.get('api/reports/GetPlacementData?stateid=' + this.selectedstatepl + '&cityid=' + this.selectedcitypl + '&classid=' + this.selectedclasspl + '&streamid=' + this.selectedstreampl, options).subscribe(
      (data) => {
        
        this.getplaceddata = data;
        this.GetplacedDetails = this.getplaceddata.data;
      }
    )
  }



  ReadMoreCompAndScholarship() {
    

    if (this.selectedclassq == 0 || this.selectedclassq == undefined) {
      this.selectedclassq = 0
    }
    if (this.selectedstreamq == 0 || this.selectedstreamq == undefined) {
      this.selectedstreamq = 0
    }


    if (this.SelectedDate51 == undefined) {
      this.s_date = "1900-01-01";
    }
    else {
      this.s_date = this.SelectedDate51.toISOString().slice(0, 10);

    }
    if (this.SelectedDate52 == undefined) {
      this.e_date = "1900-01-01";
    }
    else {
      this.e_date = this.SelectedDate52.toISOString().slice(0, 10);
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    var a;
    var tmpclass: any = [];
    this.http.get('api/reports/GetClickReport?classid=' + this.selectedclassq + '&streamid=' + this.selectedstreamq + '&startdate=' + this.s_date.toString() + '&enddate=' + this.e_date.toString(), options).subscribe(
      (data) => {
        
        this.getclickdata = data;
        this.GetClickDetails = this.getclickdata.data;
      }
    )
  }


  ReadMoreEntranceRxam() {
    

    if (this.selectedclassq == 0 || this.selectedclassq == undefined) {
      this.selectedclassq = 0
    }
    if (this.selectedstreamq == 0 || this.selectedstreamq == undefined) {
      this.selectedstreamq = 0
    }


    if (this.SelectedDate54 == undefined) {
      this.s_date = "1900-01-01";
    }
    else {
      this.s_date = this.SelectedDate54.toISOString().slice(0, 10);

    }
    if (this.SelectedDate55 == undefined) {
      this.e_date = "1900-01-01";
    }
    else {
      this.e_date = this.SelectedDate55.toISOString().slice(0, 10);
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    var a;
    var tmpclass: any = [];
    this.http.get('api/reports/GetClickEntranceReport?classid=' + this.selectedclassq + '&streamid=' + this.selectedstreamq + '&startdate=' + this.s_date.toString() + '&enddate=' + this.e_date.toString(), options).subscribe(
      (data) => {
        
        this.getclickdata = data;
        this.GetClickDetails = this.getclickdata.data;
      }
    )
  }



  GetSummerClickData() {
    

  

    if (this.SelectedDate59 == undefined) {
      this.s_date = "1900-01-01";
    }
    else {
      this.s_date = this.SelectedDate59.toISOString().slice(0, 10);

    }
    if (this.SelectedDate60 == undefined) {
      this.e_date = "1900-01-01";
    }
    else {
      this.e_date = this.SelectedDate60.toISOString().slice(0, 10);
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    var a;
    var tmpclass: any = [];
    this.http.get('api/reports/GetClickSummerReport?startdate=' + this.s_date.toString() + '&enddate=' + this.e_date.toString(), options).subscribe(
      (data) => {
        
        this.getclickdata = data;
        this.GetClickDetails = this.getclickdata.data;
      }
    )
  }

  Getbuldingusage() {
    

    if (this.selectedstatepl == 0 || this.selectedstatepl == undefined) {
      this.selectedstatepl = 0;
    }
    if (this.selectedcitypl == 0 || this.selectedcitypl == undefined) {
      this.selectedcitypl = 0;
    }
    if (this.selectedclasspl == 0 || this.selectedclasspl == undefined) {
      this.selectedclasspl = 0;
    }
    if (this.selectedstreampl == 0 || this.selectedstreampl == undefined) {
      this.selectedstreampl = 0;
    }
    if (this.selectedbuilder == 0 || this.selectedbuilder == undefined) {
      this.selectedbuilder = 0;
    }




    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    var a;
    var tmpclass: any = [];
    this.http.get('api/reports/GetBuildingUsageData?stateid=' + this.selectedstatepl + '&cityid=' + this.selectedcitypl + '&classid=' + this.selectedclasspl + '&streamid=' + this.selectedstreampl + '&op=' + this.selectedbuilder, options).subscribe(
      (data) => {
        
        this.getbuilderdata = data;
        this.GetBuilderDetails = this.getbuilderdata.data;
      }
    )
  }





  GetMaterialusage() {
    

    if (this.selectedstatepl == 0 || this.selectedstatepl == undefined) {
      this.selectedstatepl = 0;
    }
    if (this.selectedcitypl == 0 || this.selectedcitypl == undefined) {
      this.selectedcitypl = 0;
    }
    if (this.selectedclasspl == 0 || this.selectedclasspl == undefined) {
      this.selectedclasspl = 0;
    }
    if (this.selectedstreampl == 0 || this.selectedstreampl == undefined) {
      this.selectedstreampl = 0;
    }
    if (this.selectedcat == 0 || this.selectedcat == undefined) {
      this.selectedcat = 0;
    }
    if (this.selectedsubcat == 0 || this.selectedsubcat == undefined) {
      this.selectedsubcat = 0;
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    var a;
    var tmpclass: any = [];
    this.http.get('api/reports/GetMaterialUsageData?stateid=' + this.selectedstatepl + '&cityid=' + this.selectedcitypl + '&classid=' + this.selectedclasspl + '&streamid=' + this.selectedstreampl + '&category=' + this.selectedbuilder + '&subcategory=' + this.selectedsubcat, options).subscribe(
      (data) => {
        this.getbuilderdata = data;
        this.GetBuilderDetails = this.getbuilderdata.data;
      }
    )
  }

  ResetPpaymentReport() {
    this.GetPaymentDetails = [];
  }

  ResetRegistration() {
    this.selectedstateg = 0;
    this.selectedschoolg = 0;
    this.selectedcityg = 0;
    this.selectedclassg = 0;
    this.selectedstreamg = 0;
    this.SelectedDate9 = null;
    this.SelectedEndDate10 = null;
    this.selectedtype = 3;
    this.selectednotitype = 2;
    this.searchTerm = '';
    this.getNotificationReport();
  }

}
