import { Component, OnInit, Input, PipeTransform, Pipe, Directive, EventEmitter, Output, QueryList, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService,SPINNER  } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { OrderPipe } from 'ngx-order-pipe';
import { PermissionService } from '../../../permission.service';





@Component({
    selector: 'app-studentanalysis',
    templateUrl: './studentanalysis.component.html',
    styleUrls: ['./studentanalysis.component.css']
})


export class StudentAnalysisReportManager implements OnInit {

      public page: number = 1;
      public pageSize: number = 10;

      public page1: number = 1;
      public pageSize1: number = 10;

      public page2: number = 1;
      public pageSize2: number = 10;

      public page3: number = 1;
      public pageSize3: number = 10;

      public page4: number = 1;
      public pageSize4: number = 10;

      public page5: number = 1;
      public pageSize5: number = 10;

      public resData: any = [];
      StudentDocDetail:any=[];
      StudentDocDataDetail:any=[];

      public s_date: any;
      public e_date: any;

      public SelectedDate: any;
      public SelectedEndDate: any;

      studentid:number = 0;

      SchoolData: any = [];
      classdat: any = [];
      ClassData: any = [];
      streamdat: any = [];
      StreamData: any = [];

      statedat: any = [];
      StateData: any = [];
      citdata: any = [];

      selectedstateh: number = 0;
      selectedcityh: number = 0;
      selectedschoolh: number = 0;
      selectedclassh: number = 0;
      selectedstreamh: number = 0;

      public multi: object[];
      public multi1: object[];
      public multi2: object[];
      public multi3: object[];
      public view: any[] = [500, 300];
      public showXAxis = true;
      public showYAxis = true;
      public gradient = false;
      public showLegend = true;
      public showXAxisLabel = true;
      public xAxisLabel: "Month";
      public showYAxisLabel = true;
      public yAxisLabel: "Subscription";
      public graphDataChart: any = [];
      legendTitle: string = 'Type';

      public colorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
      };

      public colorScheme1 = {
        domain: ['#A10A28','#C7B42C','#AAAAAA','#5AA454']
      };

      studentanalysisreport:any = [];
      regdata:any=[];
      studata:any=[];
      stulogdata: any = [];
      studocsdata: any = [];

      spinnerType = SPINNER.rectangleBounce;
      monthwisegraphdata:any=[];
      regcountmonthwise:number=0;
      regcountstatewise: number = 0;

      public SelectedDate9: any;
      public SelectedEndDate10: any;



      TestData: any = [];
      TestDataDetail: any = [];

      downloadData: any = [];
      viewpdflink: any;


      iscareerprabhupurchased: string = '';
      successpaidfeatures: number = 0;
      pendingpaidfeatures: number = 0;
      stuimage: string = '';
      stuname: string = '';
      stuemail: string = '';
      stumobile: string = '';
      stupassword: string = '';
      regdate: string = '';
      Searchanything: string = '';
      selectedstudent: number = 0;
      careerprabhupurchasedate: string = '';
      StudentDataAnalysisReport: any = [];
      StudentData: any = [];
      StuLogDetail: any = [];
      StuLog: any = [];

      StuSuccessDetail: any = [];
      StuPendingDetail: any = [];
      StuSuccess: any = [];
      StuPending: any = [];

      selectedActivity:string='';

      CityData: any = [];
      schdata: any = [];

      year: number = 0;
      month: number = 0;
      date: number = 0;

      public ActivityData:any=[];
      public ActivityDetail:any=[];

      reverse: boolean = false;
      order: string = '';


      StudentList: any = [];
  StudentListData: any = [];


  searchTerm: string = '';

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor(private http: HttpClient, private permission: PermissionService, private router: Router, private localstorage: LocalStorageService, private toaster: ToastrService,
      private loader: NgxUiLoaderService, private config: NgbDatepickerConfig, private orderPipe: OrderPipe, private sanitize: DomSanitizer)
    {

      const currentDate = new Date();
      config.maxDate = { year: currentDate.getFullYear(), month: currentDate.getMonth(), day: currentDate.getDate() };
      config.outsideDays = 'hidden';

      this.date = new Date().getDate();
      this.year = new Date().getFullYear();
      this.month = parseInt(new Date().toISOString().slice(0, 10).split('-')[1]);

  }

  ngOnInit() {

    this.GetActions();
    this.BindState();
    this.BindClass();
    this.BindStream();
    this.BindActivityType();
  }


  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

   /*name of the excel-file which will be downloaded. */
    fileName = 'StudentAnalysisReport.xlsx';

    exportexcel(tagid: string): void {
    
      /* table id is passed over here */
      let element = document.getElementById(tagid);
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, this.fileName);

    }

     setOrder(value: string)
     {
        
        if (this.order === value)
        {
          this.reverse = !this.reverse;
        }

        this.order = value;
    }

     
    BindActivityType(){


      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          let options = { headers: headers };
          this.statedat = [];

          var tmpclass: any = [];
          this.http.get('api/reports/BindActivityType', options).subscribe(

            (data) => {

              this.ActivityData = data;

              if (this.ActivityData.Status == true)
              {
                this.ActivityDetail = this.ActivityData.data;
              }
              else {
                this.ActivityDetail = [];
              }
            }
          )

  }



    setPageno(){
        this.page=1;
    }


  getStudentList() {



    this.Searchanything = "";

    if (this.selectedstudent == 0 )
    {
      this.toaster.error("Please choose student to serach.");
      return;
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
        
    this.http.get('api/reports/getAnalysisStudentDetail?studentid=' + this.selectedstudent + '&searchstring=' + this.Searchanything + '&userid=' + this.localstorage.get('userid'), options).subscribe(

      (data) => {

        this.loader.stop();

        this.StudentList = data;

        if (this.StudentList.Status == true) {
          this.StudentListData = this.StudentList.data;
        }
        else {
          this.StudentListData = [];
        }
      }
    )

  }



  getStudentListbyFreeSearch() {

    this.selectedstudent = 0;

    if (this.Searchanything == '') {
      this.toaster.error("Please enter email, mobile or name to serach.");
      return;
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();

    this.http.get('api/reports/getAnalysisStudentDetail?studentid=' + this.selectedstudent + '&searchstring=' + this.Searchanything + '&userid=' + this.localstorage.get('userid'), options).subscribe(

      (data) => {

        this.loader.stop();

        this.StudentList = data;

        if (this.StudentList.Status == true) {
          this.StudentListData = this.StudentList.data;
        }
        else {
          this.StudentListData = [];
        }
      }
    )

  }



    GetStudentAnalysisReport(studentid:number)
    {

      //if (this.selectedstudent == 0 && this.Searchanything=='')
      //{
      //  this.toaster.error("Please choose any student to search.");
      //  return;
      //}

       

      if (this.SelectedDate9 == undefined || this.SelectedDate9 == null) {
        this.s_date = "1900-01-01";
      }
      else {
          this.s_date = this.SelectedDate9.year + '-' + this.SelectedDate9.month + '-' + this.SelectedDate9.day;

      }
      if (this.SelectedEndDate10 == undefined || this.SelectedEndDate10 == null) {
        this.e_date = "1900-01-01";
      }
      else {
          this.e_date = this.SelectedEndDate10.year + '-' + this.SelectedEndDate10.month + '-' + this.SelectedEndDate10.day;
      }



      this.Searchanything = "";

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      this.loader.start();

      this.http.get('api/reports/GetStudentAnalysisReport?studentid=' + studentid + '&fromdate=' + this.s_date + '&todate=' + this.e_date + '&activitytype=' + this.selectedActivity, options).subscribe(

        (data) => {

          this.loader.stop();

          

          this.studentanalysisreport = data;

          if (this.studentanalysisreport.Status == true)
          {
            this.regdata = this.studentanalysisreport.sturegdetail;

            this.studata = this.studentanalysisreport.studentdetail;

            this.stulogdata = this.studentanalysisreport.stulogdetail;

            this.studocsdata = this.studentanalysisreport.studocdetail;

            if (this.stulogdata == undefined)
            {
                this.stulogdata = [];
            }

            this.studentid = this.studata[0].studentid;

            this.stuimage = this.studata[0].image;
            this.regdate = this.studata[0].regdate;
            this.stupassword = this.studata[0].stupassword;
            this.stuemail = this.studata[0].email;
            this.stuname = this.studata[0].stuname;


            this.iscareerprabhupurchased = this.regdata[0].iscareerprabhupurchased;
            this.successpaidfeatures = this.regdata[0].successpaidfeatures;
            this.pendingpaidfeatures = this.regdata[0].pendingpaidfeatures;
            this.careerprabhupurchasedate = this.regdata[0].careerprabhupurchasedate;
            this.loader.stop();
          }
          else {

              this.regdata = [];
              this.studata = [];
              this.loader.stop();
          }

        }
      )
    }



    getStudentLog()
    {
       

      if (this.SelectedDate9 == undefined || this.SelectedDate9 == null) {
        this.s_date = "1900-01-01";
      }
      else {
          this.s_date = this.SelectedDate9.year + '-' + this.SelectedDate9.month + '-' + this.SelectedDate9.day;

      }
      if (this.SelectedEndDate10 == undefined || this.SelectedEndDate10 == null) {
        this.e_date = "1900-01-01";
      }
      else {
          this.e_date = this.SelectedEndDate10.year + '-' + this.SelectedEndDate10.month + '-' + this.SelectedEndDate10.day;
      }


      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      this.loader.start();

      this.http.get('api/reports/GetStudentAnalysisReport?studentid=' + this.studentid + '&fromdate=' + this.s_date + '&todate=' + this.e_date + '&activitytype=' + this.selectedActivity, options).subscribe(

        (data) => {

          this.loader.stop();

          

          this.studentanalysisreport = data;

          if (this.studentanalysisreport.Status == true)
          {
            this.regdata = this.studentanalysisreport.sturegdetail;

            this.studata = this.studentanalysisreport.studentdetail;

            this.stulogdata = this.studentanalysisreport.stulogdetail;

            if (this.stulogdata == undefined)
            {
                this.stulogdata = [];
            }

            this.studentid = this.studata[0].studentid;

            this.stuimage = this.studata[0].image;
            this.regdate = this.studata[0].regdate;
            this.stupassword = this.studata[0].stupassword;
            this.stuemail = this.studata[0].email;
            this.stuname = this.studata[0].stuname;


            this.iscareerprabhupurchased = this.regdata[0].iscareerprabhupurchased;
            this.successpaidfeatures = this.regdata[0].successpaidfeatures;
            this.pendingpaidfeatures = this.regdata[0].pendingpaidfeatures;
            this.careerprabhupurchasedate = this.regdata[0].careerprabhupurchasedate;
            this.loader.stop();
          }
          else {

              this.regdata = [];
              this.studata = [];
              this.loader.stop();
          }

        }
      )
    }




    RestSearchString() {
      this.Searchanything = "";

      this.stuimage = '';
      this.regdate = '';
      this.stupassword = '';
      this.stuemail = '';
      this.stuname = '';

      this.selectedActivity='';

      this.iscareerprabhupurchased = '';
      this.successpaidfeatures = 0;
      this.pendingpaidfeatures = 0;
      this.careerprabhupurchasedate = '';

      this.regdata = [];
      this.studata = [];
      this.stulogdata = [];
    }

    ResetAnalysisReport()
  {
      this.loader.start();
      this.selectedstateh = 0;
      this.selectedcityh = 0;
      this.selectedschoolh = 0;
      this.selectedclassh = 0;
      this.selectedstreamh = 0;
      this.selectedstudent = 0;
      this.selectedActivity='';

      this.stuimage = '';
      this.regdate ='';
      this.stupassword = '';
      this.stuemail = '';
      this.stuname = '';


      this.iscareerprabhupurchased = '';
      this.successpaidfeatures = 0;
      this.pendingpaidfeatures = 0;
      this.careerprabhupurchasedate = '';

      this.regdata = [];
      this.studata = [];
      this.stulogdata = [];

      this.loader.stop();

    }

    GetStudentAnalysisReportFromFreeSearch()
  {

      if (this.Searchanything == '')
      {
        this.toaster.error("Please enter email or phoneno. or name to search.");
        return;
      }


      if (this.SelectedDate9 == undefined || this.SelectedDate9 == null) {
        this.s_date = "1900-01-01";
      }
      else {
          this.s_date = this.SelectedDate9.year + '-' + this.SelectedDate9.month + '-' + this.SelectedDate9.day;

      }
      if (this.SelectedEndDate10 == undefined || this.SelectedEndDate10 == null) {
        this.e_date = "1900-01-01";
      }
      else {
          this.e_date = this.SelectedEndDate10.year + '-' + this.SelectedEndDate10.month + '-' + this.SelectedEndDate10.day;
      }

      this.selectedstateh = 0;
      this.selectedcityh = 0;
      this.selectedschoolh = 0;
      this.selectedclassh = 0;
      this.selectedstreamh = 0;
      this.selectedstudent = 0;

      

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      this.loader.start();

      this.http.get('api/reports/GetStudentAnalysisReportFreeSearch?searchdata=' + this.Searchanything + '&fromdate=' + this.s_date + '&todate=' + this.e_date + '&activitytype=' + this.selectedActivity, options).subscribe(

        (data) => {

          this.loader.stop();
          
          this.studentanalysisreport = data;

          if (this.studentanalysisreport.Status == true) {

            this.regdata = this.studentanalysisreport.sturegdetail;

            this.studata = this.studentanalysisreport.studentdetail;

            this.stulogdata = this.studentanalysisreport.stulogdetail;


            this.studentid = this.studata[0].studentid;

            if (this.stulogdata == undefined) { this.stulogdata = [];}

            this.stuimage = this.studata[0].image;
            this.regdate = this.studata[0].regdate;
            this.stupassword = this.studata[0].stupassword;
            this.stuemail = this.studata[0].email;
            this.stuname = this.studata[0].stuname;


            this.iscareerprabhupurchased = this.regdata[0].iscareerprabhupurchased;
            this.successpaidfeatures = this.regdata[0].successpaidfeatures;
            this.pendingpaidfeatures = this.regdata[0].pendingpaidfeatures;
            this.careerprabhupurchasedate = this.regdata[0].careerprabhupurchasedate;
          }
          else {
            this.regdata = [];
            this.studata = [];
          }
   

        }
      )
  }
  

    showDetails(primaryId: number, type: string)
    {

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };
     
        this.http.get('api/reports/GetStudentDetailsonPopup?primaryid=' + primaryId + '&type=' + type , options).subscribe(

          (data) => {

            this.StuLogDetail = data;

            if (this.StuLogDetail.Status == true) {
              this.StuLog = this.StuLogDetail.data;
            }
            else {
              this.StuLog = [];
            }
          }
        )

    }

    getStudent() {
      
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.statedat = [];

      this.http.get('api/reports/GetStudent?classid=' + this.selectedclassh + '&streamid' + this.selectedstreamh + '&schoolid=' + this.selectedschoolh + '&stateid=' + this.selectedstateh + '&cityid=' + this.selectedcityh + '&userid=' + this.localstorage.get('userid'), options).subscribe(

        (data) => {

          this.StudentDataAnalysisReport = data;

          if (this.StudentDataAnalysisReport.Status == true)
          {
            this.StudentData = this.StudentDataAnalysisReport.data;
          }
          else
          {
            this.StudentData = [];
          }
        }
      )
    }


    showSuccessDetail() {
      
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      this.http.get('api/reports/GetStudentSuccessDetail?primaryid=' + this.studentid, options).subscribe(

        (data) => {

          this.StuSuccessDetail = data;

          if (this.StuSuccessDetail.Status == true) {
            this.StuSuccess = this.StuSuccessDetail.successdata;
          }
          else {
            this.StuSuccess = [];
          }
        }
      )
    }

    showPendingDetail() {
      
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      this.http.get('api/reports/GetStudentPendingDetail?primaryid=' + this.studentid, options).subscribe(

        (data) => {

          this.StuPendingDetail = data;

          if (this.StuPendingDetail.Status == true) {
            this.StuPending = this.StuPendingDetail.pendingdata;
          }
          else {
            this.StuPending = [];
          }
        }
      )
    }



    BindState() {
      
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.statedat = [];

      var tmpclass: any = [];
      this.http.post('api/addstudentpartner/Bindstate', options).subscribe(

        (data) => {
          this.statedat = data;
          if (this.statedat.Status == true) {
            this.StateData = this.statedat.data;
          }
          else {
            this.StateData = this.statedat.data;
          }
        }
      )
    }


    BindCity() {
      
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.citdata = [];
      var body = {

        "stateid": this.selectedstateh
      }
      var tmpclass: any = [];
      this.http.post('api/addstudentpartner/BindCity', body, options).subscribe(

        (data) => {
          this.citdata = data;
          if (this.citdata.Status == true) {
            this.CityData = this.citdata.data;
          }
          else {
            this.CityData = this.citdata.data;
          }
        }
      )
    }


    BindSchool() {
      
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.schdata = [];
      var body = {

        "stateid": this.selectedstateh,
        "cityid": this.selectedcityh
      }
      var tmpclass: any = [];
      this.http.post('api/addstudentpartner/BindSchool', body, options).subscribe(

        (data) => {
          this.schdata = data;
          if (this.schdata.Status == true) {
            this.SchoolData = this.schdata.data;
          }
          else {
            this.SchoolData = this.schdata.data;
          }
        }
      )
    }

   BindClass() {
      
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.classdat = [];

    
      this.http.post('api/reports/Bindclass', options).subscribe(

        (data) => {
          this.classdat = data;
          if (this.classdat.Status == true) {
            this.ClassData = this.classdat.data;
          }
          else {
            this.ClassData = this.classdat.data;
          }
        }
      )
    }

   BindStream() {
      
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.streamdat = [];

    
      this.http.post('api/reports/BindStream', options).subscribe(

        (data) => {
          
          this.streamdat = data;
          if (this.streamdat.Status == true) {
            this.StreamData = this.streamdat.data;
          }
          else {
            this.StreamData = this.streamdat.data;
          }
        }
      )
    }


  GetTestlink() {
    //this.studentid
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('/api/reports/IsTestSubmited?studentid=' + this.studentid, options).subscribe(
      (data) => {

        

        this.TestData = data;

        if (this.TestData.status == true) {
          this.TestDataDetail = this.TestData.data;
        }
        else {
          this.TestDataDetail = [];
        }


        if (this.TestDataDetail.length != 0 && this.TestDataDetail.pdflink != '') {
          this.ViewPDF(this.TestDataDetail.pdflink);

        }
        else { this.viewpdflink = ''; this.loader.stop(); }

        //this.loader.stop();

      }
    )
  }



  ViewPDF(link: string)
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var data = {
      "filelink": link
    }
    let body = JSON.stringify(data);
    this.loader.start();
    this.http.post('/api/reports/GetFileDetailsWithCertificate', body, { headers: headers, responseType: 'blob' }).subscribe(
      (data) => {

        this.downloadData = data;
        this.viewpdflink = this.sanitize.bypassSecurityTrustResourceUrl(URL.createObjectURL(data));
        this.loader.stop();

      }
    )
  }

}
