import { Component, OnInit, Input, ElementRef, ViewChild, PipeTransform, Pipe, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, Event } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { EmbedVideoService } from 'ngx-embed-video';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

import { Time } from '@angular/common';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbTimepickerConfig, NgbTimeStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;




@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    console.log(`Pipe Called!`);
    switch (type) {
      case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default: throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}

@Component({
  selector: 'app-plannedactivity',
  templateUrl: './plannedactivity.component.html',
  styleUrls: ['./plannedactivity.component.css'],
 
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, NgbTimepickerConfig]
})
export class PlannedaActivityManager implements OnInit {
  // public page: number = 0;
  //public pageSize: number = 10;
  public SelectedTopic: number = 0;
  public videofile: any = [];
  public imagefile: any = [];
  public TopicData: any = [];
  public statedat: any = [];
  public StateData: any = [];
  public citdata: any = [];
  public CityData: any = [];
  public selectedstate: number = 0;
  public selectedcity: number = 0;
  public selectedschool: number = 0;
  public schdata: any = [];
  public SchoolData: any = [];
  public Detail: any = [];
  public SelectedClass: number = 0;
  public ClassData: any=[];
  public topicid: number = 0;
  public SelectedStream: number = 0;
  public StreamData: any = [];
  public ButtonText: string = 'Save';
  public HeaderData: any = [];
  public GetSaveData: Array<any> = [];
  public SelectedVideo: string = "";
  public SelectedImage: string = "";
  public Id: number = 0;
  search: string = "";
  public SelectedDate: Date;
  public SelectedEndDate: Date;
  public st_time: Time;

  public end_time: Time;
  public meridian: boolean = true;


  public GetEditedData: any = [];
  public DeletedData: any = [];
  public PlannedActivityData: any = [];
  public orgVideoName: string = "";
  public orgImageName: string = "";
  public ClassDetails: any = [];
  public StreamDetails: any = [];
  public videouploaded: String = "0";
  public videouploaddiv: boolean = false;
  public videoToUpload: any;
  public imageToUpload: any;
  public video: string = "";
  public image: string = "";
  public vname: boolean = false;
  public display: any = "none";
  public url: string = "";
  public yt: any;
  @ViewChild('inputFile', { static: true }) private myInputVariable: ElementRef;
  public Details: any = [];
  public classid: string = "";
  public AllClass: boolean = false;
  public streamid: string = "";
  public AllStream: boolean = false;
  public s_date: any;
  public e_date: any;
  public todaydate: any;
  public starttime: NgbTimeStruct;
  public endtime: NgbTimeStruct;
  public checklink: boolean = false;
  public Selecteduser: number = 0;
  public UserDetails: any = [];
  public UserData: any = [];
  public showstream: number = 0;
  //public publish: number = 0;
  public checklink_v: boolean = false;

  //public publish: boolean = false;
  public publish: number = 0;
  public IsSchool: boolean = false;
  chatlink: string = "";
  StateIds: string = "";
  AllState: boolean = false;
  CityIds: string = "";
  AllCity: boolean = false;
  SchoolIds: string = "";
  AllSchool: boolean = false;
  GetSchoolData: any = [];
  GetCityData: any = [];
  selectedwebtype: number = 0;
  ShowTable: boolean = false;

  videolink: any;
  p: any;

  constructor(private http: HttpClient, private sanitize: DomSanitizer, private router: Router, private localstorage: LocalStorageService, private toaster: ToastrService, private Loader: NgxUiLoaderService, private renderer: Renderer2, config: NgbTimepickerConfig, private config1: NgbDatepickerConfig) {
    config.seconds = false;
    config.spinners = false;
    config.meridian = true;

    const current = new Date();
    config1.minDate = {
      year: current.getFullYear(), month:
        current.getMonth() + 1, day: current.getDate()
    };
    config1.outsideDays = 'hidden';


  }
  ngOnInit() {
    this.GetClass();
    this.GetStream();
    this.BindState();
    this.GetTopic();
    this.BindUser();
    this.GetData();
  }

  getSanitizeHTML(html: string) {
    return this.sanitize.bypassSecurityTrustHtml(html);
  }
  ShowVideo(link) {
    this.videolink = link;
  }

  BindWebinar(id:number) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.Detail = [];

    this.http.get('api/Plannedactivity/GetSavedDataFilter?acttype=' + 'GetSavedData'+'&id='+ id, options).subscribe(
      (data) => {
        
        this.Detail = data;
        if (this.Detail.Statue == true) {
          this.GetSaveData = this.Detail.data;
          this.ShowTable = true;
        }
        else {
          this.GetSaveData = [];
          this.ShowTable = false;
        }
             
      }
    )
  }

  //Bind User
  BindUser() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/Plannedactivity/BindUser', options).subscribe(
      (data) => {
        
        this.UserDetails = data;
        if (this.UserDetails.status = true) {
          this.UserData = this.UserDetails.data;
        }
        else {
          this.toaster.error(this.UserDetails.message.toString(), '', { easeTime: 1000, timeOut: 3000 })
        }
      }
    )
  }

  //Get all data for bind dropdowns
  GetTopic() {
    
    let stateid = this.selectedstate;
    let cityid = this.selectedcity;
    let schoolid = this.selectedschool;
    let classid = this.SelectedClass;
    let streamid = this.SelectedStream;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };


    this.http.get('api/Plannedactivity/GetTopicData?acttype=' + 'Topic', options).subscribe(

      (data) => {
        
        this.Detail = data;

        if (this.Detail.Status == true) {
          this.TopicData = this.Detail.data;
        }
        else {
          this.toaster.error(this.Detail.Message.toString(), '', { easeTime: 1000, timeOut: 3000 })
          //this.SelectedTopic = 0;
          this.TopicData = [];
        }
      }
    )
  }

  BindState() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.statedat = [];

    var tmpclass: any = [];
    this.http.post('api/Plannedactivity/Bindstate', options).subscribe(

      (data) => {
        this.statedat = data;
        if (this.statedat.Status == true) {
          this.StateData = this.statedat.data;
        }
        else {
          this.StateData = this.statedat.data;
        }
      }
    )
  }

  BindCity() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.citdata = [];
    var body = {

      "stateid": this.StateIds
    }
    var tmpclass: any = [];
    this.http.post('api/Plannedactivity/BindCity', body, options).subscribe(

      (data) => {
        this.citdata = data;
        if (this.citdata.Status == true) {
          this.CityData = this.citdata.data;
        }
        else {
          this.CityData = this.citdata.data;
        }
      }
    )
  }

  BindSchool() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.schdata = [];
    var body = {
      "stateid": this.StateIds,
      "cityid": this.CityIds
    }
    var tmpclass: any = [];
    this.http.post('api/Plannedactivity/BindSchool', body, options).subscribe(

      (data) => {
        this.schdata = data;
        if (this.schdata.Status == true) {
          this.SchoolData = this.schdata.data;
        }
        else {
          this.SchoolData = this.schdata.data;
        }
      }
    )
  }

  GetClass() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/Plannedactivity/GetClass', options).subscribe(
      (data) => {
        this.Details = data;
        if (this.Details.status == true) {
          this.ClassData = this.Details.data;
        }
        else {
          this.toaster.error(this.Details.message.toString(), '', { easeTime: 1000, timeOut: 3000 })
        }
      }
    )
  }
  //get stream
  GetStream() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.http.get('api/Plannedactivity/getStream', options).subscribe(
      (data) => {
        this.StreamDetails = data;
        if (this.StreamDetails.status == true) {
          this.StreamData = this.StreamDetails.data;
          this.GetData();
        }
        else {
          this.toaster.error(this.StreamDetails.message.toString(), '', { easeTime: 1000, timeOut: 3000 })
        }
      }
    )
  }

  //get selected class
  getSelectedClass() {
    
    this.classid = "";
    var count = 0;

    for (var i = 0; i < this.ClassData.length; i++) {
      if (this.ClassData[i].selected === true) {
        if (this.classid === '') {
          this.classid = this.ClassData[i].classid;
          count++;
        }
        else {
          this.classid = this.classid + ',' + this.ClassData[i].classid;
          count++;
        }
      }
      else {
        this.showstream = 0;
      }
    }

    for (var i = 0; i < this.ClassData.length; i++) {
      if (this.ClassData[i].selected === true) {
        if (this.ClassData[i].classid == 1 || this.ClassData[i].classid == 2 || this.ClassData[i].classid == 3) {
          this.showstream = 0;
        }
        else {
          this.showstream = 1;
        }
      }     
    }

    if (this.ClassData.length === count) {
      this.AllClass = true;
      this.classid = "0";
    }
    else {
      this.AllClass = false;
    }
  }

  //get selected stream
  //get stream
  getSelectedStream() {
    
    this.streamid = "";
    var count = 0;

    for (var i = 0; i < this.StreamData.length; i++) {
      if (this.StreamData[i].selected === true) {
        if (this.streamid === '') {
          this.streamid = this.StreamData[i].streamid;
          count++;
        }
        else {
          this.streamid = this.streamid + ',' + this.StreamData[i].streamid;
          count++;
        }
      }
    }

    if (this.StreamData.length === count) {
      this.AllStream = true;
      this.streamid = "0";
    }
    else {
      this.AllStream = false;
    }
  }


  //Select All function for class
  SelectAllClass() {
    
    this.classid = "";

    if (this.AllClass === true) {
      for (var i = 0; i < this.ClassData.length; i++) {
        this.ClassData[i].selected = true;        
      }
      this.showstream = 1;
      this.classid = "0";
    }
    else {
      for (var i = 0; i < this.ClassData.length; i++) {
        this.ClassData[i].selected = false;
      }
      this.showstream = 0;
    }
  }

  //Select All function for stream
  SelectAllStream() {
    
    this.streamid = "";

    if (this.AllStream === true) {
      for (var i = 0; i < this.StreamData.length; i++) {
        this.StreamData[i].selected = true;      
      }
      this.streamid = "0";
    }
    else {
      for (var i = 0; i < this.StreamData.length; i++) {
        this.StreamData[i].selected = false;
      }
    }

  }

  GetData() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.Detail = [];

    this.http.get('api/Plannedactivity/GetSavedData?acttype=' + 'GetSavedData', options).subscribe(
      (data) => {
        
        this.Detail = data;
        if (this.Detail.Statue == true) {
          this.GetSaveData = this.Detail.data;
          this.ShowTable = true;
        }
        else {
          this.GetSaveData = [];
          this.ShowTable = false;
        }
          
      }
    )
  }

  GetImageDetail(event) {
    
    this.imagefile = event
    let file = event.target.files[0];


    //let file = event.filesData[0];

    let fileList: FileList = event.target.files;

    //let fileList: FileList = file;
    //this.imageToUpload = file.rawFile;

    this.imageToUpload = fileList[0];

    if (file.type.includes("png") || file.type.includes("jpg") || file.type.includes("jpeg")) {
      //this.orgImageName = event.filesData[0].name;
      this.orgImageName = file.name;
    }
    else {
      Swal.fire("", "Please Select Image", "error");
      this.myInputVariable.nativeElement.value = "";

    }
  }

  //new save plan activity

  onSubmit() {
    
    if (this.ButtonText == "Save") {

      //validation start 


      if (this.SelectedDate == null || this.SelectedDate == undefined) {
        Swal.fire("", "Please enter start date ", "error");
        return;
      }
      var res = this.SelectedVideo.match(/vimeo/g);
      if (res == null || res == undefined) {
        Swal.fire("", "Please enter only vimeo video link", "error");
       return;
      }

      //if (this.SelectedVideo == "" || this.SelectedDate == undefined || this.SelectedVideo.match("")) {
      //  Swal.fire("", "Please enter start date ", "error");
      //  return;
      //}

      if (this.SelectedEndDate == null || this.SelectedEndDate == undefined) {
        Swal.fire("", "Please enter end date ", "error");
        return;
      }
      if (this.starttime == null || this.starttime == undefined) {
        Swal.fire("", "Please enter start time", "error");
        return;
      }
      if (this.endtime == null || this.endtime == undefined) {
        Swal.fire("", "Please enter end time", "error");
        return;
      }

      if (this.Selecteduser == 0 || this.Selecteduser == undefined) {
        Swal.fire("", "Please select user type", "error");
        return;
      }
      if (this.IsSchool == false) {
        this.publish = 0;
      }
      else {
        this.publish = 1;
      }



      this.s_date = this.SelectedDate.toISOString().slice(0, 10);
      this.e_date = this.SelectedEndDate.toISOString().slice(0, 10);
      this.todaydate = new Date().toISOString().slice(0, 10);
      var st_time = this.starttime.hour.toString() + ':' + this.starttime.minute.toString() + ':' + this.starttime.second.toString();
      var end_time = this.endtime.hour.toString() + ':' + this.endtime.minute.toString() + ':' + this.endtime.second.toString();
      if (this.s_date == this.e_date) {
        if (this.s_date < this.todaydate) {
          Swal.fire("", "Start date should contains future date", "error");
          return;
        }
        if (this.e_date < this.todaydate) {
          Swal.fire("", "End date should contain future date", "error");
          return;
        }

        if ((this.starttime.hour > this.endtime.hour) || ((this.starttime.hour == this.endtime.hour && this.starttime.minute >= this.endtime.minute))) {
          Swal.fire("", "Start time always less then end time", "error");
          return;
        }
      }
      else {
        if (this.s_date < this.todaydate) {
          Swal.fire("", "Start date should contains future date", "error");
          return;
        }
        if (this.e_date < this.todaydate) {
          Swal.fire("", "End date should contain future date", "error");
          return;
        }


        if (this.s_date > this.e_date) {
          Swal.fire("", "End date always greater then start date", "error");
          return;
        }
      }



      if (this.selectedstate == 0 || this.selectedstate == undefined) {
        this.selectedstate = 0;
      }
      if (this.selectedcity == 0 || this.selectedcity == undefined) {
        this.selectedcity = 0;
      }
      if (this.selectedschool == 0 || this.selectedschool == undefined) {
        this.selectedschool = 0;
      }

      if (this.classid == "" || this.classid == undefined) {
        Swal.fire("", "Please select class", "error");
        return;
      }
      if (this.SelectedTopic == 0 || this.SelectedTopic == undefined) {
        Swal.fire("", "Please select topic", "error");
        return;
      }
      if (this.selectedwebtype == 0 || this.selectedwebtype == undefined) {
        Swal.fire("", "Please select webinar type", "error");
        return;
      }


      //if (this.SelectedVideo == "" || this.SelectedVideo == undefined) {
      //  Swal.fire("", "Please enter video link", "error");
      //  return;
      //}
      //if (this.orgImageName == "" || this.orgImageName == undefined) {
      //  Swal.fire("", "Please choose image", "error");
      //  return;
      //}


      //validation end


      let input = new FormData();

      //input.append("video", this.videoToUpload);
      input.append("image", this.imageToUpload);

      input.append("acttype", "Save");
      input.append("Id", this.Id.toString());

      input.append("topicid", this.SelectedTopic.toString());
      //input.append("classid", this.SelectedClass.toString());
      input.append("classid", this.classid.toString());
      // input.append("streamid", this.SelectedStream.toString());
      input.append("streamid", this.streamid.toString());

      input.append("orgvideoname", this.SelectedVideo);
      input.append("chatlink", this.chatlink);
      input.append("orgimagename", this.orgImageName);

      input.append("ondate", this.s_date.toString());
      input.append("enddatetime", this.e_date.toString());

      input.append("starttime", st_time.toString());
      input.append("endtime", end_time.toString());
      input.append("stateid", this.StateIds.toString());
      input.append("schoolid", this.SchoolIds.toString());
      input.append("cityid", this.CityIds.toString());
      input.append("usertype", this.Selecteduser.toString());
      input.append("published", this.publish.toString());
      input.append("webinartype", this.selectedwebtype.toString());

      input.append("createdby", this.localstorage.get("userid").toString());

      this.http.post('api/Plannedactivity/SavePlannActivity', input)
        .subscribe(
          (data) => {
            
            this.PlannedActivityData = data;
            if (this.PlannedActivityData.length > 10) {
              Swal.fire("", "Saved Successfully", "success");
              this.onClear();
              this.GetData();

              return;
            }

          })



    }
    else {
      
      if (this.SelectedDate == null || this.SelectedDate == undefined) {
        Swal.fire("", "Please enter start date ", "error");
        return;
      }
      var res = this.SelectedVideo.match(/vimeo/g);
      if (res == null || res == undefined) {
        Swal.fire("", "Please enter only vimeo video link", "error");
        return;
      }

      if (this.SelectedEndDate == null || this.SelectedEndDate == undefined) {
        Swal.fire("", "Please enter end date ", "error");
        return;
      }
      if (this.starttime == null || this.starttime == undefined) {
        Swal.fire("", "Please enter start time", "error");
        return;
      }
      if (this.endtime == null || this.endtime == undefined) {
        Swal.fire("", "Please enter end time", "error");
        return;
      }

      if (this.IsSchool == false) {
        this.publish = 0;
      }
      else {
        this.publish = 1;
      }
      this.s_date = this.SelectedDate.toISOString().slice(0, 10);
      this.e_date = this.SelectedEndDate.toISOString().slice(0, 10);
      this.todaydate = new Date().toISOString().slice(0, 10);
      var st_time = this.starttime.hour.toString() + ':' + this.starttime.minute.toString() + ':' + this.starttime.second.toString();
      var end_time = this.endtime.hour.toString() + ':' + this.endtime.minute.toString() + ':' + this.endtime.second.toString();
      if (this.s_date == this.e_date) {
        //if (this.s_date < this.todaydate) {
        //  Swal.fire("", "Start date should contains future date", "error");
        //  return;
        //}
        //if (this.e_date < this.todaydate) {
        //  Swal.fire("", "End date should contain future date", "error");
        //  return;
        //}

        if ((this.starttime.hour > this.endtime.hour) || ((this.starttime.hour == this.endtime.hour && this.starttime.minute >= this.endtime.minute))) {
          Swal.fire("", "Start time always less then end time", "error");
          return;
        }
      }
      else {
        //if (this.s_date < this.todaydate) {
        //  Swal.fire("", "Start date should contains future date", "error");
        //  return;
        //}
        //if (this.e_date < this.todaydate) {
        //  Swal.fire("", "End date should contain future date", "error");
        //  return;
        //}


        if (this.s_date > this.e_date) {
          Swal.fire("", "End date always greater then start date", "error");
          return;
        }
      }


      if (this.selectedstate == 0 || this.selectedstate == undefined) {
        this.selectedstate = 0;
      }
      if (this.selectedcity == 0 || this.selectedcity == undefined) {
        this.selectedcity = 0;
      }
      if (this.selectedschool == 0 || this.selectedschool == undefined) {
        this.selectedschool = 0;
      }

      if (this.classid == "" || this.classid == undefined) {
        Swal.fire("", "Please select class", "error");
        return;
      }

      if (this.SelectedTopic == 0 || this.SelectedTopic == undefined) {
        Swal.fire("", "Please select any topic", "error");
        return;
      }
      if (this.selectedwebtype == 0 || this.selectedwebtype == undefined) {
        Swal.fire("", "Please select webinar type", "error");
        return;
      }


      //if (this.orgVideoName == "" || this.orgVideoName == undefined) {
      //  Swal.fire("", "Please Choose any video", "error");
      //  return;
      //}
      //if (this.orgImageName == "" || this.orgImageName == undefined) {
      //  Swal.fire("", "Please Choose any image", "error");
      //  return;
      //}


      //validation end


      let input = new FormData();

      //input.append("video", this.videoToUpload);
      input.append("image", this.imageToUpload);

      input.append("acttype", "Update");
      input.append("Id", this.Id.toString());

      input.append("topicid", this.SelectedTopic.toString());
      //input.append("classid", this.SelectedClass.toString());
      input.append("classid", this.classid.toString());
      // input.append("streamid", this.SelectedStream.toString());
      input.append("streamid", this.streamid.toString());

      input.append("orgvideoname", this.SelectedVideo);
      input.append("chatlink", this.chatlink);
      input.append("orgimagename", this.orgImageName);

      input.append("ondate", this.s_date.toString());
      input.append("enddatetime", this.e_date.toString());
      input.append("starttime", st_time.toString());
      input.append("endtime", end_time.toString());
      input.append("stateid", this.StateIds.toString());
      input.append("schoolid", this.SchoolIds.toString());
      input.append("cityid", this.CityIds.toString());
      input.append("usertype", this.Selecteduser.toString());
      input.append("published", this.publish.toString());
      input.append("webinartype", this.selectedwebtype.toString());

      input.append("createdby", this.localstorage.get("userid").toString());

      this.http.post('api/Plannedactivity/SavePlannActivity', input)
        .subscribe(
          (data) => {
            
            this.PlannedActivityData = data;
            if (this.PlannedActivityData.length > 10) {
              Swal.fire("", "Updated Successfully", "success");
              this.onClear();
              this.GetData();
              this.AllCity = false;
              this.AllState = false;
              this.AllSchool = false;

              for (var i = 0; i < this.ClassData.length; i++) {
                this.ClassData[i].selected = false;
              }
              for (var i = 0; i < this.StreamData.length; i++) {
                this.StreamData[i].selected = false;
              }
              for (var i = 0; i < this.StateData.length; i++) {
                this.StateData[i].selected = false;
              }
              for (var i = 0; i < this.CityData.length; i++) {
                this.CityData[i].selected = false;
              }
              for (var i = 0; i < this.SchoolData.length; i++) {
                this.SchoolData[i].selected = false;
              }
              return;
            }

          })


    }



  }

  //check valid file
  validateFile(name: string) {

    var ext = name.substring(name.lastIndexOf('.') + 1);

    if (ext.toLowerCase() == 'docx' || ext.toLowerCase() == 'doc' || ext.toLowerCase() == 'pdf' || ext.toLowerCase() == 'xls' || ext.toLowerCase() == 'xlsx' || ext.toLowerCase() == 'ppt') {
      return true;
    }
    else {
      return false;
    }
  }

  //Reset Button

  onClear() {
    
    this.StateIds = "";
    this.CityIds = "";
    this.SchoolIds = "";

    this.ButtonText = 'Save';
    this.showstream = 0;
    this.SelectedTopic = 0;
    this.IsSchool = false;
    this.publish = 0;
    //this.model.published = false;
    //this.model.unpublished = false;
    //this.publish = false;
    // this.publish_v = 0;

    //this.SelectedClass = 0;
    //this.SelectedStream = 0;
    //this.SelectedDate = "";
    //this.SelectedEndDate = "";
    this.selectedstate = 0;
    this.selectedcity = 0;
    this.selectedschool = 0;
    this.Selecteduser = 0;
    this.SelectedTopic = 0;
    this.orgVideoName = "";
    this.orgImageName = "";
    this.SelectedVideo = "";
    //this.SelectedDate = new Date("");
    //this.s_date = "";
    //this.SelectedEndDate = new Date("");
    //this.e_date = "";
    this.SelectedDate = null;
    this.SelectedEndDate = null;
    this.st_time = null;
    this.end_time = null;
    this.s_date = null;
    this.e_date = null;
    this.starttime = null;
    this.endtime = null;
    this.AllClass = false;
    this.AllStream = false;
    this.chatlink = "";
    this.AllCity = false;
    this.AllState = false;
    this.AllSchool = false;
   // this.GetData();

    this.CityData = [];
    this.SchoolData = [];
    this.StreamData = [];
    

    for (var i = 0; i < this.ClassData.length; i++) {
      this.ClassData[i].selected = false;
    }
    for (var i = 0; i < this.StateData.length; i++) {
      this.StateData[i].selected = false;
    }   

    this.myInputVariable.nativeElement.value = "";
    this.selectedwebtype = 0;


  }

  //For Delete Data
  DeleteData(i: number, Id) {
    var data;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    data =
    {
      "activity_id": Id,
      "acttype": "Delete",
      "createdby": parseInt(this.localstorage.get("userid"))
    };

    let body = JSON.stringify(data);
    Swal.fire({
      text: 'Are you sure to delete this record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.http.post('api/Plannedactivity/DeleteActivity', body, options).subscribe(
          (data) => {
            this.DeletedData = data;
            if (this.DeletedData.Status == true) {
              Swal.fire("", "Deleted Successfully", "success");
              this.GetData();
              return;
            }
          }
        )
      }
    })
  }

  //for Edit Data
  EditData(i: number, Id) {
    this.onClear();
    this.BindUser();
    this.GetStream();

    this.ButtonText = 'Update';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/Plannedactivity/GetEditData?acttype=' + 'Edit&activity_id=' + Id, options).subscribe(
      (data) => {
        
        this.GetEditedData = data;
        if (this.GetEditedData.Status == true) {

          if (this.GetEditedData.data.stateid == "0" && this.GetEditedData.data.cityid == "0" && this.GetEditedData.data.schoolid == "0") {
            this.AllState = true;
            this.SelectAllState();
            this.onChangeOfMultiCheckBoxToGetCity();
            this.AllCity = true;
            this.SelectAllCity()
            this.onChangeOfMultiCheckBoxToGetSchool();
            this.AllSchool = true;
            this.SelectAllSchool();
          }
          else {
            if (this.GetEditedData.Status != undefined) {
              if (this.GetEditedData.Status == true) {
                this.StateIds = this.GetEditedData.data.stateid;

                if (this.StateIds == '0') {
                  this.AllState = true;
                  this.StateData.forEach(item => { item.selected = true; });
                }
                else {
                  var tmpstateId = this.GetEditedData.data.stateid.split(",");

                  for (var j = 0; j < tmpstateId.length; j++) {
                    for (var i = 0; i < this.StateData.length; i++) {
                      if (tmpstateId[j] === this.StateData[i].stateId.toString()) {
                        this.StateData[i].selected = true;
                      }
                    }
                  }
                }

                this.onChangeOfMultiCheckBoxToGetCity();
              }
            }
          }

          this.classid = this.GetEditedData.data.Classid;
          this.streamid = this.GetEditedData.data.streamId;


          var tmpClassId = this.GetEditedData.data.Classid.split(",");
          for (var i = 0; i < this.ClassData.length; i++) {
            for (var j = 0; j < tmpClassId.length; j++) {
              if (this.ClassData[i].classid == tmpClassId[j]) {
                this.ClassData[i].selected = true;
              }
            }
          }



          for (var i = 0; i < this.ClassData.length; i++) {
            if (this.ClassData[i].selected === true) {
              if (this.ClassData[i].classid == 1 || this.ClassData[i].classid == 2 || this.ClassData[i].classid == 3) {
                this.showstream = 0;
              }
              else {
                this.showstream = 1;
              }
            }          
          }


          if (this.ClassData.length == tmpClassId.length) {
            this.AllClass = true;
          }
          else {
            this.AllClass = false;
          }



          var tmpStreamid = this.GetEditedData.data.streamId.split(",");
          for (var i = 0; i < this.StreamData.length; i++) {
            for (var j = 0; j < tmpStreamid.length; j++) {
              if (this.StreamData[i].streamid == tmpStreamid[j]) {
                this.StreamData[i].selected = true;
              }
            }
          }


          if (this.StreamData.length == tmpStreamid.length) {
            this.AllStream = true;
          }
          else {
            this.AllStream = false;
          }


          var mdate = new Date(this.GetEditedData.data.Date);
          var edate = new Date(this.GetEditedData.data.endDate);

          this.GetTopic();
          this.Id = this.GetEditedData.data.Id;
          //this.SelectedDate = new Date(Date.parse(this.GetEditedData.data.Date));
          this.SelectedDate = mdate;
          this.SelectedEndDate = edate
          this.SelectedVideo = this.GetEditedData.data.VideoName;
          this.chatlink = this.GetEditedData.data.chatlink;
          //this.orgImageName = this.GetEditedData.data.ImageName;


          var mtime = this.GetEditedData.data.starttime.split(":");
          var etime = this.GetEditedData.data.endtime.split(":");

          this.starttime = { hour: parseInt(mtime[0]), minute: parseInt(mtime[1]), second: parseInt(mtime[2]) };
          this.endtime = { hour: parseInt(etime[0]), minute: parseInt(etime[1]), second: parseInt(etime[2]) };



          this.Selecteduser = this.GetEditedData.data.usertype;
          //  this.model.published = this.GetEditedData.data.published == 0 ? false : true;
          //if (this.GetEditedData.data.publish == 0) {
          //  this.model.unpublished = true;

          //  this.publish = 0;
          //}
          //else {
          //  this.model.published = true;
          //  this.publish = 1;
          //}

          this.IsSchool = this.GetEditedData.data.publish == 0 ? false : true;





          //if (this.GetEditedData.data.publish == 0) {
          //  this.publish = 0;
          //}
          //else {
          //  this.publish = 1;
          //}
          if (tmpClassId.length == 6) {
            this.AllClass = true;
          }
          if (tmpStreamid.length == 6) {
            this.AllStream = true;
          }


          //this.st_time = this.GetEditedData.data.starttime;
          //this.end_time = this.GetEditedData.data.endtime;


          this.SelectedTopic = this.GetEditedData.data.ID;
          this.selectedwebtype = this.GetEditedData.data.webinartype;

        }
      }
    )
  }

  //Select All function for State
  SelectAllState() {
    this.StateIds = "";
    if (this.AllState === true) {      
      for (var i = 0; i < this.StateData.length; i++) {
        this.StateData[i].selected = true;
      }
      this.StateIds = '0';
    }
    else {
      for (var i = 0; i < this.StateData.length; i++) {
        this.StateData[i].selected = false;
      }
    }
  }

  //convert Selected state in String format
  SelectedState() {
    
    this.StateIds = "";
    var count = 0;

    for (var i = 0; i < this.StateData.length; i++) {
      if (this.StateData[i].selected === true) {
        if (this.StateIds === '') {
          this.StateIds = this.StateData[i].stateId;
          count++;
        }
        else {
          this.StateIds = this.StateIds + ',' + this.StateData[i].stateId;
          count++;
        }
      }
    }

    if (this.StateData.length === count) {
      this.AllState = true;
      this.StateIds = "0";
    }
    else {
      this.AllState = false;
    }

  }

  //Select All function for City
  SelectAllCity() {
    this.CityIds = "";

    if (this.AllCity === true) {
      for (var i = 0; i < this.CityData.length; i++) {
        this.CityData[i].selected = true;      
      }
      this.CityIds = "0";
    }
    else {
      for (var i = 0; i < this.CityData.length; i++) {
        this.CityData[i].selected = false;
      }
    }
  }

  //convert Selected city in String format
  SelectedCity() {
    
    this.CityIds = "";
    var count = 0;

    for (var i = 0; i < this.CityData.length; i++) {
      if (this.CityData[i].selected === true) {
        if (this.CityIds === '') {
          this.CityIds = this.CityData[i].cityid;
          count++;
        }
        else {
          this.CityIds = this.CityIds + ',' + this.CityData[i].cityid;
          count++;
        }
      }
    }

    if (this.CityData.length === count) {
      this.AllCity = true;
      this.CityIds = "0";
    }
    else {
      this.AllCity = false;
    }
  }

  //Select All function for City
  SelectAllSchool() {
    this.SchoolIds = "";
    if (this.AllSchool === true) {
      for (var i = 0; i < this.SchoolData.length; i++) {
        this.SchoolData[i].selected = true;       
      }
      this.SchoolIds = "0";
    }
    else {
      for (var i = 0; i < this.SchoolData.length; i++) {
        this.SchoolData[i].selected = false;
      }
    }

  }

  //convert Selected city in String format
  SelectedSchool() {
    this.SchoolIds = "";
    var count = 0;

    for (var i = 0; i < this.SchoolData.length; i++) {
      if (this.SchoolData[i].selected === true) {
        if (this.SchoolIds === '') {
          this.SchoolIds = this.SchoolData[i].schoolid;
          count++;
        }
        else {
          this.SchoolIds = this.SchoolIds + ',' + this.SchoolData[i].schoolid;
          count++;
        }
      }
    }

    if (this.SchoolData.length === count) {
      this.AllSchool = true;
      this.SchoolIds = "0";
    }
    else {
      this.AllSchool = false;
    }
  }

  //Multi check box filter
  onChangeOfMultiCheckBoxToGetCity() {
    
    if (this.StateIds == null) { this.StateIds = ""; }
    if (this.CityIds == null) { this.CityIds = ""; }

    if (this.StateIds != "") {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.http.get('api/Subscription/getcityfilter?StateId=' + this.StateIds, options).subscribe(
        (data) => {
          this.GetCityData = data;

          if (this.GetCityData.Status == true) {
            this.CityData = this.GetCityData.citydata;
          }

          if (this.GetEditedData.Status != undefined) {
            if (this.GetEditedData.Status == true) {
              this.CityIds = this.GetEditedData.data.cityid;

              if (this.CityIds == '0') {
                this.AllCity = true;
                this.CityData.forEach(item => { item.selected = true; });
              }
              else {
                var tmpcityid = this.GetEditedData.data.cityid.split(",");
                for (var i = 0; i < this.CityData.length; i++) {
                  for (var j = 0; j < tmpcityid.length; j++) {
                    if (this.CityData[i].cityid == tmpcityid[j]) {
                      this.CityData[i].selected = true;

                    }
                  }
                }
              }
              this.onChangeOfMultiCheckBoxToGetSchool();
            }            
          }
        }
      );
    }
    else {
      this.CityData = [];
      this.SchoolData = [];
      this.AllCity = false;
      this.AllSchool = false;
    }
  }

  onChangeOfMultiCheckBoxToGetSchool() {
    
    if (this.StateIds == null) { this.StateIds = ""; }
    if (this.CityIds == null) { this.CityIds = ""; }

    if (this.StateIds != "" && this.CityIds != "") {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.http.get('api/Subscription/getschoolfilter?StateId=' + this.StateIds + '&CityId=' + this.CityIds, options).subscribe(
        (data) => {
          this.GetSchoolData = data;

          if (this.GetSchoolData.Status == true) {
            this.SchoolData = this.GetSchoolData.schooldata;
          }

          if (this.GetCityData.Status == true) {
            this.CityData = this.GetCityData.citydata;
          }


          if (this.GetEditedData.Status != undefined) {
            if (this.GetEditedData.Status == true) {
              this.SchoolIds = this.GetEditedData.data.schoolid;

              if (this.SchoolIds == '0') {
                this.AllSchool = true;
                this.SchoolData.forEach(item => { item.selected = true; });
              }
              else {
                var tmpschoolid = this.GetEditedData.data.schoolid.split(",");

                for (var i = 0; i < this.SchoolData.length; i++) {
                  for (var j = 0; j < tmpschoolid.length; j++) {
                    if (this.SchoolData[i].schoolid == tmpschoolid[j]) {
                      this.SchoolData[i].selected = true;

                    }
                  }
                }
              }
            }
          }
        }
      );
    }
    else {
      this.SchoolData = [];
      this.AllSchool = false;
    }
  }


  handleButtonClick(link: string) {
    
    this.url = link;
    this.yt = '<iframe width="727" height="409" src="' + this.url + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
  }
  toggleMeridian() {
    this.meridian = !this.meridian;
  }

}
