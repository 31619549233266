import { Component, OnInit, Input, ElementRef, ViewChild, PipeTransform, Pipe, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, Event } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PermissionService } from '../../../permission.service';


@Component({
  selector: 'app-assignstudent',
  templateUrl: './assignstudent.component.html',

})
export class AssignStudentMaster implements OnInit {

  ButtonText: string = "Save";

  selectedpartner: number = 0;
  selectedschool: number = 0;
  selectedcount: number = 0;

  studentData: any = [];
  schoolData: any = [];
  partnerData: any = [];
  resData: any = [];
  assignedData: any = [];

  public IsCheckedAll: boolean = false;

  public userid: number = 0;
  public usertype: number = 0;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private permission: PermissionService   ) {
  }

  ngOnInit() {
    this.userid = this.localstorage.get("userid");
    this.usertype = this.localstorage.get("usertype");

    this.GetActions();
    this.BindPartner();
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  OnSelectAll() {
    if (this.IsCheckedAll == true) {
      if (this.studentData.length > 0) {
        this.studentData.forEach(item => {
          item.IsChecked = true;
        })
      }
    }
    else {
      if (this.studentData.length > 0) {
        this.studentData.forEach(item => {
          item.IsChecked = false;
        })
      }
    }
  }

  OnSelect() {

    this.selectedcount = 0;
    if (this.studentData.length > 0) {
      this.studentData.forEach(item => {
        if (item.IsChecked == true) {
          this.selectedcount++;
        }
      })
    }
  }


  BindPartner() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/assignstudent/BindPartner', options).subscribe(
      (data) => {
        this.resData = data;

        if (this.resData.Status == true) {
          this.partnerData = this.resData.data;
        }
        else {
          this.partnerData = [];
        }
      }
    )
  }

  BindSchool() {
    this.studentData = [];
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/assignstudent/BindSchool?partnerid=' + this.selectedpartner, options).subscribe(
      (data) => {
        this.resData = data;

        if (this.resData.Status == true) {
          this.schoolData = this.resData.data;
        }
        else {
          this.schoolData = [];
        }
      }
    )
  }

  BindStudent() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.selectedschool == 0) {
      this.studentData = [];
    }
    else {
      this.http.get('api/assignstudent/BindStudent?partnerid=' + this.selectedpartner + '&schoolid=' + this.selectedschool, options).subscribe(
        (data) => {
          this.resData = data;

          if (this.resData.Status == true) {
            this.studentData = this.resData.data;
          }
          else {
            this.studentData = [];
          }
        }
      )
    }
  }

  AssignStudents() {
    

    this.assignedData = [];

    if (this.studentData.length > 0) {
      this.studentData.forEach(item => {
        if (item.IsChecked == true) {
          this.assignedData.push({ "studentid": item.studentid });
        }
      })
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.assignedData.length > 0) {

      var body = {
        "partnerid": this.selectedpartner,
        "assignedData": JSON.stringify(this.assignedData)
      }

      this.loader.start();
      this.http.post('api/assignstudent/AssignStudents', body, options).subscribe(
        (data) => {
          this.resData = data;
          this.loader.stop();

          if (this.resData.Status == true) {

            this.selectedcount = 0
            this.toaster.success(this.resData.Message, '', { easeTime: 1000, timeOut: 3000 });
            this.BindStudent();
            return;
          }
          else {
            this.toaster.error(this.resData.Message, '', { easeTime: 1000, timeOut: 3000 });
            return;
          }
        }
      )

    }
  }

  Reset() {
    this.selectedpartner = 0;
    this.selectedschool = 0;
    this.selectedcount = 0;
    this.studentData = [];
  }


}
