import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { PermissionService } from '../../../../permission.service';

@Component({
  selector: 'app-academycareerreport',
  templateUrl: './academycareerreport.component.html'
})
export class AcademyCareerReport implements OnInit {

  spinnerType = SPINNER.rectangleBounce;

  public academypageSize: number = 10;
  public academypage: number = 1;

  public careerpageSize: number = 10;
  public careerpage: number = 1;

  public partnerpageSize: number = 10;
  public partnerpage: number = 1;

  public studentpageSize: number = 10;
  public studentpage: number = 1;

  public activitypageSize: number = 10;
  public activitypage: number = 1;

  public studentbyactivitypageSize: number = 10;
  public studentbyactivitypage: number = 1;

  public activityinterestedpageSize: number = 10;
  public activityinterestedpage: number = 1;

  public activitynotinterestedpageSize: number = 10;
  public activitynotinterestedpage: number = 1;


  public resData: any;
  public academyData: any;
  public careerData: any;
  public partnerData: any;
  public studentData: any;
  public activityData: any;
  public academywithcareerData: any;
  public careerwithacademyData: any;
  public academyselectedbystudentData: any;
  public careerselectedbystudentData: any;
  public academybyactivityData: any;
  public careerbyactivityData: any;
  public partneruniversitywiseData: any;
  public activitywiseData: any;
  public studentwiseData: any;

  public academyassociatedlist: boolean = true;
  public careerassociatedlist: boolean = false;
  public partnerlist: boolean = false;
  public studentlist: boolean = false;
  public activitylist: boolean = false;

  public listtype: number;
  public searchacademy: string = "";
  public searchcareer: string = "";
  public searchpartner: string = "";
  public searchstudent: string = "";
  public searchactivity: string = "";
  public searchstudentbyactivity: string = "";
  public searchactivityinterested: string = "";
  public searchactivitynotinterested: string = "";

  public classData: any;
  public streamData: any;
  public stateData: any;
  public cityData: any;
  public universityData: any;
  public allStateData: any;
  public allCityData: any;
  public allSchoolData: any;
  public studentstateData: any;
  public studentcityData: any;
  public allocatedAcademyData: any;
  public allocatedCareerData: any;
  public schoolData: any;
  public studentbyactivityData: any;

  public selectedpartneruniversity: number = 0;
  public selectedactivitystate: number = 0;
  public selectedactivitycity: number = 0;
  public selectedactivityschool: number = 0;
  public selectedactivityclass: number = 0;
  public selectedactivitystream: number = 0;
  public selectedactivityacademy: number = 0;
  public selectedactivitycareer: number = 0;
  public selectedpublish: number = 0;

  public selecteduniversitystate: number = 0;
  public selecteduniversitycity: number = 0;
  public selecteduniversityacademy: number = 0;
  public selecteduniversitycareer: number = 0;

  public selectedstudentstate: number = 0;
  public selectedstudentcity: number = 0;
  public selectedstudentschool: number = 0;
  public selectedstudentclass: number = 0;
  public selectedstudentstream: number = 0;
  public selectedstudentacademy: number = 0;
  public selectedstudentcareer: number = 0;

  public selectedstate: number = 0;
  public selectedcity: number = 0;
  public selectedschool: number = 0;
  public selectedclass: number = 0;
  public selectedstream: number = 0;

  public interestedData: any;
  public notInterestedData: any;
  public totalinterested: number = 0;
  public totalnotinterested: number = 0;

  public activityname: string = "";
  public list_type: string = "";
  public student_name: string = "";

  public activityid: number = 0;

  public universityname: string = "";
  public academyspandetails: any;
  public academydetails: any;
  public careerspandetails: any;
  public careerdetails: any;
  public studentdetails: any;
  public HeaderData: any;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor
    (
      private http: HttpClient,
      private router: Router,
      private localstorage: LocalStorageService,
      private toaster: ToastrService,
      private loader: NgxUiLoaderService,
      private permission: PermissionService
    ) { }


  ngOnInit() {

    this.loader.start();
    this.GetActions();
    this._bindAllState();
    this._getAcademyAssociatedwithCareer();
    this._getCareerAssociatedwithAcademy();
    this._getPartneruniversityAssociatedwithAcademyandCareer();
    this._getAcademyandCareerSelectedbyStudent();
    this._getUniversityState();
    this._getAcademy();
    this._getState();
    this._getClass();
    this._getStream();
    this._getAcademySelectedbyStudent();
    this._getAcademyandCareerbyActivity();
    this._getPartnerUniversity();
    this._getAcademybyActivity();
    //this._getActivityReport();
    this.loader.stop();

  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  Reset() {
    this.selecteduniversitystate = 0;
    this.selecteduniversitycity = 0;
    this.selecteduniversityacademy = 0;
    this.selecteduniversitycareer = 0;
    this.cityData = [];
    this.allocatedCareerData = [];

    this._getPartneruniversityAssociatedwithAcademyandCareer();
  }

  StudentFilterReset() {
    this.selectedstudentstate = 0;
    this.selectedstudentcity = 0;
    this.selectedstudentschool = 0;
    this.selectedstudentclass = 0;
    this.selectedstudentstream = 0;
    this.selectedstudentacademy = 0;
    this.selectedstudentcareer = 0;
    this.studentcityData = [];
    this.schoolData = [];
    this.careerselectedbystudentData = [];

    this._getAcademyandCareerSelectedbyStudent();
  }

  ActivityFilterReset() {

    this.activitypage = 1;
    this.selectedpartneruniversity = 0;
    this.selectedactivitystate = 0;
    this.selectedactivitycity = 0;
    this.selectedactivityschool = 0;
    this.selectedactivityclass = 0;
    this.selectedactivitystream = 0;
    this.selectedactivityacademy = 0;
    this.selectedactivitycareer = 0;
    this.selectedpublish = 0;

    this.allCityData = [];
    this.allSchoolData = [];
    this.careerbyactivityData = [];

    this._getAcademyandCareerbyActivity();
  }

  _setCareerPage() {
    this.careerpage = 1;
  }

  _setAcademyPage() {
    this.academypage = 1;
  }

  _setPartnerPage() {
    this.partnerpage = 1;
  }

  _setStudentPage() {
    this.studentpage = 1;
  }

  _setActivityPage() {
    this.activitypage = 1;
  }

  _setStudentbyActivityPage() {
    this.studentbyactivitypage = 1;
  }

  _setActivityInterestedPage() {
    this.activityinterestedpage = 1;
  }

  _setActivityNotInterestedPage() {
    this.activitynotinterestedpage = 1;
  }

  _changeList(_ListId: number) {

    this.loader.start();

    if (_ListId == 1) {
      this.academyassociatedlist = true;
      this.careerassociatedlist = false;
      this.partnerlist = false;
      this.studentlist = false;
      this.activitylist = false;
    }
    else if (_ListId == 2) {
      this.academyassociatedlist = false;
      this.careerassociatedlist = true;
      this.partnerlist = false;
      this.studentlist = false;
      this.activitylist = false;
    }
    else if (_ListId == 3){
      this.academyassociatedlist = false;
      this.careerassociatedlist = false;
      this.partnerlist = true;
      this.studentlist = false;
      this.activitylist = false;
    }
    else if (_ListId == 4) {
      this.academyassociatedlist = false;
      this.careerassociatedlist = false;
      this.partnerlist = false;
      this.studentlist = true;
      this.activitylist = false;
    }
    else if (_ListId == 5) {
      this.academyassociatedlist = false;
      this.careerassociatedlist = false;
      this.partnerlist = false;
      this.studentlist = false;
      this.activitylist = true;
    }

    this.loader.stop();

  }

  exportexcel(_TableId: string): void {

    const today = new Date();

    let element = document.getElementById(_TableId);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'file' + today.getTime() +'.xlsx');
  }

  _bindAllState() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.post('api/LiveWebinar/Bindstate', options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.allStateData = this.resData.data;
        }
        else {
          this.allStateData = [];
        }
      }
    )
  }

  _bindAllCity() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedactivitystate
    }

    this.http.post('api/LiveWebinar/BindCity', body, options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.allCityData = this.resData.data;
        }
        else {
          this.allCityData = [];
        }
      }
    )
  }

  _bindAllSchool() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedactivitystate,
      "cityid": this.selectedactivitycity
    }

    this.http.post('api/LiveWebinar/BindSchool', body, options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.allSchoolData = this.resData.data;
        }
        else {
          this.allSchoolData = [];
        }
      }
    )
  }

  _getPartnerUniversity() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/academymaster/PartnerUniversitybyActivity', options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.status == true) {
          this.universityData = this.resData.universityresponses;
        }
      }
    )

  }

  _getUniversityState() {

    this.selecteduniversitycity = 0;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var data = {
      "countryid": 1
    }

    let body = JSON.stringify(data);

    this.http.post('api/InstituteMaster/BindLocation', body,options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.stateData = this.resData.data;
        }
      }
    )

  }

  _getUniversityCity() {

    this.selecteduniversitycity = 0;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var data = {
      "countryid": 1,
      "locationid": this.selecteduniversitystate
    }

    let body = JSON.stringify(data);

    this.http.post('api/InstituteMaster/BindCity', body,options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.cityData = this.resData.data;
        }
        else {
          this.cityData = [];
        }
      }

    )

  }

  _getAcademy() {

    this.selecteduniversitycareer = 0;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/academymaster/GetUniversityAllocatedAcademyData', options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.status == true) {
          this.allocatedAcademyData = this.resData.academyresponses;
        }
      }
    )

  }

  _getCareer() {

    this.selecteduniversitycareer = 0;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/academymaster/GetUniversityAllocatedCareerData?academyid=' + this.selecteduniversityacademy, options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.status == true) {
          this.allocatedCareerData = this.resData.careerresponses;
        }
        else {
          this.allocatedCareerData = [];
        }
      }
    )

  }

  _getState() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.post('api/reports/BindstateforRegistrationReport', options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.studentstateData = this.resData.data;
        }
        else {
          this.studentstateData = [];
        }
      }
    )
  }

  _getCity() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedstudentstate
    }
    var tmpclass: any = [];
    this.http.post('api/reports/BindCityforRegistrationReport', body, options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.studentcityData = this.resData.data;
        }
        else {
          this.studentcityData = [];
        }
      }
    )
  }

  _getSchool() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedstudentstate,
      "cityid": this.selectedstudentcity
    }

    this.http.post('api/reports/BindSchoolforRegistrationReport', body, options).subscribe(
      (data) => {

        this.resData = data;
        if (this.resData.Status == true) {
          this.schoolData = this.resData.data;
        }
        else {
          this.schoolData = [];
        }
      }
    )
  }

  _getClass() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.post('api/reports/Bindclass', options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.classData = this.resData.data;
        }
        else {
          this.classData = [];
        }
      }
    )
  }

  _getStream() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.post('api/reports/BindStream', options).subscribe(
      (data) => {

        this.resData = data;
        if (this.resData.Status == true) {
          this.streamData = this.resData.data;
        }
        else {
          this.streamData = [];
        }
      }
    )
  }

  _getAcademyAssociatedwithCareer() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/academymaster/AcademyAssociatedwithCareer', options).subscribe(
      (data) => {
        this.resData = data;
        this.academyData = this.resData.academyresponses;
        this.academywithcareerData = this.resData.dataTable;
      }
    )

  }

  _getAcademySelectedbyStudent() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/academymaster/AcademySelectedbyStudent', options).subscribe(
      (data) => {
        this.resData = data;
        this.academyselectedbystudentData = this.resData.academyresponses;
      }
    )

  }

  _getAcademybyActivity() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/academymaster/AcademybyActivity', options).subscribe(
      (data) => {
        this.resData = data;
        this.academybyactivityData = this.resData.academyresponses;
      }
    )

  }

  _getCareerAssociatedwithAcademy() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/academymaster/CareerAssociatedwithAcademy', options).subscribe(
      (data) => {
        this.resData = data;
        this.careerData = this.resData.careerresponses;
        this.careerwithacademyData = this.resData.dataTable;
      }
    )

  }

  _getCareerSelectedbyStudent() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/academymaster/CareerSelectedbyStudent?academyid=' + this.selectedstudentacademy, options).subscribe(
      (data) => {
        this.resData = data;
        this.careerselectedbystudentData = this.resData.careerresponses;
      }
    )

  }

  _getCareerbyActivity() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/academymaster/CareerbyActivity?academyid=' + this.selectedactivityacademy, options).subscribe(
      (data) => {
        this.resData = data;
        this.careerbyactivityData = this.resData.careerresponses;
      }
    )

  }

  _getPartneruniversityAssociatedwithAcademyandCareer() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var data = {
      "stateid": this.selecteduniversitystate,
      "cityid": this.selecteduniversitycity,
      "academyid": this.selecteduniversityacademy,
      "careerid": this.selecteduniversitycareer
    }

    let body = JSON.stringify(data);

    this.http.post('api/academymaster/PartnerUniversityAssociatedwithAcademyandCareer', body,options).subscribe(
      (data) => {
        this.resData = data;
        this.partneruniversitywiseData = this.resData.dataTable;

        if (this.resData.status == true) {
          this.partnerData = this.resData.partnerresponses;
        }
        else {
          this.partnerData = [];
        }
      }
    )

  }

  _getAcademyandCareerSelectedbyStudent() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var data = {
      "stateid": this.selectedstudentstate,
      "cityid": this.selectedstudentcity,
      "schoolid": this.selectedstudentschool,
      "classid": this.selectedstudentclass,
      "streamid": this.selectedstudentstream,
      "academyid": this.selectedstudentacademy,
      "careerid": this.selectedstudentcareer
    }

    let body = JSON.stringify(data);

    this.http.post('api/academymaster/AcademyandCareerSelectedbyStudent', body,options).subscribe(
      (data) => {
        this.resData = data;
        this.studentwiseData = this.resData.dataTable;
        if (this.resData.status == true) {
          this.studentData = this.resData.studentresponses;
        }
        else {
          this.studentData = [];
        }
      }
    )

  }

  _getAcademyandCareerbyActivity() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var data = {
      "universityid": this.selectedpartneruniversity,
      "stateid": this.selectedactivitystate,
      "cityid": this.selectedactivitycity,
      "schoolid": this.selectedactivityschool,
      "classid": this.selectedactivityclass,
      "streamid": this.selectedactivitystream,
      "academyid": this.selectedactivityacademy,
      "careerid": this.selectedactivitycareer,
      "publish": this.selectedpublish
    }

    let body = JSON.stringify(data);

    this.http.post('api/academymaster/AcademyandCareerbyActivity', body, options).subscribe(
      (data) => {
        this.resData = data;
        this.activitywiseData = this.resData.dataTable;

        if (this.resData.status == true) {
          this.activityData = this.resData.activityresponses;
          this.totalinterested = this.activityData[0].totalinterested;
          this.totalnotinterested = this.activityData[0].totalnotinterested;

          this.interestedData = this.resData.interestedData;
          this.notInterestedData = this.resData.notInterestedData;
        }
        else {
          this.activityData = [];
        }
      }
    )

  }


  _getActivityEnrolledStudents(_ActivityName: string, _ActivityId: number, _ListType: string) {

    this.activityname = _ActivityName;
    this.list_type = _ListType;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/academymaster/ActivityEnrolledbyStudents?activityid=' + _ActivityId + '&listtype=' + _ListType, options).subscribe(
      (data) => {
        this.resData = data;

        this.loader.stop();
        if (this.resData.status == true) {
          this.studentbyactivityData = this.resData.studentresponses;
        }
        else {
          this.studentbyactivityData = [];
        }
      }
    )
  }

  _getActivityReport() {

    if (this.selectedpartneruniversity != 0) {

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      this.http.get('api/academymaster/GetVirtualActivityReport?universityid=' + this.selectedpartneruniversity, options).subscribe(
        (data) => {
          this.resData = data;
          

          if (this.resData.status == true) {

            this.academyspandetails = this.resData.academyspandetails;
            this.universityname = this.academyspandetails[0].universityname;
            this.academydetails = this.resData.academydetails;
            this.careerspandetails = this.resData.careerspandetails;
            this.careerdetails = this.resData.careerdetails;
            this.studentdetails = this.resData.studentdetails;
            this.HeaderData = Object.keys(this.studentdetails[0]);

          }
        }
      )
    }

  }
}
