
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderModule } from 'ngx-order-pipe';
import { LocalStorageModule } from 'angular-2-local-storage';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SafePipeModule } from 'safe-pipe';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { LoginManager } from './Layouts/adminpanel/Login/login.component';
import { ForgotPassword } from './Layouts/adminpanel/resetpassword/forgotpassword.component';
import { LandingpagesModule } from '../../src/app/Layouts/adminpanel/landingpages.module';
import { RegistrationReportManager } from './Layouts/adminpanel/registrationreport/registrationreport.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    LoginManager,
    ForgotPassword,
    RegistrationReportManager
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    NgxChartsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    HttpClientModule,

    NgbModule.forRoot(),

    //TooltipModule,

    LocalStorageModule.forRoot({
      prefix: '',
      storageType: 'localStorage'
    }),

    ToastrModule.forRoot(),
    SafePipeModule,
    NgxUiLoaderModule,
    ClipboardModule,
    OrderModule,
    LandingpagesModule   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
