//<reference path="../../../../assets/statichtml/js/vendors.min.js" />
import { Component, OnInit } from '@angular/core';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { LogoutService } from '../../../logout.service';
import * as $ from 'jquery';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import * as validator from 'validator';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  spinnerType = SPINNER.rectangleBounce;
  roleid: number = 0;
  role: string = "";
  username: string = "";
  mobile: string = "";
  email: string = "";
  resData: any = [];
  MenuDetail: any = [];
  MenuDataDetail: any = [];

  currentpassword: string = "";
  newpassword: string = "";
  confirmpassword: string = "";

  public loadScript(url: string)
  {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }


  constructor(
    private localstorage: LocalStorageService,
    private router: Router,
    private http: HttpClient,
    private logoutService: LogoutService,
    private loader: NgxUiLoaderService,
    private toaster: ToastrService) {

  }

  ngOnInit()
  {
    
    this.roleid = this.localstorage.get("roleid");
    this.username = this.localstorage.get("username");
    this.role = this.localstorage.get("role");
    this.email = this.localstorage.get("email");
    this.mobile = this.localstorage.get("mobile");

    this.loadScript('../assets/statichtml/js/vendors.min.js');
    this.loadScript('../assets/statichtml/js/app.min.js');
    this.logoutService.ForceLogout();

    this.loadMenu();
  }


  loadMenu()
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    
    this.loader.start();
    this.http.get('api/menus/GetMenuItemsByRole?roleid=' + this.roleid, options).subscribe(
      (data) => {
        
        this.MenuDetail = data;
        this.loader.stop();

        if (this.MenuDetail.status == true) {
          this.MenuDataDetail = this.MenuDetail.menudata;
        }
        else {
          this.MenuDataDetail = [];
        }

      }
    )
  }

  AddClass(_Index: string) {
    
    if (!$('#sidebarli_' + _Index).hasClass('open')) {
      $('#sidebarli_' + _Index).addClass('open');
    }
    else {
      $('#sidebarli_' + _Index).removeClass('open');
    }
  }

  AddSubClass(_Index: string) {
    
    if (!$('#sidebarsubli_' + _Index).hasClass('open')) {
      $('#sidebarsubli_' + _Index).addClass('open');
    }
    else {
      $('#sidebarsubli_' + _Index).removeClass('open');
    }
  }

  setPageId(_Id: number) {
    this.localstorage.set("pageid", _Id);
  }


  Logout() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var data = {
      "userid": this.localstorage.get('userid'),
      "logid": this.localstorage.get('logid'),
      "reason": "Self Logout"
    }

    let body = JSON.stringify(data);

    this.loader.start();
    this.http.post('api/Login/LogoutAPI',body, options).subscribe(
      (data) => {
        
        this.loader.stop();
        this.resData = data;

        if (this.resData.Status == true) {
          this.localstorage.clearAll();
          this.router.navigate(['/login']);
          this.toaster.success(this.resData.Message.toString(), '', { easeTime: 1000, timeOut: 3000 });
          return;
        }
        else {
          this.toaster.warning(this.resData.Message.toString(), '', { easeTime: 1000, timeOut: 3000 });
          return;
        }

      },
      (err) => {
        this.localstorage.clearAll();
        this.router.navigate(['/login']);
      }
    )
  }


  ShowPwd(_ControlId: string, _IconId: string) {
    $(document).ready(function () {
      if ($(_ControlId).attr('type') == 'password') {
        $(_ControlId).attr('type', 'text');
        $(_IconId).removeClass('fa-eye');
        $(_IconId).addClass('fa-eye-slash');
      } else if ($(_ControlId).attr('type') == 'text') {
        $(_ControlId).attr('type', 'password');
        $(_IconId).removeClass('fa-eye-slash');
        $(_IconId).addClass('fa-eye');
      }
    });
  }


  onClear() {
    this.currentpassword = "";
    this.newpassword = "";
    this.confirmpassword = "";
  }

  UpdatePassword() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.currentpassword == "") {
      this.toaster.error('Enter Current Password', '', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.newpassword == "") {
      this.toaster.error('Please Enter New Password', '', { easeTime: 1000, timeOut: 3000 });
      return;
    }
    else {
      

      if (this.newpassword.trim().toString().match(/^\S*$/) == null) {
        this.toaster.error('Spaces are not allowed in between password', 'Read the instruction below.', { easeTime: 1000, timeOut: 3000 });
        return;
      }

      if (!validator.isStrongPassword(this.newpassword)) {
        this.toaster.error('Enter a valid password', 'Read the instruction below.', { easeTime: 1000, timeOut: 3000 });
        return;
      }
    }

    if (this.confirmpassword == "") {
      this.toaster.error('Please Enter Confirm Password', '', { easeTime: 1000, timeOut: 3000 });
      return;
    }
    else {

      if (this.confirmpassword.trim().toString().match(/^\S*$/) == null) {
        this.toaster.error('Spaces are not allowed in between password', 'Read the instruction below.', { easeTime: 1000, timeOut: 3000 });
        return;
      }

      if (!validator.isStrongPassword(this.confirmpassword)) {
        this.toaster.error('Enter a valid password', 'Read the instruction below.', { easeTime: 1000, timeOut: 3000 });
        return;
      }
    }

    if (this.newpassword === this.confirmpassword) {
    }
    else {
      this.toaster.error('Password mismatch', '', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    var data = {
      "userid": this.localstorage.get("userid"),
      "oldpassword": this.currentpassword.trim(),
      "newpassword": this.confirmpassword.trim()
    }

    let body = JSON.stringify(data);

    this.loader.start();
    this.http.post('api/Login/ChangePassword', body, options)
      .subscribe(
        (data) => {
          this.loader.stop();
          this.resData = data;
          if (this.resData.status == true) {
            this.toaster.success(this.resData.message.toString(), '', { easeTime: 1000, timeOut: 3000 });
            this.onClear();
            $('#btmprincipalpwdchange').click();
            return;
          }
          else {
            this.toaster.error(this.resData.message.toString(), 'Read the instruction below.', { easeTime: 1000, timeOut: 3000 });
            return;
          }
        })
  }

}
