import { Component, OnInit} from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { PermissionService } from '../../../../permission.service';

@Component({
  selector: 'app-rolemaster',
  templateUrl: './rolemaster.component.html'
})
export class RoleMaster implements OnInit {

  public spinnerType = SPINNER.rectangleBounce;

  public resData: any = [];
  public editData: any = [];
  public roleData: any = [];
  public pageActionData: any = [];
  public menuitemsData: any = [];
  public headerData: any = [];

  public tempData: any = [];

  public page: number = 1;
  public pageSize: number = 10;

  public search: string = "";
  public roleid: number = 0;
  public rolename: string = "";

  public btntext: string = "Save";

  public modalroleid: number = 0;

  public selectall: boolean = false;
  public selectindivisual: boolean = false;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor(
    private http: HttpClient,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private permission: PermissionService  ) {

  }
  ngOnInit() {
    this.GetActions();
    this.BindData();
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  setRoleId(_Id: number) {
    this.modalroleid = _Id;
  }


  getSelectedAction(Row,actionid) {

    
    var count = 0;
    var itemcount = 0;
    var uncheckall = 0;

    Row.actionDatas.forEach(action => {
      if (action.selected == true && action.IsActionExists == true) {
        count++;
      }

      if (action.IsActionExists == true) {
        itemcount++;
      }
    })

    if (itemcount == count) {
      Row.selectindivisual = true;
    }
    else {
      Row.selectindivisual = false;
    }

    var totalitem = 0;
    var selectedcount = 0;

    this.pageActionData.forEach(item => {
      item.actionDatas.forEach(action => {
        if (action.IsActionExists == true) {
          totalitem++;
        }
      })

      item.actionDatas.forEach(action => {
        if (action.selected == true) {
          selectedcount++;
        }
      })
    })

    if (totalitem == selectedcount) {
      this.selectall = true;
    }
    else {
      this.selectall = false;
    }

    if (actionid == 1 || actionid > 2) {      
      Row.actionDatas.forEach(action => {
        if (action.actionid == 2) action.selected = true;
      });
    }
    else {
      Row.actionDatas.forEach(action => {
        if (action.selected == false)
          uncheckall=1;
      });
    }

    if (uncheckall == 1) {
      Row.actionDatas.forEach(action => {
        action.selected = false
      });
    }

  }

  BindData() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/role/GetRoles', options).subscribe(
      (data) => {
        
        this.resData = data;

        if (this.resData.status == true) {
          this.roleData = this.resData.data;
        }
        else {
          this.roleData = [];
        }

      }
    )
  }

  SelectAllAction() {
    
    if (this.pageActionData.length > 0) {
      if (this.selectall == true) {

        this.pageActionData.forEach(item => {

          item.selectindivisual = true;
          item.actionDatas.forEach(action => {
            if (action.IsActionExists == true) {
              action.selected = true;
            }
          })

        })
      }
      else {
        this.selectindivisual = false;
        this.pageActionData.forEach(item => {

          item.selectindivisual = false;
          item.actionDatas.forEach(action => {
            if (action.IsActionExists == true) {
              action.selected = false;
            }
          })

        })
      }
    }
  }

  SelectIndivisualRowAction(IsChecked, Row) {
    
    if (IsChecked == true) {
      Row.selectindivisual = true;
      Row.actionDatas.forEach(action => {
        action.selected = true
      })
    }
    else {
      Row.selectindivisual = false;
      Row.actionDatas.forEach(action => {
        action.selected = false

      })
    }

    var total = 0;
    var count = 0;

    this.pageActionData.forEach(item => {
      item.actionDatas.forEach(action => {
        if (action.IsActionExists == true) {
          total++;
        }
        if (action.selected == true && action.IsActionExists == true) {
          count++;
        }
      })
    })

    if (total == count) {
      this.selectall = true;
    }
    else {
      this.selectall = false;
    }

  }

  GetPageActionDetails(_RoleId: number) {
    this.modalroleid = _RoleId;
    this.headerData = [];
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/role/GetPageActionDetails?roleid=' + _RoleId, options).subscribe(
      (data) => {
        
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.pageActionData = this.resData.pageDatas;

          this.resData.pageDatas[0].actionDatas.forEach(item => {
            this.headerData.push({"name": item.actiontype });
          })


          this.pageActionData.forEach(item => {

            var total = 0;
            var count = 0;

            item.actionDatas.forEach(action => {
              if (action.IsActionExists == true) {
                total++;
              }
              if (action.selected == true && action.IsActionExists == true) {
                count++;
              }
            })
            
            if (total == count) {
              this.selectall = true;
              item.selectindivisual = true;
            }
            else {
              this.selectall = false;
              item.selectindivisual = false;
            }

          })
        }
        else {
          this.pageActionData = [];
        }

      }
    )
  }


  Reset() {
    this.rolename = "";
    this.roleid = 0;
    this.search = "";
    this.btntext = "Save";
  }

  Submit() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.rolename == "" || this.rolename == undefined) {
      this.toaster.error('Please Enter Role', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    var body = {
      "roleid": this.roleid,
      "userid": this.localstorage.get('userid'),
      "rolename": this.rolename
    }

    this.loader.start();
    this.http.post('api/role/SaveUpdateRole', body, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          
          this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          this.BindData();
          this.Reset();
          return;
        }
        else {
          this.toaster.error(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          return;
        }
      }
    )

  }

  EditRole(_Id: number) {

    this.btntext = "Update";

    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/role/EditRoleData?roleid=' + _Id, options).subscribe(
      (data) => {
        
        this.resData = data;
        this.editData = this.resData.data;

        this.roleid = this.editData.roleid;
        this.rolename = this.editData.rolename;

      }
    )
  }

  DeleteRole(_Id: number) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var data =
    {
      "roleid": _Id,
      "userid": this.localstorage.get("userid")
    };

    let body = JSON.stringify(data);

    Swal.fire({
      text: 'Are you sure to delete this record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.http.post('api/role/DeleteroleData', body, options).subscribe(
          (data) => {
            this.resData = data;
            if (this.resData.status == true) {
              Swal.fire("", "Deleted Successfully", "success");
              this.BindData();
              this.Reset();
              return;
            }
            else {
              Swal.fire("", this.resData.message, "warning");
            }
          }
        )
      }
    })
  }

  SaveAssignedPageToRole() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var count = 0;
    this.tempData = [];

    this.pageActionData.forEach(item => {
      item.actionDatas.forEach(actionItem => {
        if (actionItem.selected == true) {
          count++;
        }
      })
    })

    if (count == 0) {
      this.toaster.error('Please Select Permission', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }
    else {

      this.pageActionData.forEach(item => {
        item.actionDatas.forEach(actionItem => {
          if (actionItem.selected == true && actionItem.IsActionExists == true) {
            this.tempData.push({ "RoleId": this.modalroleid, "PageId": item.pageid, "ActionTypeId": actionItem.actionid })
          }
        })
      });


      var body = {
        "roleid": this.modalroleid,
        "permissionData": JSON.stringify(this.tempData)
      }

      this.loader.start();
      this.http.post('api/role/SaveAssignedPages', body, options).subscribe(
        (data) => {
          this.resData = data;
          this.loader.stop();
          if (this.resData.status == true) {
            
            this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
            this.GetPageActionDetails(this.modalroleid);
            return;
          }
          else {
            this.toaster.error(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
            return;
          }
        }
      )

    }


  }
}
