import { Component, OnInit, Input, ElementRef, ViewChild, PipeTransform, Pipe, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, Event } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { EmbedVideoService } from 'ngx-embed-video';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

import { Time } from '@angular/common';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbTimepickerConfig, NgbTimeStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import * as xlsx from 'xlsx';
declare var $: any;
import { PermissionService } from '../../../permission.service';

@Component({
  selector: 'app-collegerepository',
  templateUrl: './collegerepository.component.html',
  //styleUrls: ['./plannedactivity.component.css']

  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, NgbTimepickerConfig]
})
export class collegerepository implements OnInit {
  // public page: number = 0;
  //public pageSize: number = 10;

  spinnerType = SPINNER.rectangleBounce;

  public page: number = 1;
  public SelectedVideo:string="";
  public collegename: string = "";
  public imagefile: any = [];
  public Selectedcareer: number = 0;
  public CareerData: any = [];
  public careerdat: any = [];
  public Selecteduniversity: number = 0;
  public UniversityData: any = [];
  public universitydat: any = [];
  @ViewChild('inputFile', { static: true }) private myInputVariable: ElementRef;
  public description: string = "";
  public Detail: any = [];
  public ButtonText: string = 'Save';
  public HeaderData: any = [];
  public GetSaveData: any = [];
  public GetAllSaveData: any = [];
  public SelectedImage: string = "";
  public repositoryid: number = 0;
  public GetEditedData: any = [];
  public DeletedData: any = [];
  public collegerepositoryData: any = [];
  public orgImageName: string = "";
  public imageToUpload: any;
  public image: string = "";
  public display: any = "none";
  public Details: any = [];
  public checklink: boolean = false;
  public checklink_v: boolean = false;
  selectedlanguage: number = 0;
  languagedata: any = [];
  languagedatadetail: any = [];
  //public IsSchool: boolean = false;  SelectedImage: any = [];
  SelectedImage1: any = [];
  @ViewChild('fileInput', { static: true }) private myInputVariableprefile: ElementRef;
  excelfile: any = [];
  arrayBuffer: any = [];
  exceldata: any = [];
  GetData1: any = [];
  dw: string = "";
  Instruction: any;

  resData: any = [];
  selectedcountry: number = 0;
  CountryDetails: any = [];
  CountryData: any = [];

  selectedlocation: number = 0;
  selectedcity: number = 0;
  stateData: any = [];
  cityData: any = [];
  downloadData: any;

  public search: string = "";

  public totalrepository: number = 0;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor(
    private http: HttpClient,
    private sanitize: DomSanitizer,
    private router: Router,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private renderer: Renderer2,
    private config: NgbTimepickerConfig,
    private config1: NgbDatepickerConfig,
    private permission: PermissionService  ) {



  }
  ngOnInit() {
    this.loader.start();

    this.GetActions();
    this.BindCareer();
    this.Bindcollege();
    this.GetData(this.page);
    this.getLanguage();
    this.getCountry();
    this.dw = "https://adm.careerprabhu.com/collegerepository.xlsx";
    this.loader.stop();
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }


  setpageno() {
    this.page = 1;
  }


  /*name of the excel-file which will be downloaded. */
  fileName = 'collegerepository.xlsx';

  exportexcel(tagid: string): void {
    
    /* table id is passed over here */
    let element = document.getElementById(tagid);
    const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    xlsx.writeFile(wb, this.fileName);

  }


  ShowInst(msg) {
    this.Instruction = msg;
  }

  incomingfile(event) {

    this.excelfile = event.target.files[0];
    //if (!this.excelfile.type.includes(".sheet")) {
    //  this.toaster.warning("Please upload only Excel files.", '', { easeTime: 1000, timeOut: 3000 });
    //  var $el = $('#UploadedFile');
    //  $el.wrap('<form>').closest('form').get(0).reset();
    //  $el.unwrap();
    //  this.excelfile = null;
    //}
  }



  Uploadexcel() {
    
    if (this.excelfile != undefined || this.excelfile != null) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = xlsx.read(bstr, { type: "binary" });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];

        //console.log(xlsx.utils.sheet_to_json(worksheet, { raw: true }));
        //
        this.exceldata = xlsx.utils.sheet_to_json(worksheet, { raw: true });
        this.ValidateExcel(this.exceldata);
        //var $el = $('#UploadedFile');
        //$el.wrap('<form>').closest('form').get(0).reset();
        //$el.unwrap();
        //this.excelfile = null;
      }
      fileReader.readAsArrayBuffer(this.excelfile);
    }
    else {
      this.toaster.warning("Please choose an Excel file.");
    }

  }


  ValidateExcel(Data: any) {

    var Validate = true;
    var cols = ["college", "career", "language", "description", "videolink"];
    for (var i = 0; i < cols.length; i++) {
      //for (var j = 0; j < Data.length; j++) {
      //  if (Data[j][cols[i]] == undefined) {
      //    Swal.fire('Oops...', cols[i] + " is not available at  row number " + (j + 2), 'warning')
      //    var Validate = false;
      //    return;
      //  }
      //}
    }
    if (Validate == true) {
      
      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      let options = { headers: headers }

      var data = {
        "schoolDatas": Data
      };

      let body = JSON.stringify(data);

      // this.Loader.start();
      this.http.post('api/collegerepository/Upload', body, options).subscribe(
        (data) => {
          // this.Loader.stop();
          
          this.GetData1 = data;
          if (this.GetData1.Status == true) {
            Swal.fire("", "Data Imported Succesfully", "success");
            this.myInputVariableprefile.nativeElement.value = "";
            //this.onClear();
            this.GetData(this.page);
           
            this.excelfile = [];
            return;

          }
          else {
            Swal.fire("", "Something Went Wrong", "warning");
            this.myInputVariableprefile.nativeElement.value = "";
            // this.onClear();
            this.GetData(this.page);
            
            this.excelfile = [];
            return;
          }
        }
      );
    }
  }

  getLanguage() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var a;
    var tmpclass: any = [];
    this.http.get('api/collegerepository/bindlanguage', options).subscribe(
      (data) => {
        
        this.languagedatadetail = data;
        if (this.languagedatadetail.Status == true) {
          this.languagedata = this.languagedatadetail.data;
        }
      }
    )
  }

  BindCareer() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.careerdat = [];

    var tmpclass: any = [];
    this.http.post('api/collegerepository/BindCareer', options).subscribe(

      (data) => {
        this.careerdat = data;
        if (this.careerdat.Status == true) {
          this.CareerData = this.careerdat.data;
        }
        else {
          this.CareerData = this.careerdat.data;
        }
      }
    )
  }

  getCountry() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.CountryDetails = [];

    this.http.get('api/InstituteMaster/Bindcountry', options).subscribe(
      (data) => {
        
        this.CountryDetails = data;

        this.CountryData = this.CountryDetails;

      }
    )
  }


  BindUniversityStates() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "countryid": this.selectedcountry
    }

    this.http.post('api/InstituteMaster/BindLocation', body, options).subscribe(
      (data) => {
        this.resData = data;
        
        if (this.resData.Status == true) {
          this.stateData = this.resData.data;
        }
        else {
          this.stateData = this.resData.data;
        }
      }
    )
  }

  BindUniversityCities() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "countryid": this.selectedcountry,
      "locationid": this.selectedlocation
    }
    var tmpclass: any = [];
    this.http.post('api/collegerepository/BindCity', body, options).subscribe(
      (data) => {
        this.resData = data;
        
        if (this.resData.Status == true) {
          this.cityData = this.resData.data;
        }
        else {
          this.cityData = this.resData.data;
        }
      }
    )
  }

  Bindcollege() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.careerdat = [];

    this.http.post('api/collegerepository/BindUniversityByStateCity?countryid=' + this.selectedcountry + '&locationid=' + this.selectedlocation + '&cityid=' + this.selectedcity, options).subscribe(
      (data) => {
        this.universitydat = data;
        if (this.universitydat.Status == true) {
          this.UniversityData = this.universitydat.data;
        }
        else {
          this.UniversityData = this.universitydat.data;
        }
      }
    )
  }

  GetImageDetail(event) {
    
    this.imagefile = event
    let file = event.target.files[0];


    //let file = event.filesData[0];

    let fileList: FileList = event.target.files;

    //let fileList: FileList = file;
    //this.imageToUpload = file.rawFile;

    this.imageToUpload = fileList[0];

    if (file.type.includes("png") || file.type.includes("jpg") || file.type.includes("jpeg")) {
      //this.orgImageName = event.filesData[0].name;
      this.orgImageName = file.name;
    }
    else {
      Swal.fire("", "Please Select Image", "error");
      this.myInputVariable.nativeElement.value = "";

    }
  }

  onSubmit() {
    
    if (this.ButtonText == "Save") {
      if (this.Selecteduniversity == 0 || this.Selecteduniversity == undefined) {
        Swal.fire("", "Please enter collegename", "error");
        return;
      }
      //if (this.Selectedcollege.match(/[ˆ(\d|+|\-)]/)) {
      //  Swal.fire("", "Name should not contain digit", "error");
      //  return;
      //}
      if (this.Selectedcareer == 0 || this.Selectedcareer == undefined) {
        Swal.fire("", "Please select career type", "error");
        return;
      }

      if (this.selectedlanguage == 0 || this.selectedlanguage == undefined) {
        Swal.fire("", "Please select language", "error");
        return;
      }

      if (this.description == "" || this.description == undefined) {
        Swal.fire("", "Please enter description", "error");
        return;
      }
      if (this.orgImageName == "" || this.orgImageName == undefined) {
        this.orgImageName == ""
      }
      //if (this.selectedvideo == "" || this.selectedvideo == undefined) {
      //  Swal.fire("", "Please choose videolink", "error");
      //  return;
      //}
     
      let input = new FormData();
      input.append("image", this.imageToUpload);
      input.append("videourl", this.SelectedVideo);
      input.append("repositoryid", "0");
      input.append("collegename", this.Selecteduniversity.toString());
      input.append("languageid", this.selectedlanguage.toString());
      input.append("careerid", this.Selectedcareer.toString());
      input.append("description", this.description.toString());
      input.append("orgimagename", this.orgImageName.toString());
      input.append("createdby", this.localstorage.get("userid").toString());

      this.http.post('api/collegerepository/savecollegerepository', input)
        .subscribe(
          (data) => {
            
            this.collegerepositoryData = data;
            if (this.collegerepositoryData.Status == true) {
              if (this.collegerepositoryData.Message == "Video Already Exists For College") {
                Swal.fire("", "Video Already Exists For College", "success");
                this.GetData(this.page);
                return;
              }
              else {
                Swal.fire("", "Saved Successfully", "success");
                this.GetData(this.page);
                this.onClear();
                return;
              }
            
            }

          })

    }
    else {
      
      if (this.Selecteduniversity == 0 || this.Selecteduniversity == undefined) {
        Swal.fire("", "Please enter collegename", "error");
        return;
      }
      //if (this.collegename.match(/[ˆ(\d|+|\-)]/)) {
      //  Swal.fire("", "Name should not contain digit", "error");
      //  return;
      //}
      if (this.Selectedcareer == 0 || this.Selectedcareer == undefined) {
        Swal.fire("", "Please select career type", "error");
        return;
      }

      if (this.selectedlanguage == 0 || this.selectedlanguage == undefined) {
        Swal.fire("", "Please select language", "error");
        return;
      }

      if (this.description == "" || this.description == undefined) {
        Swal.fire("", "Please enter description", "error");
        return;
      }
      if (this.orgImageName == "" || this.orgImageName == undefined) {
        this.orgImageName == "" 
      }
      //if (this.SelectedVideo == "" || this.SelectedVideo == undefined) {
      //  Swal.fire("", "Please choose videolink", "error");
      //  return;
      //}


      let input = new FormData();
      input.append("image", this.imageToUpload);


      input.append("repositoryid", this.repositoryid.toString());
      input.append("collegename", this.Selecteduniversity.toString());
      input.append("languageid", this.selectedlanguage.toString());
      input.append("careerid", this.Selectedcareer.toString());
      input.append("description", this.description.toString());
      input.append("orgimagename", this.orgImageName.toString());
      input.append("videourl", this.SelectedVideo);

      input.append("createdby", this.localstorage.get("userid").toString());



      this.http.post('api/collegerepository/updatecollegerepository', input)
        .subscribe(
          (data) => {
            
            this.collegerepositoryData = data;
            if (this.collegerepositoryData.Status ==true) {
              if (this.collegerepositoryData.Message == "Video Already Exists For College") {
                Swal.fire("", "Video Already Exists For College", "success");
                this.GetData(this.page);          
                return;
              }
              else {
                Swal.fire("", "Updated Successfully", "success");
                this.GetData(this.page);
                this.onClear();
                return;
              }
            

            }

          })

    }
  }

  GetData(pageno: number) {
    this.page = pageno;

    var apiname = this.search != "" && this.search != undefined ? "SearchSavedData?searchstring=" + this.search+'&' : "GetSavedData?";

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.Detail = [];

    this.http.get('api/collegerepository/' + apiname + 'page=' + pageno, options).subscribe(
      (data) => {
        
        this.Detail = data;
        this.GetSaveData = this.Detail.data;

        this.totalrepository = this.Detail.totalrepository;

        if (this.GetSaveData[0].Image == "") {
          this.checklink = true;
        }
        else {
          this.checklink = false;
        }

      }
    )
  }

  SearchData(pageno: number) {
    this.loader.start();
    this.page = pageno;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.Detail = [];

    this.http.get('api/collegerepository/GetSavedData?page=' + pageno, options).subscribe(
      (data) => {
        
        this.Detail = data;
        this.loader.stop();
        this.GetSaveData = this.Detail.data;
        this.totalrepository = this.Detail.totalrepository;

        if (this.GetSaveData[0].Image == "") {
          this.checklink = true;
        }
        else {
          this.checklink = false;
        }

      }
    )
  }

  EditData(i: number, Id) {
   
    this.onClear();
    this.BindCareer();
    this.Bindcollege();
    this.getLanguage();
    this.ButtonText = 'Update';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/collegerepository/GetEditData?repositoryid=' + Id, options).subscribe(
      (data) => {
        
        this.GetEditedData = data;
        if (this.GetEditedData.Status == true) {
          this.repositoryid = this.GetEditedData.data.repositoryid;
          this.Selecteduniversity = this.GetEditedData.data.collegename;
          this.selectedlanguage = this.GetEditedData.data.languageid;
          this.Selectedcareer = this.GetEditedData.data.careerid;
          this.description = this.GetEditedData.data.description;
          this.SelectedVideo = this.GetEditedData.data.videourl;



          

        }

          
      }
    )
  }

  DeleteData(i: number, Id) {
    var data;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    data =
    {
      "repositoryid": Id
    };

    let body = JSON.stringify(data);

    Swal.fire({
      
      text: 'Are you sure to delete this record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.http.post('api/collegerepository/DeleteActivity', body, options).subscribe(
          (data) => {
            this.DeletedData = data;
            if (this.DeletedData.Status == true) {
              Swal.fire("", "Deleted Successfully", "success");
              this.GetData(this.page);
              return;
            }
          }
        )
      }
    })

  }

  onClear() {
    
    this.Selecteduniversity = 0;
    this.ButtonText = 'Save';
    this.Selectedcareer = 0;
    this.SelectedVideo = "";
    this.SelectedImage = "";
    this.selectedlanguage = 0;
    this.orgImageName = "";
    this.description = "";
    this.myInputVariable.nativeElement.value = "";

    this.selectedcountry = 0;
    this.selectedlocation = 0;
    this.selectedcity = 0;

    this.stateData = [];
    this.cityData = [];
    this.UniversityData = [];

    this.page = 1;
    this.search = "";
  }


  getSanitizeHTML(html: string) {
    return this.sanitize.bypassSecurityTrustHtml(html);
  }


  ExportToExcel() {

    this.loader.start();

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    this.http.get('api/collegerepository/ExportToExcelAllData?searchstring=' + this.search, { headers: headers, responseType: 'blob' }).subscribe(
      (data) => {
        
        this.loader.stop();
        this.downloadData = data;

        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(this.downloadData);
        downloadLink.setAttribute('download', 'file_' + new Date().getTime());
        document.body.appendChild(downloadLink);
        downloadLink.click();

      }
    )
  }

}


