import { Component, OnInit, Input, ElementRef, ViewChild, PipeTransform, Pipe } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',

})
export class ForgotPassword implements OnInit {

  emailormobile: string = "";
  otpnum: string = "";
  newpassword: string = "";
  confirmpassword: string = "";
  passwordData: any = [];
 
  showMobile: boolean = true;
  showVerify: boolean = false;
  showChngpwd: boolean = false;
  showResend: boolean = false;

  constructor(private http: HttpClient, private router: Router, private localstorage: LocalStorageService, private toaster: ToastrService) {


  }
  ngOnInit() {
  }


  startCountdown(seconds) {
    let counter = seconds;

    const interval = setInterval(() => {
      document.getElementById('timer').innerHTML = counter < 10 ? '00' + ':' + '0' + counter + ' Seconds' : '00' + ':' + counter + ' Seconds';
      counter--;

      if (counter < 0) {
        clearInterval(interval);
        this.showResend = true;
      }
      else {
        this.showResend = false;
      }
    }, 1000);
  }

  SendOTP(type: string) {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (type === "Resend") {
      this.emailormobile = this.localstorage.get('medium');
    }

    if (type === "Send") {
      if (this.emailormobile == "" || this.emailormobile == undefined) {
        Swal.fire("", "Enter Email/Mobile", "warning");
        return;
      }
    }

    var data = {
      "mobileno": this.emailormobile
    }

    let body = JSON.stringify(data);

    this.http.post('api/resetpassword/SendOTP', body, options)
      .subscribe(
        (data) => {
          
          this.passwordData = data;
          if (this.passwordData.status == true) {            
            Swal.fire({
              text: this.passwordData.message,
              type: 'success',
              confirmButtonText: 'Yes'            
            }).then((result) => {
              if (result.value) {
                this.showResend = false;
                this.startCountdown(59);
              }
            })
            this.localstorage.set("medium", this.emailormobile);
            this.onClear();
            this.showVerify = true;
            this.showMobile = false;
          }
          else {
            Swal.fire("", this.passwordData.message, "warning");
            this.onClear();
            this.showVerify = false;
            this.showMobile = true;
            return;
          }
        }
      )
  }

  VerifyOTP() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.otpnum == "" || this.otpnum == undefined) {
      Swal.fire("", "Enter OTP", "warning");
      return;
    }

    this.http.get('api/resetpassword/MatchOTP?otp=' + this.otpnum + '&mobileno=' + this.localstorage.get('medium'), options)
      .subscribe(
        (data) => {
          
          this.passwordData = data;
          if (this.passwordData.status == true) {            
            //this.toaster.success('', this.passwordData.message, { easeTime: 1000, timeOut: 3000 })
            this.onClear();
            this.showChngpwd = true;
            this.showVerify = false;
            this.showMobile = false;
          }
          else {
            Swal.fire("", this.passwordData.message, "warning");
            this.onClear();
            this.showChngpwd = false;
            this.showVerify = true;
            this.showMobile = false;
            return;
          }
        }
      )
  }

  ChangePassword() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.newpassword == "" || this.newpassword == undefined) {
      Swal.fire("", "Enter New Password", "warning");
      return;
    }
    if (this.confirmpassword == "" || this.confirmpassword == undefined) {
      Swal.fire("", "Enter Confirm Password", "warning");
      return;
    }


    var data = {
      "mobileno": this.localstorage.get('medium'),
      "newpassword": this.newpassword,
      "confirmpassword": this.confirmpassword
    }

    let body = JSON.stringify(data);

    this.http.post('api/resetpassword/UpdatePassword', body, options)
      .subscribe(
        (data) => {
          
          this.passwordData = data;
          if (this.passwordData.status == true) {
            this.toaster.success(this.passwordData.message,'', { easeTime: 1000, timeOut:3000 })
            this.router.navigate(['login']);
            this.onClear();           
          }
          else {
            Swal.fire("", this.passwordData.message, "warning");
            this.onClear();
            this.showChngpwd = true;
            this.showVerify = false;
            this.showMobile = false;
            return;
          }
        }
      )
  }

  onClear() {
    this.emailormobile = "";
    this.newpassword = "";
    this.confirmpassword = "";
  }
}

