import { Component, OnInit} from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
declare var $: any;
import { PermissionService } from '../../../permission.service';

@Component({
  selector: 'app-designationmaster',
  templateUrl: './designationmaster.component.html'
})
export class DesignationMaster implements OnInit {

  public spinnerType = SPINNER.rectangleBounce;

  resData: any = [];
  editData: any = [];
  designationData: any = [];
  studentsData: any = [];

  public page: number = 1;
  public pageSize: number = 10;

  public search: string = "";
  public designationid: number = 0;
  public designation: string = "";
  public btntext: string = "Save";

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private permission: PermissionService  ) {

  }
  ngOnInit() {
    this.GetActions();
    this.BindData();
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  BindData() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/designation/GetDesignations', options).subscribe(
      (data) => {
        
        this.resData = data;
        this.designationData = this.resData.data;
      }
    )
  }

  Reset() {
    this.designation = "";
    this.designationid = 0;
    this.btntext = "Save";
  }

  onSubmit() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.designation == "" || this.designation == undefined) {
      this.toaster.error('Please Enter Designation', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    var body = {
      "designationid": this.designationid,
      "userid": this.localstorage.get('userid'),
      "designationname": this.designation
    }

    this.loader.start();
    this.http.post('api/designation/SaveUpdateDesignation', body, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          
          this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          this.BindData();
          this.Reset();
          return;
        }
        else {
          this.toaster.error(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          return;
        }
      }
    )

  }

  EditDesignation(_Id: number) {

    this.btntext = "Update";

    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/designation/EditDesignationData?designationid=' + _Id, options).subscribe(
      (data) => {
        
        this.resData = data;
        this.editData = this.resData.data;

        this.designationid = this.editData.designationid;
        this.designation = this.editData.designationname;

      }
    )
  }

  DeleteDesignation(_Id: number) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var data =
    {
      "designationid": _Id,
      "userid": this.localstorage.get("userid")
    };

    let body = JSON.stringify(data);

    Swal.fire({
      text: 'Are you sure to delete this record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.http.post('api/designation/DeleteDesignationData', body, options).subscribe(
          (data) => {
            this.resData = data;
            if (this.resData.status == true) {
              Swal.fire("", "Deleted Successfully", "success");
              this.BindData();
              this.Reset();
              return;
            }
            else {
              Swal.fire("", this.resData.message, "warning");
            }
          }
        )
      }
    })
  }
}
