import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import * as validator from 'validator';
import { PermissionService } from '../../../../permission.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-usermaster',
  templateUrl: './usermaster.component.html'
})
export class UserMaster implements OnInit {

  public spinnerType = SPINNER.rectangleBounce;

  public resData: any = [];
  public editData: any = [];
  public roleData: any = [];
  public usersData: any = [];

  public stateData: any = [];
  public cityData: any = [];
  public schoolData: any = [];
  public assignedschoolData: any = [];

  public channelpartner: string = "";

  public selectedstate: number = 0;
  public selectedcity: number = 0;
  public selectedschool: number = 0;

  public page: number = 1;
  public pageSize: number = 10;

  public selectedrole: number = 0;
  public userid: number=0;
  public usertype: number = 0;

  public search: string = "";

  public roleid: number = 0;
  public user_id: number = 0;
  public user_name: string = "";
  public user_email: string = "";
  public user_mobile: string = "";

  public btntext: string = "Save";

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  public stateids: string = "";
  public cityids: string = "";
  public schoolids: string = "";

  public selectedStateData: any = [];
  public selectedCityData: any = [];
  public selectedSchoolData: any = [];

  statedropdownSettings = {};
  citydropdownSettings = {};
  schooldropdownSettings = {};

  constructor(
    private http: HttpClient,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private permission: PermissionService  ) {

  }

  ngOnInit() {

    this.roleid = this.localstorage.get('roleid');
    this.usertype = this.localstorage.get('usertype');

    this.GetActions();
    this.BindState();
    this.BindData();
    this.BindRoles();

    this.statedropdownSettings = {
      singleSelection: false,
      idField: 'stateid',
      textField: 'state',
      enableCheckAll: true,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 1,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };

    this.citydropdownSettings = {
      singleSelection: false,
      idField: 'cityid',
      textField: 'cityname',
      enableCheckAll: true,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 1,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };


    this.schooldropdownSettings = {
      singleSelection: false,
      idField: 'schoolid',
      textField: 'schoolname',
      enableCheckAll: true,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 1,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
  }

  fileName = 'AssignedSchoollist.xlsx';

  exportexcel(tagid: string): void {
    let element = document.getElementById(tagid);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }

  OnSelectAllState() {
    this.stateids = "";

    if (this.stateData.length > 0) {
      this.selectedStateData = this.stateData;
    }

    for (var i = 0; i < this.selectedStateData.length; i++) {
      if (this.stateids === "") {
        this.stateids = this.selectedStateData[i].stateid;
      }
      else {
        this.stateids = this.stateids + ',' + this.selectedStateData[i].stateid;
      }
    }

    console.log(this.stateids);
    if (this.stateids != "") this.BindCity();

  }

  OnDeSelectAllState() {
    this.stateids = "";
    this.cityids = "";
    this.schoolids = "";
    this.cityData = [];
    this.schoolData = [];
    this.selectedStateData = [];
    this.selectedCityData = [];
    this.selectedSchoolData = [];
  }

  OnStateChange() {

    this.stateids = "";

    for (var i = 0; i < this.selectedStateData.length; i++) {
      if (this.stateids === "") {
        this.stateids = this.selectedStateData[i].stateid;
      }
      else {
        this.stateids = this.stateids + ',' + this.selectedStateData[i].stateid;
      }
    }

    if (this.stateids == "") {

      this.cityData = [];
      this.schoolData = [];
      this.selectedCityData = [];
      this.selectedSchoolData = [];

    }
    else {
      this.BindCity();
    }
  }

  OnSelectAllCity() {

    this.cityids = "";

    if (this.cityData.length > 0) {
      this.selectedCityData = this.cityData;
    }

    for (var i = 0; i < this.selectedCityData.length; i++) {
      if (this.cityids === "") {
        this.cityids = this.selectedCityData[i].cityid;
      }
      else {
        this.cityids = this.cityids + ',' + this.selectedCityData[i].cityid;
      }
    }

    console.log(this.cityids);
    if (this.cityids != "") this.BindSchool();
  }

  OnDeSelectAllCity() {
    this.cityids = "";
    this.schoolids = "";
    this.schoolData = [];
    this.selectedSchoolData = [];
  }

  OnCityChange() {

    this.cityids = "";

    for (var i = 0; i < this.selectedCityData.length; i++) {
      if (this.cityids === "") {
        this.cityids = this.selectedCityData[i].cityid;
      }
      else {
        this.cityids = this.cityids + ',' + this.selectedCityData[i].cityid;
      }
    }

    if (this.cityids == "") {

      this.schoolData = [];
      this.selectedSchoolData = [];

    }
    else {
      this.BindSchool();
    }

  }

  OnSelectAllSchool() {

    this.schoolids = "";

    if (this.schoolData.length > 0) {
      this.selectedSchoolData = this.schoolData;
    }

    for (var i = 0; i < this.selectedSchoolData.length; i++) {
      if (this.schoolids === "") {
        this.schoolids = this.selectedSchoolData[i].schoolid;
      }
      else {
        this.schoolids = this.schoolids + ',' + this.selectedSchoolData[i].schoolid;
      }
    }
    console.log(this.schoolids);
  }

  OnDeSelectAllSchool() {
    this.schoolids = "";
  }

  OnSchoolChange() {

    this.schoolids = "";

    for (var i = 0; i < this.selectedSchoolData.length; i++) {
      if (this.schoolids === "") {
        this.schoolids = this.selectedSchoolData[i].schoolid;
      }
      else {
        this.schoolids = this.schoolids + ',' + this.selectedSchoolData[i].schoolid;
      }
    }

  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  BindState() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.http.post('api/users/Bindstate', options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.stateData = this.resData.data;
        }
        else {
          this.stateData = [];
        }
      }
    )
  }

  BindCity() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateids": this.stateids
    }

    this.http.post('api/users/BindCity', body, options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.cityData = this.resData.data;
        }
        else {
          this.cityData = [];
        }
      }
    )
  }

  BindSchool() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateids": this.stateids,
      "cityids": this.cityids
    }

    this.http.post('api/users/BindSchool', body, options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.schoolData = this.resData.data;
        }
        else {
          this.schoolData = [];
        }
      }
    )
  }

  BindData() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/users/GetUserDetails', options).subscribe(
      (data) => {
        
        this.resData = data;

        if (this.resData.status == true) {
          this.usersData = this.resData.data;
        }
        else {
          this.usersData = [];
        }

      }
    )
  }

  BindRoles() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/role/GetRoles', options).subscribe(
      (data) => {
        
        this.resData = data;

        if (this.resData.status == true) {
          this.roleData = this.resData.data;
        }
        else {
          this.roleData = [];
        }

      }
    )
  }

  Reset() {
    this.user_id = 0;
    this.user_name = "";
    this.user_email = "";
    this.user_mobile = "";
    this.selectedrole = 0;
    this.search = "";
    this.btntext = "Save";

    this.stateids = "";
    this.cityids = "";
    this.schoolids = "";

    this.cityData = [];
    this.schoolData = [];
    this.selectedStateData = [];
    this.selectedCityData = [];
    this.selectedSchoolData = [];
  }

  Submit() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.selectedrole == 0 || this.selectedrole == undefined) {
      this.toaster.error('Please Select Role', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.user_name == "" || this.user_name == undefined) {
      this.toaster.error('Please Enter User Name', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.user_email == "" || this.user_email == undefined) {
      this.toaster.error('Please Enter User Email', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }
    else {
      if (!validator.isEmail(this.user_email)) {
        this.toaster.error('Please Enter Vaild User Email', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }

    if (this.user_mobile == "" || this.user_mobile == undefined) {
      this.toaster.error('Please Enter User Mobile', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }
    else {
      if (!validator.isMobilePhone(this.user_mobile)) {
        this.toaster.error('Please Enter Vaild User Mobile', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }

    var body = {
      "userid": this.user_id,
      "roleid": this.selectedrole,
      "user_name": this.user_name,
      "user_email": this.user_email,
      "user_mobile": this.user_mobile,
      "schoolids": this.schoolids,
      "createdby": this.localstorage.get('userid')
    }

    this.loader.start();
    this.http.post('api/users/SaveUpdateUserDetails', body, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          
          this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          this.BindData();
          this.Reset();
          return;
        }
        else {
          this.toaster.error(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          return;
        }
      }
    )

  }

  EditUser(_Id: number) {

    this.btntext = "Update";

    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/users/EditUserDetail?userid=' + _Id, options).subscribe(
      (data) => {
        
        this.resData = data;
        this.editData = this.resData.data;

        this.selectedrole = this.editData.roleid;
        this.user_id = this.editData.userid;
        this.user_name = this.editData.user_name;
        this.user_email = this.editData.user_email;
        this.user_mobile = this.editData.user_mobile;

        if (this.editData.roleid == 4) {
          this.selectedStateData = this.resData.statedata;
          this.OnStateChange();
          this.selectedCityData = this.resData.citydata;
          this.OnCityChange();
          this.selectedSchoolData = this.resData.schooldata;
          this.OnSchoolChange();
        }

      }
    )
  }

  DeleteUser(_Id: number) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var data =
    {
      "userid": _Id,
      "createdby": this.localstorage.get("userid")
    };

    let body = JSON.stringify(data);

    Swal.fire({
      text: 'Are you sure to delete this record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.http.post('api/users/DeleteUserDetail', body, options).subscribe(
          (data) => {
            this.resData = data;
            if (this.resData.status == true) {
              Swal.fire("", "Deleted Successfully", "success");
              this.BindData();
              this.Reset();
              return;
            }
            else {
              Swal.fire("", this.resData.message, "warning");
            }
          }
        )
      }
    })
  }

  setSchoolData(user:string, data: any) {
    this.assignedschoolData = [];
    this.channelpartner = user;
    this.assignedschoolData = data;
  }
}
