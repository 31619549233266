
import { Component, OnInit, Input, PipeTransform, Pipe } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbTimepickerConfig, NgbTimeStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { find, pull, filter, times, constant, debounce, set, get, keyBy, reduce, cloneDeep, sortedUniq } from 'lodash';
import { PermissionService } from '../../../permission.service';

@Component({
  selector: 'app-summerschoolreport',
  templateUrl: './summerschoolreport.component.html',
  styleUrls: ['./summerschoolreport.css'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, NgbTimepickerConfig]

})
export class SummerSchoolReportManager implements OnInit {

  spinnerType = SPINNER.rectangleBounce;

  public page: number = 1;
  public pageSize: number = 10;

  public page1: number = 1;
  public pageSize1: number = 10;
  public resData: any = [];
  public single: object = [];
  public multi: object[];
  public view: any[] = [500, 300];
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel: "Month";
  public showYAxisLabel = true;
  public yAxisLabel: "Subscription";
  public graphDataChart: any = [];
  legendTitle: string = 'Type';
  public colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };


  stuname: string = '';
  email: string = '';
  phone: string = '';

  searchTerm: string = "";
  searchTermdata: string = "";

  searchString: string = '';
  p: any;

  noofappdownloader: number = 0;
  noofnotregistered: number = 0;
  noofthroughapp: number = 0;
  noofbackend: number = 0;
  nooffreeusers: number = 0;
  noofclickonpaynow: number = 0;
  noofpaidclick: number = 0;
  notusing: number = 0;
  studentdetails: any = [];
  countdetails: any = [];
  DownloaderData: any = [];
  AppDownloaderData: any = [];
  public s_date: any;
  public e_date: any;
  public start_date: any;
  public end_date: any;
  public SelectedDate: Date;
  public SelectedEndDate: Date;
  public SelectedDate1: Date;
  public SelectedEndDate1: Date;
  public SelectedDate2: Date;
  public SelectedEndDate2: Date;
  public SelectedDate3: Date;
  public SelectedEndDate3: Date;
  public SelectedDate5: Date;
  public SelectedEndDate6: Date;
  public SelectedDate7: Date;
  public SelectedEndDate8: Date;
  public SelectedDate9: Date;
  public SelectedEndDate10: Date;
  public SelectedDate11: Date;
  public SelectedEndDate12: Date;
  public SelectedDate13: Date;
  public SelectedEndDate14: Date;
  public SelectedDate15: Date;
  public SelectedEndDate16: Date;
  public SelectedDate17: Date;
  public SelectedEndDate18: Date;
  public SelectedDate19: Date;
  public SelectedEndDate20: Date;
  public SelectedDate21: Date;
  public SelectedEndDate22: Date;
  public SelectedDate23: Date;
  public SelectedEndDate24: Date;
  public SelectedDate25: Date;
  public SelectedEndDate26: Date;
  public SelectedDate27: Date;
  public SelectedEndDate28: Date;
  public SelectedDate29: Date;
  public SelectedEndDate30: Date;

  public SelectedDate51: Date;
  public SelectedDate52: Date;

  public SelectedDate54: Date;
  public SelectedDate55: Date;

  public SelectedDate59: Date;
  public SelectedDate60: Date;

  public SelectedDate61: Date;
  public SelectedDate62: Date;
  selectedbuilder: number = 0;
  selectedstudentq: number = 0;
  StudentData: any = [];
  statedat: any = [];
  StateData: any = [];
  citdata: any = [];
  selectedstate: number = 0;
  selectedstatef: number = 0;
  selectedstateg: number = 0;
  selectedstateh: number = 0;
  selectedstateq: number = 0;
  selectedstatepl: number = 0;
  GetPaymentDetails: any = [];

  CityData: any = [];
  schdata: any = [];
  selectedcity: number = 0;
  selectedcityf: number = 0;
  selectedcityg: number = 0;
  selectedcityh: number = 0;
  selectedcityq: number = 0;
  selectedcitypl: number = 0;


  SchoolData: any = [];
  classdat: any = [];
  ClassData: any = [];
  streamdat: any = [];
  StreamData: any = [];
  selectedschool: number = 0;
  selectedschoolf: number = 0;
  selectedschoolg: number = 0;
  selectedschoolh: number = 0;
  selectedschoolq: number = 0;

  selectedclass: number = 0;
  selectedclassf: number = 0;
  selectedclassg: number = 0;
  selectedclassh: number = 0;
  selectedclassi: number = 0;
  selectedclassj: number = 0;
  selectedclassq: number = 0;
  selectedclasspl: number = 0;


  selectedstream: number = 0;
  selectedstreamf: number = 0;
  selectedstreamg: number = 0;
  selectedstreamh: number = 0;
  selectedstreami: number = 0;
  selectedstreamj: number = 0;
  selectedstreamq: number = 0;
  selectedstreampl: number = 0;

  productdat: any = [];
  Products: any = [];
  selectproduct: number = 0;

  getunregisteredstudent: any = [];
  Getstudentdetails: any = [];
  getfreeuser: any = [];
  GetFreeUserDetails: any = [];
  getnotsubclickpaid: any = [];
  GetClickPaidNotSub: any = [];
  GetSubscribed: any = [];
  getsubscribeduser: any = [];
  getscholarshipdata: any = [];
  GetScholarshipDetails: any = [];
  getentrancedata: any = [];
  GetEntranceExamDetails: any = [];
  noofsummerschool: number = 0;
  Schooldetails: any = [];
  summercountdetails: any = [];
  SummerData: any = [];
  SummerSchoolData: any = [];
  selectedcountry: number = 0;
  location: number = 0;
  city: number = 0;
  univercity: number = 0;
  UniversityData: any = [];
  CityDataCareer: any = [];
  LocationData: any = [];
  CountryData: any = [];
  selectedinterest: number = 0;
  AreaData: any = [];
  AreaDetails: any = [];
  CountryDetails: any = [];
  locdata: any = [];
  univdata: any = [];
  getsummerschooldata: any = [];
  GetSummerSchoolDetails: any = [];
  studata: any = [];
  StudentDatas: any = [];
  getquerydata: any = [];
  GetQueryDetails: any = [];
  selectedcareer: string = "";
  CareerData: any = [];
  keyword: string = "";
  careerdta: any = [];
  getfaqdata: any = [];
  GetFaqDetails: any = [];
  selectedcareer1: string = "";
  getfaqissuedata: any = [];
  GetFaqissueDetails: any = [];
  keyword1: string = "";
  getarticledata: any = [];
  GetArticleDetails: any = [];
  Coachtypedetails: any = [];
  TopicData: any = [];
  Coachdetail: any = [];
  CoachData: any = [];
  selectedtopic: number = 0;
  selectedcoach: number = 0;
  getcoachdata: any = [];
  GetcoachDetails: any = [];
  getplaceddata: any = [];
  GetplacedDetails: any = [];
  getdatapaid: any = [];
  getclickdata: any = [];
  GetClickDetails: any = [];
  getbuilderdata: any = [];
  GetBuilderDetails: any = [];
  selectedcat: number = 0;
  selectedsubcat: number = 0;
  selectedstatus: number = 0;
  getusagedata: any = [];
  GetUsageDetails: any = [];
  NodataFound1: boolean = false;
  NodataFound2: boolean = false;
  NodataFound3: boolean = false;
  NodataFound4: boolean = false;
  ShowTable1: boolean = false;
  ShowTable2: boolean = false;
  ShowTable3: boolean = false;
  ShowTable4: boolean = false;

  monthwisegraphdata: any = [];
  noofclickonpaynow1: number = 0;
  selectedtype: number = -1;
  studentid: number = 0;

  year: number = 0;
  month: number = 0;
  date: number = 0;

  
  description: string = '';
  heading: string = '';
  summerschool_country: string = '';

  studentDetail: any = [];
  studentDetailData: any = [];

  SummerSchoolReportData: any = [];
  SummerSchoolDataDetail: any = [];

  filterdata: any = [];

  pipe = new DatePipe('en-US');

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor(private http: HttpClient, private permission: PermissionService, private router: Router, private localstorage: LocalStorageService, private toaster: ToastrService,
    private loader: NgxUiLoaderService, config: NgbTimepickerConfig, private config1: NgbDatepickerConfig, private sanitize: DomSanitizer) {
    config.seconds = false;
    config.spinners = false;
    config.meridian = true;

    this.date = new Date().getDate();
    this.year = new Date().getFullYear();
    this.month = parseInt(new Date().toISOString().slice(0, 10).split('-')[1]);

  }
  ngOnInit() {
    this.GetActions();
    this.getSummerSchoolReport();
    this.getCountry();
    this.getArea();
  }


  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }



   /*name of the excel-file which will be downloaded. */
    fileName = 'SummerSchoolSheet.xlsx';

    exportexcel(tagid: string): void {
      
      /* table id is passed over here */
      let element = document.getElementById(tagid);
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, this.fileName);

    }

  getSummerSchoolReport() {

    if (this.SelectedDate9 == undefined || this.SelectedDate9 == null) {
      this.s_date = "1900-01-01";
    }
    else {
      this.s_date = this.SelectedDate9.toISOString().slice(0, 10);

    }
    if (this.SelectedEndDate10 == undefined || this.SelectedEndDate10 == null) {
      this.e_date = "1900-01-01";
    }
    else {
      this.e_date = this.SelectedEndDate10.toISOString().slice(0, 10);
    }

    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();

    this.http.get('api/reports/SummerSchoolReport?fromdate=' + this.s_date + '&todate=' + this.e_date + '&countryid=' + this.selectedcountry
      + '&interestid=' + this.selectedinterest , options).subscribe(
        (data) => {

          

          this.loader.stop();

          this.SummerSchoolReportData = [];

          this.SummerSchoolReportData = data;

          this.page = 1;

          if (this.SummerSchoolReportData.Status == true)
          {
            this.SummerSchoolDataDetail = this.SummerSchoolReportData.data;
            this.loader.stop();
          }
          else
          {
            this.SummerSchoolDataDetail = [];
            this.loader.stop();
          }
        }
      )
  }



  getSummerschoolReportDetail(primaryid:number) {
    
    this.filterdata = [];
    this.filterdata = find(this.SummerSchoolDataDetail, (summerdata) => summerdata.ID == primaryid)
    this.description = '';
    this.description = this.filterdata.description;
  }



  GetSummerStudentDetail(id: number, heading: string, country: string) {

    this.heading = heading;
    this.summerschool_country = country;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    
    this.http.get('api/reports/GetSummerStudentData?id=' + id, options).subscribe(
      (data) => {
        this.loader.stop();
        
        this.page1 = 1;

        this.studentDetail = data;
        if (this.studentDetail.Status == true)
        {
          this.studentDetailData = this.studentDetail.detaildata;
          this.loader.stop();
        }
        else { this.studentDetailData = []; this.loader.stop(); }

      }
    )

  }


  setPageNo() {
    this.page = 1;
    this.page1 = 1;
  }

  getCountry()
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.CountryDetails = [];
    var a;
    var tmpclass: any = [];
    this.http.get('api/summerschool/Bindcountry', options).subscribe(
      (data) => {
        
        this.CountryDetails = data;

        this.CountryData = this.CountryDetails;

      }
    )
  }

  getArea() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.AreaDetails = [];
    var a;
    var tmpclass: any = [];
    this.http.get('api/summerschool/Bindintrestarea', options).subscribe(
      (data) => {
        
        this.AreaDetails = data;

        this.AreaData = this.AreaDetails;

      }
    )
  }

  
  ResetSummerSchoolReport() {

    this.selectedinterest = 0;
    this.selectedcountry = 0;
    this.SelectedDate9 = null;
    this.SelectedEndDate10 = null;
    this.searchTerm = '';
    this.getSummerSchoolReport();
  }

}
