import { Component, OnInit, Input, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { EmbedVideoService } from 'ngx-embed-video';
import { DomSanitizer } from '@angular/platform-browser';
import { Time } from '@angular/common';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbTimepickerConfig, NgbTimeStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { debug } from 'util';
import { PermissionService } from '../../../permission.service';
declare var $: any;



@Component({
  selector: 'app-activitymaster',
  templateUrl: './activitymaster.component.html',
  styleUrls: ['./activitymaster.component.css'],

  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, NgbTimepickerConfig]
})
export class ActivityManager implements OnInit {

  spinnerType = SPINNER.rectangleBounce;

  public SelectedTopic: number = 0;
  public videofile: any = [];
  public imagefile: any = [];
  public TopicData: any = [];
  public statedat: any = [];
  public StateData: any = [];
  public citdata: any = [];
  public CityData: any = [];
  public selectedstate: number = 0;
  public selectedcity: number = 0;
  public selectedschool: number = 0;
  public schdata: any = [];
  public SchoolData: any = [];
  public Detail: any = [];
  public SelectedClass: number = 0;
  public ClassData: any = [];
  public topicid: number = 0;
  public SelectedStream: number = 0;
  public StreamData: any = [];
  public ButtonText: string = 'Save';
  public HeaderData: any = [];
  public GetSaveData: Array<any> = [];
  public SelectedVideo: string = "";
  public SelectedImage: string = "";
  public Id: number = 0;
  search: string = "";

  public SelectedStartDate: Date;
  public SelectedEndDate: Date;
  public Start_Date: any;
  public End_Date: any;

  public st_time: Time;
  public end_time: Time;
  public meridian: boolean = true;


  public GetEditedData: any = [];
  public DeletedData: any = [];
  public PlannedActivityData: any = [];
  public orgVideoName: string = "";
  public orgImageName: string = "";
  public ClassDetails: any = [];
  public StreamDetails: any = [];
  public videouploaded: String = "0";
  public videouploaddiv: boolean = false;
  public videoToUpload: any;
  public imageToUpload: any;
  public video: string = "";
  public image: string = "";
  public vname: boolean = false;
  public display: any = "none";
  public url: string = "";
  public yt: any;
  @ViewChild('inputFile', { static: true }) private myInputVariable: ElementRef;
  public Details: any = [];
  public classid: string = "";
  public AllClass: boolean = false;
  public streamid: string = "";
  public AllStream: boolean = false;
  public s_date: any;
  public e_date: any;
  public todaydate: any;
  public starttime: NgbTimeStruct;
  public endtime: NgbTimeStruct;
  public checklink: boolean = false;
  public Selecteduser: number = 0;
  public UserDetails: any = [];
  public UserData: any = [];
  public showstream: number = 0;
  //public publish: number = 0;
  public checklink_v: boolean = false;

  //public publish: boolean = false;
  public publish: number = 0;
  public IsSchool: boolean = false;
  public IsPublishSelect: boolean = false;
  chatlink: string = "";
  StateIds: string = "";
  AllState: boolean = false;
  CityIds: string = "";
  AllCity: boolean = false;
  SchoolIds: string = "";
  AllSchool: boolean = false;
  GetSchoolData: any = [];
  GetCityData: any = [];
  selectedwebtype: number = 0;
  ShowTable: boolean = false;
  editmode: boolean = false;

  p: any;

  public imageSrc: string="";

  public speaker_name: string = "";
  public speaker_about: string = "";
  public speaker_profile: any;

  public resData: any;
  public savedData: any;
  public editData: any;
  public imagetouploadData: any;

  public userid: number = 0;
  public activityid: number=0;
  public universityid: number;
  public userids: string = "";
  public activityname: string = "";
  public academyids: string = "";
  public careerids: string = "";
  public stateids: string = "";
  public cityids: string = "";
  public schoolids: string = "";
  public classids: string = "";
  public streamids: string = "";
  public activityrules: string = "";
  public activitydescription: string = "";
  public registrationlink: string = "";
  public videolink: string = "";
  public viewvideolink: string = "";
  public speakername: string = "";
  public speakerdesignation: string = "";
  public profileimage: string = "";
  public stu_expectation: string = "";
  public activity_output: string = "";
  public btn_text: string = "Save";

  public userData: any = [];
  public academyData: any = [];
  public careerData: any = [];
  public partnerData: any = [];
  public usersData: any = [];
  public speakerData: any = [];
  public academycareersData: any = [];
  public partnerUniversityData: any = [];
  public selectedUserData: any = [];
  public selectedAcademyData: any = [];
  public selectedCareerData: any = [];
  public selectedUniversityData: any = [];
  public selectedStateData: any = [];
  public selectedCityData: any = [];
  public selectedSchoolData: any = [];
  public selectedClassData: any = [];
  public selectedStreamData: any = [];

  public universityname: string = "";
  public description: string = "";
  public expectation: string = "";
  public expected: string = "";
  public activity_rules: string = "";
  public speaker_designation: string = "";
  public speakerabout: string = "";
  public speaker_photo_url: string = "";
  public startdate: string = "";
  public enddate: string = "";
  public onday: string = "";

  public thumbnailpath: string = "";

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  userdropdownSettings = {};
  academydropdownSettings = {};
  careerdropdownSettings = {};
  universitydropdownSettings = {};
  statedropdownSettings = {};
  citydropdownSettings = {};
  schooldropdownSettings = {};
  classdropdownSettings = {};
  streamdropdownSettings = {};

  @ViewChild('thumbnailimage', { static: true }) private ImageUploadVeriable: ElementRef;
  constructor(
    private http: HttpClient,
    private sanitize: DomSanitizer,
    private router: Router,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private renderer: Renderer2,
    private config: NgbTimepickerConfig,
    private config1: NgbDatepickerConfig,
    private permission: PermissionService  )
  {
    config.seconds = false;
    config.spinners = false;
    config.meridian = true;
    
    const current = new Date();
    config1.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    config1.maxDate = {
      year: current.getFullYear()+25,
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    config1.outsideDays = 'hidden';

    this.userData = [
      { "userid": 1, "username": "Student" },
      { "userid": 2, "username": "Parent" },
      { "userid": 3, "username": "Principal" }]
  }

  ngOnInit() {
    this.loader.start();
    this.userid = this.localstorage.get("userid");
    this.todaydate = new Date().toISOString().slice(0, 10);

    this.GetActions();
    this.BindState();
    this.GetClass();
    this.GetStream();
    this.BindUser();
    this.GetData();

    this.GetPartnerUniversityLists();

    this.userdropdownSettings = {
      singleSelection: false,
      idField: 'userid',
      textField: 'username',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableCheckAll: true,
      allowSearchFilter: false,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 1,
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };

    this.universitydropdownSettings = {
      singleSelection: true,
      idField: 'universityid',
      textField: 'universityname',
      enableCheckAll: false,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: true,
      defaultOpen: false,

    };

    this.academydropdownSettings = {
      singleSelection: false,
      idField: 'academyid',
      textField: 'academyname',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableCheckAll: true,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 1,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };

    this.careerdropdownSettings = {
      singleSelection: false,
      idField: 'careerid',
      textField: 'careername',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableCheckAll: true,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 1,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };

    this.statedropdownSettings = {
      singleSelection: false,
      idField: 'stateId',
      textField: 'statename',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableCheckAll: true,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 1,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: true,
      defaultOpen: false,

    };

    this.citydropdownSettings = {
      singleSelection: false,
      idField: 'cityid',
      textField: 'cityname',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableCheckAll: true,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 1,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };

    this.schooldropdownSettings = {
      singleSelection: false,
      idField: 'schoolid',
      textField: 'schoolname',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableCheckAll: true,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 1,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };

    this.classdropdownSettings = {
      singleSelection: false,
      idField: 'classid',
      textField: 'classname',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableCheckAll: true,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };

    this.streamdropdownSettings = {
      singleSelection: false,
      idField: 'streamid',
      textField: 'streamname',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableCheckAll: true,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 2,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
    this.loader.stop();
  }


  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }
  _getThumbnailDetails(event) {
    

    let file = event.target.files[0];
    let fileList: FileList = event.target.files;

    if (file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg")) {
      this.imagetouploadData = fileList[0];
    }
    else {
      Swal.fire("", "Please Select Image", "error");
      this.ImageUploadVeriable.nativeElement.value = "";
      this.imagetouploadData = [];
    }
  }

  add3Dots(string): string {
    var dots = " .....";

    if (string.length > 100) {
      string = string.substring(0, 100) + dots;
    }
    return string;
  }

  ChangeTab(_Tab: string) {
    $(document).ready(function () {
      $(_Tab).click();
    });
  }

  getSanitizeHTML(html: string) {
    return this.sanitize.bypassSecurityTrustHtml(html);
  }

  GetPartnerUniversityLists() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/activitymaster/GetPartnerUniversityData', options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        this.partnerUniversityData = this.resData.data;
      }
    )
  }

  OnUserChange() {
    this.userids = "";

    for (var i = 0; i < this.selectedUserData.length; i++) {
      if (this.userids === "") {
        this.userids = this.selectedUserData[i].userid;
      }
      else {
        this.userids = this.userids + ',' + this.selectedUserData[i].userid;
      }
    }
  }

  OnSelectAllUser() {
    this.userids = "0";
  }

  OnDeSelectAllUser() {
    this.userids = "";
  }

  OnUniversityChange() {

    this.universityid = 0;

    for (var i = 0; i < this.selectedUniversityData.length; i++) {
      if (this.universityid === 0) {
        this.universityid = this.selectedUniversityData[i].universityid;
      }
    }
    this.GetPartnerUniversityAcademyLists(this.universityid);
  }

  GetPartnerUniversityAcademyLists(_PatnerUniversityId: number) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/activitymaster/GetAcademyDetailByPartnerId?partnetuniversityid=' + _PatnerUniversityId, options).subscribe(
      (data) => {

        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.academyData = this.resData.data;
        }
        else {
          this.academyData = [];
        }
      }
    )
  }

  OnAdacemyChange() {

    this.academyids = "";

    if (this.selectedAcademyData.length > 0) {

      if (this.academyData.length == this.selectedAcademyData.length) {
        this.academyids = "0";

      }
      else {

        for (var i = 0; i < this.selectedAcademyData.length; i++) {
          if (this.academyids === "") {
            this.academyids = this.selectedAcademyData[i].academyid;
          }
          else {
            this.academyids = this.academyids + ',' + this.selectedAcademyData[i].academyid;
          }
        }

      }
    }

    this.GetCareerLists(this.academyids);
  }

  OnSelectAllAcademy() {
    this.selectedCareerData = [];
    this.academyids = "0";      
    this.GetCareerLists(this.academyids);
  }

  OnDeSelectAllAcademy() {
    this.academyids = "";
    this.careerData = [];
    this.selectedCareerData = [];
  }

  GetCareerLists(_AcademyIds: string) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/partneruniversitymaster/GetPartnerCareerData?academyids=' + _AcademyIds + '&partner_universityid=' + this.universityid, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.careerData = this.resData.data;
        }
        else {
          this.careerData = [];
          this.selectedCareerData = [];
        }
      }
    )

  }

  OnCareerChange() {
    this.careerids = "";

    if (this.selectedCareerData.length > 0) {

      if (this.careerData.length == this.selectedCareerData.length) {
        this.careerids = "0";

      }
      else {

        for (var i = 0; i < this.selectedCareerData.length; i++) {
          if (this.careerids === "") {
            this.careerids = this.selectedCareerData[i].careerid;
          }
          else {
            this.careerids = this.careerids + ',' + this.selectedCareerData[i].careerid;
          }
        }

      }
    }


  }

  OnSelectAllCareer() {
    this.careerids = "0";
  }

  OnDeSelectAllCareer() {
    this.careerids = "";
    this.careerData = [];
  }

  BindUser() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/Plannedactivity/BindUser', options).subscribe(
      (data) => {

        this.UserDetails = data;
        if (this.UserDetails.status = true) {
          this.UserData = this.UserDetails.data;
        }
        else {
          this.toaster.error(this.UserDetails.message.toString(), '', { easeTime: 1000, timeOut: 3000 })
        }
      }
    )
  }

  BindState() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.statedat = [];
    this.http.post('api/Plannedactivity/Bindstate', options).subscribe(

      (data) => {
        this.statedat = data;
        if (this.statedat.Status == true) {
          this.StateData = this.statedat.data;
        }
        else {
          this.StateData = [];
        }
      }
    )
  }

  OnStateChange() {

    this.stateids = "";

    if (this.selectedStateData.length > 0) {
      if (this.StateData.length == this.selectedStateData.length) {
        this.stateids = "0";
      }
      else {
        for (var i = 0; i < this.selectedStateData.length; i++) {
          if (this.stateids === "") {
            this.stateids = this.selectedStateData[i].stateId;
          }
          else {
            this.stateids = this.stateids + ',' + this.selectedStateData[i].stateId;
          }
        }
      }
    }

    this.GetCityByState();
  }

  OnSelectAllState() {
    this.selectedCityData = [];
    this.selectedSchoolData = [];
    this.stateids = "0";
    this.GetCityByState();
  }

  OnDeSelectAllState() {
    this.stateids = "";
    this.CityData = [];
    this.selectedCityData = [];
    this.selectedSchoolData = [];
  }

  GetCityByState() {

    if (this.stateids == null) { this.stateids = ""; }

    if (this.stateids != "") {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.http.get('api/Subscription/getcityfilter?StateId=' + this.stateids, options).subscribe(
        (data) => {
          
          this.GetCityData = data;
          if (this.GetCityData.Status == true) {
            this.CityData = this.GetCityData.citydata;

            if (this.selectedCityData.length > 0) {
              if (this.CityData.length == this.selectedCityData.length) {
                this.cityids = "0";
              }
            }
          }
        }
      )
    }
    else {
      this.CityData = []
      this.selectedCityData = [];
    }
  }

  OnCityChange() {
    this.cityids = "";
    var count = 0;

    if (this.selectedCityData.length > 0) {

      if (this.CityData.length == this.selectedCityData.length) {
        this.cityids = "0";
      }
      else {

        for (var i = 0; i < this.selectedCityData.length; i++) {
          if (this.cityids === "") {
            this.cityids = this.selectedCityData[i].cityid;
            count++;
          }
          else {
            this.cityids = this.cityids + ',' + this.selectedCityData[i].cityid;
            count++;
          }
        }
      }
    }

    this.GetSchoolByStateCity();
  }

  OnSelectAllCity() {
    this.selectedSchoolData = [];
    this.cityids = "0";
    this.GetSchoolByStateCity();
  }

  OnDeSelectAllCity() {
    this.cityids = "";
    this.SchoolData = [];
    this.selectedSchoolData = [];
  }

  GetSchoolByStateCity() {
    
    if (this.stateids == null) { this.stateids = ""; }
    if (this.cityids == null) { this.cityids = ""; }

    if (this.stateids != "" && this.cityids != "") {

      let input = new FormData();

      input.append("StateId", this.stateids);
      input.append("CityId", this.cityids);

      this.http.post('api/Subscription/getschoolfilterbystatecity', input).subscribe(
        (data) => {
          
          this.GetSchoolData = data;

          if (this.GetSchoolData.Status == true) {
            this.SchoolData = this.GetSchoolData.schooldata;

            if (this.selectedSchoolData.length > 0) {
              if (this.SchoolData.length == this.selectedSchoolData.length) {
                this.schoolids = "0";
              }
            }
          }


        }
      )
    }
    else {
      this.SchoolData = [];
      this.selectedSchoolData = [];
    }

  }

  OnSchoolChange() {
    
    this.schoolids = "";

    if (this.selectedSchoolData.length > 0) {
      if (this.SchoolData.length == this.selectedSchoolData.length) {
        this.schoolids = "0";
      }
      else {

        for (var i = 0; i < this.selectedSchoolData.length; i++) {
          if (this.schoolids === "") {
            this.schoolids = this.selectedSchoolData[i].schoolid;
          }
          else {
            this.schoolids = this.schoolids + ',' + this.selectedSchoolData[i].schoolid;
          }
        }

      }
    }

  }

  OnSelectAllSchool() {
    this.schoolids = "0";
  }

  OnDeSelectAllSchool() {
    this.schoolids = "";
  }

  GetClass() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/Plannedactivity/GetClass', options).subscribe(
      (data) => {
        this.Details = data;
        if (this.Details.status == true) {
          this.ClassData = this.Details.data;
        }
        else {
          this.toaster.error(this.Details.message.toString(), '', { easeTime: 1000, timeOut: 3000 })
        }
      }
    )
  }

  OnClassChange() {
    this.classids = "";

    for (var i = 0; i < this.selectedClassData.length; i++) {
      if (this.classids === "") {
        this.classids = this.selectedClassData[i].classid;
      }
      else {
        this.classids = this.classids + ',' + this.selectedClassData[i].classid;
      }
    }


    for (var i = 0; i < this.selectedClassData.length; i++) {
      if (this.selectedClassData[i].classid == 1 || this.selectedClassData[i].classid == 2 || this.selectedClassData[i].classid == 3) {
        this.showstream = 0;
      }
      else {
        this.GetStream();
        this.showstream = 1;
      }
    }

    if (this.classids.toString().includes('4') || this.classids.toString().includes('5') || this.classids.toString().includes('6')) {
      this.showstream = 1;
    }
    else {
      this.showstream = 0;
    }

    if (this.selectedClassData.length == 0) {
      this.showstream = 0;
      this.StreamData = [];
    }

    if (this.selectedClassData.length > 0) {

      if (this.ClassData.length == this.selectedClassData.length) {
        this.classids = "0";

      }
    }

  }

  OnSelectAllClass() {
    this.classids = "0";
    this.GetStream();
    this.showstream = 1;
  }

  OnDeSelectAllClass() {
    this.classids = "";
    this.showstream = 0;
    this.StreamData = [];
    this.selectedStreamData = [];
  }

  GetStream() {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.http.get('api/Plannedactivity/getStream', options).subscribe(
      (data) => {
        this.StreamDetails = data;
        if (this.StreamDetails.status == true) {
          this.StreamData = this.StreamDetails.data;
          this.GetData();
        }
        else {
          this.toaster.error(this.StreamDetails.message.toString(), '', { easeTime: 1000, timeOut: 3000 })
        }
      }
    )
  }

  OnStreamChange() {
    

    this.streamids = "";

    if (this.selectedStreamData.length > 0) {

      if (this.StreamData.length == this.selectedStreamData.length) {

        this.streamids = "0";

      }
      else {

        for (var i = 0; i < this.selectedStreamData.length; i++) {
          if (this.streamids === "") {
            this.streamids = this.selectedStreamData[i].streamid;
          }
          else {
            this.streamids = this.streamids + ',' + this.selectedStreamData[i].streamid;
          }
        }

      }
    }

  }

  OnSelectAllStream() {
    this.streamids = "0";
  }

  OnDeSelectAllStream() {
    this.streamids = "";
  }

  GetData() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/activitymaster/GetActivityData', options).subscribe(
      (data) => {
        
        this.resData = data;
        if (this.resData.status == true) {
          this.GetSaveData = this.resData.data;
          this.ShowTable = true;
        }
        else {
          this.GetSaveData = [];
          this.ShowTable = false;
        }
      }
    )
  }

  SpeakerDetails(_ActivityId: number) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/activitymaster/GetSpeakerDetails?activityid=' + _ActivityId, options).subscribe(
      (data) => {
        this.resData = data;

        if (this.resData.status == true) {
          this.speakerData = this.resData.data;
          this.speaker_name = this.speakerData[0].speakername;
          this.speaker_about = this.speakerData[0].speakerdesignation;
          this.speaker_profile = this.sanitize.bypassSecurityTrustResourceUrl(this.speakerData[0].speaker_profile);
        }
        else {
          this.speakerData = [];
        }
      }
    )
  }

  Reset() {
    this.btn_text = "Save"
    this.editmode = false;
    this.myInputVariable.nativeElement.value = "";
    this.imageSrc = "";
    this.activityid = 0;
    this.activityname = "";
    this.activityrules = "";
    this.activitydescription = "";
    this.registrationlink = "";
    this.videolink = "";
    this.SelectedStartDate = null;
    this.SelectedEndDate = null;
    this.Start_Date = null;
    this.End_Date = null;
    this.starttime = null;
    this.endtime = null;
    this.speakername = "";
    this.speakerdesignation = "";
    this.speaker_profile = "";
    this.imageToUpload = [];
    this.selectedUserData = [];
    this.userids = "";
    this.universityid = 0;
    this.selectedUniversityData = [];
    this.academyids = "";
    this.selectedAcademyData = [];
    this.careerids = "";
    this.selectedCareerData = [];
    this.stateids = "";
    this.selectedStateData = [];
    this.cityids;
    this.selectedCityData = [];
    this.schoolids = "";
    this.selectedSchoolData = [];
    this.classids = "";
    this.selectedClassData = [];
    this.streamids = "";
    this.selectedStreamData = [];
    this.stu_expectation = "";
    this.activity_output = "";
    this.Selecteduser = 0;
    this.selectedwebtype = 0;
    this.showstream = 0;
    this.IsPublishSelect = false;
    this.academyData = [];
    this.careerData = [];
    this.CityData = [];
    this.SchoolData = [];
    this.StreamData = [];
    this.ImageUploadVeriable.nativeElement.value = "";
    this.imagetouploadData = [];
  }


  VideoLinkReset() {
    this.videolink = "";
    this.ImageUploadVeriable.nativeElement.value = "";
    this.imagetouploadData = [];
  }

  activityReset() {
    this.activityname = "";
    this.activityrules = "";
    this.activitydescription = "";
    this.registrationlink = "";
    this.videolink = "";
    this.SelectedStartDate = null;
    this.SelectedEndDate = null;
    this.Start_Date = null;
    this.End_Date = null;
    this.starttime = null;
    this.endtime = null;
    this.stu_expectation = "";
    this.activity_output = "";
    this.selectedwebtype = 0;
    this.selectedUserData = [];
    this.userids = "";
  }

  speakerReset() {
    this.speakername = "";
    this.speakerdesignation = "";
    this.speaker_profile = "";
    this.imageToUpload = [];
    this.myInputVariable.nativeElement.value = "";
    this.imageSrc = "";
  }

  filterReset() {
    this.universityid = 0;
    this.selectedUniversityData = [];
    this.academyids = "";
    this.selectedAcademyData = [];
    this.careerids = "";
    this.selectedCareerData = [];
    this.stateids = "";
    this.selectedStateData = [];
    this.cityids;
    this.selectedCityData = [];
    this.schoolids = "";
    this.selectedSchoolData = [];
    this.classids = "";
    this.selectedClassData = [];
    this.streamids = "";
    this.selectedStreamData = [];
    this.academyData = [];
    this.careerData = [];
    this.CityData = [];
    this.SchoolData = [];
    this.StreamData = [];
    this.showstream = 0;
  }

  OnEdit(_ActivityId: number) {
    this.btn_text = "Update";
    this.editmode = true;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/activitymaster/EditActivityData?activityid=' + _ActivityId, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          
          this.editData = this.resData.data;
          this.activityid=this.resData.data[0].activityid;
          this.activityname = this.resData.data[0].activityname;
          this.activityrules = this.resData.data[0].activityrules;
          this.activitydescription = this.resData.data[0].description;
          this.registrationlink = this.resData.data[0].registrationlink;
          this.videolink = this.resData.data[0].videolink;
          this.thumbnailpath = this.resData.data[0].video_thumbnail;
          this.speakername = this.resData.data[0].speakername;
          this.speakerdesignation = this.resData.data[0].speakerdesignation;
          this.imageSrc = this.resData.data[0].speaker_profile;
          this.speaker_profile = this.resData.data[0].speaker_profile;
          this.stu_expectation = this.resData.data[0].stu_expectation;
          this.activity_output = this.resData.data[0].activity_output;
          this.SelectedStartDate = new Date(this.resData.data[0].startdate);
          this.SelectedEndDate = new Date(this.resData.data[0].enddate);
          this.publish = this.resData.data[0].publish;

          var mtime = this.resData.data[0].starttime.split(":");
          var etime = this.resData.data[0].endtime.split(":");

          this.starttime = { hour: parseInt(mtime[0]), minute: parseInt(mtime[1]), second: parseInt(mtime[2]) };
          this.endtime = { hour: parseInt(etime[0]), minute: parseInt(etime[1]), second: parseInt(etime[2]) };        

          this.selectedUserData = this.resData.data[0].userdata;
          this.OnUserChange();

          this.selectedwebtype = this.resData.data[0].activitytype;

          this.selectedUniversityData = this.resData.data[0].universitydata;
          this.OnUniversityChange();
          
          this.selectedAcademyData = this.resData.data[0].academydata;
          this.OnAdacemyChange();
          this.selectedCareerData = this.resData.data[0].careerdata;
          this.OnCareerChange();
          this.selectedStateData = this.resData.data[0].statedata;
          this.OnStateChange();
          this.selectedCityData = this.resData.data[0].citydata;
          this.OnCityChange();
          this.selectedSchoolData = this.resData.data[0].schooldata;
          this.OnSchoolChange();
          this.selectedClassData = this.resData.data[0].classdata;
          this.OnClassChange();
          this.selectedStreamData = this.resData.data[0].streamdata;
          this.OnStreamChange();
          this.ShowTable = true;

          $('#_menu1').click();
          $("html, body").animate({ scrollTop: 0 }, "smooth");
        }
        else {
          this.editData = [];
          this.ShowTable = false;
        }
      }
    )
  }

  OnDelete(_ActivityId: number) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    Swal.fire({
      text: 'Are you sure to delete ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.http.get('api/activitymaster/DeleteActivityDetails?activityid=' + _ActivityId, options).subscribe(
          (data) => {
            this.resData = data;
            this.loader.stop();
            if (this.resData.status == true) {
              Swal.fire("", this.resData.message, "success");
              this.GetData();
              this.Reset();
              return;
            }
            else {
              Swal.fire("", this.resData.message, "warning");
            }
          }
        )
      }
    })

  }

  GetActivityId(_ActivityId: number) {
    this.activityid = _ActivityId;
  }

  GetActivityDetailsById(_ActivityId: number) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let options = { headers: headers };

    this.loader.start();
    this.http.get('/api/activitymaster/GetActivityDetailsById?id=' + _ActivityId, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.activityid = this.resData.data.activityid;
          this.universityname = this.resData.data.universityname;
          this.description = this.resData.data.description;
          this.expectation = this.resData.data.expectation;
          this.expected = this.resData.data.expected;
          this.activity_rules = this.resData.data.activity_rules;
          this.speaker_name = this.resData.data.speaker_name;
          this.speaker_designation = this.add3Dots(this.resData.data.speaker_designation);
          this.speakerabout = this.resData.data.speaker_designation;
          this.speaker_photo_url = this.resData.data.speaker_photo_url;
          this.starttime = this.resData.data.starttime;
          this.endtime = this.resData.data.endtime;
          this.startdate = this.resData.data.startdate;
          this.enddate = this.resData.data.enddate;
          this.onday = this.resData.data.onday;
        }        
      }
    )
  }

  ViewVideoLink(_Video: string) {
    this.viewvideolink = _Video;
  }

  AddVideoLink() {

    if (this.videolink == undefined || this.videolink == "") {
      Swal.fire('Oops... !', 'Please Enter Video Link', 'warning');
      return;
    }

    let input = new FormData();

    input.append("activityid", this.activityid.toString());
    input.append("videolink", this.videolink.toString());

    if (this.imagetouploadData != null) {
      input.append("ThumbnailImage", this.imagetouploadData);
    }
    else {
      input.append("ThumbnailPath", this.thumbnailpath);
    }

    this.http.post('api/activitymaster/AddideoLink', input).subscribe(
      (data) => {
        this.resData = data;
        
        if (this.resData.status == true) {
          this.GetData();
          this.Reset();
          $('#addvideo').click();
        }
        else {
          this.resData = [];
        }
      }
    )
  }

  OnPublish(_IsChecked: boolean, _ActivityId: number) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var _text =_IsChecked == true ? 'Publish':'Unpublish'

    Swal.fire({
      text: 'Are you sure to ' + _text+' ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.http.get('api/activitymaster/PublishActivity?activityid=' + _ActivityId + '&userid=' + this.userid + '&ispublished=' + (_IsChecked==true?1:0), options).subscribe(
          (data) => {
            this.resData = data;
            this.loader.stop();
            if (this.resData.status == true) {
              Swal.fire("Success", this.resData.message, "success");
              this.GetData();
              this.Reset();
              return;
            }
            else {
              Swal.fire("Oops...", this.resData.message, "warning");
            }
          }
        )
      }
      else {
        this.GetData();
        this.Reset();
      }
    })
  }

  GetImageDetail(event) {
    
    const reader = new FileReader();

    this.imagefile = event
    let file = event.target.files[0];
    let fileList: FileList = event.target.files;

    this.imageToUpload = fileList[0];

    if (file.type.includes("png") || file.type.includes("jpg") || file.type.includes("jpeg")) {
      this.orgImageName = file.name;

      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.imageSrc = reader.result as string;
        };

      }
    }
    else {
      Swal.fire("", "Please Select Image", "error");
      this.myInputVariable.nativeElement.value = "";
      this.imageSrc = "";
    }
  }


  OnSubmit() {
    

    if (this.activityname == "" || this.activityname == undefined) {
      this.toaster.error('', 'Please Enter Activity Name', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.SelectedStartDate == null || this.SelectedStartDate == undefined) {
      this.toaster.error('', 'Please Select Start Date', { easeTime: 1000, timeOut: 3000 });
      return;
    }
    else {
      this.Start_Date = this.SelectedStartDate.toISOString().slice(0, 10);
    }
    if (this.SelectedEndDate == null || this.SelectedEndDate == undefined) {
      this.toaster.error('', 'Please Select End Date', { easeTime: 1000, timeOut: 3000 });
      return;
    }
    else {
      this.End_Date = this.SelectedEndDate.toISOString().slice(0, 10);
    }

    if (this.starttime == null || this.starttime == undefined) {
      this.toaster.error('', 'Please enter end time', { easeTime: 1000, timeOut: 3000 });
      return;
    }
    else {
      var st_time = this.starttime.hour.toString() + ':' + this.starttime.minute.toString() + ':' + this.starttime.second.toString();
    }

    if (this.endtime == null || this.endtime == undefined) {
      this.toaster.error('', 'Please Select End Date', { easeTime: 1000, timeOut: 3000 });
      Swal.fire("", "Please enter end time", "error");
      return;
    }
    else {
      var end_time = this.endtime.hour.toString() + ':' + this.endtime.minute.toString() + ':' + this.endtime.second.toString();
    }

    if (this.s_date == this.e_date) {
      if (this.s_date < this.todaydate) {
        this.toaster.error('', 'Start date should contains future date', { easeTime: 1000, timeOut: 3000 });
        return;
      }
      if (this.e_date < this.todaydate) {
        this.toaster.error('', 'End date should contain future date', { easeTime: 1000, timeOut: 3000 });
        return;
      }

      if ((this.starttime.hour > this.endtime.hour) || ((this.starttime.hour == this.endtime.hour && this.starttime.minute >= this.endtime.minute))) {
        this.toaster.error('', 'Start time always less then end time', { easeTime: 1000, timeOut: 3000 });
        return;
      }
    }
    else {
      if (this.s_date < this.todaydate) {
        this.toaster.error('', 'Start date should contains future date', { easeTime: 1000, timeOut: 3000 });
        return;
      }
      if (this.e_date < this.todaydate) {
        this.toaster.error('', 'End date should contain future date', { easeTime: 1000, timeOut: 3000 });
        return;
      }

      if (this.s_date > this.e_date) {
        this.toaster.error('', 'End date always greater then start date', { easeTime: 1000, timeOut: 3000 });
        return;
      }
    }

    if (this.activityrules == "" || this.activityrules == undefined) {
      this.toaster.error('', 'Please Enter Activity Rules and Regulations', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.activitydescription == "" || this.activitydescription == undefined) {
      this.toaster.error('', 'Please Enter Activity Description', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.stu_expectation == "" || this.stu_expectation == undefined) {
      this.toaster.error('', 'Please Enter Student Expections', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.activity_output == "" || this.activity_output == undefined) {
      this.toaster.error('', 'Please Enter Activity Outputs', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.registrationlink == "" || this.registrationlink == undefined) {
      this.toaster.error('', 'Please Enter Registration Link', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.userids == "" || this.userids == undefined) {
      this.toaster.error('', 'Please select user type', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.selectedwebtype == 0 || this.selectedwebtype == undefined) {
      this.toaster.error('', 'Please select Activity type', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.speakername == "" || this.speakername == undefined) {
      this.toaster.error('', 'Please Enter Speaker Name', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.speakerdesignation == "" || this.speakerdesignation == undefined) {
      this.toaster.error('', 'Please Enter Speaker Designation', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.btn_text != "Update") {
      if (this.imageToUpload == null || this.speakerdesignation == undefined) {
        this.toaster.error('', 'Please Select Photo', { easeTime: 1000, timeOut: 3000 });
        return;
      }
    }

    if (this.universityid == 0 || this.universityid == undefined) {
      this.toaster.error('', 'Please Select Partner University', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.academyids == "" || this.academyids == undefined) {
      this.toaster.error('', 'Please Select Acadmy', { easeTime: 1000, timeOut: 3000 });
      return;
    }
    if (this.careerids == "" || this.careerids == undefined) {
      this.toaster.error('', 'Please Select Career', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.stateids == "" || this.stateids == undefined) {
      this.toaster.error('', 'Please Select State', { easeTime: 1000, timeOut: 3000 });
      return;
    }
    if (this.cityids == "" || this.cityids == undefined) {
      this.toaster.error('', 'Please Select City', { easeTime: 1000, timeOut: 3000 });
      return;
    }
    if (this.schoolids == "" || this.schoolids == undefined) {
      this.toaster.error('', 'Please Select School', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.classids == "" || this.classids == undefined) {
      this.toaster.error('', 'Please Select Class', { easeTime: 1000, timeOut: 3000 });
      return;
    }

    if (this.classids.toString().includes('4') || this.classids.toString().includes('5') || this.classids.toString().includes('6')) {
      if (this.streamids == "" || this.streamids == undefined) {
        this.toaster.error('', 'Please Select Stream', { easeTime: 1000, timeOut: 3000 });
        return;
      }
    }


    //this.publish = this.IsPublishSelect == false ? 0 : 1;

    if (this.btn_text == 'Save') {
      let input = new FormData();

      input.append("activityname", this.activityname.toString());
      input.append("startdate", this.Start_Date.toString());
      input.append("enddate", this.End_Date.toString());
      input.append("starttime", st_time.toString());
      input.append("endtime", end_time.toString());
      input.append("activityrules", this.activityrules.toString());
      input.append("description", this.activitydescription.toString());
      input.append("registrationlink", this.registrationlink.toString());
      input.append("speakername", this.speakername.toString());
      input.append("speakerdesignation", this.speakerdesignation);
      input.append("ProfileData", this.imageToUpload);
      input.append("partnetuniversityid", this.universityid.toString());
      input.append("careerids", this.careerids.toString());
      input.append("stateids", this.stateids.toString());
      input.append("cityids", this.cityids.toString());
      input.append("schoolids", this.schoolids.toString());
      input.append("classids", this.classids.toString());
      input.append("streamids", this.streamids.toString());
      input.append("stu_expectation", this.stu_expectation.toString());
      input.append("activity_output", this.activity_output.toString());
      input.append("usertype", this.userids.toString());
      input.append("activitytype", this.selectedwebtype.toString());
      input.append("publish", this.publish.toString());
      input.append("userid", this.localstorage.get("userid").toString());
 

      this.loader.start();
      this.http.post('api/activitymaster/SaveActivityDetails', input).subscribe(
        (data) => {
          this.resData = data;
          
          this.loader.stop();
          if (this.resData.status == true) {
            this.GetData();
            this.Reset();
            this.toaster.success('Activity Saved', '', { easeTime: 1000, timeOut: 3000 });
            return;
          }
          else {
            this.toaster.warning(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          }
        }
      )
    }
    else {
      
      let input = new FormData();

      input.append("activityid", this.activityid.toString());
      input.append("activityname", this.activityname.toString());
      input.append("startdate", this.Start_Date.toString());
      input.append("enddate", this.End_Date.toString());
      input.append("starttime", st_time.toString());
      input.append("endtime", end_time.toString());
      input.append("activityrules", this.activityrules.toString());
      input.append("description", this.activitydescription.toString());
      input.append("registrationlink", this.registrationlink.toString());
      input.append("videolink", this.videolink.toString());
      input.append("speakername", this.speakername.toString());
      input.append("speakerdesignation", this.speakerdesignation);
      input.append("speaker_profile", this.speaker_profile);
      input.append("ProfileData", this.imageToUpload);
      input.append("partnetuniversityid", this.universityid.toString());
      input.append("careerids", this.careerids.toString());
      input.append("stateids", this.stateids.toString());
      input.append("cityids", this.cityids.toString());
      input.append("schoolids", this.schoolids.toString());
      input.append("classids", this.classids.toString());
      input.append("streamids", this.streamids.toString());
      input.append("stu_expectation", this.stu_expectation.toString());
      input.append("activity_output", this.activity_output.toString());
      input.append("usertype", this.userids.toString());
      input.append("activitytype", this.selectedwebtype.toString());
      input.append("publish", this.publish.toString());
      input.append("userid", this.localstorage.get("userid").toString());

      if (this.imagetouploadData != null) {
        input.append("ThumbnailImage", this.imagetouploadData);
      }
      else {
        input.append("ThumbnailName", this.thumbnailpath);
      }

      this.loader.start();
      this.http.post('api/activitymaster/UpdateActivityDetails', input).subscribe(
        (data) => {
          this.resData = data;
          
          this.loader.stop();
          if (this.resData.status == true) {
            this.GetData();
            this.Reset();
            this.toaster.success('Activity Updated', '', { easeTime: 1000, timeOut: 3000 });
            return;
          }
          else {
            this.toaster.warning(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          }
        }
      )
    }
  }

  toggleMeridian() {
    this.meridian = !this.meridian;
  }


}
