import { Component, OnInit, Input, PipeTransform, Pipe } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService,SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbTimepickerConfig, NgbTimeStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { PermissionService } from '../../../permission.service';

@Component({
  selector: 'app-pendingdiscussionreport',
  templateUrl: './pendingdiscussionreport.component.html',
  styleUrls: ['./pendingdiscussionreport.css'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, NgbTimepickerConfig]

})
export class PendingDiscussionReportManager implements OnInit {

  spinnerType = SPINNER.rectangleBounce;

  searchTerm: string = '';

  public page: number = 1;
  public pageSize: number = 10;

  public page1: number = 1;
  public pageSize1: number = 10;
  public resData: any = [];
  public SelectedDate9: Date;
  public SelectedEndDate10 : Date;
  public selectedfeature:number=1;
  public selectedstateg:number = 0;
  public selectedcityg:number =0;
  public selectedclassg:number = 0;
  public selectedstreamg: number = 0;
  public selectedType:number = 3;
  public selectedPaperType:number=0;

  public streamdat: any = [];
  public classdat: any = [];
  public citdata: any = [];
  public statedat: any = [];

  public ClassData: any = [];
  public StreamData: any = [];
  public CityData: any = [];
  public StateData: any = [];

  public s_date: any;
  public e_date: any;

  year: number = 0;
  month: number = 0;
  date: number = 0;

  public DownloadData: any = [];
  public DownloadDetailData: any = [];

  public DiscountReportData: any = [];
  public DiscountReportdetailData: any = [];

  public typedata:any=[];
  public typedataDetail:any=[];

  public papertype: string = "";

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  pipe = new DatePipe('en-US');

  constructor(private http: HttpClient, private permission: PermissionService, private router: Router, private localstorage: LocalStorageService, private toaster: ToastrService, private loader: NgxUiLoaderService, config: NgbTimepickerConfig, private config1: NgbDatepickerConfig) {
    config.seconds = false;
    config.spinners = false;
    config.meridian = true;

    this.date = new Date().getDate();
    this.year = new Date().getFullYear();
    this.month = parseInt(new Date().toISOString().slice(0, 10).split('-')[1]);

  }
  ngOnInit() {
    this.GetActions();
    this.getPendingDiscussionReport();
    this.BindType();
    this.BindState();
    this.BindClass();
    this.BindStream();
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

 
  /*name of the excel-file which will be downloaded. */
  fileName = 'PendingTestDiscussionreport.xlsx';

  exportexcel(tagid: string): void {
    
    /* table id is passed over here */
    let element = document.getElementById(tagid);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }


  getPendingDiscussionReport() {



    if (this.SelectedDate9 == undefined) {
      this.s_date = "1900-01-01";
    }
    else {
      this.s_date = this.SelectedDate9.toISOString().slice(0, 10);

    }
    if (this.SelectedEndDate10 == undefined) {
      this.e_date = "1900-01-01";
    }
    else {
      this.e_date = this.SelectedEndDate10.toISOString().slice(0, 10);
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.statedat = [];

    this.loader.start();

    this.http.get('api/reports/getPendingDiscussionReport?classid=' + this.selectedclassg +
      '&streamid=' + this.selectedstreamg + '&stateid=' + this.selectedstateg + '&cityid=' + this.selectedcityg, options).subscribe(

        (data) => {

        

        this.DownloadData = data;

        this.loader.stop();

        this.page = 1;

        if (this.DownloadData.Status == true)
        {
          this.DownloadDetailData = this.DownloadData.data;
          this.loader.stop();
        }
        else
        {
          this.DownloadDetailData = [];
          this.loader.stop();
        }
      }
    )

  }



  setPageno() {
    this.page=1;
  }


 BindType() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.typedata = [];

    
    this.http.get('api/reports/BindType', options).subscribe(

      (data) => {
        this.typedata = data;
        if (this.typedata.Status == true) {
          this.typedataDetail = this.typedata.data;
          this.selectedType = 3;
        }
        else {
          this.typedataDetail = this.typedata.data;
          this.selectedType = 0;
        }
      }
    )
  }

  BindClass() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.classdat = [];

    var tmpclass: any = [];
    this.http.post('api/addstudent/Bindclass', options).subscribe(

      (data) => {
        this.classdat = data;
        if (this.classdat.Status == true) {
          this.ClassData = this.classdat.data;
        }
        else {
          this.ClassData = this.classdat.data;
        }
      }
    )
  }

  BindStream() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.streamdat = [];

    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/BindStream', options).subscribe(

      (data) => {
        
        this.streamdat = data;
        if (this.streamdat.Status == true) {
          this.StreamData = this.streamdat.data;
        }
        else {
          this.StreamData = this.streamdat.data;
        }
      }
    )
  }

  BindCityg() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.citdata = [];
    var body = {

      "stateid": this.selectedstateg
    }
    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/BindCity', body, options).subscribe(

      (data) => {
        this.citdata = data;
        if (this.citdata.Status == true) {
          this.CityData = this.citdata.data;
        }
        else {
          this.CityData = this.citdata.data;
        }
      }
    )
  }

  BindState() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.statedat = [];

    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/Bindstate', options).subscribe(

      (data) => {
        this.statedat = data;
        if (this.statedat.Status == true) {
          this.StateData = this.statedat.data;
        }
        else {
          this.StateData = this.statedat.data;
        }
      }
    )
  }


  ResetPendingDiscussionReport()
  {
    this.SelectedDate9 = null;
    this.SelectedEndDate10 = null;
    this.selectedstateg = 0;
    this.selectedcityg = 0;
    this.selectedclassg = 0;
    this.selectedstreamg = 0;
    this.searchTerm = '';
    this.getPendingDiscussionReport();
  }
 
}
