import { Component, OnInit, Input, ElementRef, ViewChild, PipeTransform, Pipe, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, Event } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import {NgbTimepickerConfig, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
declare var $: any;
import { PermissionService } from '../../../permission.service';

@Component({
  selector: 'app-placement',
  templateUrl: './placement.component.html',

})
export class AddPlacementManager implements OnInit {

  public spinnerType = SPINNER.rectangleBounce;
  ButtonText: string = "Save";
  statedat: any = [];
  StateData: any = [];
  citdata: any = [];
  CityData: any = [];
  selectedstate: number = 0;
  selectedcity: number = 0;
  selectedschool: number = 0;
  schdata: any = [];
  SchoolData: any = [];
  StreamData: any = [];
  streamdat: any = [];
  AcademicYearData: any = [];
  selectedyear: number = 0;
  yeardat: any = [];
  selectedstream: number = 0;
  studentname: string = "";
  mobile: string = "";
  fathername: string = "";
  university: string = "";
  college: string = "";
  course: string = "";
  specialization: string = "";
  placedid: number = 0;
  PlacementData: any = [];
  Detail: any = [];
  GetSaveData: any = [];
  GetEditedData: any = [];
  DeletedData: any = [];
  search: string = "";
  public resData: any = [];
  public schoolData: any = [];
  public studentData: any = [];
  public placementData: any = [];
  public placestudentsData: any = [];
  public downloadData: any = [];

  public userid: number = 0;

  public page: number = 1;
  public pageSize: number = 10;

  @ViewChild('excelfile', { static: true }) private excelfileelement: ElementRef;
  excelfile: any;
  arrayBuffer: any = [];
  exceldata: any = [];
  GetData1: any = [];
  dw: string = "";
  SelectedImage: any = [];

  public placementtype: string = "Taken Admission";
  public updatetab: boolean = true;
  public viewtab: boolean = false;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor(
    private http: HttpClient,
    private permission: PermissionService,
    private router: Router,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private renderer: Renderer2, config: NgbTimepickerConfig,
    private config1: NgbDatepickerConfig) {

  }
  ngOnInit() {
    this.userid = this.localstorage.get('userid');
    this.GetActions();
    this.BindState();
  }

  fileName = 'placementdetailslist.xlsx';

  exportexcel(tagid: string): void {
    let element = document.getElementById(tagid);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];

          if (this.IsAddPermission == 0 && this.IsViewPermission == 1) {
            $('#View-tab').addClass('active');
            $('#Update-tab').removeClass('active');

            this.updatetab = false;
            this.viewtab = true;
            this.GetPlacedStudentDetails();
          }
        }
      }
    );
  }

  incomingfile(event) {
    this.excelfile = event.target.files[0];
  }

  Uploadexcel() {
    
    if (this.excelfile != undefined || this.excelfile != null) {

      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary" });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];

        this.exceldata = XLSX.utils.sheet_to_json(worksheet, { raw: true });

        this.exceldata.forEach(item => {
          delete item["name"]; delete item["class"]; delete item["stream"];
        });

        this.ValidateExcel(this.exceldata);
      }
      fileReader.readAsArrayBuffer(this.excelfile);
    }
    else {
      this.toaster.warning("Please choose an Excel file.");
      this.excelfileelement.nativeElement.value = "";
    }

  }

  ValidateExcel(Data: any) {
    console.log(Data)
    console.log(Object.keys(Data[0]))
    
    var Validate = true;
    // var cols = ["placementid", "studentid", "placed_country", "placed_state", "placed_city", "placed_institute", "placed_course"];

    var cols = Object.keys(Data[0]);

    for (var i = 0; i < cols.length; i++) {
      for (var j = 0; j < Data.length; j++) {

        if (Data[j][cols[i]] == undefined) {
          this.toaster.warning(cols[i] + " is not available at  row number " + (j + 2),'Oops...', { easeTime: 1000, timeOut: 3000 })

          var Validate = false;
          return;
        }

      }
    }

    if (Validate == true) {
      

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers }

      var body = {
        "userid": this.userid,
        "placementsData": JSON.stringify(Data),
        "placementtype": this.placementtype
      }

      this.loader.start();
      this.http.post('api/placement/savePlacedStudents', body, options).subscribe(
        (data) => {
          this.resData = data;
          this.loader.stop();
          if (this.resData.status == true) {
            
            this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });

            if (this.selectedschool != 0 && this.selectedcity != 0 && this.selectedstate != 0) {
              this.BindStudent();
            }

            this.excelfileelement.nativeElement.value = "";
            this.excelfile = [];
            return;
          }
          else {
            this.toaster.error(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
            this.excelfileelement.nativeElement.value = "";
            this.excelfile = [];
            return;
          }
        }
      )
    }
  }

  BindState() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.post('api/placement/Bindstate', options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.StateData = this.resData.data;
        }
        else {
          this.StateData = [];
        }
      }
    )
  }

  BindCity() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedstate
    }

    this.http.post('api/placement/BindCity', body, options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.CityData = this.resData.data;
        }
        else {
          this.CityData = [];
        }
      }
    )
  }

  BindSchool() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedstate,
      "cityid": this.selectedcity
    }

    this.http.post('api/placement/BindSchool', body, options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.schoolData = this.resData.data;
        }
        else {
          this.schoolData = [];
        }
      }
    )
  }

  BindStudent() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedstate,
      "cityid": this.selectedcity,
      "schoolid": this.selectedschool,
      "placementtype": this.placementtype
    }

    this.loader.start();
    this.http.post('api/placement/BindStudent', body, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.Status == true) {
          this.studentData = this.resData.data;
        }
        else {
          this.studentData = [];
        }
      }
    )
  }


  GetPlacedStudentDetails() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedstate,
      "cityid": this.selectedcity,
      "schoolid": this.selectedschool,
      "placementtype": this.placementtype,
      "userid": this.localstorage.get('userid')
    }

    this.loader.start();
    this.http.post('api/placement/GetPlacedStudentDetails', body,options).subscribe(
      (data) => {
        
        this.loader.stop();
        this.resData = data;
        if (this.resData.Status == true) {
          this.placementData = this.resData.data;
        }
        else {
          this.placementData = [];
        }
      }
    )
  }

  savePlacedStudents() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.studentData.length > 0) {

      this.placestudentsData = [];

      this.studentData.forEach(item => {
        if (item.isplaced == true) {

          if (item.placed_country == "" && this.placementtype =="Taken Admission") {
            this.toaster.error('Please Enter Country for student ' + item.stuname, '', { easeTime: 1000, timeOut: 3000 })
            return;
          }

          if (item.placed_state == "" && this.placementtype == "Taken Admission") {
            this.toaster.error('Please Enter State for student ' + item.stuname, '', { easeTime: 1000, timeOut: 3000 })
            return;
          }

          if (item.placed_city == "" && this.placementtype == "Taken Admission") {
            this.toaster.error('Please Enter City Name for student ' + item.stuname, '', { easeTime: 1000, timeOut: 3000 })
            return;
          }

          if (item.placed_institute == "" && this.placementtype == "Taken Admission") {
            this.toaster.error('Please Enter Institute Name for student ' + item.stuname, '', { easeTime: 1000, timeOut: 3000 })
            return;
          }

          if (item.placed_course == "") {
            this.toaster.error('Please Enter Course Name for student ' + item.stuname, '', { easeTime: 1000, timeOut: 3000 })
            return;
          }

          this.placestudentsData.push(item);
        }
      })    
    }

    if (this.placestudentsData.length > 0) {
      var body = {
        "userid": this.userid,
        "placementsData": JSON.stringify(this.placestudentsData),
        "placementtype": this.placementtype
      }

      this.loader.start();
      this.http.post('api/placement/savePlacedStudents', body, options).subscribe(
        (data) => {
          this.resData = data;
          this.loader.stop();
          if (this.resData.status == true) {
            
            this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });

            this.BindStudent();
            return;
          }
          else {
            this.toaster.error(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
            return;
          }
        }
      )
    }
    else {

        this.toaster.error('Please select atleast one student ', '', { easeTime: 1000, timeOut: 3000 })
        return;
    }
  }

  ExportToExcel() {

    this.loader.start();
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    var body = {
      "stateid": this.selectedstate,
      "cityid": this.selectedcity,
      "schoolid": this.selectedschool,
      "placementtype": this.placementtype
    }

    this.http.post('api/placement/ExportPlacementData?searchstring=' + this.search, body,{ headers: headers, responseType: 'blob' }).subscribe(
      (data) => {
        
        this.loader.stop();
        this.downloadData = data;

        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(this.downloadData);
        downloadLink.setAttribute('download', 'file_' + new Date().getTime());
        document.body.appendChild(downloadLink);
        downloadLink.click();

      }
    )
  }

  LoadStudent() {
    if (this.updatetab == true) {
      this.BindStudent();
    }
    else if (this.viewtab == true) {
      this.GetPlacedStudentDetails();
    }
  }

  ChangeTabs(_IsActive: string) {
    if (_IsActive === "Update") {
      $('#View-tab').removeClass('active');
      $('#Update-tab').addClass('active');

      this.updatetab = true;
      this.viewtab = false;
    }
    else {
      $('#View-tab').addClass('active');
      $('#Update-tab').removeClass('active');

      this.updatetab = false;
      this.viewtab = true;
      this.GetPlacedStudentDetails();
    }
    this.ResetDropdowns();
  }

  ResetDropdowns() {
    //this.excelfileelement.nativeElement.value = "";
    this.page = 1;
    this.selectedstate = 0;
    this.selectedschool = 0;
    this.selectedcity = 0;
    this.search = "";
    this.studentData = [];
    this.placementData = [];
    this.excelfile = [];
    this.placementtype = "Taken Admission";
  }
}
