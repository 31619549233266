import { Injectable, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import jwt_decode from "jwt-decode";



@Injectable()
export class LogoutService implements OnInit {

  resData: any;
  public token: any;

  constructor(
    private router: Router,
    private localstorage: LocalStorageService,
    private toaster: ToastrService) {}

  ngOnInit() {

    this.token = jwt_decode(this.localstorage.get("token"));

  }

  ForceLogout() {

    this.token = jwt_decode(this.localstorage.get("token"));
    //this.localstorage.clearAll();
    //this.router.navigate(['/login']);
  }

}
