import { Component, OnInit} from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import * as XLSX from 'xlsx';
import { PermissionService } from '../../../permission.service';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-placementdetails',
  templateUrl: './placementdetails.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class PlacementDetailsMaster implements OnInit {

  public spinnerType = SPINNER.rectangleBounce;
  resData: any = [];
  editData: any = [];
  placementData: any = [];
  excelplacementData: any = [];
  studentsData: any = [];
  excelData: any = [];

  selectedstate: number = 0;
  selectedcity: number = 0;
  selectedschool: number = 0;

  public StartDate: Date;
  public EndDate: Date;

  public flag: boolean = false;

  public page: number = 1;
  public pageSize: number = 10;

  StateData: any = [];
  CityData: any = [];
  statedat: any = [];
  citdata: any = [];
  schoolData: any = [];
  search: string = "";

  id: number = 0;
  filterid: number = 0;
  statename: string = "";
  cityname: string = "";
  schoolname: string = "";
  placementtype: string = "";
  btntext: string = "Save";

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private config: NgbDatepickerConfig,
    private permission: PermissionService  ) {

    const current = new Date();

    config.minDate = {
      year: current.getFullYear(), month:
        current.getMonth() + 1, day: current.getDate()
    };

    config.maxDate = {
      year: current.getFullYear() + 25,
      month: current.getMonth() + 1,
      day: current.getDate()
    };

    config.outsideDays = 'hidden';

  }
  ngOnInit() {
    this.GetActions();
    this.BindData();
    this.BindState();
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  //bind state
  BindState() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.post('api/placement/Bindstate', options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.StateData = this.resData.data;
        }
        else {
          this.StateData = [];
        }
      }
    )
  }

  //bindc  city code
  BindCity() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedstate
    }

    this.http.post('api/placement/BindCity', body, options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.CityData = this.resData.data;
        }
        else {
          this.CityData = [];
        }
      }
    )
  }

  BindSchool() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedstate,
      "cityid": this.selectedcity
    }

    this.http.post('api/placement/BindSchool', body, options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.schoolData = this.resData.data;
        }
        else {
          this.schoolData = [];
        }
      }
    )
  }

  BindTableData() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.post('api/reports/GetPlacementDetailsData', options).subscribe(
      (data) => {
        
        this.resData = data;
        this.placementData = this.resData.data;
      }
    )
  }


  BindData() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/placement/getplacementfiltes', options).subscribe(
      (data) => {
        
        this.resData = data;

        if (this.resData.Status == true) {
          this.placementData = this.resData.data;
          this.excelplacementData = this.resData.excelData;
        } else {
          this.placementData = [];
          this.excelplacementData = [];
        }

      }
    )
  }


  /*name of the excel-file which will be downloaded. */
  fileName = 'placementdetailslist.xlsx';

  exportexcel(tagid: string): void {

    /* table id is passed over here */
    let element = document.getElementById(tagid);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }

  Reset() {

    this.flag = false;
    this.selectedcity = 0;
    this.selectedstate = 0;
    this.selectedschool = 0;
    this.StartDate = null;
    this.EndDate = null;
    this.search = "";
    this.filterid = 0;
    this.id = 0;
    this.btntext = "Save";
    this.CityData = [];
    this.schoolData = [];
  }

  ChangeTabs(_IsActive: string) {
    $(document).ready(function () {
      if (_IsActive === "process") {
        $('#admission-tab').removeClass('active');
        $('#drop-tab').removeClass('active');

        $('#process-tab').addClass('active');
      }
      else if (_IsActive === "admission") {
        $('#admission-tab').addClass('active');

        $('#process-tab').removeClass('active');
        $('#drop-tab').removeClass('active');
      }
      else if (_IsActive === "drop") {
        $('#drop-tab').addClass('active');

        $('#admission-tab').removeClass('active');
        $('#process-tab').removeClass('active');
      }
      else {
        $('#process-tab').addClass('active');

        $('#drop-tab').removeClass('active');
        $('#admission-tab').removeClass('active');
      }
    });
  }

  onSubmit() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.btntext == "Save") {
      if (this.selectedstate == 0 || this.selectedstate == undefined) {
        this.toaster.error('Please Select State', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }

      if (this.selectedcity == 0 || this.selectedcity == undefined) {
        this.toaster.error('Please Select City', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }

      if (this.selectedschool == 0 || this.selectedschool == undefined) {
        this.toaster.error('Please Select School', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }

    if (this.StartDate == null || this.StartDate == undefined) {
      this.toaster.error('Please Select Start Date', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.EndDate == null || this.EndDate == undefined) {
      this.toaster.error('Please Select End Date', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    var body = {
      "id": this.id,
      "userid": this.localstorage.get('userid'),
      "stateid": this.selectedstate,
      "cityid": this.selectedcity,
      "schoolid": this.selectedschool,
      "startdate": this.StartDate.toISOString().slice(0, 10),
      "enddate": this.EndDate.toISOString().slice(0, 10),
      "actiontype": this.btntext,
    }

    this.loader.start();
    this.http.post('api/placement/saveplacementfiltes', body, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.Status == true) {
          

          this.toaster.success(this.resData.Message, '', { easeTime: 1000, timeOut: 3000 });
          this.BindData();
          this.Reset();
          return;
        }
        else {
          this.toaster.error(this.resData.Message, '', { easeTime: 1000, timeOut: 3000 });
          return;
        }
      }
    )

  }


  getPlacementStudentDetails(_TagId: string, _Id: number, _State: string, _City: string, _School: string) {

    this.ChangeTabs(_TagId);

    this.placementtype = _TagId;
    this.filterid = _Id;
    this.statename = _State;
    this.cityname = _City;
    this.schoolname = _School;

    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.loader.start();
    this.http.get('api/placement/getPlacementStudentDetails?id=' + _Id + '&type=' + _TagId, options).subscribe(
      (data) => {
        
        this.resData = data;
        this.loader.stop();
        this.excelData = this.resData.PlacementData;

        if (this.resData.Status == true) {
          this.studentsData = this.resData.data;
        }
        else {
          this.studentsData =[];
        }
      }
    )
  }


  EditPlacement(_Id: number) {

    this.btntext = "Update";

    this.flag = true;
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/placement/editPlacementDetails?id=' + _Id, options).subscribe(
      (data) => {
        
        this.resData = data;
        this.editData = this.resData.data;

        this.id = this.editData.id;
        this.selectedstate = this.editData.stateid;
        this.BindCity();
        this.selectedcity = this.editData.cityid;
        this.BindSchool();
        this.selectedschool = this.editData.schoolid;

        this.StartDate = new Date(this.editData.startdate);
        this.EndDate = new Date(this.editData.enddate);
      }
    )
  }


  DeletePlacement(_Id: number) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var data =
    {
      "id": _Id,
      "userid": this.localstorage.get("userid")
    };

    let body = JSON.stringify(data);

    Swal.fire({
      text: 'Are you sure to delete this record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.http.post('api/placement/deletePlacementDetails', body, options).subscribe(
          (data) => {
            this.resData = data;
            if (this.resData.Status == true) {
              Swal.fire("", "Deleted Successfully", "success");
              this.BindData();
              this.Reset();
              return;
            }
          }
        )
      }
    })
  }
}
