import { Component, OnInit, Input, ElementRef, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { PermissionService } from '../../../permission.service';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-registrationpaymentreport',
  templateUrl: './registrationpaymentreport.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]

})
export class RegistrationPaymentReport implements OnInit {

  public spinnerType = SPINNER.rectangleBounce;
  StateData: any = [];
  citdata: any = [];
  CityData: any = [];

  selectedstate: number = 0;
  selectedcity: number = 0;
  selectedschool: number = 0;
  selectedclass: number = 0;
  selectedstream: number = 0;

  SchoolData: any = [];

  ClassData: any = [];
  StreamData: any = [];

  excelfile: any = [];
  exceldata: any;
  arrayBuffer: any = [];
  resData: any = [];
  downloadData: any = [];

  regids: string = "";
  studentname: string = "";
  email: string = "";
  mobileno: string = "";

  studentid: number = 0;
  StudentData: any = [];
  Detail: any = [];
  GetSaveData: any = [];
  GetEditedData: any = [];
  DeletedData: any = [];
  isshow: number = 1;

  public userid: number = 0;
  public usertype: number = 0;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  public IsCheckedAll: boolean
  public selectedStudentCount: number = 0;

  public ButtonText: string = "View";

  public year: number = 0;
  public month: number = 0;
  public date: number = 0;

  public s_date: any;
  public e_date: any;

  public SelectedStartDate: Date;
  public SelectedEndDate: Date;

  public total_student: string = "0";
  public total_amount: string = "0";

  public page: number = 1;

  public selectedstatus: string = "success";

  @ViewChild('inputfile', { static: true }) private excelfileelement: ElementRef;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private permission: PermissionService) {

    this.date = new Date().getDate();
    this.year = new Date().getFullYear();
    this.month = parseInt(new Date().toISOString().slice(0, 10).split('-')[1]);
  }

  ngOnInit() {

    this.loader.start();

    this.userid = this.localstorage.get("userid");
    this.usertype = this.localstorage.get("usertype");

    this.GetActions();
    this.BindState();
    this.BindClass();
    this.BindStream();
    this.GetData();

    this.loader.stop();
  }

  fileName = 'StudentList.xlsx';

  exportexcel(tagid: string): void {
    
    let element = document.getElementById(tagid);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);

  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  ExportToExcel() {

    this.loader.start();
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    var body = {
      "stateid": this.selectedstate,
      "cityid": this.selectedcity,
      "schoolid": this.selectedschool
    }

    this.http.post('api/addstudent/ExportSampleStudentData', body, { headers: headers, responseType: 'blob' }).subscribe(
      (data) => {
        
        this.loader.stop();
        this.downloadData = data;

        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(this.downloadData);
        downloadLink.setAttribute('download', 'file_' + new Date().getTime());
        document.body.appendChild(downloadLink);
        downloadLink.click();

      }
    )
  }

  BindState() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/Bindstate', options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.StateData = this.resData.data;
        }
        else {
          this.StateData =[];
        }
      }
    )
  }

  BindCity() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedstate
    }
    this.http.post('api/addstudentpartner/BindCity', body, options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.CityData = this.resData.data;
        }
        else {
          this.CityData = [];
        }
      }
    )
  }

  BindSchool() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedstate,
      "cityid": this.selectedcity
    }
    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/BindSchool', body, options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.SchoolData = this.resData.data;
        }
        else {
          this.SchoolData = [];
        }
      }
    )
  }

  BindClass() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.post('api/addstudent/Bindclass', options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.ClassData = this.resData.data;
        }
        else {
          this.ClassData = [];
        }
      }
    )
  }

  BindStream() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.post('api/addstudentpartner/BindStream', options).subscribe(

      (data) => {
        
        this.resData = data;
        if (this.resData.Status == true) {
          this.StreamData = this.resData.data;
        }
        else {
          this.StreamData = [];
        }
      }
    )
  }

  GetData() {

    if (this.SelectedStartDate == undefined || this.SelectedStartDate == null) {
      this.s_date = "";
    }
    else {
      this.s_date = this.SelectedStartDate.toISOString().slice(0, 10);
    }
    if (this.SelectedEndDate == undefined || this.SelectedEndDate == null) {
      this.e_date = "";
    }
    else {
      this.e_date = this.SelectedEndDate.toISOString().slice(0, 10);
    }
    this.loader.start();

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var data = {
      "startdate": this.s_date,
      "enddate": this.e_date,
      "stateid": this.selectedstate,
      "cityid": this.selectedcity,
      "schoolid": this.selectedschool,
      "classid": this.selectedclass,
      "streamid": this.selectedstream,
      "status": this.selectedstatus,
      "userid": this.localstorage.get('userid')
    }

    let body = JSON.stringify(data);

    this.http.post('api/reports/GetRegistrationPaymentData', body, options).subscribe(
      (data) => {
        
        this.loader.stop();
        this.resData = data;

        if (this.resData.Status == true) {
          this.GetSaveData = this.resData.data;
          this.total_amount = this.resData.total_amount;
          this.total_student = this.resData.total_student;
        }
        else {
          this.GetSaveData = [];
          this.total_amount = "0"
          this.total_student ="0"
        }
      }
    )
  }

  onClear() {
    this.selectedclass = 0;
    this.selectedstream = 0;
    this.selectedcity = 0;
    this.selectedschool = 0;
    this.selectedstate = 0;
    this.selectedstatus = "success";
    this.selectedStudentCount = 0;

    this.CityData = [];
    this.SchoolData = [];
    this.SelectedStartDate = null
    this.SelectedEndDate = null

    this.GetData();
  }


  SelectAll(IsCheckedAll) {
    
    this.regids = "";
    this.selectedStudentCount = 0;

    if (IsCheckedAll == true) {
      if (this.GetSaveData.length > 0) {

        this.GetSaveData.forEach(item => {

          if (item.IsRegistered == false) {
            item.IsChecked = true;

            if (this.regids == "") {
              this.regids = item.reg_id;
            }
            else {
              this.regids = this.regids + ',' + item.reg_id;
            }

            this.selectedStudentCount++;
          }
           
        });
      }
    }
    else {
      this.GetSaveData.forEach(item => {
        item.IsChecked = false;
      });
    }
  }


  SelectStudent() {
    
    this.regids = "";
    this.selectedStudentCount = 0;

    if (this.GetSaveData.length > 0) {
      this.GetSaveData.forEach(item => {

        if (item.IsChecked == true) {
          if (this.regids == "") {
            this.regids = item.reg_id;
          }
          else {
            this.regids = this.regids + ',' + item.reg_id;
          }

          this.selectedStudentCount++;        
        }

      });
    }
  }


  BulkRegistration() {
    
    if (this.selectedStudentCount > 0 && this.regids != "") {

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      var data =
      {
        "userid": this.localstorage.get('userid'),
        "regids": this.regids
      };

      let body = JSON.stringify(data);

      Swal.fire({
        text: 'Are you sure ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          this.http.post('api/reports/BulkRegistration', body, options).subscribe(
            (data) => {
              
              this.resData = data;
              if (this.resData.status == true) {
                this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
                this.onClear();
                return;
              }
              else {
                this.toaster.error(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
                return;
              }
            }
          )
        }
      })

    }
    else {
      Swal.fire("", "Please select Student", "error");
      return;
    }


  }

}
