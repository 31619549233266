import { Component } from '@angular/core';

@Component({
    selector: 'app-internetconnectivity',
    templateUrl: './internetconnectivity.component.html',
    styleUrls: ['./internetconnectivity.component.css']
})
/** internetconnectivity component*/
export class InternetConectivityCheckComponent {
    /** internetconnectivity ctor */
    constructor() {

    }
}
