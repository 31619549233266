import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as XLSX from 'xlsx';
import { PermissionService } from '../../../permission.service';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-rollovermaster',
  templateUrl: './rollovermaster.component.html',
  styleUrls: ['./rollovermaster.component.css'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]

})
export class RollOverMaster implements OnInit {

  spinnerType = SPINNER.rectangleBounce;
  public resData: any = [];
  public actionsData: any = [];
  public sessionData: any = [];
  public rolloverData: any = [];

  public StateData: any = [];
  public CityData: any = [];
  public SchoolData: any = [];
  public ClassData: any = [];
  public StreamData: any = [];
  public eClassData: any = [];
  public pClassData: any = [];
  public pStreamData: any = [];
  public StudentData: any = [];
  public SessionData: any = [];

  public StartDate: Date;
  public EndDate: Date;
  public s_date: any;
  public e_date: any;

  public selectedstate: number = 0;
  public selectedcity: number = 0;
  public selectedschool: number = 0;
  public selectedclass: number = 0;
  public selectedstream: number = 0;
  public selectedsession: number = 0;

  public selectedpclass: number = 0;
  public selectedpstream: number = 0;

  public IsAllSelected: boolean = false;
  public ShowFilters: boolean = false;

  public userid: number;

  public btn_text: string = "Promote";

  public psessionname: string = "";
  public sessionname: string = "";
  public sessionid: number = 0;

  public sessiontab: boolean = true;
  public rollovertab: boolean = false;

  public searchTerm: string;
  public pageSize: number = 10;
  public page: number = 1;

  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;


  constructor(
    private http: HttpClient,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private permission: PermissionService,
    private config: NgbDatepickerConfig
  ) {

    const current = new Date();
    config.minDate = {
      year: current.getFullYear(), month:
        current.getMonth() + 1, day: current.getDate()
    };
    config.maxDate = {
      year: current.getFullYear() + 25,
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    config.outsideDays = 'hidden';

  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  ngOnInit() {
    this.loader.start();
    this.userid = this.localstorage.get("userid");

    this.GetActions();
    this.BindState();
    this.BindClass();
    this.BindStream();

    this.loader.stop();
  }


  ChangeTabs(_IsActive: string) {
    if (_IsActive === "Session") {
      $('#rollover-tab').removeClass('active');
      $('#session-tab').addClass('active');
    }
    else {
      $('#rollover-tab').addClass('active');
      $('#session-tab').removeClass('active');
    }
  }

  setPageno() {
    this.page = 1;
  }

  Reset() {
    this.loader.start();

    this.sessionname = "";
    this.psessionname = "";

    this.StudentData = [];
    this.SchoolData = [];
    this.CityData = [];
    this.SessionData = [];
    this.pClassData = [];

    this.selectedstate = 0;
    this.selectedsession = 0;
    this.selectedclass = 0;
    this.selectedstream = 0;
    this.selectedpclass = 0;
    this.selectedpstream = 0;
    this.selectedcity = 0;

    this.IsAllSelected = false;

    this.loader.stop();
  }

  BindState() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.post('api/addstudentpartner/Bindstate', options).subscribe(

      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.StateData = this.resData.data;
        }
        else {
          this.StateData = this.resData.data;
        }
      }
    )
  }

  BindCity() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedstate
    }

    this.http.post('api/addstudentpartner/BindCity', body, options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.CityData = this.resData.data;
        }
        else {
          this.CityData = this.resData.data;
        }
      }
    )
  }

  BindSchool() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "stateid": this.selectedstate,
      "cityid": this.selectedcity
    }

    this.http.post('api/addstudentpartner/BindSchoolHavingStudent', body, options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.SchoolData = this.resData.data;
        }
        else {
          this.SchoolData = this.resData.data;
        }
      }
    )
  }

  BindClass() {
    
    this.eClassData = [];

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.post('api/reports/Bindclass', options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.Status == true) {
          this.ClassData = this.resData.data;

          this.ClassData.forEach((item, index) => {
            if (item.classid != 6)
            this.eClassData.push(item)
          });
        }
        else {
          this.ClassData = [];
        }
      }
    )
  }

  BindStream() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.post('api/reports/BindStream', options).subscribe(
      (data) => {
        
        this.resData = data;
        if (this.resData.Status == true) {
          this.StreamData = this.resData.data;
        }
        else {
          this.StreamData = [];
        }
      }
    )
  }

  BindStudent() {
    
    this.selectedpclass = 0;
    this.IsAllSelected = false;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.selectedstate != 0 && this.selectedcity != 0 && this.selectedschool != 0) {

      this.http.get('api/rollover/GetStudent?classid=' + this.selectedclass + '&schoolid=' + this.selectedschool + '&stateid=' + this.selectedstate + '&cityid=' + this.selectedcity + '&streamid=' + this.selectedstream + '&userid=' + this.localstorage.get('userid'), options).subscribe(
        (data) => {

          this.resData = data;

          if (this.resData.status == true) {
            this.sessionid = this.resData.sessionid
            this.sessionname = this.resData.sessionname
            this.StudentData = this.resData.data;

            this.BindSession();

            this.pClassData = [];

            if (this.selectedclass != 0) {
              this.ClassData.forEach(item => {
                if (item.classid > this.selectedclass) {
                  this.pClassData.push(item);
                }
              })
            }
          }
          else {
            this.sessionid = 0;
            this.sessionname = "";
            this.psessionname = "";
            this.StudentData = [];
            this.pClassData = [];
            this.SessionData = [];
          }
        }
      )
    }
  }

  BindSession() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/rollover/GetSession?sessionid=' + this.sessionid, options).subscribe(
      (data) => {
        this.resData = data;

        if (this.resData.status == true) {
          this.SessionData = this.resData.data;
          this.psessionname = this.SessionData[0].sessionname
          this.selectedsession = this.SessionData[0].sessionid
        }
        else {
          this.SessionData = [];
        }
      }
    )
  }

  SelectStudent() {
    var count = 0;
    if (this.StudentData.length > 0) {
      this.StudentData.forEach(item => {
        if (item.IsSelected == true) {
          count++;
        }
      })

      if (count == this.StudentData.length) {
        this.IsAllSelected = true;
      }
      else {
        this.IsAllSelected = false;
      }
    }

  }

  SelectAllStudent(e) {
    
    if (e == true) {
      if (this.StudentData.length > 0) {
        this.StudentData.forEach(item => {
          item.IsSelected = true;
        })
      }
    }
    else {
      if (this.StudentData.length > 0) {
        this.StudentData.forEach(item => {
          item.IsSelected = false;
        })
      }
    }
    
  }

  Submit() {
    
    var count = 0;
    this.rolloverData = [];

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.selectedstate == 0 || this.selectedstate == undefined) {
      this.toaster.error('Please Select state', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.selectedcity == 0 || this.selectedcity == undefined) {
      this.toaster.error('Please Select City', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.selectedschool == 0 || this.selectedschool == undefined) {
      this.toaster.error('Please Select School', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.selectedclass == 0 || this.selectedclass == undefined) {
      this.toaster.error('Please Select Existing class', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.selectedpclass == 0 || this.selectedpclass == undefined) {
      this.toaster.error('Please Select Promoting class', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.selectedpclass > 3) {
      if (this.selectedpstream == 0 || this.selectedpstream == undefined) {
        this.toaster.error('Please select Promoting stream', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
    }

    if (this.StudentData.length > 0) {
      this.StudentData.forEach(item => {
        if (item.IsSelected == true) {
          this.rolloverData.push({"studentid": item.studentid})
          count++;
        }
      })
    }

    if (count == 0) {
      this.toaster.error('Please select student', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    var body = {
      "sessionid": this.selectedsession,
      "classid": this.selectedpclass,
      "streamid": this.selectedpstream,
      "rolloverjson": JSON.stringify(this.rolloverData),
      "userid": this.localstorage.get('userid')
    }

    this.loader.start();
    this.http.post('api/rollover/StudentRollover', body, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          
          this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          this.Reset();
          return;
        }
        else {
          this.toaster.error(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          return;
        }
      }
    )

  }


}
