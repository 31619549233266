import { Component, OnInit, Input, ElementRef, ViewChild, PipeTransform, Pipe, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, Event } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbTimepickerConfig, NgbTimeStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { PermissionService } from '../../../permission.service';
import { forEach } from 'lodash';


@Component({
  selector: 'app-addstudent',
  templateUrl: './addstudent.component.html',

})
export class addstudent implements OnInit {

  public spinnerType = SPINNER.rectangleBounce;
  ButtonText: string = "Save";
  statedat: any = [];
  StateData: any = [];
  citdata: any = [];
  CityData: any = [];

  selectedstate: number = 0;
  selectedcity: number = 0;
  selectedschool: number = 0;
  selectedclass: number = 0;
  selectedstream: number = 0;
  selectedlanguage: number = 1;
  selectedusertype: number = 0;

  schdata: any = [];
  SchoolData: any = [];

  ClassData: any = [];
  classdat: any = [];
  StreamData: any = [];
  streamdat: any = [];

  excelfile: any=[];
  exceldata: any;
  arrayBuffer: any = [];
  resData: any = [];
  downloadData: any = [];

  studentids: string = "";
  studentname: string = "";
  email: string = "";
  mobileno: string = "";

  studentsdata: any = [];
  
  studentid: number = 0;
  StudentData: any = [];
  Detail: any = [];
  GetSaveData: any = [];
  GetEditedData: any = [];
  DeletedData: any = [];
  isshow: number = 1;

  public userid: number = 0;
  public usertype: number = 0;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  public btnBulkPublish: boolean = false;
  public btnBulkDelete: boolean = false;
  public IsCheckedAll: boolean
  public selectedStudentCount: number = 0;

  public isEmailChecked: boolean = true;
  public isSMSChecked: boolean = true;

  public searchTerm: string;
  public pageSize: number = 10;
  public page: number = 1;

  @ViewChild('inputfile', { static: true }) private excelfileelement: ElementRef;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private permission: PermissionService) {
    this.localstorage.set("pageid",73);
  }

  ngOnInit() {

    this.userid = this.localstorage.get("userid");
    this.usertype = this.localstorage.get("usertype");

    this.GetActions();
    this.BindState();
    this.BindClass();
    this.BindStream();
    //this.GetData();
  }


  setpageno() {
    this.page = 1;
  }

  fileName = 'StudentList.xlsx';

  exportexcel(tagid: string): void {
    
    let element = document.getElementById(tagid);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);

  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  incomingfile(event) {
    this.excelfile = event.target.files[0];
  }

  Uploadexcel() {
    
    if (this.excelfile != undefined || this.excelfile != null) {

      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary" });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];

        this.exceldata = XLSX.utils.sheet_to_json(worksheet, { raw: true });

        this.ValidateExcel(this.exceldata);
      }
      fileReader.readAsArrayBuffer(this.excelfile);
    }
    else {
      this.toaster.warning("Please choose an Excel file.");
      this.excelfileelement.nativeElement.value = "";
    }

  }

  ValidateExcel(Data: any) {
    var Validate = true;
    var cols = Object.keys(Data[0]);

    for (var i = 0; i < cols.length; i++) {
      for (var j = 0; j < Data.length; j++) {

        if (Data[j][cols[i]] == undefined) {
          this.toaster.warning(cols[i] + " is not available at  row number " + (j + 2), 'Oops...', { easeTime: 1000, timeOut: 3000 })
          var Validate = false;
          return;
        }

      }
    }

    if (Validate == true) {
      

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers }

      var body = {
        "userid": this.userid,
        "studentsData": JSON.stringify(Data)
      }

      this.loader.start();
      this.http.post('api/addstudent/SaveBulkStudentData', body, options).subscribe(
        (data) => {
          this.resData = data;
          this.loader.stop();
          if (this.resData.status == true) {
            
            this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
            this.excelfileelement.nativeElement.value = "";
            this.excelfile = [];
            return;
          }
          else {
            this.toaster.error(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
            this.excelfileelement.nativeElement.value = "";
            this.excelfile = [];
            return;
          }
        }
      )
    }
  }

  ExportToExcel() {

    this.loader.start();
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    var body = {
      "stateid": this.selectedstate,
      "cityid": this.selectedcity,
      "schoolid": this.selectedschool
    }

    this.http.post('api/addstudent/ExportSampleStudentData', body, { headers: headers, responseType: 'blob' }).subscribe(
      (data) => {
        
        this.loader.stop();
        this.downloadData = data;

        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(this.downloadData);
        downloadLink.setAttribute('download', 'file_' + new Date().getTime());
        document.body.appendChild(downloadLink);
        downloadLink.click();

      }
    )
  }

  BindState() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.statedat = [];

    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/Bindstate', options).subscribe(

      (data) => {
        this.statedat = data;
        if (this.statedat.Status == true) {
          this.StateData = this.statedat.data;
        }
        else {
          this.StateData = this.statedat.data;
        }
      }
    )
  }

  BindCity() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.citdata = [];
    var body = {

      "stateid": this.selectedstate
    }
    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/BindCity', body, options).subscribe(

      (data) => {
        this.citdata = data;
        if (this.citdata.Status == true) {
          this.CityData = this.citdata.data;
        }
        else {
          this.CityData = this.citdata.data;
        }
      }
    )
  }

  BindSchool() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.schdata = [];
    var body = {

      "stateid": this.selectedstate,
      "cityid": this.selectedcity
    }
    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/BindSchool', body, options).subscribe(

      (data) => {
        this.schdata = data;
        if (this.schdata.Status == true) {
          this.SchoolData = this.schdata.data;
        }
        else {
          this.SchoolData = this.schdata.data;
        }
      }
    )
  }

  BindClass() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.classdat = [];

    var tmpclass: any = [];
    this.http.post('api/addstudent/Bindclass', options).subscribe(

      (data) => {
        this.classdat = data;
        if (this.classdat.Status == true) {
          this.ClassData = this.classdat.data;
        }
        else {
          this.ClassData = this.classdat.data;
        }
      }
    )
  }

  BindStream() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.streamdat = [];

    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/BindStream', options).subscribe(

      (data) => {
        
        this.streamdat = data;
        if (this.streamdat.Status == true) {
          this.StreamData = this.streamdat.data;
        }
        else {
          this.StreamData = this.streamdat.data;
        }
      }
    )
  }

  onSubmit() {
    
    if (this.studentname == "" || this.studentname == undefined) {
      Swal.fire("", "Please enter student name ", "error");
      return;
    }

    if (this.selectedstate == 0 || this.selectedstate == undefined) {
      Swal.fire("", "Please select state", "error");
      return;
    }
    if (this.selectedcity == 0 || this.selectedcity == undefined) {
      Swal.fire("", "Please select city", "error");
      return;
    }
    if (this.selectedschool == 0 || this.selectedschool == undefined) {
      Swal.fire("", "Please select school", "error");
      return;
    }
    if (this.selectedclass == 0 || this.selectedclass == undefined) {
      Swal.fire("", "Please select class", "error");
      return;
    }

    if (this.selectedclass > 3) {
      if (this.selectedstream == 0 || this.selectedstream == undefined) {
        Swal.fire("", "Please select stream", "error");
        return;
      }
    }

    if (this.email == "" || this.email == undefined) {
      Swal.fire("", "Please select email", "error");
      return;
    }
    if (!this.email.match('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')) {
      Swal.fire("", "Please enter valid email", "error");
      return;
    }
    if (this.mobileno == "" || this.mobileno == undefined) {
      Swal.fire("", "Please enter mobileno", "error");
      return;
    }
    if (!this.mobileno.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
      Swal.fire("", "Please enter valid mobile no ", "error");
      return;
    }

    let input = new FormData();

    input.append("studentid", this.studentid.toString());
    input.append("name", this.studentname);
    input.append("stateid", this.selectedstate.toString());
    input.append("schoolid", this.selectedschool.toString());
    input.append("cityid", this.selectedcity.toString());
    input.append("classid", this.selectedclass.toString());
    input.append("streamid", this.selectedstream.toString());
    input.append("languageid", this.selectedlanguage.toString());
    input.append("usertype", this.selectedusertype.toString());
    input.append("email", this.email);
    input.append("mobileno", this.mobileno);
    input.append("createdby", this.localstorage.get("userid").toString());

    this.loader.start();
    this.http.post('api/addstudent/AddStudent', input)
      .subscribe(
        (data) => {
          
          this.StudentData = data;
          this.loader.stop();

          if (this.StudentData.Status == true) {

            this.toaster.success(this.StudentData.Message.toString(), '', { easeTime: 400 });
            this.GetData();
            this.onClear();
            return;
          }
          else {
            this.toaster.success(this.StudentData.Message.toString(), '', { easeTime: 400 });
            return;
          }

        })
  }

  SelectAll(IsCheckedAll) {
    
    this.studentsdata = [];
    this.studentids = "";
    this.selectedStudentCount = 0;

    if (IsCheckedAll == true) {
      if (this.GetSaveData.length > 0) {

        this.GetSaveData.forEach(item => {
          item.IsChecked = true;

          if (this.studentids == "") {
            this.studentids = item.studentid;
          }
          else {
            this.studentids = this.studentids + ',' + item.studentid;
          }

          this.selectedStudentCount++;

          this.studentsdata.push({ "studentid": item.studentid, "name": item.studentname, "password": item.password, "email": item.email, "mobile": item.mobileno });
        });
      }
    }
    else {
      this.GetSaveData.forEach(item => {
        item.IsChecked = false;
      });
    }

    if (this.selectedStudentCount > 0) {
      this.btnBulkDelete = true;
      this.btnBulkPublish = true;
    }

  }

  SelectStudent() {
    
    this.studentsdata = [];
    this.studentids = "";
    this.selectedStudentCount = 0;

    if (this.GetSaveData.length > 0) {
      this.GetSaveData.forEach(item => {

        if (item.IsChecked == true) {
          if (this.studentids == "") {
            this.studentids = item.studentid;
          }
          else {
            this.studentids = this.studentids + ',' + item.studentid;
          }

          this.selectedStudentCount++;

          this.studentsdata.push({ "studentid": item.studentid, "name": item.studentname,"password":item.password, "email": item.email, "mobile": item.mobileno });
        }

      });
    }

    if (this.selectedStudentCount > 0) {
      this.btnBulkDelete = true;
      this.btnBulkPublish = true;
    }
      

  }

  GetData() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/addstudent/GetData?stateid=' + this.selectedstate + '&cityid=' + this.selectedcity + '&schoolid=' + this.selectedschool + '&classid=' + this.selectedclass + '&streamid=' + this.selectedstream + '&userid=' + this.localstorage.get('userid') , options).subscribe(
      (data) => {
        
        this.resData = data;

        if (this.resData.Status == true) {
          this.GetSaveData = this.resData.data;
        }
        else {
          this.GetSaveData = [];
        }

      }
    )
  }

  EditData(i: number, Id) {

    this.ButtonText = 'Update';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/addstudent/GetEditData?studentid=' + Id, options).subscribe(
      (data) => {
        
        this.GetEditedData = data;
        if (this.GetEditedData.Status == true) {
          this.studentid = this.GetEditedData.data.studentid;
          this.studentname = this.GetEditedData.data.studentname;
          this.selectedstate = this.GetEditedData.data.stateid;
          this.BindCity();
          this.selectedcity = this.GetEditedData.data.cityid;
          this.BindSchool();
          this.selectedschool = this.GetEditedData.data.schoolid;
          this.selectedclass = this.GetEditedData.data.classid;
          this.selectedstream = this.GetEditedData.data.streamid;
          this.email = this.GetEditedData.data.email;
          this.mobileno = this.GetEditedData.data.mobileno;
          this.selectedusertype = this.GetEditedData.data.paidstatus;
          this.isshow = 0;
        }
      }
    )
  }

  DeleteData(i: number, Id) {
    var data;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    data =
    {
      "studentid": Id
    };

    let body = JSON.stringify(data);

    Swal.fire({
      text: 'Are you sure to delete this record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.http.post('api/addstudentpartner/DeleteActivity', body, options).subscribe(
          (data) => {
            this.DeletedData = data;
            if (this.DeletedData.Status == true) {
              Swal.fire("", "Deleted Successfully", "success");
              this.GetData();
              return;
            }
          }
        )
      }
    })

  }

  BulkDelete() {

    if (this.selectedStudentCount > 0 && this.studentids != "") {

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      var data =
      {
        "userid": this.localstorage.get('userid'),
        "stateid": this.selectedstate,
        "cityid": this.selectedcity,
        "schoolid": this.selectedschool,
        "studentids": this.studentids
      };

      let body = JSON.stringify(data);

      Swal.fire({
        text: 'Are you sure to delete ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          this.http.post('api/addstudent/BulkDelete', body, options).subscribe(
            (data) => {
              
              this.DeletedData = data;
              if (this.DeletedData.Status == true) {
                Swal.fire("", "Deleted Successfully", "success");
                this.onClear();
                return;
              }
            }
          )
        }
      })

    }
    else {
      Swal.fire("", "Please select Student", "error");
      return;
    }


  }

  SendBulk() {
    
    if (this.selectedStudentCount > 0 && this.studentsdata.length > 0) {

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      var body = {
        "userid": this.userid,
        "isSMSChecked": this.isSMSChecked,
        "isEmailChecked": this.isEmailChecked,
        "studentsData": JSON.stringify(this.studentsdata)
      }

      this.http.post('api/addstudent/SendBulkEmailSms', body, options).subscribe(
        (data) => {
          
          this.resData = data;
          if (this.resData.status == true) {
            Swal.fire("Success", this.resData.message, "success");
            this.GetData();
            return;
          }
          else {
            Swal.fire("Oops...", this.resData.message, "error");
          }
        }
      )
    }
  }

  BulkPulish() {

    if (this.selectedStudentCount > 0 && this.studentids != "") {

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      var data =
      {
        "userid": this.localstorage.get('userid'),
        "stateid": this.selectedstate,
        "cityid": this.selectedcity,
        "schoolid": this.selectedschool,
        "studentids": this.studentids
      };

      let body = JSON.stringify(data);

      Swal.fire({
        text: 'Are you sure to update Analysis Report permissions ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          this.http.post('api/addstudent/BulkPublish', body, options).subscribe(
            (data) => {
              
              this.DeletedData = data;
              if (this.DeletedData.Status == true) {
                Swal.fire("", "Analysis Report permission updated successfully", "success");
                this.onClear();
                return;
              }
            }
          )
        }
      })

    }
    else {
      Swal.fire("", "Please select Student", "error");
      return;
    }


  }


  onClear() {
    this.IsCheckedAll = false;
    this.isshow = 1;
    this.selectedclass = 0;
    this.selectedstream = 0;
    this.selectedcity = 0;
    this.selectedschool = 0;
    this.selectedstate = 0;
    this.selectedusertype = 0;
    this.studentname = "";
    this.email = "";
    this.mobileno = "";
    this.ButtonText = "Save";
    this.btnBulkDelete = false;
    this.btnBulkPublish = false;
    this.selectedStudentCount = 0;
    this.GetSaveData = [];
    this.page = 1;
    this.pageSize = 10;
  }

}
