import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SidebarComponent } from '../landingpagelayouts/sidebar/sidebar.component';
import { HeaderComponent } from '../landingpagelayouts/header/header.component';
import { InternetConectivityCheckComponent } from '../adminpanel/internetconnectivity/internetconnectivity.component';
import { SubscriptionManager } from '../adminpanel/Subscription/subscription.component';
import { WebinarManager } from '../adminpanel/Webinar/webinar.component';
import { PlannedaActivityManager } from '../adminpanel/plannedactivity/plannedactivity.component';
import { PlannedaActivityManagerNew } from '../adminpanel/plannedactivity_new/plannedactivity_new.component';
import { RepositoryManager } from '../adminpanel/ssrepository/ssrepository.component';
import { SummerSchoolManager } from '../adminpanel/summerschool/summerschool.component';
import { personalessaymanager } from '../adminpanel/personalessay/personalessay.component';
import { portfoliomanager } from '../adminpanel/portfolio/portfolio.component';
import { lifecoachesmanager } from '../adminpanel/lifecoaches/lifecoaches.component';
import { prepratorymaterialManager } from '../adminpanel/prepratorymaterial/prepratorymaterial.component';
import { uploadmaterialmanager } from '../adminpanel/uploadmaterial/uploadmaterial.component';
import { prepnameManager } from '../adminpanel/prepnamemaster/prepname.component';
import { SafePipe } from '../adminpanel/plannedactivity/plannedactivity.component';
import { SummerTopicManager } from '../adminpanel/summerschooltopic/summertopic.component';
import { UniversityManager } from '../adminpanel/universitymaster/universitymaster.component';
import { SampleEssayManager } from '../adminpanel/sampleessay/sampleessay.component';
import { professionalManager } from '../adminpanel/professionalmaster/professionalmaster.component';
import { TraitManager } from '../adminpanel/traitmaster/traitmaster.component';
import { SamplePortfolioManager } from '../adminpanel/sampleportfolio/sampleportfolio.component';
import { SOPIntrestManager } from '../adminpanel/SOPintrestmaster/sopintrestmaster.component';
import { SampleSOPManager } from '../adminpanel/samplesop/samplesop.component';
import { professionalSOPManager } from '../adminpanel/professionalsop/professionalsop.component';
import { LifeCoachManager } from '../adminpanel/lifecoachtopic/lifecoachtopic.component';
import { WelcomeManager } from '../adminpanel/welcompage/welcome.component';
import { videopdfmanager } from '../adminpanel/uploadvideopdf/uploadvideopdf.component';
import { DatePipe } from '@angular/common';
import { SearchfilterPipe } from '../adminpanel/uploadvideopdf/filtergrid';
import { SearchSummerfilterPipe } from '../adminpanel/summerschool/filtersummerschoolgrid';
import { lifecoachactivitymanager } from '../adminpanel/lifecoachactivity/lifecoachactivity.component';
import { EntranceExamManager } from '../adminpanel/entranceexam/entranceexam.component';
import { PlacementStudentManager } from '../adminpanel/studentplacement/placement.component';
import { PlacementDetailsMaster } from '../adminpanel/placementdetails/placementdetails.component';
import { AddPlacementManager } from '../adminpanel/placement/placement.component';
import { PrincipalRegistration } from '../adminpanel/principalregistration/principalregistration.component';
import { Selfanalysis } from '../adminpanel/selfanalysis/selfanalysis.component';
import { couponmaster } from '../adminpanel/couponmaster/couponmaster.component';
import { partnerregistration } from '../adminpanel/partner/partner.component';
import { PaidFeaturemanager } from '../adminpanel/paidfeature/paidfeature.component';
import { addstudent } from '../adminpanel/addstudent/addstudent.component';
import { Partnerbankdetail } from '../adminpanel/partnerbankdetail/partnerbankdetail.component';
import { resetpassword } from '../adminpanel/resetpassword/resetpassword.component';
import { guideline } from '../adminpanel/guideline/guideline.component';
import { collegerepository } from '../adminpanel/collegerepository/collegerepository.component';
import { paidfeaturebackend } from '../adminpanel/paidfeaturebackend/paidfeaturebackend.component';
import { boards } from '../adminpanel/boards/boards.component';
import { citymaster } from '../adminpanel/citymaster/citymaster.component';
import { statemaster } from '../adminpanel/statemaster/statemaster.component';
import { lifecoachtypemaster } from '../adminpanel/lifecoachtypemaster/lifecoachtypemaster.component';
import { lifecoachagenda } from '../adminpanel/lifecoachagenda/lifecoachagenda.component';
import { lifecoachinformation } from '../adminpanel/lifecoachinformation/lifecoachinformation.component';
import { lifecoachesinfo } from '../adminpanel/lifecoachesinfo/lifecoachesinfo.component';
import { productusagemanager } from '../adminpanel/productusage/productusage.component';
import { ProductManager } from '../adminpanel/productmaster/productmaster';
import { ReportManager } from '../adminpanel/report/report.component';
import { countrymaster } from '../adminpanel/country/country.component';
import { ProductCoupon } from '../adminpanel/productwisecoupon/productwisecoupon.component';
import { LiveWebinarManager } from '../adminpanel/livewebinar/livewebinar.component';
import { permissionmanager } from '../adminpanel/permission/permission.component';
import { MobileAppManager } from '../adminpanel/MobileApp/mobile.component';
import { ScholarshipReportManager } from '../adminpanel/ScholarshipReport/scholarshipreport.component';
import { EntranceExaminationReportManager } from '../adminpanel/EntranceExaminationReport/entranceexamination.component';
import { ProfileBuildingReportManager } from '../adminpanel/ProfileBuilding/profilebuildingreport.component';
import { SummerSchoolReportManager } from '../adminpanel/SummerSchoolReport/summerschoolreport.component';
import { PrepratoryMaterialReportManager } from '../adminpanel/PrepratoryMaterialReport/prepratorymaterialreport.component';
import { SelfAnalysisTestReportManager } from '../adminpanel/SelfAnalysisTestReport/selfanalysisreport.component';
import { LifeCoachesReportManager } from '../adminpanel/LifeCoachesReport/lifecoachesreport.component';
import { PlacementReportManager } from '../adminpanel/PlacementReport/placementreport.component';
import { WebinarReportManager } from '../adminpanel/WebinarReport/webinarreport.component';
import { OtherReportManager } from '../adminpanel/OtherReport/otherreport.component';
import { FreeReportManager } from '../adminpanel/FreeUserReport/freereport.component';
import { PaidFeaturesReportManager } from '../adminpanel/PaidFeaturesReport/paidfeaturesreport.component';
import { NonSubscriberReportManager } from '../adminpanel/NonSubscriberReport/nonsubscriberreport.component';
import { FAQReportManager } from '../adminpanel/FAQreport/faqreport.component';
import { QueryReportManager } from '../adminpanel/report/FAQuery/Queryreport/queryreport.component';
import { StudentAnalysisReportManager } from '../adminpanel/studentanalysisreport/studentanalysis.component';
import { NotificationReportManager } from '../adminpanel/NotificationReport/notificationreport.component';
import { AcademyMaster } from '../adminpanel/academymaster/academymaster.component';
import { PartnerUniversityMaster } from '../adminpanel/partneruniversitymaster/partneruniversitymaster.component';
import { SopAndEssayReportManager } from '../adminpanel/SOPandEssayReport/sopessayreport.component';
import { DownloadReportManager } from '../adminpanel/DownloadReport/downloadreport.component';
import { MaterialReportManager } from '../adminpanel/MaterialReport/materialreport.component';
import { ActivityManager } from '../adminpanel/activitymaster/activitymaster.component';
import { TestDownloadReportManager } from '../adminpanel/TestDownloadReport/testdownloadreport.component';
import { ArticlesReportManager } from '../adminpanel/ArticlesReport/articlesreport.component';
import { DownloadTestReportManager } from '../adminpanel/DownloadTestReport/downloadtestreport.component';
import { CvReportManager } from '../adminpanel/Cvreport/cvreport.component';
import { TestDiscussionReportManager } from '../adminpanel/TestDiscussionReport/testdiscussionreport.component';
import { DiscountReportManager } from '../adminpanel/DiscountReport/discountreport.component';
import { PendingDiscussionReportManager } from '../adminpanel/PendingDiscussionReport/pendingdiscussionreport.component';
import { AcademyCareerReport } from '../adminpanel/virtualactivityreport/academycareerreport/academycareerreport.component';
import { RegistrationReportManager } from '../adminpanel/RegistrationReport/registrationreport.component';
import { SchoolMaster } from '../adminpanel/schoolmaster/schoolmaster.component';
import { DesignationMaster } from '../adminpanel/designationmaster/designationmaster.component';
import { RoleMaster } from './security/rolemaster/rolemaster.component';
import { UserMaster } from './security/usermaster/usermaster.component';
import { RollOverMaster } from './rollovermaster/rollovermaster.component';
import { AssignStudentMaster } from './assignstudent/assignstudent.component';
import { SessionMaster } from './sessionmaster/sessionmaster.component';

import { AuthguardService } from '../../AuthGuard.Service';
import { MenuMaster } from './security/menumaster/menumaster.component';
import { RegistrationPaymentReport } from './registrationpaymentreport/registrationpaymentreport.component';
import { SchoolVerifyMaster } from './schoolverifymaster/schoolverifymaster.component';

const routes: Routes = [
  {
    path: '', component: HeaderComponent, canActivateChild: [AuthguardService],
    children:
      [
        { path: 'WelcomeManager', component: WelcomeManager, canActivate: [AuthguardService] },
        { path: 'checkconnectivity', component: InternetConectivityCheckComponent },

        { path: 'subscription', component: SubscriptionManager, canActivate: [AuthguardService] },
        { path: 'webinar', component: WebinarManager, canActivate: [AuthguardService] },
        { path: 'plannedactivity', component: PlannedaActivityManager, canActivate: [AuthguardService] },
        { path: 'plannedactivity_new', component: PlannedaActivityManagerNew, canActivate: [AuthguardService] },
        { path: 'LiveWebinarManager', component: LiveWebinarManager, canActivate: [AuthguardService] },

        { path: 'repositorymanager', component: RepositoryManager, canActivate: [AuthguardService] },
        { path: 'summerschool', component: SummerSchoolManager, canActivate: [AuthguardService] },
        { path: 'personalessay', component: personalessaymanager, canActivate: [AuthguardService] },
        { path: 'portfolio', component: portfoliomanager, canActivate: [AuthguardService] },
        { path: 'lifecoaches', component: lifecoachesmanager, canActivate: [AuthguardService] },
        { path: 'prepratorymaterial', component: prepratorymaterialManager, canActivate: [AuthguardService] },
        { path: 'uploadmaterial', component: uploadmaterialmanager, canActivate: [AuthguardService] },
        { path: 'prepname', component: prepnameManager, canActivate: [AuthguardService] },
        { path: 'summertopic', component: SummerTopicManager, canActivate: [AuthguardService] },
        { path: 'universitymaster', component: UniversityManager, canActivate: [AuthguardService] },
        { path: 'sampleessay', component: SampleEssayManager, canActivate: [AuthguardService] },
        { path: 'professionalmaster', component: professionalManager, canActivate: [AuthguardService] },
        { path: 'traitmaster', component: TraitManager, canActivate: [AuthguardService] },
        { path: 'SamplePortfolioManager', component: SamplePortfolioManager, canActivate: [AuthguardService] },
        { path: 'SOPIntrestManager', component: SOPIntrestManager, canActivate: [AuthguardService] },
        { path: 'SampleSOPManager', component: SampleSOPManager, canActivate: [AuthguardService] },
        { path: 'professionalSOPManager', component: professionalSOPManager, canActivate: [AuthguardService] },
        { path: 'LifeCoachManager', component: LifeCoachManager, canActivate: [AuthguardService] },
        { path: 'videopdfmanager', component: videopdfmanager, canActivate: [AuthguardService] },

        { path: 'lifecoachactivitymanager', component: lifecoachactivitymanager, canActivate: [AuthguardService] },
        { path: 'EntranceExamManager', component: EntranceExamManager, canActivate: [AuthguardService] },
        { path: 'PlacementStudentManager', component: PlacementStudentManager, canActivate: [AuthguardService] },
        { path: 'Placement Details', component: PlacementDetailsMaster, canActivate: [AuthguardService] },
        { path: 'AddPlacementManager', component: AddPlacementManager, canActivate: [AuthguardService] },
        { path: 'PrincipalRegistration', component: PrincipalRegistration, canActivate: [AuthguardService] },
        { path: 'DesignationMaster', component: DesignationMaster, canActivate: [AuthguardService] },
        { path: 'Selfanalysis', component: Selfanalysis, canActivate: [AuthguardService] },
        { path: 'couponmaster', component: couponmaster, canActivate: [AuthguardService] },
        { path: 'partnerregistration', component: partnerregistration, canActivate: [AuthguardService] },
        { path: 'PaidFeaturemanager', component: PaidFeaturemanager, canActivate: [AuthguardService] },
        { path: 'paidfeaturebackend', component: paidfeaturebackend, canActivate: [AuthguardService] },

        { path: 'addstudent', component: addstudent, canActivate: [AuthguardService] },
        { path: 'Partnerbankdetail', component: Partnerbankdetail, canActivate: [AuthguardService] },
        { path: 'resetpassword', component: resetpassword, canActivate: [AuthguardService] },
        { path: 'guideline', component: guideline, canActivate: [AuthguardService] },
        { path: 'collegerepository', component: collegerepository, canActivate: [AuthguardService] },
        { path: 'boards', component: boards, canActivate: [AuthguardService] },
        { path: 'citymaster', component: citymaster, canActivate: [AuthguardService] },
        { path: 'statemaster', component: statemaster, canActivate: [AuthguardService] },
        { path: 'lifecoachtypemaster', component: lifecoachtypemaster, canActivate: [AuthguardService] },
        { path: 'lifecoachagenda', component: lifecoachagenda, canActivate: [AuthguardService] },
        { path: 'lifecoachinformation', component: lifecoachinformation, canActivate: [AuthguardService] },
        { path: 'lifecoachesinfo', component: lifecoachesinfo, canActivate: [AuthguardService] },
        { path: 'productusagemanager', component: productusagemanager, canActivate: [AuthguardService] },
        { path: 'ProductManager', component: ProductManager, canActivate: [AuthguardService] },

        { path: 'ReportManager', component: ReportManager, canActivate: [AuthguardService] },

        { path: 'countrymaster', component: countrymaster, canActivate: [AuthguardService] },

        { path: 'ProductCoupon', component: ProductCoupon, canActivate: [AuthguardService] },

        { path: 'permissionmanager', component: permissionmanager, canActivate: [AuthguardService] },

        { path: 'subscriptionreport', component: MobileAppManager, canActivate: [AuthguardService] },

        { path: 'scholarshipreport', component: ScholarshipReportManager, canActivate: [AuthguardService] },

        { path: 'admissionalertreport', component: EntranceExaminationReportManager, canActivate: [AuthguardService] },

        { path: 'profilebuildingreport', component: ProfileBuildingReportManager, canActivate: [AuthguardService] },

        { path: 'summerschoolreport', component: SummerSchoolReportManager, canActivate: [AuthguardService] },

        { path: 'prepratorymaterialreport', component: PrepratoryMaterialReportManager, canActivate: [AuthguardService] },

        { path: 'selfanalysistestreport', component: SelfAnalysisTestReportManager, canActivate: [AuthguardService] },

        { path: 'lifecoachesreport', component: LifeCoachesReportManager, canActivate: [AuthguardService] },

        { path: 'placementreport', component: PlacementReportManager, canActivate: [AuthguardService] },

        { path: 'webinarreport', component: WebinarReportManager, canActivate: [AuthguardService] },

        { path: 'otherreport', component: OtherReportManager, canActivate: [AuthguardService] },

        { path: 'freereport', component: FreeReportManager, canActivate: [AuthguardService] },

        { path: 'subscriberreport', component: PaidFeaturesReportManager, canActivate: [AuthguardService] },

        { path: 'nonsubscriberreport', component: NonSubscriberReportManager, canActivate: [AuthguardService] },

        { path: 'FAQReport', component: FAQReportManager, canActivate: [AuthguardService] },

        { path: 'QueryReport', component: QueryReportManager, canActivate: [AuthguardService] },

        { path: 'registrationreport', component: RegistrationReportManager, canActivate: [AuthguardService] },

        { path: 'studentanalysisreport', component: StudentAnalysisReportManager, canActivate: [AuthguardService] },

        { path: 'notificationreport', component: NotificationReportManager, canActivate: [AuthguardService] },

        { path: 'ActivityMaster', component: ActivityManager, canActivate: [AuthguardService] },

        { path: 'AcademyMaster', component: AcademyMaster, canActivate: [AuthguardService] },

        { path: 'SchoolMaster', component: SchoolMaster, canActivate: [AuthguardService] },

        { path: 'PartnerUniversityMaster', component: PartnerUniversityMaster, canActivate: [AuthguardService] },


        { path: 'sopandessayreport', component: SopAndEssayReportManager, canActivate: [AuthguardService] },

        { path: 'downloadreport', component: DownloadReportManager, canActivate: [AuthguardService] },

        { path: 'materialreport', component: MaterialReportManager, canActivate: [AuthguardService] },

        { path: 'testdownloadreport', component: TestDownloadReportManager, canActivate: [AuthguardService] },

        { path: 'articlesreport', component: ArticlesReportManager, canActivate: [AuthguardService] },

        { path: 'downloadtestreport', component: DownloadTestReportManager, canActivate: [AuthguardService] },

        { path: 'cvreport', component: CvReportManager, canActivate: [AuthguardService] },

        { path: 'testdiscussionreport', component: TestDiscussionReportManager, canActivate: [AuthguardService] },

        { path: 'discountreport', component: DiscountReportManager, canActivate: [AuthguardService] },

        { path: 'pendingdiscussionreport', component: PendingDiscussionReportManager, canActivate: [AuthguardService] },

        { path: 'academycareerreport', component: AcademyCareerReport, canActivate: [AuthguardService] },

        { path: 'RoleMaster', component: RoleMaster, canActivate: [AuthguardService] },

        { path: 'UserMaster', component: UserMaster, canActivate: [AuthguardService] },

        { path: 'MenuMaster', component: MenuMaster, canActivate: [AuthguardService] },

        { path: 'RollOverMaster', component: RollOverMaster, canActivate: [AuthguardService] },

        { path: 'AssignStudent', component: AssignStudentMaster, canActivate: [AuthguardService] },

        { path: 'SessionMaster', component: SessionMaster, canActivate: [AuthguardService] },

        { path: 'RegistrationPaymentReport', component: RegistrationPaymentReport, canActivate: [AuthguardService] },

        { path: 'verifyschool', component: SchoolVerifyMaster, canActivate: [AuthguardService] },

        // { path: 'LiveWebinarManager', component: LiveWebinarManager, canActivate: [AuthguardService] },


        { path: '**', redirectTo: 'WelcomeManager' }  //For Now i give subscription but actually it would be dashboard

      ]
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingpagesRoutingModule { }
