import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class PermissionService {

  resData: any;
  public actionsData: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private localstorage: LocalStorageService) {}


  GetActionsByRoleId(): any {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http.get('api/menus/GetActionsByRoleId?roleid=' + this.localstorage.get("roleid") + '&pageid=' + this.localstorage.get("pageid"), options)
  }

}
