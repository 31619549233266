import { Component, OnInit} from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { PermissionService } from '../../../../app/permission.service';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sessionmaster',
  templateUrl: './sessionmaster.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class SessionMaster implements OnInit {

  public spinnerType = SPINNER.rectangleBounce;

  public sessionid: number = 0;
  public sessionname: string = "";

  public resData: any = [];
  public editData: any = [];
  public roleData: any = [];
  public sessionData: any = [];

  public page: number = 1;
  public pageSize: number = 10;

  public search: string = "";

  public roleid: number = 0;
  public rolename: string = "";

  public btntext: string = "Save";

  public SelectedStartDate: Date;
  public SelectedEndDate: Date;
  public s_date: any;
  public e_date: any;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor(
    private http: HttpClient,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private permission: PermissionService,
    private config: NgbDatepickerConfig  ) {

    const current = new Date();

    config.minDate = {
      year: current.getFullYear(), month:
        current.getMonth() + 1, day: current.getDate()
    };
    config.maxDate = {
      year: current.getFullYear() + 25,
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    config.outsideDays = 'hidden';
  }

  ngOnInit() {
    this.GetActions();
    this.BindData();
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  BindData() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/session/GetSession', options).subscribe(
      (data) => {
        
        this.resData = data;

        if (this.resData.status == true) {
          this.sessionData = this.resData.data;
        }
        else {
          this.sessionData = [];
        }

      }
    )
  }

  Reset() {
    this.sessionid = 0;
    this.sessionname = "";
    this.SelectedStartDate = null;
    this.SelectedEndDate = null;
    this.search = "";
    this.btntext = "Save";
  }

  Submit() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.SelectedStartDate == null || this.SelectedStartDate == undefined) {
      this.toaster.error('Please enter start date', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.SelectedEndDate == null || this.SelectedEndDate == undefined) {
      this.toaster.error('Please enter end date', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.sessionname == "" || this.sessionname == undefined) {
      this.toaster.error('Please Enter Session Name', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    this.s_date = this.SelectedStartDate.toISOString().slice(0, 10);
    this.e_date = this.SelectedEndDate.toISOString().slice(0, 10);

    var body = {
      "sessionid": this.sessionid,
      "startdate": this.s_date,
      "enddate": this.e_date,
      "sessionname": this.sessionname,
      "userid": this.localstorage.get('userid')
    }

    this.loader.start();
    this.http.post('api/session/SaveUpdateSession', body, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          
          this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          this.BindData();
          this.Reset();
          return;
        }
        else {
          this.toaster.error(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          return;
        }
      }
    )

  }

  EditSession(_Id: number) {

    this.btntext = "Update";

    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/session/EditSessionData?sessionid=' + _Id, options).subscribe(
      (data) => {
        
        this.resData = data;
        this.editData = this.resData.data;

        this.sessionid = this.editData.sessionid;
        this.sessionname = this.editData.sessionname;
        this.SelectedStartDate = new Date(this.editData.startdate);
        this.SelectedEndDate = new Date(this.editData.enddate);

      }
    )
  }

  DeleteSession(_Id: number) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var data =
    {
      "sessionid": _Id,
      "userid": this.localstorage.get("userid")
    };

    let body = JSON.stringify(data);

    Swal.fire({
      text: 'Are you sure to delete this record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        this.http.post('api/session/DeleteSessionData', body, options).subscribe(
          (data) => {
            this.resData = data;
            if (this.resData.status == true) {
              Swal.fire("", "Deleted Successfully", "success");
              this.BindData();
              this.Reset();
              return;
            }
            else {
              Swal.fire("", this.resData.message, "warning");
            }
          }
        )
      }
    })
  }
}
