import { Component, OnInit, Input, PipeTransform, Pipe } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService,SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbTimepickerConfig, NgbTimeStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { PermissionService } from '../../../permission.service';

@Component({
  selector: 'app-materialreport',
  templateUrl: './materialreport.component.html',
  styleUrls: ['./materialreport.css'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, NgbTimepickerConfig]

})
export class MaterialReportManager implements OnInit {

  spinnerType = SPINNER.rectangleBounce;

  searchTerm: string = '';

  public page: number = 1;
  public pageSize: number = 10;

  public page1: number = 1;
  public pageSize1: number = 10;

  public resData: any = [];

  public SelectedDate9: Date;
  public SelectedEndDate10 : Date;
  public selectedfeature:number=1;
  public selectedstateg:number = 0;
  public selectedcityg:number =0;
  public selectedclassg:number = 0;
  public selectedstreamg: number = 0;
  public selectedType:number = 3;
  public selectedPaperType:number = 0;
  public selectedPrepType:number = 0;



  public s_date: any;
  public e_date: any;

  year: number = 0;
  month: number = 0;
  date: number = 0;

  public MaterialData: any = [];
  public MaterialDetailData: any = [];

  public DownloadReportData: any = [];
  public DownloadReportDataDetail: any = [];

  public typedata:any=[];
  public typedataDetail:any=[];

  public preptypedata:any=[];
  public PreparatoryDetail:any=[];

  public papertype:string="";

  pipe = new DatePipe('en-US');

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor(private http: HttpClient, private permission: PermissionService,private router: Router, private localstorage: LocalStorageService, private toaster: ToastrService, private loader: NgxUiLoaderService, config: NgbTimepickerConfig, private config1: NgbDatepickerConfig) {
    config.seconds = false;
    config.spinners = false;
    config.meridian = true;

    this.date = new Date().getDate();
    this.year = new Date().getFullYear();
    this.month = parseInt(new Date().toISOString().slice(0, 10).split('-')[1]);

  }

  ngOnInit()
  {
    this.GetActions();
    this.getMaterialReport();
    this.BindType();
    this.getPreparatory();
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

 
  /*name of the excel-file which will be downloaded. */
  fileName = 'MaterialDownloadSheet.xlsx';

  exportexcel(tagid: string): void {
    
    /* table id is passed over here */
    let element = document.getElementById(tagid);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }


  getMaterialReport()
  {

    if(this.selectedType == 0){ this.toaster.error("Please choose type to search.");return; }

    if (this.SelectedDate9 == undefined) {
      this.s_date = "1900-01-01";
    }
    else {
      this.s_date = this.SelectedDate9.toISOString().slice(0, 10);

    }
    if (this.SelectedEndDate10 == undefined) {
      this.e_date = "1900-01-01";
    }
    else {
      this.e_date = this.SelectedEndDate10.toISOString().slice(0, 10);
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    

    this.loader.start();

    

    this.http.get('api/reports/getMaterialReport?fromdate=' + this.s_date + '&todate=' + this.e_date + '&type=' +this.selectedType+
    '&paperType='+this.selectedPaperType+'&Prepmattype='+this.selectedPrepType, options).subscribe(

        (data) => {

        this.MaterialData = data;

        this.loader.stop();

        this.page = 1;

        if (this.MaterialData.Status == true)
        {
          this.MaterialDetailData = this.MaterialData.data;
          this.loader.stop();
        }
        else
        {
          this.MaterialDetailData = [];
          this.loader.stop();
        }
      }
    )

  }


  setPageno() {
    this.page=1;
  }


BindType() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.typedata = [];

    
    this.http.get('api/reports/BindType', options).subscribe(

      (data) => {
        this.typedata = data;
        if (this.typedata.Status == true) {
          this.typedataDetail = this.typedata.data;
          //this.selectedType = 0;
        }
        else {
          this.typedataDetail = this.typedata.data;
          this.selectedType = 0;
        }
      }
    )
  }

 getPreparatory() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.typedata = [];

    
    this.http.get('api/reports/getPreparatory?prep_id='+this.selectedType, options).subscribe(

      (data) => {

        this.preptypedata = data;

        if (this.preptypedata.Status == true)
        {
          this.PreparatoryDetail = this.preptypedata.data;
          this.selectedPrepType = 0;
        }
        else {
          this.PreparatoryDetail =[];
          this.selectedPrepType = 0;
        }
      }
    )
  }


  ResetMaterialReport()
  {
    this.SelectedDate9 = null;
    this.SelectedEndDate10 = null;
    this.selectedType = 0;
    this.selectedPrepType=0;
    this.selectedPaperType = 0;
    this.searchTerm = '';
    this.getMaterialReport();
  }
 
}
