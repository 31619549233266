import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';

import { PermissionService } from '../../../permission.service';


@Component({
  selector: 'app-schoolverifymaster',
  templateUrl: './schoolverifymaster.component.html',
  styleUrls: ['./schoolverifymaster.component.css'],

})
export class SchoolVerifyMaster implements OnInit {

  spinnerType = SPINNER.rectangleBounce;

  public userid: number;
  public btn_text: string = "Save";

  public resData: any;
  public savedData: any;
  public editData: any;

  public verifystatus: string = "Pending";
  public BoardData: any = [];

  public selectedboard: number = 0;

  public searchTerm: string;
  public pageSize: number = 10;
  public page: number = 1;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  public isEditMode: boolean = false;

  constructor(
    private http: HttpClient,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private permission: PermissionService
  ) { }



  ngOnInit() {
    this.loader.start();
    this.userid = this.localstorage.get("userid");

    this.GetActions();
    this.BindBoard();
    this.GetSavedData(0,"Pending");

    this.loader.stop();
  }

  setpageno() {
    this.page = 1;
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  BindBoard() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/addstudentpartner/BindBoard', options).subscribe(
      (data) => {
        this.resData = data;
        if (this.resData.status == true) {
          this.BoardData = this.resData.data;
        }
        else {
          this.BoardData = this.resData.data;
        }
      }
    )
  }

  GetSavedData(_Status: number, _Verify: string) {

    this.loader.start();
    this.verifystatus = _Verify;
    this.selectedboard = 0;

    if (_Verify == "Approved") {
      $('#board').prop("disabled", true)
    }
    else {
      $('#board').prop("disabled", false)
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/schoolmaster/GetVerifySchool?verifystatus=' + _Status, options).subscribe(
      (data) => {
        this.loader.stop();
        this.resData = data;

        if (this.resData.status == true) {
          this.savedData = this.resData.data;

          this.savedData = this.savedData.map(function (el) {
            var o = Object.assign({}, el);
            o.isEditMode = false;
            return o;
          })

        }
        else {
          this.savedData =[];
        }

      }
    )
  }

  ApproveSchool(_Id: number, _Type: string) {
    

    if (this.selectedboard == 0 && _Type == "Approved") {
      this.toaster.error('Please Select Board', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();

    this.http.get('api/schoolmaster/ApproveSchool?id=' + _Id + '&boardid=' + this.selectedboard + '&type=' + _Type, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 })

          if (_Type == "Rejected") {
            $('#Rejected-tab').click();
            this.GetSavedData(2, 'Rejected');
          }
          else {
            $('#Approved-tab').click();
            this.GetSavedData(1, 'Approved');
          }

          this.selectedboard = 0;

          return;
        }
        else {
          this.toaster.warning(this.resData.message, '', { easeTime: 1000, timeOut: 3000 })
        }
      }
    )
  }

  UpdateSchool(_Id: number, _Name: string,_Type:string) {
    

    if (_Name == "") {
      this.toaster.error('Please Enter School Name', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();

    this.http.get('api/schoolmaster/UpdateSchool?id=' + _Id + '&name=' + _Name, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 })

          if (_Type == "Pending")
            this.GetSavedData(0, 'Pending');

          if (_Type == "Approved")
            this.GetSavedData(1, 'Approved');

          if (_Type == "Rejected")
            this.GetSavedData(2, 'Rejected');

          return;
        }
        else {
          this.toaster.warning(this.resData.message, '', { easeTime: 1000, timeOut: 3000 })
        }
      }
    )
  }

  OnEdit(_data) {
    this.loader.start();
    _data.isEditMode = true
    this.loader.stop();
  }

  ResetEdit(_data) {
    this.loader.start();
    _data.isEditMode = false
    this.loader.stop();
  }
}
