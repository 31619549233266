
import { Component, OnInit, Input, PipeTransform, Pipe } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders, HttpEvent, HttpEventType } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbTimepickerConfig, NgbTimeStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { PermissionService } from '../../../permission.service';

@Component({
  selector: 'app-selfanalysisreport',
  templateUrl: './selfanalysisreport.component.html',
  styleUrls: ['./selfanalysisreport.css'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, NgbTimepickerConfig]

})
export class SelfAnalysisTestReportManager implements OnInit
{

  spinnerType = SPINNER.rectangleBounce;

  public page: number = 1;
  public pageSize: number = 10;

  public page1: number = 1;
  public pageSize1: number = 10;

  public single: object = [];
  public multi: object[];
  public view: any[] = [500, 300];
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel: "Month";
  public showYAxisLabel = true;
  public yAxisLabel: "Subscription";
  public graphDataChart: any = [];
  legendTitle: string = 'Type';
  public colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  TestData: any = [];
  TestDataDetail: any = [];

  downloadData: any = [];
  viewpdflink: any;

  stuname: string = '';
  email: string = '';
  phone: string = '';

  searchTerm: string = "";

  searchString: string = '';
  p: any;

  noofappdownloader: number = 0;
  noofnotregistered: number = 0;
  noofthroughapp: number = 0;
  noofbackend: number = 0;
  nooffreeusers: number = 0;
  noofclickonpaynow: number = 0;
  noofpaidclick: number = 0;
  notusing: number = 0;
  studentdetails: any = [];
  countdetails: any = [];
  DownloaderData: any = [];
  AppDownloaderData: any = [];
  public s_date: any;
  public e_date: any;
  public start_date: any;
  public end_date: any;
  public SelectedDate: Date;
  public SelectedEndDate: Date;
  public SelectedDate1: Date;
  public SelectedEndDate1: Date;
  public SelectedDate2: Date;
  public SelectedEndDate2: Date;
  public SelectedDate3: Date;
  public SelectedEndDate3: Date;
  public SelectedDate5: Date;
  public SelectedEndDate6: Date;
  public SelectedDate7: Date;
  public SelectedEndDate8: Date;
  public SelectedDate9: Date;
  public SelectedEndDate10: Date;
  public SelectedDate11: Date;
  public SelectedEndDate12: Date;
  public SelectedDate13: Date;
  public SelectedEndDate14: Date;
  public SelectedDate15: Date;
  public SelectedEndDate16: Date;
  public SelectedDate17: Date;
  public SelectedEndDate18: Date;
  public SelectedDate19: Date;
  public SelectedEndDate20: Date;
  public SelectedDate21: Date;
  public SelectedEndDate22: Date;
  public SelectedDate23: Date;
  public SelectedEndDate24: Date;
  public SelectedDate25: Date;
  public SelectedEndDate26: Date;
  public SelectedDate27: Date;
  public SelectedEndDate28: Date;
  public SelectedDate29: Date;
  public SelectedEndDate30: Date;

  public SelectedDate51: Date;
  public SelectedDate52: Date;

  public SelectedDate54: Date;
  public SelectedDate55: Date;

  public SelectedDate59: Date;
  public SelectedDate60: Date;

  public SelectedDate61: Date;
  public SelectedDate62: Date;
  selectedbuilder: number = 0;
  selectedstudentq: number = 0;
  StudentData: any = [];
  statedat: any = [];
  StateData: any = [];
  citdata: any = [];
  selectedstate: number = 0;
  selectedstatef: number = 0;
  selectedstateg: number = 0;
  selectedstateh: number = 0;
  selectedstateq: number = 0;
  selectedstatepl: number = 0;
  GetPaymentDetails: any = [];

  CityData: any = [];
  schdata: any = [];
  selectedcity: number = 0;
  selectedcityf: number = 0;
  selectedcityg: number = 0;
  selectedcityh: number = 0;
  selectedcityq: number = 0;
  selectedcitypl: number = 0;


  SchoolData: any = [];
  classdat: any = [];
  ClassData: any = [];
  streamdat: any = [];
  StreamData: any = [];
  selectedschool: number = 0;
  selectedschoolf: number = 0;
  selectedschoolg: number = 0;
  selectedschoolh: number = 0;
  selectedschoolq: number = 0;

  selectedclass: number = 0;
  selectedclassf: number = 0;
  selectedclassg: number = 0;
  selectedclassh: number = 0;
  selectedclassi: number = 0;
  selectedclassj: number = 0;
  selectedclassq: number = 0;
  selectedclasspl: number = 0;


  selectedstream: number = 0;
  selectedstreamf: number = 0;
  selectedstreamg: number = 0;
  selectedstreamh: number = 0;
  selectedstreami: number = 0;
  selectedstreamj: number = 0;
  selectedstreamq: number = 0;
  selectedstreampl: number = 0;

  productdat: any = [];
  Products: any = [];
  selectproduct: number = 0;

  getunregisteredstudent: any = [];
  Getstudentdetails: any = [];
  getfreeuser: any = [];
  GetFreeUserDetails: any = [];
  getnotsubclickpaid: any = [];
  GetClickPaidNotSub: any = [];
  GetSubscribed: any = [];
  getsubscribeduser: any = [];
  getscholarshipdata: any = [];
  GetScholarshipDetails: any = [];
  getentrancedata: any = [];
  GetEntranceExamDetails: any = [];
  noofsummerschool: number = 0;
  Schooldetails: any = [];
  summercountdetails: any = [];
  SummerData: any = [];
  SummerSchoolData: any = [];
  selectedcountry: number = 0;
  location: number = 0;
  city: number = 0;
  univercity: number = 0;
  UniversityData: any = [];
  CityDataCareer: any = [];
  LocationData: any = [];
  CountryData: any = [];
  selectedinterest: number = 0;
  AreaData: number = 0;
  AreaDetails: any = [];
  CountryDetails: any = [];
  locdata: any = [];
  univdata: any = [];
  getsummerschooldata: any = [];
  GetSummerSchoolDetails: any = [];
  studata: any = [];
  StudentDatas: any = [];
  getquerydata: any = [];
  GetQueryDetails: any = [];
  selectedcareer: string = "";
  CareerData: any = [];
  keyword: string = "";
  careerdta: any = [];
  getfaqdata: any = [];
  GetFaqDetails: any = [];
  selectedcareer1: string = "";
  getfaqissuedata: any = [];
  GetFaqissueDetails: any = [];
  keyword1: string = "";
  getarticledata: any = [];
  GetArticleDetails: any = [];
  Coachtypedetails: any = [];
  TopicData: any = [];
  Coachdetail: any = [];
  CoachData: any = [];
  selectedtopic: number = 0;
  selectedcoach: number = 0;
  getcoachdata: any = [];
  GetcoachDetails: any = [];
  getplaceddata: any = [];
  GetplacedDetails: any = [];
  getdatapaid: any = [];
  getclickdata: any = [];
  GetClickDetails: any = [];
  getbuilderdata: any = [];
  GetBuilderDetails: any = [];
  selectedcat: number = 0;
  selectedsubcat: number = 0;
  selectedstatus: number = 0;
  getusagedata: any = [];
  GetUsageDetails: any = [];
  NodataFound1: boolean = false;
  NodataFound2: boolean = false;
  NodataFound3: boolean = false;
  NodataFound4: boolean = false;
  ShowTable1: boolean = false;
  ShowTable2: boolean = false;
  ShowTable3: boolean = false;
  ShowTable4: boolean = false;

  monthwisegraphdata: any = [];
  noofclickonpaynow1: number = 0;
  selectedtype: number = -1;
  studentid: number = 0;

  year: number = 0;
  month: number = 0;
  date: number = 0;

  Instruction: any;

  ProductDetail: any = [];
  ProductDetails: any = [];

  SelfAnalysisData: any = [];
  SelfAnalysisDataDetail: any = [];

  SelfAnalysisDetail: any=[];
  SelfAnalysisDetailData: any = [];

  stuid: number = 0;

  pipe = new DatePipe('en-US');

  public resData: any;
  public selectedStudentData: any = [];
  public selectAllStudent: boolean = false;

  public downloadPer: number = 0;


  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor(private http: HttpClient, private permission: PermissionService, private router: Router, private localstorage: LocalStorageService, private toaster: ToastrService,
    private loader: NgxUiLoaderService, config: NgbTimepickerConfig, private config1: NgbDatepickerConfig, private sanitize: DomSanitizer) {
    config.seconds = false;
    config.spinners = false;
    config.meridian = true;

    this.date = new Date().getDate();
    this.year = new Date().getFullYear();
    this.month = parseInt(new Date().toISOString().slice(0, 10).split('-')[1]);

  }
  ngOnInit() {
    this.GetActions();
    this.getAnalysisReport();
    this.BindState();
    this.BindClass();
    this.BindStream();
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  /*name of the excel-file which will be downloaded. */
  fileName = 'selfanalysistestreports.xlsx';

  exportexcel(tagid: string): void {
    
    /* table id is passed over here */
    let element = document.getElementById(tagid);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }

  onPageChange(event) {
    
    this.page = event
    this.selectAllStudent = false;
    this.SelfAnalysisDataDetail.forEach(item => { item.selected = false; });
  }

  _getAllSelectedStudent() {

    this.selectedStudentData = [];

    var index = 0;
    var length = 0;

    if (this.selectAllStudent == true) {

      index = (this.page == 1 ? 0 : (this.page - 1) * this.pageSize);
      length = (this.page * this.pageSize) - 1;

      for (var i = index; i <= length && i <= this.SelfAnalysisDataDetail.length - 1; i++)
      {
        this.SelfAnalysisDataDetail[i].selected = true;
      }

      for (var i = 0; i <= this.SelfAnalysisDataDetail.length-1; i++) {

        if (this.SelfAnalysisDataDetail[i].selected == true) {

          this.selectedStudentData.push({ "studentid": this.SelfAnalysisDataDetail[i].studentid, "studentname": this.SelfAnalysisDataDetail[i].stuname });
        }
      }

    }
    else {
      this.SelfAnalysisDataDetail.forEach(item => { item.selected = false });
      this.selectAllStudent = false;
    }

  }

  _getSelectedStudent() {
    
    this.selectedStudentData = [];
    var count = 0;

    for (var i = 0; i <= this.SelfAnalysisDataDetail.length-1; i++) {

      if (this.SelfAnalysisDataDetail[i].selected == true) {

        this.selectedStudentData.push({ "studentid": this.SelfAnalysisDataDetail[i].studentid, "studentname": this.SelfAnalysisDataDetail[i].stuname });
        count++;
      }
    }

    if (count === this.pageSize) {
      this.selectAllStudent = true;
    }
    else {
      this.selectAllStudent = false;
    }
  }

  _BulkDownload() {
    
    this.downloadPer = 0;

    if (this.selectedStudentData.length == 0 || this.selectedStudentData == null) {
      Swal.fire("", "Please select alteast one student", "warning");
      return;
    }
    else {
      $('#donloadbutton').click();
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    this.selectedStudentData = [];

    this.selectedStudentData.push({ "studentid": 2988, "studentname": "Jatinder Malik" }, { "studentid": 3076, "studentname": "Sujit Kumar Bindhani" });


    var data = {
      "studentlist": JSON.stringify(this.selectedStudentData)
    }

    this.http.post('api/reports/BulkDownload', data, {
      headers: headers,
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    }).subscribe(
      (event: HttpEvent<any>) => {

        this.SelfAnalysisDataDetail.forEach(item => { item.selected = false });
        this.selectedStudentData = [];
        this.selectAllStudent = false;

        if (event.type == HttpEventType.DownloadProgress) {
          this.downloadPer = Math.round(100 * event.loaded / event.total);
        }
        else if (event.type == HttpEventType.Response) {

          this.resData = event.body;

          if (this.resData != null) {
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(this.resData);
            downloadLink.setAttribute('download', 'file' + new Date().getTime());
            document.body.appendChild(downloadLink);
            downloadLink.click();
          }
        }

      }
    )
  }

  getAnalysisReport()
  {

    if (this.SelectedDate9 == undefined || this.SelectedDate9 == null) {
      this.s_date = "1900-01-01";
    }
    else {
      this.s_date = this.SelectedDate9.toISOString().slice(0, 10);

    }
    if (this.SelectedEndDate10 == undefined || this.SelectedEndDate10 == null) {
      this.e_date = "1900-01-01";
    }
    else {
      this.e_date = this.SelectedEndDate10.toISOString().slice(0, 10);
    }

    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();

    this.http.get('api/reports/getSelfAnalysisTestReport?stateid=' + this.selectedstateg + '&cityid=' + this.selectedcityg + '&schoolid=' + this.selectedschoolg
      + '&fromdate=' + this.s_date + '&todate=' + this.e_date + '&status=' + this.selectedtype + '&classid=' + this.selectedclassg +
      '&streamid=' + this.selectedstreamg + '&examid=' +this.selectedtype, options).subscribe(
        (data) => {

          

          this.loader.stop();

          this.SelfAnalysisData = [];

          this.SelfAnalysisData = data;

          this.page = 1;

          if (this.SelfAnalysisData.Status == true)
          {
            this.SelfAnalysisDataDetail = this.SelfAnalysisData.testdata;
            this.loader.stop();
          }
          else
          {
            this.SelfAnalysisDataDetail = [];
            this.loader.stop();
          }
        }
      )
  }


  getAnalysisReportDetail(studentid:number) {

    this.stuid = studentid;

    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();

    this.http.get('api/reports/getSelfAnalysisTestData?studentid=' + studentid, options).subscribe(
        (data) => {

          

          this.loader.stop();

          this.SelfAnalysisDetail = [];

          this.SelfAnalysisDetail = data;

          this.page1 = 1;

          if (this.SelfAnalysisDetail.Status == true)
          {
            this.SelfAnalysisDetailData = this.SelfAnalysisDetail.data;
            this.loader.stop();
          }
          else
          {
            this.SelfAnalysisDetailData = [];
            this.loader.stop();
          }
        }
      )
  }



  
  setpageno() {
    this.page = 1;
  }

  BindState() {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.statedat = [];

      var tmpclass: any = [];
      this.http.post('api/LiveWebinar/Bindstate', options).subscribe(
        (data) => {
          this.statedat = data;
          if (this.statedat.Status == true) {
            this.StateData = this.statedat.data;
          }
          else {
            this.StateData = this.statedat.data;
          }
        }
      )
    }

   BindCityg() {
      
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      this.citdata = [];
      var body = {

        "stateid": this.selectedstateg
      }
      var tmpclass: any = [];
      this.http.post('api/addstudentpartner/BindCity', body, options).subscribe(

        (data) => {
          this.citdata = data;
          if (this.citdata.Status == true) {
            this.CityData = this.citdata.data;
          }
          else {
            this.CityData = this.citdata.data;
          }
        }
      )
    }

 

  BindSchoolg() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.schdata = [];
    var body = {

      "stateid": this.selectedstateg,
      "cityid": this.selectedcityg
    }
    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/BindSchool', body, options).subscribe(

      (data) => {
        this.schdata = data;
        if (this.schdata.Status == true) {
          this.SchoolData = this.schdata.data;
        }
        else {
          this.SchoolData = this.schdata.data;
        }
      }
    )
  }



  BindClass() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.classdat = [];

    var tmpclass: any = [];
    this.http.post('api/reports/Bindclass', options).subscribe(

      (data) => {
        this.classdat = data;
        if (this.classdat.Status == true) {
          this.ClassData = this.classdat.data;
        }
        else {
          this.ClassData = this.classdat.data;
        }
      }
    )
  }



  BindStream() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.streamdat = [];

    var tmpclass: any = [];
    this.http.post('api/reports/BindStream', options).subscribe(

      (data) => {
        
        this.streamdat = data;
        if (this.streamdat.Status == true) {
          this.StreamData = this.streamdat.data;
        }
        else {
          this.StreamData = this.streamdat.data;
        }
      }
    )
  }









  GetCoachList() {
    

    if (this.selectedtopic == 0 || this.selectedtopic == undefined) {
      this.selectedtopic = 0;
    }
    if (this.selectedcoach == 0 || this.selectedcoach == undefined) {
      this.selectedcoach = 0;
    }



    if (this.SelectedDate29 == undefined) {
      this.s_date = "1900-01-01";
    }
    else {
      this.s_date = this.SelectedDate29.toISOString().slice(0, 10);

    }
    if (this.SelectedEndDate30 == undefined) {
      this.e_date = "1900-01-01";
    }
    else {
      this.e_date = this.SelectedEndDate30.toISOString().slice(0, 10);
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    var a;
    var tmpclass: any = [];
    this.http.get('api/reports/GetCoachData?coachtype=' + this.selectedtopic + '&coach=' + this.selectedcoach + '&startdate=' + this.s_date.toString() + '&enddate=' + this.e_date.toString(), options).subscribe(
      (data) => {
        
        this.getcoachdata = data;
        this.GetcoachDetails = this.getcoachdata.data;
      }
    )
  }


  GetTestlink()
  {
    //this.stuid
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('/api/reports/IsTestSubmited?studentid=' + this.stuid, options).subscribe(
      (data) => {

        

        this.TestData = data;

        if (this.TestData.status == true) {
          this.TestDataDetail = this.TestData.data;
        }
        else {
          this.TestDataDetail = [];
        }
        

        if (this.TestDataDetail.length != 0 && this.TestDataDetail.pdflink != '')
        {
          this.ViewPDF(this.TestDataDetail.pdflink);

        }
        else { this.viewpdflink = ''; this.loader.stop(); }

        //this.loader.stop();

      }
    )
  }



  ViewPDF(link: string)
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var data = {
      "filelink": link
    }
    let body = JSON.stringify(data);
    this.loader.start();
    this.http.post('/api/reports/GetFileDetailsWithCertificate', body, { headers: headers, responseType: 'blob' }).subscribe(
      (data) => {

        this.downloadData = data;
        this.viewpdflink = this.sanitize.bypassSecurityTrustResourceUrl(URL.createObjectURL(data));
        this.loader.stop();

      }
    )
  }

 


  ResetAnalysisReport() {
    this.selectedstateg = 0;
    this.selectedschoolg = 0;
    this.selectedcityg = 0;
    this.selectedclassg = 0;
    this.selectedstreamg = 0;
    this.SelectedDate9 = null;
    this.SelectedEndDate10 = null;
    this.selectedtype = -1;
    this.searchTerm = '';
    this.getAnalysisReport();
  }

}
