/// <reference path="../registrationreport/registrationreport.component.ts" />
import { Component, OnInit, Input, PipeTransform, Pipe } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService,SPINNER  } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationReportManager } from '../RegistrationReport/registrationreport.component';

declare var $: any;

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css']
})
export class WelcomeManager implements OnInit {

  public searchTerm: string = "";

      public s_date: any;
      public e_date: any;

      public readstatus: boolean = true;
      public applystatus: boolean = false;

      public page1=1;
      public pageSize1 = 10;

      public page2 = 1;
      public pageSize2 = 10;

      public SelectedDate: any;
      public SelectedEndDate: any;

      public multi: object[];
      public multi1: object[];
      public multi2: object[];
      public multi3: object[];
      public view: any[] = [500, 300];
      public showXAxis = true;
      public showYAxis = true;
      public gradient = false;
      public showLegend = true;
      public showXAxisLabel = true;
      public xAxisLabel: "Month";
      public showYAxisLabel = true;
      public yAxisLabel: "Subscription";
      public graphDataChart: any = [];
      legendTitle: string = 'Type';
      public colorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
      };

      public colorScheme1 = {
        domain: ['#A10A28','#C7B42C','#AAAAAA','#5AA454']
      };


      public helpcolorScheme = {
        domain: ['#A10A28', '#C7B42C', '#AAAAAA', '#5AA454']
      };


      cardColor: string = "#233737";


      public name_d:string='';      
      public value_d:string='';      

      //Pie chart Configuration


      piesingle: object[];
      pieview: any[] = [1000, 250];
      piegradient: boolean = true;
      pieshowLegend: boolean = false;
      pieshowLabels: boolean = true;
      pieisDoughnut: boolean = false;
      pielegendPosition: string = 'below';

      piecolorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
      };


      //Card Chart config

      helpsingle: object[];
      helpview: any[] = [1090, 200];


      public clickeddata: any = [];

      public clickeddataDetail:any=[];

      buttontype: string = 'Read';

      spinnerType = SPINNER.rectangleBounce;
      monthwisegraphdata:any=[];
      regcountmonthwise:number=0;
      regcountstatewise: number = 0;

      year: string = '';

      startdate: string = '';
      enddate: string = '';

    constructor( private http: HttpClient, private router: Router, private localstorage: LocalStorageService, private toaster: ToastrService,
      private loader: NgxUiLoaderService, private config: NgbDatepickerConfig, private regReport: RegistrationReportManager)
    {

      const currentDate = new Date();
      config.maxDate = { year: currentDate.getFullYear(), month: currentDate.getMonth(), day: currentDate.getDate() };
      config.outsideDays = 'hidden';

    }
    ngOnInit() {
      this.GetDashboardData();      
    }

  setPageno() {
    this.page1 = 1;
    this.page2 = 1;
  }

  GetDashboardData()
    {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      this.loader.start();

      this.http.get('api/reports/GetDashboardData', options).subscribe(
        (data) => {

          

          this.loader.stop();

          this.monthwisegraphdata = data;

          this.colorScheme.domain = [];
          this.piecolorScheme.domain = [];
          this.helpcolorScheme.domain = [];

          //for color code set dynamically
          for (var i = 0; i <= 35; i++) {
            var colorcode = '#' + Math.floor(Math.random() * 16777215).toString(16);
            this.colorScheme.domain[i] = colorcode;
          }

          //for color code set dynamically
          for (var i = 0; i <= 25; i++) {
            var colorcode = '#' + Math.floor(Math.random() * 16777215).toString(16);
            this.colorScheme1.domain[i] = colorcode;
          }

          this.multi1 = this.monthwisegraphdata.statewise;

          this.multi = this.monthwisegraphdata.monthwise;

          this.regcountmonthwise = this.monthwisegraphdata.monthwisecount;

          this.regcountstatewise = this.monthwisegraphdata.statehwisecount;

           //for color code set dynamically
          for (var i = 0; i <= 25; i++) {
            var colorcode = '#' + Math.floor(Math.random() * 16777215).toString(16);
            this.piecolorScheme.domain[i] = colorcode;
          }

          for (var i = 0; i <= 10; i++) {
            var colorcode = '#' + Math.floor(Math.random() * 16777215).toString(16);
            this.helpcolorScheme.domain[i] = colorcode;
          }

          this.piesingle = this.monthwisegraphdata.clickedcountdata;

          this.helpsingle = this.monthwisegraphdata.clickedHelpcountdata;

        }
      )
    }

  GetDashboardDataDatewise()
    {
      

           if (this.SelectedDate == undefined) {
            this.s_date = "1900-01-01";
          }
          else {
          //this.s_date = this.SelectedDate.toISOString().slice(0, 10);
             this.s_date =this.SelectedDate.year + "-" + this.SelectedDate.month + "-" + this.SelectedDate.day
          }
          if (this.SelectedEndDate == undefined) {
          this.e_date = "1900-01-01";
          }
          else {
           // this.e_date = this.SelectedEndDate.toISOString().slice(0, 10);
            this.e_date = this.SelectedEndDate.year + "-" + this.SelectedEndDate.month + "-" + this.SelectedEndDate.day
          }


        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };

        this.loader.start();

        this.http.get('api/reports/GetDashboardDatadatewise?fromdate='+this.s_date+'&todate='+this.e_date, options).subscribe(
          (data) => {

          

          this.loader.stop();

          this.monthwisegraphdata = data;

          this.colorScheme.domain = [];

          //for color code set dynamically
          for (var i = 0; i <= 35; i++) {
            var colorcode = '#' + Math.floor(Math.random() * 16777215).toString(16);
            this.colorScheme.domain[i] = colorcode;
          }

          //for color code set dynamically
          for (var i = 0; i <= 35; i++) {
            var colorcode = '#' + Math.floor(Math.random() * 16777215).toString(16);
            this.colorScheme1.domain[i] = colorcode;
          }

          this.multi2 = this.monthwisegraphdata.statewise;

          this.multi3 = this.monthwisegraphdata.monthwise;


        }
      )
    }

   getClickedData(clicktype:string){

      this.loader.start();

      if (clicktype == 'Read') {
        this.readstatus = true;
        this.applystatus = false;
        this.buttontype = 'Read';
      }

      if (clicktype == 'Apply') {
        this.applystatus = true;
        this.readstatus = false;
        this.buttontype = 'Apply';
      }

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };
 
            this.http.get('api/reports/GetClickedData?clickedtype_d='+clicktype, options).subscribe(
            (data) => {

              

              this.loader.stop();

              this.clickeddata=[];
              this.clickeddataDetail=[];

              this.clickeddata=data;

               if(this.clickeddata.Status==true)
               {

                    for (var i = 0; i <= 25; i++) {
                        var colorcode = '#' + Math.floor(Math.random() * 16777215).toString(16);
                        this.piecolorScheme.domain[i] = colorcode;
                      }


                   this.clickeddataDetail=this.clickeddata.data;
                   this.piesingle=this.clickeddataDetail;
               }
               else{
                    this.clickeddataDetail=[];
               }

            }
          )

        }


  onSelectreggraph(data): void
  {
    
    this.localstorage.set("pageid", 44);

    if (data.name.slice(0, 3) == 'Jan') {

      this.year = '20' + data.name.slice(3, 5);
      this.startdate = this.year + '-01' + '-01';


      var newDate = new Date(this.startdate);
      var lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


      this.enddate = lastDayOfMonth.toLocaleDateString().split('/')[2] + '-' + lastDayOfMonth.toLocaleDateString().split('/')[0] + '-' +
        lastDayOfMonth.toLocaleDateString().split('/')[1];

      this.localstorage.set('startdate', this.startdate);
      this.localstorage.set('enddate', this.enddate);

      this.router.navigate(['/registrationreport']);
      
    }
    if (data.name.slice(0, 3) == 'Feb') {

      this.year = '20' + data.name.slice(3, 5);
      this.startdate = this.year + '-02' + '-01';

      var newDate = new Date(this.startdate);
      var lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


      this.enddate = lastDayOfMonth.toLocaleDateString().split('/')[2] + '-' + lastDayOfMonth.toLocaleDateString().split('/')[0] + '-' +
        lastDayOfMonth.toLocaleDateString().split('/')[1];

      this.localstorage.set('startdate', this.startdate);
      this.localstorage.set('enddate', this.enddate);

      this.router.navigate(['/registrationreport']);

    }
    if (data.name.slice(0, 3) == 'Mar') {

      this.year = '20' + data.name.slice(3, 5);
      this.startdate = this.year + '-03' + '-01';

      var newDate = new Date(this.startdate);
      var lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


      this.enddate = lastDayOfMonth.toLocaleDateString().split('/')[2] + '-' + lastDayOfMonth.toLocaleDateString().split('/')[0] + '-' +
        lastDayOfMonth.toLocaleDateString().split('/')[1];

      this.localstorage.set('startdate', this.startdate);
      this.localstorage.set('enddate', this.enddate);

      this.router.navigate(['/registrationreport']);
    }
    if (data.name.slice(0, 3) == 'Apr') {

      this.year = '20' + data.name.slice(3, 5);
      this.startdate = this.year + '-04' + '-01';

      var newDate = new Date(this.startdate);
      var lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


      this.enddate = lastDayOfMonth.toLocaleDateString().split('/')[2] + '-' + lastDayOfMonth.toLocaleDateString().split('/')[0] + '-' +
        lastDayOfMonth.toLocaleDateString().split('/')[1];

      this.localstorage.set('startdate', this.startdate);
      this.localstorage.set('enddate', this.enddate);

      this.router.navigate(['/registrationreport']);
      
    }
    if (data.name.slice(0, 3) == 'May') {

      this.year = '20' + data.name.slice(3, 5);
      this.startdate = this.year + '-05' + '-01';

      var newDate = new Date(this.startdate);
      var lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


      this.enddate = lastDayOfMonth.toLocaleDateString().split('/')[2] + '-' + lastDayOfMonth.toLocaleDateString().split('/')[0] + '-' +
        lastDayOfMonth.toLocaleDateString().split('/')[1];

      this.localstorage.set('startdate', this.startdate);
      this.localstorage.set('enddate', this.enddate);

      this.router.navigate(['/registrationreport']);
    }
    if (data.name.slice(0, 3) == 'Jun') {

      this.year = '20' + data.name.slice(3, 5);
      this.startdate = this.year + '-06' + '-01';

      var newDate = new Date(this.startdate);
      var lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


      this.enddate = lastDayOfMonth.toLocaleDateString().split('/')[2] + '-' + lastDayOfMonth.toLocaleDateString().split('/')[0] + '-' +
        lastDayOfMonth.toLocaleDateString().split('/')[1];

      this.localstorage.set('startdate', this.startdate);
      this.localstorage.set('enddate', this.enddate);

      this.router.navigate(['/registrationreport']);
    }
    if (data.name.slice(0, 3) == 'Jul') {

      this.year = '20' + data.name.slice(3, 5);
      this.startdate = this.year + '-07' + '-01';

      var newDate = new Date(this.startdate);
      var lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


      this.enddate = lastDayOfMonth.toLocaleDateString().split('/')[2] + '-' + lastDayOfMonth.toLocaleDateString().split('/')[0] + '-' +
        lastDayOfMonth.toLocaleDateString().split('/')[1];

      this.localstorage.set('startdate', this.startdate);
      this.localstorage.set('enddate', this.enddate);

      this.router.navigate(['/registrationreport']);
      
    }

    if (data.name.slice(0, 3) == 'Aug') {

      this.year = '20' + data.name.slice(3, 5);
      this.startdate = this.year + '-08' + '-01';

      var newDate = new Date(this.startdate);
      var lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


      this.enddate = lastDayOfMonth.toLocaleDateString().split('/')[2] + '-' + lastDayOfMonth.toLocaleDateString().split('/')[0] + '-' +
        lastDayOfMonth.toLocaleDateString().split('/')[1];

      this.localstorage.set('startdate', this.startdate);
      this.localstorage.set('enddate', this.enddate);

      this.router.navigate(['/registrationreport']);
      
    }
    if (data.name.slice(0, 3) == 'Sep') {

      this.year = '20' + data.name.slice(3, 5);
      this.startdate = this.year + '-09' + '-01';

      var newDate = new Date(this.startdate);
      var lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


      this.enddate = lastDayOfMonth.toLocaleDateString().split('/')[2] + '-' + lastDayOfMonth.toLocaleDateString().split('/')[0] + '-' +
        lastDayOfMonth.toLocaleDateString().split('/')[1];

      this.localstorage.set('startdate', this.startdate);
      this.localstorage.set('enddate', this.enddate);

      this.router.navigate(['/registrationreport']);
      
    }
    if (data.name.slice(0, 3) == 'Oct') {

      this.year = '20' + data.name.slice(3, 5);
      this.startdate = this.year + '-10' + '-01';


      var newDate = new Date(this.startdate);
      var lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


      this.enddate = lastDayOfMonth.toLocaleDateString().split('/')[2] + '-' + lastDayOfMonth.toLocaleDateString().split('/')[0] + '-' +
        lastDayOfMonth.toLocaleDateString().split('/')[1];

      this.localstorage.set('startdate', this.startdate);
      this.localstorage.set('enddate', this.enddate);

      this.router.navigate(['/registrationreport']);
      
    }
    if (data.name.slice(0, 3) == 'Nov') {

      this.year = '20' + data.name.slice(3, 5);
      this.startdate = this.year + '-11' + '-01';


      var newDate = new Date(this.startdate);
      var lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


      this.enddate = lastDayOfMonth.toLocaleDateString().split('/')[2] + '-' + lastDayOfMonth.toLocaleDateString().split('/')[0] + '-' +
        lastDayOfMonth.toLocaleDateString().split('/')[0];

      this.localstorage.set('startdate', this.startdate);
      this.localstorage.set('enddate', this.enddate);

      this.router.navigate(['/registrationreport']);
    }
    if (data.name.slice(0, 3) == 'Dec') {

      this.year = '20' + data.name.slice(3, 5);
      this.startdate = this.year + '-12' + '-01';

      var newDate = new Date(this.startdate);
      var lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


      this.enddate = lastDayOfMonth.toLocaleDateString().split('/')[2] + '-' + lastDayOfMonth.toLocaleDateString().split('/')[0] + '-' +
        lastDayOfMonth.toLocaleDateString().split('/')[1];

      this.localstorage.set('startdate', this.startdate);
      this.localstorage.set('enddate', this.enddate);

      this.router.navigate(['/registrationreport']);
    }
      
  }


    onSelect(data): void
    {
        
       if(data.name=='Competition & Scholarship' && data.value>0)
       {
          
          this.name_d=data.name;
          this.value_d=data.value;

          this.loader.start();

          $('#buttonid').click();


          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          let options = { headers: headers };

         this.loader.start();

         this.http.get('api/reports/GetClickedDetailsData?clickedtype_d=' + this.name_d + '&clickedtype=' + this.buttontype, options).subscribe(
            (data) => {

              

              this.loader.stop();

              this.clickeddata=data;

             this.clickeddataDetail = [];

               if(this.clickeddata.Status==true)
               {
                   this.clickeddataDetail=this.clickeddata.data;
               }
               else{
                    this.clickeddataDetail=[];
               }

            }
          )
          
       }

      if(data.name=='Admission Alerts' && data.value>0)
       {
          
          this.name_d=data.name;
          this.value_d=data.value;

          this.loader.start();

          $('#buttonid').click();


          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          let options = { headers: headers };

        this.loader.start();

        this.http.get('api/reports/GetClickedDetailsData?clickedtype_d=' + this.name_d + '&clickedtype=' + this.buttontype, options).subscribe(
            (data) => {

              

              this.loader.stop();

              this.clickeddata=data;

               this.clickeddataDetail = [];

               if(this.clickeddata.Status==true)
               {
                   this.clickeddataDetail=this.clickeddata.data;
               }
               else{
                    this.clickeddataDetail=[];
               }

            }
          )
          
       }

      if(data.name=='Summer School' && data.value>0)
       {
          
          this.name_d=data.name;
          this.value_d=data.value;

          this.loader.start();

          $('#buttonid').click();

        this.loader.start();
          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          let options = { headers: headers };

         

        this.http.get('api/reports/GetClickedDetailsData?clickedtype_d=' + this.name_d + '&clickedtype=' + this.buttontype, options).subscribe(
            (data) => {

              

              this.loader.stop();

              this.clickeddata=data;

               this.clickeddataDetail = [];

               if(this.clickeddata.Status==true)
               {
                   this.clickeddataDetail=this.clickeddata.data;
               }
               else{
                    this.clickeddataDetail=[];
               }

            }
          )
          
       }


    }

    onSelectHelpData(data): void {
    
    if (data.name == 'SOP' && data.value > 0) {

      this.name_d = data.name +' Professional Seek Help';
      this.value_d = data.value;

      this.loader.start();

      $('#buttonhelpid').click();


      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      this.loader.start();

      this.http.get('api/reports/GetClickedDetailsData?clickedtype_d=' + this.name_d + '&clickedtype=' + this.buttontype, options).subscribe(
        (data) => {

          

          this.loader.stop();

          this.clickeddata = data;

          this.clickeddataDetail = [];

          if (this.clickeddata.Status == true) {
            this.clickeddataDetail = this.clickeddata.data;
          }
          else {
            this.clickeddataDetail = [];
          }

        }
      )

    }

    if (data.name == 'Personal Essay' && data.value > 0) {

      this.name_d = data.name + ' Professional Seek Help';
      this.value_d = data.value;

      this.loader.start();

      $('#buttonhelpid').click();


      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      this.loader.start();

      this.http.get('api/reports/GetClickedDetailsData?clickedtype_d=' + this.name_d + '&clickedtype=' + this.buttontype, options).subscribe(
        (data) => {

          

          this.loader.stop();

          this.clickeddata = data;

          this.clickeddataDetail = [];

          if (this.clickeddata.Status == true) {
            this.clickeddataDetail = this.clickeddata.data;
          }
          else {
            this.clickeddataDetail = [];
          }

        }
      )

    }

    if (data.name == 'Portfolio' && data.value > 0) {

      this.name_d = data.name + ' Professional Seek Help';
      this.value_d = data.value;

      this.loader.start();

      $('#buttonhelpid').click();

      this.loader.start();
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };



      this.http.get('api/reports/GetClickedDetailsData?clickedtype_d=' + this.name_d + '&clickedtype=' + this.buttontype, options).subscribe(
        (data) => {

          

          this.loader.stop();

          this.clickeddata = data;

          this.clickeddataDetail = [];

          if (this.clickeddata.Status == true) {
            this.clickeddataDetail = this.clickeddata.data;
          }
          else {
            this.clickeddataDetail = [];
          }

        }
      )

    }

  }

}
