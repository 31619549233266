import { Component, OnInit, Input, PipeTransform, Pipe } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
//import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgxUiLoaderService,SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbTimepickerConfig, NgbTimeStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import isURL from 'validator/lib/isURL';
import { PermissionService } from '../../../../../permission.service';

@Component({
  selector: 'app-queryreport',
  templateUrl: './queryreport.component.html',
  styleUrls: ['./otherreport.css'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, NgbTimepickerConfig]

})
export class QueryReportManager implements OnInit {


  spinnerType = SPINNER.rectangleBounce;

    public page: number = 1;
    public pageSize: number = 8;

    public page1: number = 1;
    public pageSize1: number = 10;
  
    searchTerm: string = '';

    public selectedstateg: number = 0;
    public selectedcityg: number = 0;
    public selectedclassg: number = 0;
    public selectedstreamg: number = 0;
    public selectedTypeDetail: string = 'Gallery';
  
    public selectedPaperType: number = 0;

    public streamdat: any = [];
    public classdat: any = [];
    public citdata: any = [];
    public statedat: any = [];

    public ClassData: any = [];
    public StreamData: any = [];
    public CityData: any = [];
    public StateData: any = [];

    public showTextBox = false;

    QueryData: any = [];
    QueryDetailData: any = [];

    clickedstudentid: number = 0;

    public answerByAdmin: string = '';

    public stuimage:string='';

    AnswerData: any = [];

    studentname:string='';

    selectedType:number =1;

    QueryReport: any = [];
    QueryDetailDataReport: any = [];

    selectedschoolg: number = 0;

    schdata: any = [];
    SchoolData: any = [];

    QueryReportList: any = [];
    QueryDetailDataReportList: any = [];

  pipe = new DatePipe('en-US');

  public careernames: string = "";
  public dropdownSettings = {};
  public careerData: any;
  public resData: any;
  public selectedItems: any;
  public stu_question: string = "";
  public stu_answer: string = "";
  public keywords: string = "";
  public queryid: number = 0;
  public stu_answers: any;

  public ckconfig: any;
  public isAnswerUrl: boolean = false;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor(private http: HttpClient, private permission: PermissionService, private router: Router, private localstorage: LocalStorageService,
    private toaster: ToastrService, private loader: NgxUiLoaderService, config: NgbTimepickerConfig, private config1: NgbDatepickerConfig) {
    config.seconds = false;
    config.spinners = false;
    config.meridian = true;
  }
  ngOnInit()
  {
    this.GetActions();
    this.getQueryReport();
    this.BindState();
    this.BindClass();
    this.BindStream();
    this.GetCarrerLists();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'careerid',
      textField: 'careername',
      defaultOpen: true,
      enableCheckAll: false,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 7,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: true     
    };
 
  }

  SetStyle() {
    if ($('#ddlqueryreply').hasClass('show')) {
      $('#ddlqueryreply').removeClass('show');
    }
    else {
      $('#ddlqueryreply').addClass('show');
    }
  }

  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  GetCarrerLists() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.loader.start();
    this.http.post('api/collegerepository/BindCareer', options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        this.careerData = this.resData.data;
      }
    )
  }


  onItemSelect() {
    this.careernames = "";

    for (var i = 0; i < this.selectedItems.length; i++) {
      if (this.careernames === "") {
        this.careernames = this.selectedItems[i].careername;
      }
      else {
        this.careernames = this.careernames + ',' + this.selectedItems[i].careername;
      }
    }
  }

  _setQuestionAnswer(data: any) {
    this.queryid = data.QueryId;
    this.stu_question = data.Question;
    this.stu_answers = data.Answers;
  }

  removeAnswer(i) {

    if (this.stu_answers.length >1)
      this.stu_answers.splice(i,1);
  }

  ResetFAQ() {
    this.careernames = "";
    this.keywords = "";
    this.selectedItems = [];
    this.stu_answers = [];
    this.getQueryReportDetail(this.clickedstudentid, this.studentname);

  }

  Add() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.stu_answer = ""

    if (this.stu_answers.length > 1) {
      Swal.fire("", "You can add only one answer with associated question", "error");
      return;
    }
    else {
      if (this.stu_answers.length == 0) {
        Swal.fire("", "Please Enter Query Answer", "error");
        return;
      }
      else {
        if (this.stu_answers[0].Answer == "") {
          Swal.fire("", "Please Enter Query Answer", "error");
          return;
        }
        else {
          this.stu_answer = this.stu_answers[0].Answer
        }
      }
    }

    if (this.careernames == "" || this.careernames == undefined) {
      Swal.fire("", "Please Select Career", "error");
      return;
    }

    var data = {
      "queryid": this.queryid,
      "question": this.stu_question,
      "answer": this.stu_answer,
      "careernamelists": this.careernames,
      "keyword":this.keywords
    }

    let body = JSON.stringify(data);

    this.http.post('api/reports/savefaq', body, options).subscribe(
      (data) => {
        this.resData = data;

        if (this.resData.status == true) {
          $('#faq-modal-close').click();
          Swal.fire("", this.resData.message, "success");
          return;
        }
        else {
          Swal.fire("", this.resData.message, "warning");
        }
      }
    )
  }


  /*name of the excel-file which will be downloaded. */
  fileName = 'QurySheet.xlsx';

  exportexcel(tagid: string): void {
    
    /* table id is passed over here */
    let element = document.getElementById(tagid);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }

  setType() {

    if(this.selectedType == 0 ) this.selectedTypeDetail = 'List' ;

  }

  getQueryReport() {

    if (this.selectedTypeDetail != 'List')
    {

      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };

      this.statedat = [];
      this.loader.start();

      
      this.http.get('api/reports/getQueryReportsDetail?classid=' + this.selectedclassg + '&streamid=' + this.selectedstreamg + '&stateid=' + this.selectedstateg +
        '&cityid=' + this.selectedcityg + '&typeid_d=' + this.selectedType + '&schoolid=' + this.selectedschoolg + '&userid=' + this.localstorage.get('userid'), options).subscribe(

          (data) => {

            this.QueryData = data;

            this.loader.stop();

            this.page = 1;

            if (this.QueryData.Status == true) {
              this.QueryDetailData = this.QueryData.data;
              this.loader.stop();
            }
            else {
              this.QueryDetailData = [];
              this.loader.stop();
            }
          }
        )
    }

    else
    {
      this.GetStudentQueryDetail('List');
    }
  }

  getQueryReportDetail(stuid: number,studname:string)
  {

    this.clickedstudentid = stuid;

    this.studentname=studname;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.statedat = [];

    this.loader.start();

    

    this.http.get('api/reports/getQueryDetailReport?studentid=' + stuid, options).subscribe(

        (data) => {

          this.QueryReport = data;

          this.loader.stop();

          this.page = 1;

        if (this.QueryReport.Status == true)
        {

            this.QueryDetailDataReport = this.QueryReport.querydata;

            this.loader.stop();

            this.stuimage=this.QueryDetailDataReport[0].profilefullpath;
        }
          else {
          this.QueryDetailDataReport = [];
            this.loader.stop();
          }
        }
      )
  }

  StringToUrl(msg: string): any {
    if (isURL(msg) == true) {
      return `<a style="cursor:pointer;" target="_blank" href=` + msg + `>` + msg + `</a>`;
    }
    else {
      return msg;
    }
  }

  setTextBoxStatus(i: number) {

    

    if ($('#textbox_' + i).hasClass('hide'))
    {
      $('#textbox_' + i).addClass('show');
      $('#textbox_' + i).removeClass('hide');
    }

    else
    {
      $('#textbox_' + i).removeClass('show');
      $('#textbox_' + i).addClass('hide');
    }

  }


  setReplyToQuestion(queryid:number) {

    

    if(this.answerByAdmin==''){ this.toaster.error("Please add some content."); return; }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
  
    var body = {
      "QueryId": queryid,
      "answer": this.answerByAdmin,
      "UserId": this.localstorage.get('userid')
    }

    this.loader.start();

    this.http.post('api/reports/SetAnswerByAdmin', body, options).subscribe(

      (data) => {

        this.loader.stop();

        this.AnswerData = data;

        if (this.AnswerData.Status == true)
        {
          this.answerByAdmin='';
          this.getQueryReportDetail(this.clickedstudentid, this.studentname);
        }
        else
        {
          this.answerByAdmin='';
          this.toaster.error("Something went wrong, please try again.");
          return;
        }
      }
    )

  }


  setPageno() {
    this.page = 1;
  }


  BindClass() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.classdat = [];

    var tmpclass: any = [];
    this.http.post('api/addstudent/Bindclass', options).subscribe(

      (data) => {
        this.classdat = data;
        if (this.classdat.Status == true) {
          this.ClassData = this.classdat.data;
        }
        else {
          this.ClassData = this.classdat.data;
        }
      }
    )
  }

  BindStream() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.streamdat = [];

    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/BindStream', options).subscribe(

      (data) => {
        
        this.streamdat = data;
        if (this.streamdat.Status == true) {
          this.StreamData = this.streamdat.data;
        }
        else {
          this.StreamData = this.streamdat.data;
        }
      }
    )
  }

  BindCityg() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.citdata = [];
    var body = {
      "stateid": this.selectedstateg
    }
    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/BindCity', body, options).subscribe(

      (data) => {
        this.citdata = data;
        if (this.citdata.Status == true) {
          this.CityData = this.citdata.data;
        }
        else {
          this.CityData = this.citdata.data;
        }
      }
    )
  }

  BindState() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.statedat = [];

    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/Bindstate', options).subscribe(

      (data) => {
        this.statedat = data;
        if (this.statedat.Status == true) {
          this.StateData = this.statedat.data;
        }
        else {
          this.StateData = this.statedat.data;
        }
      }
    )
  }

  BindSchoolg()
  {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.schdata = [];
    var body = {

      "stateid": this.selectedstateg,
      "cityid": this.selectedcityg
    }
    var tmpclass: any = [];
    this.http.post('api/addstudentpartner/BindSchool', body, options).subscribe(

      (data) => {
        this.schdata = data;
        if (this.schdata.Status == true) {
          this.SchoolData = this.schdata.data;
        }
        else {
          this.SchoolData = this.schdata.data;
        }
      }
    )
  }



  setpagenolist() { this.page1 = 1; }

  GetStudentQueryDetail(type: string) {

    if (type == 'Gallery' && this.selectedType == 0) { this.toaster.error("Please choose type to list "); return; }

    else {

      if (type == 'List') {

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };
        this.statedat = [];

        this.loader.start();

        

        this.http.get('api/reports/getQueryDetailReportInList?classid=' + this.selectedclassg + '&streamid=' + this.selectedstreamg + '&stateid=' + this.selectedstateg +
          '&cityid=' + this.selectedcityg + '&typeid_d=' + this.selectedType + '&schoolid=' + this.selectedschoolg + '&userid=' + this.localstorage.get('userid'), options).subscribe(

            (data) => {

              this.QueryReportList = data;

              this.loader.stop();

              this.page1 = 1;

              if (this.QueryReportList.Status == true) {

                this.QueryDetailDataReportList = this.QueryReportList.querydata;

                this.loader.stop();
              }
              else {
                this.QueryDetailDataReportList = [];
                this.loader.stop();
              }
            }
          )

      }
    }

  }


  ResetQueryReport() {
   
    this.selectedstateg = 0;
    this.selectedcityg = 0;
    this.selectedclassg = 0;
    this.selectedstreamg = 0;
    this.searchTerm = '';
    this.showTextBox = false;
    this.answerByAdmin='';
    this.selectedType=1;
    this.getQueryReport();
  }



}
