import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import * as Icons from '../ ../../../../../../assets/statichtml/icon/anticons.json';
import { DomSanitizer } from '@angular/platform-browser';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-menumaster',
  templateUrl: './menumaster.component.html'
})
export class MenuMaster implements OnInit {

  public spinnerType = SPINNER.rectangleBounce;

  public resData: any = [];
  public editData: any = [];
  public menusData: any = [];
  public pagesData: any = [{ "pageid": 1, "pagetitle": "page title 1" }, { "pageid": 2, "pagetitle": "page title 2" }];
  public submenuData: any = [];
  public selectedPages: any = [];
  public selectedItems: any = [];
  public finalData: any = [];
 

  public iconsData: any = (Icons as any).default;

  public page: number = 1;
  public pageSize: number = 10;

  public dropdownSettings = {};

  public search: string = "";

  public menuid: number = 0;
  public menuname: string = "";
  public isrouting: boolean = false;
  public pagerouting: string = "";
  public pagetitle: string = "";
  public iconname: string = "";
  public icontitle: string = "";

  public btntext: string = "Save";

  constructor(
    private http: HttpClient,
    private sanitize: DomSanitizer,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService) {

  }
  ngOnInit() {
    this.AddControlsData();
    this.FilterIconsData();
    this.BindData();
    this.RoutingCheck();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'pageid',
      textField: 'pagetitle',
      enableCheckAll: false,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 1,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
  }

  AddControlsData() {
    this.submenuData.push({ "submenuid": 0, "submenuname": "", "selectedPages": [] });
    console.log(this.submenuData)
  }

  Remove(_Index: number) {
    this.submenuData.splice(_Index, 1);
  }

  onMemuItemSelect() {

  }

  onSubmenuItemSelect() {

  }

  onDeSelect() {

  }

  FilterIconsData() {
    _.filter(this.iconsData, { "id": this.iconsData.forEach(item => { item.id = `<i class="anticon anticon-` + item.id + `"></i>` }) });
  }


  public setIconName(_IconName: string, _IconTitle: string) {
    this.iconname = _IconName;
    this.icontitle = _IconTitle;
  }

  public RoutingCheck() {
    if (!this.isrouting) {
      $('#pageroutingid').prop('readonly', true);
    }
    else {
      $('#pageroutingid').prop('readonly', false);
    }
  }

  BindData() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/menus/GetMenuDetails', options).subscribe(
      (data) => {
        
        this.resData = data;

        if (this.resData.status == true) {
          this.menusData = this.resData.data;
        }
        else {
          this.menusData = [];
        }
      }
    )
  }

  Reset() {
    this.menuname = "";
    this.iconname = "";
    this.icontitle = "";
    this.pagerouting = "";
    this.isrouting = false;
    this.btntext = "Save";
  }


  Submit() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.iconname == "" || this.iconname == undefined) {
      this.toaster.error('Please Select Icon', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.menuname == "" || this.menuname == undefined) {
      this.toaster.error('Please Enter Menu Title', '', { easeTime: 1000, timeOut: 3000 })
      return;
    }

    if (this.isrouting == true) {
      //if (this.pagerouting == "" || this.pagerouting == undefined) {
      //  this.toaster.error('Please Enter Page Routing', '', { easeTime: 1000, timeOut: 3000 })
      //  return;
      //}

      //if (this.pagetitle == "" || this.pagetitle == undefined) {
      //  this.toaster.error('Please Enter Page Title', '', { easeTime: 1000, timeOut: 3000 })
      //  return;
      //}
    }
    else {
      if (this.selectedItems == [] || this.selectedItems == undefined) {
        this.toaster.error('Please Select Page', '', { easeTime: 1000, timeOut: 3000 })
        return;
      }
      else {
        this.finalData = [];
      }
    }

    var body = {
      "menuid": this.menuid,
      "menuname": this.menuname,
      "iconname": this.iconname,
      "isrouting": this.isrouting== true ? 1 : 0,
      "pagerouting": this.pagerouting,
      "pagedata": this.isrouting == true ? JSON.stringify(this.submenuData) : JSON.stringify(this.selectedItems),
    }

    this.loader.start();
    this.http.post('api/menus/SaveUpdateMenuDetails', body, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        if (this.resData.status == true) {
          
          this.toaster.success(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          this.BindData();
          this.Reset();
          return;
        }
        else {
          this.toaster.error(this.resData.message, '', { easeTime: 1000, timeOut: 3000 });
          return;
        }
      }
    )

  }


  EditMenu(_Id: number) {

    this.btntext = "Update";

    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/menus/EditMenuDetail?menuid=' + _Id, options).subscribe(
      (data) => {
        

        this.resData = data;
        this.editData = this.resData.data;

        this.menuid = this.editData.menuid;
        this.menuname = this.editData.menuname;
        this.iconname = this.editData.iconname;
        var icontitle = this.editData.iconname.toString();
        var str = icontitle.substring(26, icontitle.indexOf('>') - 1);

        this.iconsData.forEach(item => {
          if (item.id === this.editData.iconname.toString()) {
            this.icontitle = item.name;
          }
        });

        this.isrouting = this.editData.isrouting == 1 ? true : false;
        this.RoutingCheck();
        this.pagerouting = this.editData.pagerouting;
      }
    )
  }

  DeleteMenu(_Id: number) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var data =
    {
      "menuid": _Id
    };

    let body = JSON.stringify(data);

    Swal.fire({
      text: 'Are you sure to delete this record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.http.post('api/menus/DeleteMenuDetail', body, options).subscribe(
          (data) => {
            this.resData = data;
            if (this.resData.status == true) {
              Swal.fire("", "Deleted Successfully", "success");
              this.BindData();
              this.Reset();
              return;
            }
            else {
              Swal.fire("", this.resData.message, "warning");
            }
          }
        )
      }
    })
  }
}
