import { Component, OnInit, Input, PipeTransform, Pipe } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

import { find, pull, filter, times, constant, debounce, set, get, keyBy, reduce, cloneDeep, sortedUniq } from 'lodash';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import { PermissionService } from '../../../permission.service';

@Component({
  selector: 'app-lifecoachesreport',
  templateUrl: './lifecoachesreport.component.html',
  styleUrls: ['./lifecoachesreport.css']
})
export class LifeCoachesReportManager implements OnInit {


  spinnerType = SPINNER.rectangleBounce;

  page: number = 1;
  pageSize: number = 10;

  page2: number = 0;
  pageSize2: number = 10;

  searchTerm: string = '';

  selectedShowtype:string='Gallery';

  LifeCoachesData: any = [];
  LifeCoachesDataDetail: any = [];

  Details: any = [];
  GetSaveData: any = [];

  selectedtype: number = 3;
  selectedtopic: number = 0;

  Coachtypedetails: any = [];
  TopicData: any = [];

  Coachdetail: any = [];
  CoachData: any = [];

  Articledetails: any = [];
  GetSaveArticleData: any = [];

  journeydetails: any = [];

  GetSaveJourneyData: any = [];

  resData: any = [];

  docname: any ;
  videourl: any;

  public usertypeids: string = "0";
  public selectedCoachTypeData: any = [];
  public coachdropdownSettings = {};
  public coachData: any;

  public actionsData: any;
  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  constructor(private http: HttpClient, private permission: PermissionService, private router: Router, private localstorage: LocalStorageService, private toaster: ToastrService,
    private loader: NgxUiLoaderService, private config1: NgbDatepickerConfig, private sanitize: DomSanitizer) {

    this.coachData = [
      { "usertypeid": 1, "usertype": "Articles" },
      { "usertypeid": 2, "usertype": "Interviews" },
      { "usertypeid": 3, "usertype": "Life Journey" }]
  }






  ngOnInit()
  {
    //this.GetInterviewDataInPageload();
    this.GetActions();
    this.BindCoachType();
    this.BindAllCoachTypeData();
    //this.BindInterviewData();
    //this.BindArticleData();
    //this.BindJourneyData();

    this.coachdropdownSettings = {
      singleSelection: false,
      idField: 'usertypeid',
      textField: 'usertype',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableCheckAll: true,
      allowSearchFilter: false,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 1,
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
  }


  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }

  /*name of the excel-file which will be downloaded. */
  fileName = 'lifecoachesreports.xlsx';

  exportexcel(tagid: string): void {
    
    /* table id is passed over here */
    let element = document.getElementById(tagid);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }

  OnCoachTypeChange() {
    
    this.usertypeids = "";

    for (var i = 0; i < this.selectedCoachTypeData.length; i++) {
      if (this.usertypeids === "") {
        this.usertypeids = this.selectedCoachTypeData[i].usertypeid.toString();
      }
      else {
        this.usertypeids = this.usertypeids + ',' + this.selectedCoachTypeData[i].usertypeid;
      }
    }

    if (this.selectedCoachTypeData.length == 0) {
      this.usertypeids="0"
      this.BindAllCoachTypeData();
    }
    else
      this.BindAllCoachTypeData();
 
  }

  OnCoachTypeDeselectChange() {
    this.usertypeids = "0";
    this.selectedCoachTypeData = [];
    this.BindAllCoachTypeData();
  }

  OnCoachTypeSelectChange() {
    this.usertypeids = "0";
    this.BindAllCoachTypeData();
  }

  GetInterviewDataInPageload() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.LifeCoachesData = [];
    this.LifeCoachesDataDetail = [];

    this.loader.start();

    this.http.get('api/lifecoachactivity/Bindtabledata', options).subscribe(
      (data) => {
        

        this.loader.stop();

        this.LifeCoachesData = data;

        this.page = 1;

        if (this.LifeCoachesData.Statue == true) { this.LifeCoachesDataDetail = this.LifeCoachesData.data; this.loader.stop(); }
        else { this.LifeCoachesDataDetail = []; this.loader.stop(); }


      }
    )
  }

  setPageDetail() {
    this.page2 = 1;
    this.pageSize2 = 10;
  }

  BindCoachType() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var a;
    var tmpclass: any = [];
    this.http.get('api/lifecoachactivity/BindCoachType', options).subscribe(
      (data) => {
        
        this.Coachtypedetails = data;

        this.TopicData = this.Coachtypedetails.data;

      }
    )
  }

  BindCoach() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    var body = {
      "coachtypeid": this.selectedtopic
    }
    var tmpclass: any = [];
    this.http.post('api/lifecoachactivity/BindCoach', body, options).subscribe(

      (data) => {
        this.Coachdetail = data;
        this.CoachData = this.Coachdetail.data;
      }
    )
  }

  BindAllCoachTypeData() {
    

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.loader.start();
    this.http.get('api/lifecoachactivity/BindAllCoachData?types=' + this.usertypeids + '&topicid=' + this.selectedtopic, options).subscribe(
      (data) => {
        
        this.loader.stop();
        this.resData = data;
        if (this.resData.Statue == true) {
          this.LifeCoachesDataDetail = this.resData.data;
        }
        else {
          this.LifeCoachesDataDetail = [];
        }
      })
  }

  BindArticleData() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };


    this.http.get('api/lifecoachactivity/BindArticletabledata', options).subscribe(
      (data) => {
        
        this.Articledetails = data;
        this.GetSaveArticleData = this.Articledetails.data;
        this.LifeCoachesDataDetail = this.GetSaveArticleData;
        this.loader.stop();

      })

  }

  BindInterviewData() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.Details = [];
    this.GetSaveData = [];

    this.http.get('api/lifecoachactivity/Bindtabledata', options).subscribe(
      (data) => {
        

        this.loader.stop();

        this.Details = data;


        if (this.Details.Statue == true) { this.GetSaveData = this.Details.data; this.LifeCoachesDataDetail = this.GetSaveData; this.loader.stop(); }
        else { this.GetSaveData = []; this.loader.stop(); }


      }
    )
  }

  BindJourneyData() {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };


    this.http.get('api/lifecoachactivity/Bindjourneytabledata', options).subscribe(
      (data) => {
        
        this.journeydetails = data;
        this.GetSaveJourneyData = this.journeydetails.data;
        this.LifeCoachesDataDetail = this.GetSaveJourneyData;
        this.loader.stop();
      }
    )
  }

  setpageno() {
    this.page = 1;
  }

  setpagenos() {
      this.page2 = 1;
  }

  ResetlifecoachReport()
  {
    this.selectedCoachTypeData = [];
    this.usertypeids="0"
    this.selectedtype = 2;
    this.selectedtopic = 0;
    this.selectedShowtype='Gallery';
    this.GetInterviewDataInPageload();
  }

}
