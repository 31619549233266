import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as XLSX from 'xlsx';
import { PermissionService } from '../../../permission.service';


@Component({
  selector: 'app-academymaster',
  templateUrl: './academymaster.component.html',
  styleUrls: ['./academymaster.component.css'],

})
export class AcademyMaster implements OnInit {

  spinnerType = SPINNER.rectangleBounce;

  public ModalAcademyName: string = "";
  public selectedItems = [];
  public academyname: string = "";
  public careerids: string = "";
  public userid: number;

  public activityid: number;
  public academyid: number;
  public btn_text: string = "Save";

  public careerData: any;
  public resData: any;
  public savedData: any;
  public editData: any;
  public AcademyCareerData: any;
  public allsavedData: any;
  public actionsData: any;

  public searchTerm: string;
  public pageSize: number = 10;
  public page: number = 1;

  public IsAddPermission: number = 0;
  public IsModifyPermission: number = 0;
  public IsDeletePermission: number = 0;
  public IsViewPermission: number = 0;
  public IsExportPermission: number = 0;
  public IsImportPermission: number = 0;

  dropdownSettings = {};

  constructor(
    private http: HttpClient,
    private localstorage: LocalStorageService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private permission: PermissionService
  ) { }


  GetActions(): void {
    
    this.loader.start();
    this.permission.GetActionsByRoleId().subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        
        if (this.resData.status == true) {
          this.actionsData = this.resData.data;
          this.IsAddPermission = this.actionsData[0]['Permission'];
          this.IsViewPermission = this.actionsData[1]['Permission'];
          this.IsModifyPermission = this.actionsData[2]['Permission'];
          this.IsDeletePermission = this.actionsData[3]['Permission'];
          this.IsExportPermission = this.actionsData[4]['Permission'];
          this.IsImportPermission = this.actionsData[5]['Permission'];
        }
      }
    );
  }


  ngOnInit() {
    this.loader.start();
    this.userid = this.localstorage.get("userid");

    this.GetActions();
    this.GetCarrerLists();
    this.GetSavedData('');
    this.GetAllSavedData();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'careerid',
      textField: 'careername',
      enableCheckAll: false,
      allowSearchFilter: true,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 2,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: true
    };

    this.loader.stop();
  }

  setPageno() {
    this.page = 1;
  }

  Reset() {
    this.loader.start();
    this.careerData.forEach(item => { item.selected = false; });
    this.academyname = "";
    this.ModalAcademyName = "";
    this.academyid = 0;
    this.activityid = 0;
    this.btn_text = "Save";
    this.selectedItems = [];
    this.loader.stop();
  }

  GetCarrerLists() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.loader.start();
    this.http.post('api/collegerepository/BindCareer', options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        this.careerData = this.resData.data;
      }
    )
  }


  GetAllSavedData() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/academymaster/GetAllAcademyCareerData', options).subscribe(
      (data) => {
        this.resData = data;
        this.allsavedData = this.resData.academyresponses;
      }
    )
  }


  /*name of the excel-file which will be downloaded. */
  fileName = 'academycareerlist.xlsx';

  exportexcel(): void {

    let element = document.getElementById('academy-data-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }

  GetSavedData(_CareerId: string) {
    this.loader.start();
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/academymaster/GetAcademyData?careerids=' + _CareerId, options).subscribe(
      (data) => {
        this.loader.stop();
        this.resData = data;
        this.savedData = this.resData.academyresponses;
      }
    )
  }

  onDeSelect() {
    this.careerids = "";

    for (var i = 0; i < this.selectedItems.length; i++) {
      if (this.careerids === "") {
        this.careerids = this.selectedItems[i].careerid;
      }
      else {
        this.careerids = this.careerids + ',' + this.selectedItems[i].careerid;
      }
    }

    this.GetSavedData(this.careerids);
  }

  onItemSelect() {
    this.careerids = "";

    for (var i = 0; i < this.selectedItems.length; i++) {
      if (this.careerids === "") {
        this.careerids = this.selectedItems[i].careerid;
      }
      else {
        this.careerids = this.careerids + ',' + this.selectedItems[i].careerid;
      }
    }

    this.GetSavedData(this.careerids);
  }

  GetSelectedCareers() {
    this.careerids = "";
    for (var i = 0; i < this.careerData.length; i++) {
      if (this.careerData[i].selected === true) {
        if (this.careerids === "") {
          this.careerids = this.careerData[i].careerid;
        }
        else {
          this.careerids = this.careerids + ',' + this.careerData[i].careerid;
        }
      }
    }
  }

  ViewAllocatedCareers(_AcademyName: string, _AcademyId: number) {
    this.ModalAcademyName = _AcademyName;
    this.academyid = _AcademyId;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.loader.start();
    this.http.get('api/academymaster/GetCareerDataByAcademy?academyid=' + _AcademyId, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        this.AcademyCareerData = this.resData.careerresponses;
      }
    )
  }

  DeleteCareer(_CareerId: number) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    Swal.fire({
      text: 'Are you sure to Remove ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.http.get('api/academymaster/DeleteCareerData?academyid=' + this.academyid + '&careerid=' + _CareerId, options).subscribe(
          (data) => {
            this.resData = data;
            this.loader.stop();
            
            if (this.resData.status == true) {
              //Swal.fire("", this.resData.message, "success");
              this.ViewAllocatedCareers(this.ModalAcademyName, this.academyid);
              return;
            }
            else {
              //Swal.fire("", this.resData.message, "warning");
            }
          }
        )
      }
    })
  }

  OnSubmit() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    if (this.academyname == "" || this.academyname == undefined) {
      Swal.fire("", "Please enter Academy/Interest", "error");
      return;
    }

    if (this.careerids == "" || this.careerids == undefined) {
      Swal.fire("", "Please Select Career", "error");
      return;
    }
    this.loader.start();
    if (this.btn_text == "Save") {
      this.http.get('api/academymaster/SaveAcademyData?academyname=' + this.academyname + '&careers=' + this.careerids + '&userid=' + this.userid, options).subscribe(
        (data) => {
          this.resData = data;
          this.loader.stop();
          if (this.resData.status == true) {
            Swal.fire("", this.resData.message, "success");
            this.GetSavedData('');
            this.Reset();
            return;
          }
          else {
            Swal.fire("", this.resData.message, "warning");
          }
        }
      )
    }
    else {
      this.http.get('api/academymaster/UpdateAcademyData?academyname=' + this.academyname + '&careers=' + this.careerids + '&userid=' + this.userid + '&academyid=' + this.activityid, options).subscribe(
        (data) => {
          this.resData = data;
          this.loader.stop();
          if (this.resData.status == true) {
            Swal.fire("", this.resData.message, "success");
            this.GetSavedData('');
            this.Reset();
            return;
          }
          else {
            Swal.fire("", this.resData.message, "warning");
          }
        }
      )
    }


  }

  OnEdit(_AcademyId: number) {
    this.loader.start();
    this.btn_text = "Update";
    this.activityid = _AcademyId;
    this.selectedItems = [];

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    this.http.get('api/academymaster/EditAcademyData?academyid=' + this.activityid, options).subscribe(
      (data) => {
        this.resData = data;
        this.loader.stop();
        this.academyname = this.resData.academyinterestname;
        this.selectedItems = this.resData.careerDatas;

      }
    )
  }

  OnDelete(_AcademyId: number) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    Swal.fire({
      text: 'Are you sure to delete ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.http.get('api/academymaster/DeleteAcademyData?academyid=' + _AcademyId, options).subscribe(
          (data) => {
            this.resData = data;
            this.loader.stop();
            if (this.resData.status == true) {
              Swal.fire("", this.resData.message, "success");
              this.GetSavedData('');
              this.Reset();
              return;
            }
            else {
              Swal.fire("", this.resData.message, "warning");
            }
          }
        )
      }
    })

  }
}
